<swe-pageheader [header]="languageService.getItem(59)"><span swe-hits>&nbsp;(#{{id}})</span></swe-pageheader>
<div class="row d-block py-2">
  <div class="alert alert-danger" *ngIf="permissionService.user.UpdatePassword">
    {{languageService.getItem(826)}}
  </div>
  <div class="alert alert-danger" *ngIf="id>0 && userdata.NoBelonging">
    {{languageService.getItem(827)}}
  </div>
  <div class="col-12 col-md-4 float-left">
    <swe-userdata [id]="id" [(open)]="cards.data" (userdataChange)="manageUserdata($event)"></swe-userdata>
  </div>
  <div class="col-12 col-md-8 float-left" *ngIf="id>0&&permissionService.permissions.EmploymentPlan>0&&userdata.BookingAccess>0">
    <swe-userbookings [id]="id" [(open)]="cards.employmentplans" [type]="1" [userdata]="userdata"></swe-userbookings>
  </div>
  <div class="col-12 col-md-8 float-left" *ngIf="id>0&&!permissionService.permissions.HideShifts&&userdata.BookingAccess>0">
    <swe-userbookings [id]="id" [(open)]="cards.bookings" [userdata]="userdata"></swe-userbookings>
  </div>
  <div class="col-12 col-md-8 float-left" *ngIf="id>0&&userdata.UserMarkedDateAccess>0">
    <swe-useravailability [id]="id" [(open)]="cards.availability" [userdata]="userdata"></swe-useravailability>
  </div>
  <div class="col-12 col-md-4 float-left" *ngIf="id>0&&permissionService.permissions.ShowRoleLevels">
    <swe-userbelongings [id]="id" [(open)]="cards.belongings"></swe-userbelongings>
  </div>
  <div class="col-12 col-md-4 float-left" *ngIf="id>0&&userdata.BookingAccess>0&&userdata.StatisticAccess>0&&(permissionService.permissions.Standby>0||permissionService.permissions.Abscent>0||permissionService.permissions.ReplySms)">
    <swe-userstatistics [id]="id" [(open)]="cards.statistics"></swe-userstatistics>
  </div>
  <div class="col-12 col-md-4 float-left" *ngIf="id>0&&((userdata.ICalUrl&&userdata.ICalUrl.length>0)||(userdata.ICalLevelUrl&&userdata.ICalLevelUrl.length>0))">
    <swe-usericalendar [id]="id" [(open)]="cards.icalendar" [userdata]="userdata"></swe-usericalendar>
  </div>
  <ng-container *ngFor="let obj of objecttoobjects">
    <div class="col-12 col-md-8 float-left" *ngIf="id>0">
      <swe-objecttoobjects [id]="id" [data]="obj" [open]="false"></swe-objecttoobjects>
    </div>
  </ng-container>
  <div class="col-12 col-md-8 float-left" *ngIf="id>0&&userdata.ContractAccess>0">
    <swe-usercontract [id]="id" [(open)]="cards.contract" [userdata]="userdata"></swe-usercontract>
  </div>
  <div class="col-12 col-md-8 float-left" *ngIf="id>0&&userdata.LogAccess>0">
    <swe-card header="{{languageService.getItem(400)}}" [(open)]="cards.logs" [hasRefresh]="false">
      <swe-generallogs [id]="id" [(open)]="cards.systemlogs" [type]="'user'" [data]="userdata"></swe-generallogs>
      <swe-generalmessages [id]="id" [(open)]="cards.messagelogs" [type]="'user'" [data]="userdata"></swe-generalmessages>
    </swe-card>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-left] on columns-->

