<div class="d-inline-flex" (click)="change()">
  <div class="d-inline-block align-top mr-1" *ngIf="permissionService.permissions.BreakStart">
    <swe-element [type]="type" [label]="languageService.getItem(786)" [(model)]="modelStart" (modelChange)="manageBreakStart($event)" [disabled]="disabledStart" [container]="{hideSeconds: true, hideReset: true, markChanges: markChanges}" [changed]="startChanged"></swe-element>
  </div>
  <div class="d-inline-block w-25">
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(786)" [(model)]="model" (modelChange)="manageBreak($event)" [disabled]="disabled" [changed]="changed" [container]="{markChanges: markChanges}"></swe-element>
  </div>
  <div class="d-inline-block">
    <button class="btn btn-swe" style="color:black;cursor:unset;" [disabled]="true">{{languageService.getItem(786)}}</button>
    <button class="btn btn-swe" *ngIf="reset&&!disabled" [attr.title]="languageService.getItem(915)" (click)="resetBreak()"><i class="fas fa-trash swe-click" [ngClass]="{'swe-grayed-out': (model == -1)}"></i></button>
    <span class="align-middle small ml-3" *ngIf="hasBreakInfo">({{languageService.getItem(930)}})</span>
  </div>
</div>
