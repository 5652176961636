/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./color.component";
import * as i3 from "../_services/language.service";
import * as i4 from "../_services/list.service";
var styles_ColorComponent = [];
var RenderType_ColorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColorComponent, data: {} });
export { RenderType_ColorComponent as RenderType_ColorComponent };
function View_ColorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["style", "display:inline-block;width:10px;height:10px;border:1px solid #999999;"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "background-color": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.current.Color); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ColorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["style", "display:inline-block;width:10px;height:10px;border:1px solid #999999;"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "background-color": 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit.Color); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ColorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "li", [["class", "dropdown-item swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "active": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "ml-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dropdown-item swe-click"; var currVal_1 = _ck(_v, 3, 0, (_co.model == _v.context.$implicit.Id)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.Color; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.Name; _ck(_v, 7, 0, currVal_3); }); }
export function View_ColorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "dropdown d-inline-block align-top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "button", [["class", "btn btn-swe"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "btn-warning": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 5, "ul", [["class", "dropdown-menu py-0"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(10, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(11, { "show": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorComponent_2)), i0.ɵdid(13, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn btn-swe"; var currVal_2 = _ck(_v, 4, 0, _co.changed); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = (_co.current && _co.current.Color); _ck(_v, 6, 0, currVal_3); var currVal_5 = "dropdown-menu py-0"; var currVal_6 = _ck(_v, 11, 0, _co.open); _ck(_v, 10, 0, currVal_5, currVal_6); var currVal_7 = _co.items; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); var currVal_4 = _co.current.Name; _ck(_v, 7, 0, currVal_4); }); }
export function View_ColorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-color", [], null, null, null, View_ColorComponent_0, RenderType_ColorComponent)), i0.ɵdid(1, 114688, null, 0, i2.ColorComponent, [i3.LanguageService, i4.ListService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorComponentNgFactory = i0.ɵccf("swe-color", i2.ColorComponent, View_ColorComponent_Host_0, { label: "label", model: "model", items: "items", changed: "changed", disabled: "disabled" }, { modelChange: "modelChange" }, []);
export { ColorComponentNgFactory as ColorComponentNgFactory };
