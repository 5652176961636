import { Component, OnInit, OnDestroy } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { GeneralService } from '../../_services/general.service';
import { SettingService } from '../../_services/setting.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-systemsettings',
  templateUrl: './systemsettings.component.html'
})
export class SystemSettingsComponent implements OnInit, OnDestroy {

  private unsubscribe$: any[] = [
    new Subject<void>()
  ];
  private _settings: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private generalService: GeneralService,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();

    settingService.onSave$
      .pipe(takeUntil(this.unsubscribe$[0]))
      .subscribe((e) => {
        //Save
        this.save();
      }); 
  }

  ngOnDestroy() {
    this.unsubscribe$.forEach((unsubscribe) => {
      unsubscribe.next();
      unsubscribe.complete();
    });
  }

  ngOnInit() {
    this.loadSettings();
  }


  //Properties
  public get settings() {
    return this._settings;
  }


  //Methods
  public getList(setting) {

    let list:any[] = [];

    setting.List.forEach((item) => {

      list.push({ Id: item.Key, Name: item.Value });

    })

    return list;
  }
  public save() {
    this.dataService.tokenRequest('/api/v1/system/settings', 'PUT', this._settings, 'text')
      .subscribe((res) => {

        this.permissionService.reload().subscribe();

        this.alertService.Add({ type: 'success', message: res });
      });
  }
  public container(setting) {

    if (setting.Name == 'TimelineSearchDefaultTimelineShow') {
      return { isbit: true };
    }

    return {};
  }


  //Functions
  private loadSettings() {
    this.dataService.tokenRequest('/api/v1/system/settings', 'GET', {})
      .subscribe((res) => {

        this._settings = res;
      });
  }

}
