<swe-card header="{{languageService.getItem(75)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
  <swe-adminrule [rulelist]="['SweHasBookings']"></swe-adminrule>
  <div class="form-group" *ngIf="permissionService.permissions.Bookings>1">
    <button class="btn btn-primary btn-block" (click)="goto(1)"><i class="fa fa-calendar"></i>&nbsp;{{languageService.getItem(63)}}</button>
  </div>
  <swe-adminrule [rulelist]="['SweHasUserMarkedDates']"></swe-adminrule>
  <div class="form-group" *ngIf="permissionService.permissions.MarkedDates>1">
    <button class="btn btn-primary btn-block" (click)="goto(2)"><i class="fa fa-calendar-check"></i>&nbsp;{{languageService.getItem(537)}}</button>
  </div>
  <swe-adminrule [rulelist]="['SweHasUsers']"></swe-adminrule>
  <div class="form-group" *ngIf="permissionService.permissions.Users>1">
    <button class="btn btn-primary btn-block" (click)="goto(3)"><i class="fa fa-users"></i>&nbsp;{{languageService.getItem(62)}}</button>
  </div>
  <swe-adminrule [rulelist]="['SweHasLevels']"></swe-adminrule>
  <div class="form-group" *ngIf="permissionService.permissions.Levels>1">
    <button class="btn btn-primary btn-block" (click)="goto(4)"><i class="fa fa-sitemap"></i>&nbsp;{{languageService.getItem(64)}}</button>
  </div>
  <swe-adminrule [rulelist]="['SweCreateNewTimereport']"></swe-adminrule>
  <div class="form-group" *ngIf="false&&permissionService.permissions.NewTimereport>0">
    <button class="btn btn-primary btn-block" (click)="goto(5)"><i class="fa fa-clock"></i>&nbsp;{{languageService.getItem(1041)}}</button>
  </div>
  <swe-adminrule [rulelist]="['SweCreateNewAbscentTimereport']"></swe-adminrule>
  <div class="form-group" *ngIf="false&&permissionService.permissions.NewAbscentTimereport>0">
    <button class="btn btn-primary btn-block" (click)="goto(6)"><i class="fa fa-ban"></i>&nbsp;{{languageService.getItem(1042)}}</button>
  </div>
</swe-card>
