/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./history.component";
import * as i3 from "../_services/language.service";
import * as i4 from "../_services/data.service";
var styles_HistoryComponent = [];
var RenderType_HistoryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HistoryComponent, data: {} });
export { RenderType_HistoryComponent as RenderType_HistoryComponent };
function View_HistoryComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "swe-history-column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "i", [["class", "far fa-fw align-bottom"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "fa-check-square": 0, "fa-square": 1 })], function (_ck, _v) { var currVal_0 = "far fa-fw align-bottom"; var currVal_1 = _ck(_v, 4, 0, _v.parent.context.$implicit.Value, !_v.parent.context.$implicit.Value); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_HistoryComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_HistoryComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "swe-history-column"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HistoryComponent_5)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.Value.toString().split("|"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HistoryComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { "swe-history-item": 0 }), i0.ɵdid(4, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HistoryComponent_3)), i0.ɵdid(6, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HistoryComponent_4)), i0.ɵdid(8, 16384, null, 0, i1.NgSwitchDefault, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "swe-history-column"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_v.context.index > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.type; _ck(_v, 4, 0, currVal_1); var currVal_2 = "System.Boolean"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.Extra; _ck(_v, 10, 0, currVal_3); }); }
function View_HistoryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "swe-history"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HistoryComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.history; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HistoryComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "d-inline-block"]], null, null, null, null, null))], null, null); }
export function View_HistoryComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "i", [["class", "fas fa-fw fa-swe fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getHistory() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "fa-history": 0, "fa-times": 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HistoryComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HistoryComponent_6)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fas fa-fw fa-swe fa-pull-right swe-click"; var currVal_1 = _ck(_v, 3, 0, !_co.showhistory, _co.showhistory); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.showhistory; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.preventdrowning; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_HistoryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-history", [], null, null, null, View_HistoryComponent_0, RenderType_HistoryComponent)), i0.ɵdid(1, 114688, null, 0, i2.HistoryComponent, [i3.LanguageService, i4.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HistoryComponentNgFactory = i0.ɵccf("swe-history", i2.HistoryComponent, View_HistoryComponent_Host_0, { propertyId: "propertyId", objectId: "objectId", type: "type", preventdrowning: "preventdrowning" }, {}, []);
export { HistoryComponentNgFactory as HistoryComponentNgFactory };
