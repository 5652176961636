<div class="d-inline" (click)="open($event)">
  <!-- Trigger modal -->
  <ng-content>
  </ng-content>
</div>

<!-- Modal -->
<div class="modal fade" [ngClass]="{'d-block show': visible}">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <a href="javascript:void(0);" class="swe-no-link" (click)="close()"><i class="fas fa-fw fa-times fa-pull-right"></i></a>
      </div>
      <div class="modal-body text-center">
        <button type="button" class="btn btn-primary mr-2" (click)="accept(1, $event)" [disabled]="affectAllOnly" #btnAccept>{{languageService.getItem(406)}}</button>
        <button type="button" class="btn btn-primary mr-2" (click)="accept(2, $event)" [disabled]="affectAllOnly">{{languageService.getItem(407)}}</button>
        <button type="button" class="btn btn-primary mr-2" (click)="accept(3, $event)">{{languageService.getItem(408)}}</button>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="visible"></div>
