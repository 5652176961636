import { Component, Input, EventEmitter, Output, OnInit, OnChanges } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { AlertService } from '../_services/alert.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';



@Component({
  selector: 'swe-document',
  templateUrl: './document.component.html',
})
export class DocumentComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() changed: boolean;
  @Input() clientOnly: boolean = false;
  @Input() encoding: string;
  @Input() archive: any[] = [];
  @Input() allowedPrefix: string;
  @Input() folder: string;
  @Input() document: any;
  @Input() showimage: boolean;
  @Input() showdocument: boolean;
  @Input() hidedownload: boolean = false;
  @Input() reload: boolean = false;
  @Input() writeAccess: boolean = true;

  private _changestatus: string = 'fa-times';
  private _markedasdelete: string = '';
  private _old: any;
  private _name: string = '';
  private _doc: string = '';
  private _help: string = '';
  private _toggable: boolean = true;
  private _lastitem: any;
  private _showupload: boolean = false;
  private _uploadhover: boolean = false;
  private _deletehover: boolean = false;
  private _closerhover: boolean = false;

  constructor(public languageService: LanguageService, private alertService: AlertService, private dataService: DataService, private settingService: SettingService) {

  }
  

  ngOnInit() {

    if (this.model) {
      this._old = this.model;

      let doc = this.model.split('|');
      this._name = doc[0];
      if (doc.length > 1) {
        this._doc = doc[1];
      }
    }

    if (typeof this.allowedPrefix != 'undefined' && this.allowedPrefix != null && this.allowedPrefix.length > 0) {
      this._help = this.languageService.getItem(800).replace('{0}', this.allowedPrefix);
    }
  }

  ngOnChanges() {
    if (this.reload && this._lastitem) {
      this.upload(this._lastitem);
    }
  }


  /*Properties*/
  public get changestatus() {
    return this._changestatus;
  }
  public get markedasdelete() {
    return this._markedasdelete;
  }
  public get name() {
    return this._name;
  }
  public set name(value) {
    this._name = value;
  }
  public get showupload() {
    return this._showupload;
  }
  public set showupload(value) {
    this._showupload = value;
  }
  public get uploadhover() {
    return this._uploadhover;
  }
  public set uploadhover(value) {
    this._uploadhover = value;
  }
  public get deletehover() {
    return this._deletehover;
  }
  public set deletehover(value) {
    this._deletehover = value;
  }
  public get closerhover() {
    return this._closerhover;
  }
  public set closerhover(value) {
    this._closerhover = value;
  }
  public get toggable() {
    return this._toggable;
  }
  public get old() {
    return this._old;
  }



  /*Methods*/
  public changefile(e) {
    let item = e.target.files[0];
    if (item) {
      this.upload(item);
    }
    else {
      this.keep();
    }
  }
  public upload(item) {

    if (typeof this.allowedPrefix != 'undefined' && this.allowedPrefix != null && this.allowedPrefix.length > 0) {
      let parts = item.name.split('.');
      let extension = '';
      if (parts.length > 1) {
        extension = parts[parts.length - 1];
      }
      let found = false;
      this.allowedPrefix.split(',').forEach((prfx) => {
        if (prfx.trim().toLowerCase() == extension.toLowerCase()) {
          found = true;
        }
      });

      if (!found) {
        this.alertService.Add({ message: this._help, type: 'danger' });
        return;
      }
    }


    this._lastitem = item;

    let reader = new FileReader();
    reader.onload = (loadEvent) => {
      
        //Must remove old document before add new
        this.remove();

        if (!this.clientOnly) {
          //To Server
          this.model = item.name + "|" + reader.result;
          this.modelChange.emit(this.model);
        }
        else {
          //To Client
          this.model = reader.result;
          this.modelChange.emit(this.model);
        }
      
    }

    if (!this.clientOnly) {
      //To Server
      reader.readAsDataURL(item);
    }
    else {
      //To Client
      if (this.encoding && this.encoding.length > 0) {
        //With encoding
        reader.readAsText(item, this.encoding);
      }
      else {
        //Default encoding
        reader.readAsText(item);
      }
    }
  }
  public download() {

    this.dataService.imageRequest('/api/v1/files/' + this.folder + '/' + this.document)
      .subscribe(res => {

        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = (e) => {

          let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          let hiddenElement = document.createElement('a');
          if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
            //IE
            navigator.msSaveOrOpenBlob(res, this._name);
          }
          else if (typeof hiddenElement.download != 'undefined' && !isSafari) {
            //Support HTML5 download attribute (Chrome 14+, Firefox20+, IE13+, Opera15+)
            hiddenElement.href = reader.result.toString();
            hiddenElement.target = '_blank';
            hiddenElement.download = this._name;

            document.body.appendChild(hiddenElement);

            hiddenElement.click();
          }
          else {
            let url = '';
            try {
              let fileArr = reader.result.toString().split(';');
              let blob = this.settingService.b64toBlob(fileArr[1].replace("base64,", ""), fileArr[0].replace("data:", ""));

              url = URL.createObjectURL(blob);
            }
            catch (e) {
              //Final solution
              let is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
              url = is_chrome_ios ? reader.result.toString() : reader.result.toString().replace(/^data:[^;]*;/, 'data:attachment/file;');
            }

            var popup = window.open(url, '_blank');
            if (!popup) {
              window.location.href = url;
            }
          }
        }

      });
  }
  public delete() {
    this.modelChange.emit(null);
  }
  public toggle() {

    if (this.markedasdelete.length == 0) {
      this.remove();
    }
    else {
      this.keep();
    }
  }
  public change() {

    this._doc = this._name;
    this.model = this._name + '|' + this._doc;

    this._toggable = (this.model == this._old);
  };



  /*Functions*/
  private keep() {
    this._changestatus = 'fa-times';
    this._markedasdelete = 'none';
    this.model = this._old;

    this.modelChange.emit(this.model);
  }
  private remove() {
    this._changestatus = 'fa-check';
    this._markedasdelete = 'line-through';
    this.model = '';

    this.modelChange.emit(null);
  }
}
