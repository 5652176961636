<swe-card header="{{languageService.getItem(32)}}" [(open)]="open" (openChange)="load()" (refreshChange)="load()" [focus]="true" [hasSave]="user.Access>1" (saveChange)="save()">
  <span swe-rightcommand>
    <i class="fas fa-eye fa-pull-right" *ngIf="user.IsWatching" [attr.title]="languageService.getItem(944)"></i>
    <a class="swe-no-link" href="javascript:void(0);" (click)="send(3, $event)" [attr.title]="languageService.getItem(320)" *ngIf="user&&user.MessageAccess>0&&hasCell"><i class="fas fa-fw fa-mobile-alt fa-pull-right"></i></a>
    <a class="swe-no-link" href="javascript:void(0);" (click)="send(1, $event)" [attr.title]="languageService.getItem(321)" *ngIf="user&&user.MessageAccess>0&&hasEmail"><i class="fas fa-fw fa-envelope fa-pull-right"></i></a>
  </span>
  <div *ngIf="!loading&&permissionService.permissions.UserImage">
    <swe-element [statusLabel]="0" [(model)]="user.Image" (modelChange)="upload($event)" [label]="languageService.getItem(195)" [container]="documentcontainer(user.Image)" [type]="'Internetplatsen.Document'"></swe-element>
  </div>
  <div *ngIf="!loading">
    <swe-profile [(model)]="user.Profile" (modelChange)="profiledone()" [id]="id"></swe-profile>
  </div>
  <swe-card [header]="languageService.getItem(112)" [color]="'swe'" [open]="false" [hasRefresh]="false" *ngIf="!loading&&user.AdminAccess>0">
    <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(343)" [statusLabel]="1" [(model)]="user.IsActive" (modelChange)="activation()" [disabled]="user.AdminAccess<2"></swe-element>
    <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(781)" [statusLabel]="1" [(model)]="user.IsLockedOut" [disabled]="user.AdminAccess<2"></swe-element>
    <swe-element [type]="'System.LevelSearch'" [label]="languageService.getItem(950)" [statusLabel]="1" [(model)]="user.MainLevelId" [(display)]="user.MainLevel" [disabled]="user.AdminAccess<2" [optional]="true"></swe-element>
    <swe-element [type]="'System.Text'" [label]="languageService.getItem(1044)" [statusLabel]="1" [(model)]="user.ExternalId" [disabled]="user.AdminAccess<2"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(909)" [statusLabel]="1" [(model)]="user.FailedLogin" [access]="1"></swe-element>
    <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(910)" [statusLabel]="1" [(model)]="user.Created" [access]="1"></swe-element>
    <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(911)" [statusLabel]="1" [(model)]="user.LastModified" [access]="1"></swe-element>
    <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(912)" [statusLabel]="1" [(model)]="user.LastLogin" [access]="1"></swe-element>
    <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(913)" [statusLabel]="1" [(model)]="user.LastShift" [access]="1"></swe-element>
    <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(914)" [statusLabel]="1" [(model)]="user.LastBooking" [access]="1"></swe-element>
  </swe-card>
  <div *ngIf="!loading">
    <!--Buttons-->
    <button class="btn btn-primary btn-block" (click)="save()" *ngIf="user.Access>1" #sweSaveElement><i class="fas fa-fw fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
    <swe-confirm *ngIf="user.Id>0 && user.DeleteAccess>0" [body]="languageService.getItem(192)" (acceptChange)="delete()">
      <button class="btn btn-danger btn-block mt-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>
