/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../layout/pageheader.component.ngfactory";
import * as i2 from "../layout/pageheader.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./details/messagedata.component.ngfactory";
import * as i6 from "./details/messagedata.component";
import * as i7 from "../_services/language.service";
import * as i8 from "../_services/datetime.service";
import * as i9 from "../_services/data.service";
import * as i10 from "../_services/setting.service";
import * as i11 from "./details/messagereceivers.component.ngfactory";
import * as i12 from "./details/messagereceivers.component";
import * as i13 from "../_services/permission.service";
import * as i14 from "../_services/alert.service";
import * as i15 from "./messagedetail.component";
var styles_MessageDetailComponent = [];
var RenderType_MessageDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageDetailComponent, data: {} });
export { RenderType_MessageDetailComponent as RenderType_MessageDetailComponent };
export function View_MessageDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "swe-pageheader", [], null, null, null, i1.View_PageHeaderComponent_0, i1.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageHeaderComponent, [i3.Location, i4.ActivatedRoute, i4.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["swe-hits", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\u00A0(#", ")"])), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "col-12 col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "swe-messagedata", [], null, null, null, i5.View_MessageDataComponent_0, i5.RenderType_MessageDataComponent)), i0.ɵdid(7, 114688, null, 0, i6.MessageDataComponent, [i7.LanguageService, i8.DateTimeService, i9.DataService, i10.SettingService], { id: [0, "id"], category: [1, "category"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "col-12 col-md-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "swe-messagereceivers", [], null, null, null, i11.View_MessageReceiversComponent_0, i11.RenderType_MessageReceiversComponent)), i0.ɵdid(10, 114688, null, 0, i12.MessageReceiversComponent, [i7.LanguageService, i8.DateTimeService, i13.PermissionService, i9.DataService, i14.AlertService], { id: [0, "id"], category: [1, "category"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(345); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.id; var currVal_3 = _co.category; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _co.id; var currVal_5 = _co.category; _ck(_v, 10, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.id; _ck(_v, 3, 0, currVal_1); }); }
export function View_MessageDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-messagedetail", [], null, null, null, View_MessageDetailComponent_0, RenderType_MessageDetailComponent)), i0.ɵdid(1, 114688, null, 0, i15.MessageDetailComponent, [i7.LanguageService, i8.DateTimeService, i10.SettingService, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageDetailComponentNgFactory = i0.ɵccf("app-messagedetail", i15.MessageDetailComponent, View_MessageDetailComponent_Host_0, {}, {}, []);
export { MessageDetailComponentNgFactory as MessageDetailComponentNgFactory };
