import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { DateTimeService } from '../_services/datetime.service';


@Component({
  selector: 'swe-generallogs',
  templateUrl: './generallogs.component.html'
})
export class GeneralLogsComponent implements OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;
  @Input() data: any;
  @Input() type: string = "";

  private unsubsribe$ = new Subject<void>();
  private _logs = [];
  private _more: boolean = false;
  private _hits: number = 0;
  private _multiple: number = 1;
  private _loading: boolean = false;
  private _logstart: Date;
  private _logend: Date = new Date(new Date().getFullYear() + 4, 11, 31);

  constructor(public languageService: LanguageService, public dateTimeService: DateTimeService, private dataService: DataService, private settingService: SettingService) {

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }
  
  ngOnChanges() {
    if (this.data && this.data.Created) {
      this._logstart = new Date(this.data.Created);
      if (this.open) {
        this.search(false);
      }
    }
  }
  

  /*Properties*/
  public get logs() {
    return this._logs;
  }
  public get more(): boolean {
    return this._more;
  }
  public get hits(): number {
    return this._hits;
  }
  public get loading(): boolean {
    return this._loading;
  }


  /*Methods*/
  public search(getmore: boolean = false) {

    this._more = false;

    let top = 25;
    
    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._logs = [];
    }

    let filter = {
      Top: top,
      Multiple: this._multiple,
      //Logs
      Start: this._logstart,
      End: this._logend,
      Objects: this.settingService.log.logobject,
      Types: this.settingService.log.logtype,
      ShowAuto: this.settingService.log.showauto
    };

    if (this.type == "user") {
      filter["UserId"] = this.id;
    }
    else if (this.type == "booking") {
      filter["BookingId"] = this.id;
      filter["ShowAuto"] = true;
    }
    else if (this.type == "level") {
      filter["LevelId"] = this.id;
    }
    else {
      return;
    }

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/logs/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.Logs.forEach(log => {
            this._logs.push(log);
          });

          this._more = res.More;
          this._hits = this._logs.length;
          
          this._loading = false;
        }
      });
  }
  

}
