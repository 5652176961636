import { Component, Input, EventEmitter, Output, OnInit, OnChanges } from '@angular/core';

import { DateTimeService } from '../_services/datetime.service';
import { SettingService } from '../_services/setting.service';

@Component({
  selector: 'swe-datetime',
  templateUrl: './datetime.component.html',
})
export class DateTimeComponent implements OnInit, OnChanges {

  @Input() markChanges: boolean;
  @Input() undoChanges: boolean = false;
  @Input() startChanged: boolean;
  @Input() endChanged: boolean;
  @Input() hideStart: boolean;
  @Input() labelStart: string;
  @Input() start: any;
  @Output() startChange = new EventEmitter<any>();
  @Input() hideEnd: boolean;
  @Input() labelEnd: string;
  @Input() end: any;
  @Output() endChange = new EventEmitter<any>();
  @Input() hideDate: boolean;
  @Input() hideTime: boolean;
  @Input() hideReset: boolean;
  @Input() disabled: boolean;
  @Input() hideSeconds: boolean = false;
  @Input() alwaysshowcontents: boolean;

  private originalstart: any;
  private originalend: any;
  private laststart: any;
  private lastend: any;
  private startISO: string;
  private endISO: string;
  
  constructor(
    private dateTimeService: DateTimeService,
    private settingService: SettingService
  ) {

  }


  ngOnInit() {
    if (this.start) {
      this.originalstart = this.laststart = this.start;
      this.startISO = this.dateTimeService.format(new Date(this.start), 'yyyy-MM-ddTHH:mm');
    }
    if (this.end) {
      this.originalend = this.lastend = this.end;
      this.endISO = this.dateTimeService.format(new Date(this.end), 'yyyy-MM-ddTHH:mm');
    }
  }

  ngOnChanges() {
    if (this.undoChanges) {
      this.originalstart = null;
      this.originalend = null;
      this.startChanged = false;
      this.endChanged = false;
    }
  }


  /*Properties*/
  public get startAsISO(): string {
    //if (this.start != null) {
    //  return this.dateTimeService.format(new Date(this.start), 'yyyy-MM-ddTHH:mm');
    //}
      
    //return null;
    return this.startISO;
  }
  public set startAsISO(val) {
    if (val != null) {
      this.start = this.dateTimeService.formatWithTimeZone(new Date(val));
      this.startISO = val; 
    }
    else {
      this.start = null;
      this.startISO = null;
    }
  }
  public get startAsDateString(): string {
    
    if (this.start != null) {
      return this.dateTimeService.format(new Date(this.start), 'yyyy-MM-dd');
    }

    return null;
  }
  public set startAsDateString(val) {
    
    if (val != null && val.length > 0) {
      this.start = this.dateTimeService.formatWithTimeZone(new Date(val));
    }
    else {
      this.start = null;
    }
  }
  public get startAsTimeString(): string {
    if (this.start != null) {
      return this.dateTimeService.format(new Date(this.start), 'HH:mm');
    }

    return null;
  }
  public set startAsTimeString(val) {
    if (val != null) {
      let timeArr = val.split(':');
      if (timeArr.length == 3) {
        this.start = this.dateTimeService.formatWithTimeZone(new Date(1900, 1, 1, Number(timeArr[0]), Number(timeArr[1]), Number(timeArr[2])));
      }
      else if (timeArr.length == 2) {
        this.start = this.dateTimeService.formatWithTimeZone(new Date(1900, 1, 1, Number(timeArr[0]), Number(timeArr[1])));
      }
      else {
        this.start = null;
      }
    }
    else {
      this.start = null;
    }
  }
  public get endAsISO(): string {
    //if (this.end != null) {
    //  return this.dateTimeService.format(new Date(this.end), 'yyyy-MM-ddTHH:mm');
    //}

    //return null;
    return this.endISO;
  }
  public set endAsISO(val) {
    if (val != null) {
      this.end = this.dateTimeService.formatWithTimeZone(new Date(val));
      this.endISO = val;
    }
    else {
      this.end = null;
      this.endISO = null;
    }
  }
  public get endAsDateString(): string {
    if (this.end != null) {
      return this.dateTimeService.format(new Date(this.end), 'yyyy-MM-dd');
    }

    return null;
  }
  public set endAsDateString(val) {
    if (val != null) {
      this.end = this.dateTimeService.formatWithTimeZone(new Date(val));
    }
    else {
      this.end = null;
    }
  }
  public get endAsTimeString(): string {
    if (this.end != null) {
      return this.dateTimeService.format(new Date(this.end), 'HH:mm');
    }

    return null;
  }
  public set endAsTimeString(val) {
    if (val != null) {
      let timeArr = val.split(':');
      if (timeArr.length == 3) {
        this.end = this.dateTimeService.formatWithTimeZone(new Date(1900, 1, 1, Number(timeArr[0]), Number(timeArr[1]), Number(timeArr[2])));
      }
      else if (timeArr.length == 2) {
        this.end = this.dateTimeService.formatWithTimeZone(new Date(1900, 1, 1, Number(timeArr[0]), Number(timeArr[1])));
      }
      else {
        this.end = null;
      }
    }
    else {
      this.end = null;
    }
  }


  /*Methods*/
  public reset() {
    //Only visible when End is not included
    this.start = null;
    //Bubble event
    this.startChange.emit(this.start);
  }
  public startHasChanged() {
    
    let dtLast = new Date(this.laststart);
    let dtStart = new Date(this.start);
    let dtEnd = new Date(this.end);

    if (dtStart.getTime() > dtEnd.getTime() && !this.settingService.isDevice(['xs'])) {
      let diff = dtStart.getTime() - dtLast.getTime();
      this.end = this.dateTimeService.formatWithTimeZone(new Date(dtEnd.getTime() + diff));
      this.endISO = this.dateTimeService.format(new Date(this.end), 'yyyy-MM-ddTHH:mm');

      //Bubble event
      this.endChange.emit(this.end);
    }
    
    //Bubble event
    this.startChange.emit(this.start);

    //Save to future compare
    this.laststart = this.start;
    this.lastend = this.end;

    //Changed from original value
    if (this.markChanges) {
      this.startChanged = (typeof this.originalstart != 'undefined' && this.originalstart != this.start);
      this.endChanged = (typeof this.originalend != 'undefined' && this.originalend != this.end);
    }
  }
  public endHasChanged() {

    /*Remove because of problems when changing time before date issues*/

    //let dtLast = new Date(this.lastend);
    //let dtStart = new Date(this.start);
    //let dtEnd = new Date(this.end);

    //if (dtStart.getTime() > dtEnd.getTime() && !this.settingService.isDevice(['xs'])) {
    //  let diff = dtLast.getTime() - dtEnd.getTime();
    //  this.start = this.dateTimeService.formatWithTimeZone(new Date(dtStart.getTime() - diff));
    //  this.startISO = this.dateTimeService.format(new Date(this.start), 'yyyy-MM-ddTHH:mm');

    //  //Bubble event
    //  this.startChange.emit(this.start);
    //}

    //Bubble event
    this.endChange.emit(this.end);

    //Save to future compare
    this.laststart = this.start;
    this.lastend = this.end;

    //Changed from original value
    if (this.markChanges) {
      this.startChanged = (typeof this.originalstart != 'undefined' && this.originalstart != this.start);
      this.endChanged = (typeof this.originalend != 'undefined' && this.originalend != this.end);
    }
  }
}
