<swe-pageheader [header]="languageService.getItem(410)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-left">
    <swe-card header="{{languageService.getItem(51)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
      <!--Status-->
      <swe-element [(model)]="status" [label]="languageService.getItem(413)" [statusLabel]="2" [items]="generalService.markeddatetypes" [optional]="true" [type]="'System.List'"></swe-element>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)" [statusLabel]="2" [properties]="properties" [items]="chosenproperties"></swe-properties>
      <!--Buttons-->
      <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
        <button class="btn btn-primary btn-block mb-2"><i class="fas fa-fw fa-clone"></i>&nbsp;{{languageService.getItem(672)}}</button>
      </swe-confirm>
      <swe-confirm [body]="languageService.getItem(675)" (acceptChange)="delete()">
        <button class="btn btn-danger btn-block mb-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(674)}}</button>
      </swe-confirm>
    </swe-card>
  </div>
</div>  
