import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { AlertService } from '../../../_services/alert.service';
import { SettingService } from '../../../_services/setting.service';
import { GeneralService } from '../../../_services/general.service';



@Component({
  selector: 'swe-contractdata',
  templateUrl: './contractdata.component.html'
})
export class ContractDataComponent implements OnInit {

  @Input() id: number;
  @Output() contractdataChange = new EventEmitter<any>();

  private _contract: any = {};
  private _timebanks: any[] = [];
  private _las: any[] = [];
  
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private router: Router,
    private location: Location
  ) {

    
    
  }


  ngOnInit() {

    this.init();
    this.load();

  }



  //Properties
  public get contract() {
    return this._contract;
  }
  public get timebanks() {
    return this._timebanks;
  }
  public get las() {
    return this._las;
  }
  




  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/contracts/' + this.id, 'GET', {})
      .subscribe((res) => {

        this._contract = res;

        this.contractdataChange.emit({
          ContractId: this._contract.Id,
          TimeBankId: this._contract.TimeBankId,
          LasId: this._contract.LasId,
          OBAccess: this._contract.OBAccess,
          ATKAccess: this._contract.ATKAccess,
          TimeBankAccess: this._contract.TimeBankAccess,
          LasAccess: this._contract.LasAccess
        });
      });

  }
  public save() {

    let verb = 'Post';
    let path = '/api/v1/contracts/'
    if (this.id > 0) {
      verb = 'Put';
      path += this.id;
    }

    this.dataService.tokenRequest(path, verb, this._contract, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
        }

        this.load();
      });

  };

  public delete() {

    this.dataService.tokenRequest('/api/v1/contracts/' + this.id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  };
  



  //Functions
  private init() {

    this.generalService.timebanks.forEach((timebank) => {

      this._timebanks.push({ Id: timebank.Key, Name: timebank.Value });

    });

    this.generalService.las.forEach((las) => {

      this._las.push({ Id: las.Key, Name: las.Value });

    });
  }

}
