<h1>Översikt</h1>
<div class="row py-2">
  <div class="col-12 col-md-6">
    <div class="card border-light shadow">
      <div class="card-body">
        <h2 class="card-title">Rubrik 1</h2>
        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        <a href="#" class="btn btn-primary">Knapp 1</a>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div class="card border-light shadow">
      <div class="card-body">
        <h2 class="card-title">Rubrik 2</h2>
        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        <a href="#" class="btn btn-success">Knapp 2</a>
      </div>
    </div>
  </div>
</div>
<div class="row py-2">
  <div class="col-12 col-md-6">
    <div class="card border-light shadow">
      <div class="card-body">
        <h2 class="card-title">Rubrik 3</h2>
        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        <a href="#" class="btn btn-warning">Knapp 3</a>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div class="card border-light shadow">
      <div class="card-body">
        <h2 class="card-title">Rubrik 4</h2>
        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        <a href="#" class="btn btn-danger">Knapp 4</a>
      </div>
    </div>
  </div>
</div>
<div class="row py-2">
  <div class="col-12">
    <div class="card border-light shadow">
      <div class="card-body">
        <h2 class="card-title">Lista</h2>
        <div class="list-group">
          <a class="list-group-item d-flex flex-wrap">
            <div class="col-2">Kolumn 1</div>
            <div class="col-2">Kolumn 2</div>
            <div class="col-2">Kolumn 3</div>
            <div class="col-2">Kolumn 4</div>
            <div class="col-2">Kolumn 5</div>
            <div class="col-2">Kolumn 6</div>
          </a>
          <a class="list-group-item d-flex flex-wrap">
            <div class="col-2">Kolumn 1</div>
            <div class="col-2">Kolumn 2</div>
            <div class="col-2">Kolumn 3</div>
            <div class="col-2">Kolumn 4</div>
            <div class="col-2">Kolumn 5</div>
            <div class="col-2">Kolumn 6</div>
          </a>
          <a class="list-group-item d-flex flex-wrap">
            <div class="col-2">Kolumn 1</div>
            <div class="col-2">Kolumn 2</div>
            <div class="col-2">Kolumn 3</div>
            <div class="col-2">Kolumn 4</div>
            <div class="col-2">Kolumn 5</div>
            <div class="col-2">Kolumn 6</div>
          </a>
          <a class="list-group-item d-flex flex-wrap">
            <div class="col-2">Kolumn 1</div>
            <div class="col-2">Kolumn 2</div>
            <div class="col-2">Kolumn 3</div>
            <div class="col-2">Kolumn 4</div>
            <div class="col-2">Kolumn 5</div>
            <div class="col-2">Kolumn 6</div>
          </a>
          <a class="list-group-item d-flex flex-wrap">
            <div class="col-2">Kolumn 1</div>
            <div class="col-2">Kolumn 2</div>
            <div class="col-2">Kolumn 3</div>
            <div class="col-2">Kolumn 4</div>
            <div class="col-2">Kolumn 5</div>
            <div class="col-2">Kolumn 6</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row py-2">
  <div class="col-12">
    <div class="card border-light shadow">
      <div class="card-body">
        <h2 class="card-title">Tabell</h2>
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Förnamn</th>
                <th>Efternamn</th>
                <th>E-post</th>
                <th>Mobil</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Janne</td>
                <td>Egemalm</td>
                <td>janne@egemalm.com</td>
                <td>070-6779676</td>
              </tr>
              <tr>
                <td>Janne</td>
                <td>Egemalm</td>
                <td>janne@egemalm.com</td>
                <td>070-6779676</td>
              </tr>
              <tr>
                <td>Janne</td>
                <td>Egemalm</td>
                <td>janne@egemalm.com</td>
                <td>070-6779676</td>
              </tr>
              <tr>
                <td>Janne</td>
                <td>Egemalm</td>
                <td>janne@egemalm.com</td>
                <td>070-6779676</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
  </div>
</div>
<div class="alert alert-success" role="alert">
  This is a success alert—check it out!
</div>
<div class="alert alert-danger" role="alert">
  This is a danger alert—check it out!
</div>
<div class="alert alert-warning" role="alert">
  This is a warning alert—check it out!
</div>
