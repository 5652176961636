/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./multiple.component";
import * as i4 from "../_services/property.service";
import * as i5 from "../_services/language.service";
var styles_MultipleComponent = [];
var RenderType_MultipleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MultipleComponent, data: {} });
export { RenderType_MultipleComponent as RenderType_MultipleComponent };
function View_MultipleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i1.NgSelectOption, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i1.ɵangular_packages_forms_forms_y, [i0.ElementRef, i0.Renderer2, [2, i1.SelectMultipleControlValueAccessor]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.Id || _v.context.$implicit.Key) || 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.Id || _v.context.$implicit.Key) || 0); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getName(_v.context.$implicit); _ck(_v, 3, 0, currVal_2); }); }
export function View_MultipleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "select", [["class", "form-control"], ["multiple", ""]], [[1, "aria-label", 0], [4, "height", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onChange($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.value = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = (_co.manageChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "bg-warning": 0 }), i0.ɵdid(4, 16384, null, 0, i1.SelectMultipleControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.SelectMultipleControlValueAccessor]), i0.ɵdid(6, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(8, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleComponent_1)), i0.ɵdid(10, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = "form-control"; var currVal_10 = _ck(_v, 3, 0, _co.changed); _ck(_v, 2, 0, currVal_9, currVal_10); var currVal_11 = _co.disabled; var currVal_12 = _co.value; _ck(_v, 6, 0, currVal_11, currVal_12); var currVal_13 = _co.items; _ck(_v, 10, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; var currVal_1 = _co.cssheight; var currVal_2 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 8).ngClassValid; var currVal_7 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_MultipleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-multiple", [], null, null, null, View_MultipleComponent_0, RenderType_MultipleComponent)), i0.ɵdid(1, 638976, null, 0, i3.MultipleComponent, [i4.PropertyService, i5.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultipleComponentNgFactory = i0.ɵccf("swe-multiple", i3.MultipleComponent, View_MultipleComponent_Host_0, { label: "label", model: "model", propertyId: "propertyId", items: "items", changed: "changed", disabled: "disabled", valueAsString: "valueAsString", isbit: "isbit", min: "min", max: "max" }, { modelChange: "modelChange" }, []);
export { MultipleComponentNgFactory as MultipleComponentNgFactory };
