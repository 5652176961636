/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../element/levelsearch.component.ngfactory";
import * as i2 from "../../element/levelsearch.component";
import * as i3 from "../../_services/language.service";
import * as i4 from "../../_services/permission.service";
import * as i5 from "../../_services/setting.service";
import * as i6 from "../../_services/data.service";
import * as i7 from "../../layout/card.component.ngfactory";
import * as i8 from "../../layout/card.component";
import * as i9 from "../../layout/element.component.ngfactory";
import * as i10 from "../../layout/element.component";
import * as i11 from "../../_services/authentication.service";
import * as i12 from "../../_services/property.service";
import * as i13 from "../../_services/datetime.service";
import * as i14 from "../../_services/list.service";
import * as i15 from "@angular/common";
import * as i16 from "./userbelongings.component";
import * as i17 from "../../_services/alert.service";
import * as i18 from "@angular/router";
var styles_UserBelongingsComponent = [];
var RenderType_UserBelongingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserBelongingsComponent, data: {} });
export { RenderType_UserBelongingsComponent as RenderType_UserBelongingsComponent };
function View_UserBelongingsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-levelsearch", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = (_co.addLevel($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_LevelSearchComponent_0, i1.RenderType_LevelSearchComponent)), i0.ɵdid(1, 770048, null, 0, i2.LevelSearchComponent, [i3.LanguageService, i4.PermissionService, i5.SettingService, i6.DataService], { chosen: [0, "chosen"], onlyone: [1, "onlyone"], type: [2, "type"] }, { modelChange: "modelChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.levels; var currVal_1 = false; var currVal_2 = "User"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_UserBelongingsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-levelsearch", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.level = $event) !== false);
        ad = (pd_0 && ad);
    } if (("modelChange" === en)) {
        var pd_1 = (_co.add() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_LevelSearchComponent_0, i1.RenderType_LevelSearchComponent)), i0.ɵdid(1, 770048, null, 0, i2.LevelSearchComponent, [i3.LanguageService, i4.PermissionService, i5.SettingService, i6.DataService], { model: [0, "model"], chosen: [1, "chosen"], onlyone: [2, "onlyone"], type: [3, "type"], keepOpen: [4, "keepOpen"] }, { modelChange: "modelChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.level; var currVal_1 = _co.levels; var currVal_2 = true; var currVal_3 = "User"; var currVal_4 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_UserBelongingsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(100); _ck(_v, 1, 0, currVal_0); }); }
function View_UserBelongingsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "swe-card", [], null, null, null, i7.View_CardComponent_0, i7.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i8.CardComponent, [i3.LanguageService], { header: [0, "header"], color: [1, "color"], hasCollapse: [2, "hasCollapse"], hasRefresh: [3, "hasRefresh"] }, null), (_l()(), i0.ɵeld(2, 0, null, 2, 1, "span", [["swe-rightcommand", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-fw fa-times fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.edit = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, 3, 2, "div", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.role = $event) !== false);
        ad = (pd_0 && ad);
    } if (("modelChange" === en)) {
        var pd_1 = (_co.addRole($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_ElementComponent_0, i9.RenderType_ElementComponent)), i0.ɵdid(6, 638976, null, 0, i10.ElementComponent, [i11.AuthenticationService, i4.PermissionService, i12.PropertyService, i13.DateTimeService, i14.ListService, i3.LanguageService], { label: [0, "label"], type: [1, "type"], items: [2, "items"], model: [3, "model"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵeld(7, 0, null, 3, 4, "div", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_2)), i0.ɵdid(9, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_3)), i0.ɵdid(11, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, 3, 2, "div", [["class", "list-group mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_4)), i0.ɵdid(14, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.languageService.getItem(100), ""); var currVal_1 = "swe"; var currVal_2 = false; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.languageService.getItem(82); var currVal_5 = "System.List"; var currVal_6 = _co.availableroles; var currVal_7 = _co.role; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = !_co.advanced; _ck(_v, 9, 0, currVal_8); var currVal_9 = _co.advanced; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.advanced; _ck(_v, 14, 0, currVal_10); }, null); }
function View_UserBelongingsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-times fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeRole(_v.parent.context.$implicit.Id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_UserBelongingsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-action"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_7)), i0.ɵdid(3, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.Access > 2); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.Name; _ck(_v, 1, 0, currVal_0); }); }
function View_UserBelongingsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "list-group mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_6)), i0.ɵdid(4, 278528, null, 0, i15.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.roles; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(82); _ck(_v, 2, 0, currVal_0); }); }
function View_UserBelongingsComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-times fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeLevel(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_UserBelongingsComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "list-group-item list-group-item-action"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "swe-grayed-out"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_10)), i0.ɵdid(5, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = (_v.context.$implicit.Access > 2); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.Path; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.Name; _ck(_v, 3, 0, currVal_1); }); }
function View_UserBelongingsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "list-group mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_9)), i0.ɵdid(4, 278528, null, 0, i15.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.levels; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(3); _ck(_v, 2, 0, currVal_0); }); }
function View_UserBelongingsComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", "\u00A0"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.Role.Name; _ck(_v, 1, 0, currVal_0); }); }
function View_UserBelongingsComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-times swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeRole(_v.parent.context.$implicit.Role.Id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_UserBelongingsComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-times fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.parent.parent.context.$implicit.Role.Id, _v.parent.context.$implicit.Id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_UserBelongingsComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "list-group-item list-group-item-action"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "swe-grayed-out"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_16)), i0.ɵdid(5, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = (_v.context.$implicit.Access > 2); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.Path; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.Name; _ck(_v, 3, 0, currVal_1); }); }
function View_UserBelongingsComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "list-group mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_13)), i0.ɵdid(3, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_14)), i0.ɵdid(5, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_15)), i0.ɵdid(7, 278528, null, 0, i15.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.Role; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.context.$implicit.Role && (_v.context.$implicit.Role.Access > 2)); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.Levels; _ck(_v, 7, 0, currVal_2); }, null); }
function View_UserBelongingsComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_12)), i0.ɵdid(2, 278528, null, 0, i15.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.connections; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UserBelongingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "swe-card", [], null, [[null, "openChange"], [null, "refreshChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openChange" === en)) {
        var pd_0 = ((_co.open = $event) !== false);
        ad = (pd_0 && ad);
    } if (("refreshChange" === en)) {
        var pd_1 = (_co.load() !== false);
        ad = (pd_1 && ad);
    } if (("openChange" === en)) {
        var pd_2 = (_co.load() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_CardComponent_0, i7.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i8.CardComponent, [i3.LanguageService], { header: [0, "header"], open: [1, "open"] }, { openChange: "openChange", refreshChange: "refreshChange" }), (_l()(), i0.ɵeld(2, 0, null, 2, 1, "span", [["swe-rightcommand", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-fw fa-plus fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showEdit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, 3, 2, "div", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserBelongingsComponent_1)), i0.ɵdid(6, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_UserBelongingsComponent_5)), i0.ɵdid(8, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_UserBelongingsComponent_8)), i0.ɵdid(10, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_UserBelongingsComponent_11)), i0.ɵdid(12, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.languageService.getItem(33), ""); var currVal_1 = _co.open; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.edit; _ck(_v, 6, 0, currVal_2); var currVal_3 = (!_co.advanced && (_co.roles.length > 0)); _ck(_v, 8, 0, currVal_3); var currVal_4 = (!_co.advanced && (_co.levels.length > 0)); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.advanced; _ck(_v, 12, 0, currVal_5); }, null); }
export function View_UserBelongingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-userbelongings", [], null, null, null, View_UserBelongingsComponent_0, RenderType_UserBelongingsComponent)), i0.ɵdid(1, 704512, null, 0, i16.UserBelongingsComponent, [i3.LanguageService, i6.DataService, i17.AlertService, i5.SettingService, i12.PropertyService, i4.PermissionService, i14.ListService, i18.ActivatedRoute, i18.Router], null, null)], null, null); }
var UserBelongingsComponentNgFactory = i0.ɵccf("swe-userbelongings", i16.UserBelongingsComponent, View_UserBelongingsComponent_Host_0, { id: "id", open: "open" }, {}, []);
export { UserBelongingsComponentNgFactory as UserBelongingsComponentNgFactory };
