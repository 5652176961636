<swe-pageheader></swe-pageheader>
<div class="row justify-content-md-center py-2">
  <div class="col-12 col-md-3">
    <swe-card header="{{languageService.getItem(438)}}" [hasRefresh]="false" [hasCollapse]="false">
      <div class="form-group">
        <img sweImage alt="Logo" title="Logo" style="width:100%;" imageFolder="images" [imageFile]="permissionService.unauthpermissions.Logo" *ngIf="permissionService.unauthpermissions.Logo&&permissionService.unauthpermissions.Logo.length>0">
        <a href="https://www.swedetime.com" target="_blank" *ngIf="!permissionService.unauthpermissions.Logo||permissionService.unauthpermissions.Logo.length==0"><img alt="SwedeTime" src="assets/images/swedetime.png" /></a>
      </div>
      <ng-container *ngIf="mode==0">
        <div class="form-group">
          <label>{{languageService.getItem(439)}}</label>
        </div>
        <swe-element [type]="'System.String'" [statusLabel]="0" [label]="languageService.getItem(440)" [placeholder]="languageService.getItem(440)" [(model)]="username"></swe-element>
        <div class="form-group">
          <button class="btn btn-primary btn-block" (click)="send()">{{languageService.getItem(329)}}</button>
        </div>
      </ng-container>
      <ng-container *ngIf="mode==1">
        <swe-element [type]="'System.Password'" [statusLabel]="0" [label]="languageService.getItem(445)" [placeholder]="languageService.getItem(445)" [(model)]="password"></swe-element>
        <swe-element [type]="'System.Password'" [statusLabel]="0" [label]="languageService.getItem(446)" [placeholder]="languageService.getItem(446)" [(model)]="passwordrepeat"></swe-element>
        <div class="form-group">
          <button class="btn btn-primary btn-block" (click)="change()">{{languageService.getItem(447)}}</button>
        </div>
      </ng-container>
      <div class="float-right">
        <a href="https://www.swedetime.com" target="_blank" *ngIf="permissionService.unauthpermissions.Logo&&permissionService.unauthpermissions.Logo.length>0"><img alt="SwedeTime" src="assets/images/swedetime.png" /></a>
      </div>
    </swe-card>
  </div>
</div>
