/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layout/card.component.ngfactory";
import * as i2 from "../../layout/card.component";
import * as i3 from "../../_services/language.service";
import * as i4 from "../../layout/element.component.ngfactory";
import * as i5 from "../../layout/element.component";
import * as i6 from "../../_services/authentication.service";
import * as i7 from "../../_services/permission.service";
import * as i8 from "../../_services/property.service";
import * as i9 from "../../_services/datetime.service";
import * as i10 from "../../_services/list.service";
import * as i11 from "../../layout/pageheader.component.ngfactory";
import * as i12 from "../../layout/pageheader.component";
import * as i13 from "@angular/common";
import * as i14 from "@angular/router";
import * as i15 from "./impersonate.component";
import * as i16 from "../../_services/alert.service";
import * as i17 from "../../_services/setting.service";
var styles_ImpersonateComponent = [];
var RenderType_ImpersonateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImpersonateComponent, data: {} });
export { RenderType_ImpersonateComponent as RenderType_ImpersonateComponent };
function View_ImpersonateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "swe-card", [], null, null, null, i1.View_CardComponent_0, i1.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i2.CardComponent, [i3.LanguageService], { header: [0, "header"], open: [1, "open"], hasCollapse: [2, "hasCollapse"], hasRefresh: [3, "hasRefresh"] }, null), (_l()(), i0.ɵeld(2, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.userid = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ElementComponent_0, i4.RenderType_ElementComponent)), i0.ɵdid(3, 638976, null, 0, i5.ElementComponent, [i6.AuthenticationService, i7.PermissionService, i8.PropertyService, i9.DateTimeService, i10.ListService, i3.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵeld(4, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"], [null, "enterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.pincode = $event) !== false);
        ad = (pd_0 && ad);
    } if (("enterChange" === en)) {
        var pd_1 = (_co.impersonate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ElementComponent_0, i4.RenderType_ElementComponent)), i0.ɵdid(5, 638976, null, 0, i5.ElementComponent, [i6.AuthenticationService, i7.PermissionService, i8.PropertyService, i9.DateTimeService, i10.ListService, i3.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"] }, { modelChange: "modelChange", enterChange: "enterChange" }), (_l()(), i0.ɵeld(6, 0, null, 3, 2, "button", [["class", "btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.impersonate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-user-secret"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(318); var currVal_1 = true; var currVal_2 = false; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.languageService.getItem(59); var currVal_5 = 1; var currVal_6 = "System.UserSearch"; var currVal_7 = _co.userid; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = "Pin"; var currVal_9 = 1; var currVal_10 = "System.Password"; var currVal_11 = _co.pincode; _ck(_v, 5, 0, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_12 = _co.languageService.getItem(318); _ck(_v, 8, 0, currVal_12); }); }
function View_ImpersonateComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "swe-card", [], null, null, null, i1.View_CardComponent_0, i1.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i2.CardComponent, [i3.LanguageService], { header: [0, "header"], open: [1, "open"], hasCollapse: [2, "hasCollapse"], hasRefresh: [3, "hasRefresh"] }, null), (_l()(), i0.ɵeld(2, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"], [null, "enterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.pincode = $event) !== false);
        ad = (pd_0 && ad);
    } if (("enterChange" === en)) {
        var pd_1 = (_co.impersonate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ElementComponent_0, i4.RenderType_ElementComponent)), i0.ɵdid(3, 638976, null, 0, i5.ElementComponent, [i6.AuthenticationService, i7.PermissionService, i8.PropertyService, i9.DateTimeService, i10.ListService, i3.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"] }, { modelChange: "modelChange", enterChange: "enterChange" }), (_l()(), i0.ɵeld(4, 0, null, 3, 2, "button", [["class", "btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.impersonate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "fas fa-user-secret"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(319); var currVal_1 = true; var currVal_2 = false; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Pin"; var currVal_5 = 1; var currVal_6 = "System.Password"; var currVal_7 = _co.pincode; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.languageService.getItem(318); _ck(_v, 6, 0, currVal_8); }); }
export function View_ImpersonateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-pageheader", [], null, null, null, i11.View_PageHeaderComponent_0, i11.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i12.PageHeaderComponent, [i13.Location, i14.ActivatedRoute, i14.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "row  py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "col-12 col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpersonateComponent_1)), i0.ɵdid(5, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpersonateComponent_2)), i0.ɵdid(7, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(317); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.permissionService.permissions.Impersonate; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.permissionService.isimpersonate() > 0); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ImpersonateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-impersonate", [], null, null, null, View_ImpersonateComponent_0, RenderType_ImpersonateComponent)), i0.ɵdid(1, 49152, null, 0, i15.ImpersonateComponent, [i3.LanguageService, i7.PermissionService, i6.AuthenticationService, i16.AlertService, i17.SettingService], null, null)], null, null); }
var ImpersonateComponentNgFactory = i0.ɵccf("app-impersonate", i15.ImpersonateComponent, View_ImpersonateComponent_Host_0, {}, {}, []);
export { ImpersonateComponentNgFactory as ImpersonateComponentNgFactory };
