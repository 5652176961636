<swe-pageheader [header]="languageService.getItem(113)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-left" *ngFor="let group of settings">
    <swe-card [header]="group.Header" [open]="false" [hasCollapse]="true" [hasRefresh]="false" [hasSave]="true" (saveChange)="save()">
      <ng-container *ngFor="let setting of group.Settings"  [ngSwitch]="setting.Type">
        <swe-element [type]="'System.List'" [label]="setting.Name" [statusLabel]="2" [(model)]="setting.Value" [items]="getList(setting)" *ngSwitchCase="'System.List'"></swe-element>
        <swe-element [type]="'System.MultiList'" [label]="setting.Name" [statusLabel]="2" [(model)]="setting.Value" [items]="getList(setting)" [container]="container(setting)" *ngSwitchCase="'System.MultiList'"></swe-element>
        <swe-element [type]="setting.Type" [label]="setting.Name" [statusLabel]="2" [(model)]="setting.Value" *ngSwitchDefault></swe-element>
      </ng-container>
      <button class="btn btn-block btn-primary" (click)="save()"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
    </swe-card>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-left] on columns-->
