/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layout/element.component.ngfactory";
import * as i2 from "../../layout/element.component";
import * as i3 from "../../_services/authentication.service";
import * as i4 from "../../_services/permission.service";
import * as i5 from "../../_services/property.service";
import * as i6 from "../../_services/datetime.service";
import * as i7 from "../../_services/list.service";
import * as i8 from "../../_services/language.service";
import * as i9 from "../../layout/profile.component.ngfactory";
import * as i10 from "../../layout/profile.component";
import * as i11 from "../../element/confirm.component.ngfactory";
import * as i12 from "../../element/confirm.component";
import * as i13 from "@angular/common";
import * as i14 from "../../layout/card.component.ngfactory";
import * as i15 from "../../layout/card.component";
import * as i16 from "./objecttoobjectdata.component";
import * as i17 from "../../_services/general.service";
import * as i18 from "../../_services/data.service";
import * as i19 from "../../_services/alert.service";
import * as i20 from "../../_services/setting.service";
import * as i21 from "@angular/router";
var styles_ObjectToObjectDataComponent = [];
var RenderType_ObjectToObjectDataComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ObjectToObjectDataComponent, data: {} });
export { RenderType_ObjectToObjectDataComponent as RenderType_ObjectToObjectDataComponent };
function View_ObjectToObjectDataComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-plus fa-pull-right"]], null, null, null, null, null))], null, null); }
function View_ObjectToObjectDataComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.object.ObjectId = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ElementComponent_0, i1.RenderType_ElementComponent)), i0.ɵdid(2, 638976, null, 0, i2.ElementComponent, [i3.AuthenticationService, i4.PermissionService, i5.PropertyService, i6.DateTimeService, i7.ListService, i8.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], items: [3, "items"], model: [4, "model"] }, { modelChange: "modelChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(161); var currVal_1 = 1; var currVal_2 = "System.List"; var currVal_3 = _co.categories; var currVal_4 = _co.object.ObjectId; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ObjectToObjectDataComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "swe-profile", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.object.Profile = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ProfileComponent_0, i9.RenderType_ProfileComponent)), i0.ɵdid(2, 114688, null, 0, i10.ProfileComponent, [i8.LanguageService, i7.ListService, i5.PropertyService, i6.DateTimeService], { model: [0, "model"] }, { modelChange: "modelChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.object.Profile; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ObjectToObjectDataComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-save"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["\u00A0", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(16); _ck(_v, 2, 0, currVal_0); }); }
function View_ObjectToObjectDataComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "swe-confirm", [], null, [[null, "acceptChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("acceptChange" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ConfirmComponent_0, i11.RenderType_ConfirmComponent)), i0.ɵdid(1, 638976, null, 0, i12.ConfirmComponent, [i8.LanguageService], { body: [0, "body"] }, { acceptChange: "acceptChange" }), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "button", [["class", "btn btn-danger btn-block mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-fw fa-trash"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(689); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.languageService.getItem(17); _ck(_v, 4, 0, currVal_1); }); }
function View_ObjectToObjectDataComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectDataComponent_6)), i0.ɵdid(2, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectDataComponent_7)), i0.ɵdid(4, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.object.Access > 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.object.Id > 0) && (_co.object.Access > 2)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ObjectToObjectDataComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "swe-card", [], null, [[null, "openChange"], [null, "refreshChange"], [null, "saveChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openChange" === en)) {
        var pd_0 = ((_co.open = $event) !== false);
        ad = (pd_0 && ad);
    } if (("refreshChange" === en)) {
        var pd_1 = (_co.load() !== false);
        ad = (pd_1 && ad);
    } if (("saveChange" === en)) {
        var pd_2 = (_co.save() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i14.View_CardComponent_0, i14.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i15.CardComponent, [i8.LanguageService], { header: [0, "header"], open: [1, "open"], focus: [2, "focus"], hasSave: [3, "hasSave"] }, { openChange: "openChange", refreshChange: "refreshChange", saveChange: "saveChange" }), (_l()(), i0.ɵeld(2, 0, null, 2, 2, "span", [["swe-rightcommand", ""]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectDataComponent_2)), i0.ɵdid(4, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_ObjectToObjectDataComponent_3)), i0.ɵdid(6, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_ObjectToObjectDataComponent_4)), i0.ɵdid(8, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_ObjectToObjectDataComponent_5)), i0.ɵdid(10, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.header, ""); var currVal_1 = _co.open; var currVal_2 = true; var currVal_3 = (_co.object.Access > 1); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.id > 0); _ck(_v, 4, 0, currVal_4); var currVal_5 = (!_co.loading && (_co.categories.length > 1)); _ck(_v, 6, 0, currVal_5); var currVal_6 = !_co.loading; _ck(_v, 8, 0, currVal_6); var currVal_7 = !_co.loading; _ck(_v, 10, 0, currVal_7); }, null); }
export function View_ObjectToObjectDataComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectDataComponent_1)), i0.ɵdid(1, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.object; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ObjectToObjectDataComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-objecttoobjectdata", [], null, null, null, View_ObjectToObjectDataComponent_0, RenderType_ObjectToObjectDataComponent)), i0.ɵdid(1, 704512, null, 0, i16.ObjectToObjectDataComponent, [i8.LanguageService, i4.PermissionService, i17.GeneralService, i18.DataService, i19.AlertService, i5.PropertyService, i20.SettingService, i13.Location, i21.Router], null, null)], null, null); }
var ObjectToObjectDataComponentNgFactory = i0.ɵccf("swe-objecttoobjectdata", i16.ObjectToObjectDataComponent, View_ObjectToObjectDataComponent_Host_0, { id: "id", category: "category", header: "header", general: "general", open: "open" }, {}, []);
export { ObjectToObjectDataComponentNgFactory as ObjectToObjectDataComponentNgFactory };
