<swe-pageheader [header]="languageService.getItem(410)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-left">
    <swe-card header="{{languageService.getItem(2)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
      <!--Belongings-->
      <swe-card header="{{languageService.getItem(469)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="true">
        <swe-element [(model)]="belongoption" [label]="languageService.getItem(843)" [items]="belongoptions" [type]="'System.RadioList'" *ngIf="permissionService.permissions.AdvancedBelonging"></swe-element>
        <swe-element [(model)]="belongoption" [label]="languageService.getItem(82)" [statusLabel]="2" [items]="belongoptions" [type]="'System.RadioList'" *ngIf="!permissionService.permissions.AdvancedBelonging"></swe-element>
        <swe-element [(model)]="roles" [label]="languageService.getItem(82)" [items]="availableroles" [type]="'System.MultiList'"></swe-element>
        <swe-element [(model)]="belongoption" [label]="languageService.getItem(3)" [statusLabel]="2" [items]="belongoptions" [type]="'System.RadioList'" *ngIf="!permissionService.permissions.AdvancedBelonging"></swe-element>
        <swe-levelsearch [bottomMargin]="3" [onlyone]="false" (modelChange)="addLevel($event)" [label]="languageService.getItem(3)" [chosen]="levels" [type]="'User'"></swe-levelsearch>
        <div class="list-group pt-0" *ngIf="levels.length>0">
          <div class="list-group-item" *ngFor="let level of levels">
            <span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}
            <i class="fas fa-fw fa-swe fa-times fa-pull-right swe-click" (click)="removeLevel(level)"></i>
          </div>
        </div>
      </swe-card>
      <!--Activation-->
      <swe-element [(model)]="activationoption" [label]="languageService.getItem(466)" [statusLabel]="2" [items]="activationoptions" [type]="'System.RadioList'"></swe-element>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)" [statusLabel]="2" [properties]="properties" [items]="chosenproperties"></swe-properties>
      <!--Buttons-->
      <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
        <button class="btn btn-primary btn-block mb-2"><i class="fas fa-fw fa-clone"></i>&nbsp;{{languageService.getItem(470)}}</button>
      </swe-confirm>
      <swe-confirm [body]="languageService.getItem(686)" (acceptChange)="delete()">
        <button class="btn btn-danger btn-block mb-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(685)}}</button>
      </swe-confirm>
    </swe-card>
  </div>
</div>  
