import { of, forkJoin } from 'rxjs';
import { take, switchMap, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
import * as i2 from "./setting.service";
export class GeneralService {
    constructor(dataService, settingService) {
        this.dataService = dataService;
        this.settingService = settingService;
        this._roles = null;
        this._levelgroups = null;
        this._bookingstatus = null;
        this._timereportstatus = null;
        this._messagetypes = null;
        this._logobjects = null;
        this._markeddatetypes = null;
        this._activitytypes = null;
        this._reports = null;
        this._templates = null;
        this._propertycategories = null;
        this._timebanks = null;
        this._las = null;
        this._contracts = null;
        this._employmenttypes = null;
        this._employmentcategories = null;
        this._first = true;
    }
    resolve() {
        return forkJoin(this.loadRoles(), this.loadLevelGroups(), this.loadBookingStatus(), this.loadMessageTypes(), this.loadLogObjects(), this.loadMarkedDateTypes(), this.loadActivityTypes(), this.loadReports(), this.loadTemplates(), this.loadPropertyCategories(), this.loadTimeBanks(), this.loadLas(), this.loadContracts(), this.loadEmploymentTypes(), this.loadEmploymentCategories()).pipe(take(1), switchMap(res => {
            if (this._first) {
                //Check watchdog
                this._first = false;
                this.settingService.checkWatchdog();
                this.getVersion();
            }
            return res;
        }));
    }
    /*Properties*/
    get roles() {
        return this._roles;
    }
    get levelgroups() {
        return this._levelgroups.filter((level) => { if (level.Id > 0) {
            return level;
        } });
    }
    get levelgroupswithmissing() {
        return this._levelgroups;
    }
    get bookingstatus() {
        return this._bookingstatus;
    }
    get timereportstatus() {
        return this._timereportstatus;
    }
    get messagetypes() {
        return this._messagetypes;
    }
    get logobjects() {
        return this._logobjects;
    }
    get markeddatetypes() {
        return this._markeddatetypes;
    }
    get activitytypes() {
        return this._activitytypes;
    }
    get reports() {
        return this._reports;
    }
    get templates() {
        return this._templates;
    }
    get propertycategories() {
        return this._propertycategories;
    }
    get timebanks() {
        return this._timebanks;
    }
    get las() {
        return this._las;
    }
    get contracts() {
        return this._contracts;
    }
    get employmenttypes() {
        return this._employmenttypes;
    }
    get employmentcategories() {
        return this._employmentcategories;
    }
    get api() {
        return window.location.origin.replace('https://', '').replace('http://', '') + '/api/v1/';
    }
    get isIOS() {
        return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) /*Includes iOS keyword and ignore IE "fake" use of it*/
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document); /*iPad on iOS 13 detection*/
    }
    get isFireFox() {
        return (/Firefox/.test(navigator.userAgent) && !(/Seamonkey/.test(navigator.userAgent))); /*Includes FireFox keyword and ignore Seamonkey "fake" use of it*/
    }
    //Reload
    reloadRoles() {
        this._roles = null;
        return this.dataService.tokenRequest('/api/v1/general/roles', 'GET').pipe(tap((res) => {
            this._roles = res;
        }));
    }
    reloadLevelGroups() {
        this._levelgroups = [];
        return this.dataService.tokenRequest('/api/v1/general/levelgroups', 'GET').pipe(tap((res) => {
            res.forEach(levelgroup => {
                if (typeof levelgroup.Type == 'undefined' || levelgroup.Type == null || levelgroup.Type.length == 0) {
                    this._levelgroups.push({ Id: levelgroup.Key, Name: levelgroup.Value });
                    if (levelgroup.Extra != '1') {
                        this.settingService.defaultlevel.levelgroups.push(levelgroup.Key);
                        this.settingService.level.levelgroups.push(levelgroup.Key);
                    }
                }
                else if (levelgroup.Extra != '1') {
                    if (levelgroup.Type == 'FilterBooking') {
                        this.settingService.defaultbooking.levelgroups.push(levelgroup.Key);
                        this.settingService.booking.levelgroups.push(levelgroup.Key);
                    }
                    else if (levelgroup.Type == 'FilterUser') {
                        this.settingService.defaultuser.levelgroups.push(levelgroup.Key);
                        this.settingService.user.levelgroups.push(levelgroup.Key);
                    }
                }
            });
        }));
    }
    reloadReports() {
        this._reports = null;
        return this.dataService.tokenRequest('/api/v1/general/reports', 'GET').pipe(tap((res) => {
            this._reports = res;
        }));
    }
    //Format
    formatdecimal(val, length = 2) {
        if (length < 1) {
            return val;
        }
        if (typeof val != "undefined" && val != null && Number(val) === val) {
            let converter = Math.pow(10, length);
            val = Math.round(val * converter) / converter;
        }
        return val;
    }
    /*Functions*/
    getVersion() {
        this.dataService.basicRequest('/api/v1/security/version', 'GET', {}, 'text')
            .subscribe((res) => {
            localStorage.setItem('version', res);
        });
    }
    loadRoles() {
        if (!this._roles) {
            return this.dataService.tokenRequest('/api/v1/general/roles', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._roles = res;
                    res.forEach(role => {
                        if (role.Access > 1) {
                            this.settingService.defaultuser.roles.push(role.Id);
                            this.settingService.user.roles.push(role.Id);
                        }
                    });
                }
                return of(this._roles);
            }));
        }
        else {
            return of(this._roles);
        }
    }
    loadLevelGroups() {
        if (!this._levelgroups) {
            return this.dataService.tokenRequest('/api/v1/general/levelgroups', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._levelgroups = [];
                    res.forEach(levelgroup => {
                        if (typeof levelgroup.Type == 'undefined' || levelgroup.Type == null || levelgroup.Type.length == 0) {
                            this._levelgroups.push({ Id: levelgroup.Key, Name: levelgroup.Value });
                            if (levelgroup.Extra != '1') {
                                this.settingService.defaultlevel.levelgroups.push(levelgroup.Key);
                                this.settingService.level.levelgroups.push(levelgroup.Key);
                            }
                        }
                        else if (levelgroup.Extra != '1') {
                            if (levelgroup.Type == 'FilterBooking') {
                                this.settingService.defaultbooking.levelgroups.push(levelgroup.Key);
                                this.settingService.booking.levelgroups.push(levelgroup.Key);
                            }
                            else if (levelgroup.Type == 'FilterUser') {
                                this.settingService.defaultuser.levelgroups.push(levelgroup.Key);
                                this.settingService.user.levelgroups.push(levelgroup.Key);
                            }
                        }
                    });
                }
                return of(this._levelgroups);
            }));
        }
        else {
            return of(this._levelgroups);
        }
    }
    loadBookingStatus() {
        if (!this._bookingstatus) {
            return this.dataService.tokenRequest('/api/v1/general/status', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._bookingstatus = res;
                    this._timereportstatus = [];
                    res.forEach(status => {
                        if (status.Id >= 40) {
                            this._timereportstatus.push(status);
                        }
                    });
                }
                return of(this._bookingstatus);
            }));
        }
        else {
            return of(this._bookingstatus);
        }
    }
    loadMessageTypes() {
        if (!this._messagetypes) {
            return this.dataService.tokenRequest('/api/v1/general/messagetypes', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._messagetypes = res;
                }
                return of(this._messagetypes);
            }));
        }
        else {
            return of(this._messagetypes);
        }
    }
    loadLogObjects() {
        if (!this._logobjects) {
            return this.dataService.tokenRequest('/api/v1/general/logobjects', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._logobjects = res;
                }
                return of(this._logobjects);
            }));
        }
        else {
            return of(this._logobjects);
        }
    }
    loadMarkedDateTypes() {
        if (!this._logobjects) {
            return this.dataService.tokenRequest('/api/v1/general/markeddatetypes', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._markeddatetypes = res;
                }
                return of(this._markeddatetypes);
            }));
        }
        else {
            return of(this._markeddatetypes);
        }
    }
    loadActivityTypes() {
        if (!this._activitytypes) {
            return this.dataService.tokenRequest('/api/v1/general/activitytypes', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._activitytypes = res;
                }
                return of(this._activitytypes);
            }));
        }
        else {
            return of(this._activitytypes);
        }
    }
    loadReports() {
        if (!this._reports) {
            return this.dataService.tokenRequest('/api/v1/general/reports', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._reports = res;
                }
                return of(this._reports);
            }));
        }
        else {
            return of(this._reports);
        }
    }
    loadTemplates() {
        if (!this._templates) {
            return this.dataService.tokenRequest('/api/v1/general/templates', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._templates = res;
                }
                return of(this._templates);
            }));
        }
        else {
            return of(this._templates);
        }
    }
    loadPropertyCategories() {
        if (!this._propertycategories) {
            return this.dataService.tokenRequest('/api/v1/general/propertycategories', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._propertycategories = res;
                }
                return of(this._propertycategories);
            }));
        }
        else {
            return of(this._propertycategories);
        }
    }
    loadTimeBanks() {
        if (!this._timebanks) {
            return this.dataService.tokenRequest('/api/v1/general/timebanks', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._timebanks = res;
                }
                return of(this._timebanks);
            }));
        }
        else {
            return of(this._timebanks);
        }
    }
    loadLas() {
        if (!this._las) {
            return this.dataService.tokenRequest('/api/v1/general/las', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._las = res;
                }
                return of(this._las);
            }));
        }
        else {
            return of(this._las);
        }
    }
    loadContracts() {
        if (!this._contracts) {
            return this.dataService.tokenRequest('/api/v1/general/contracts', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._contracts = res;
                }
                return of(this._contracts);
            }));
        }
        else {
            return of(this._contracts);
        }
    }
    loadEmploymentTypes() {
        if (!this._employmenttypes) {
            return this.dataService.tokenRequest('/api/v1/general/employmenttypes', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._employmenttypes = res;
                }
                return of(this._employmenttypes);
            }));
        }
        else {
            return of(this._employmenttypes);
        }
    }
    loadEmploymentCategories() {
        if (!this._employmentcategories) {
            return this.dataService.tokenRequest('/api/v1/general/employmentcategories', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._employmentcategories = res;
                }
                return of(this._employmentcategories);
            }));
        }
        else {
            return of(this._employmentcategories);
        }
    }
}
GeneralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeneralService_Factory() { return new GeneralService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.SettingService)); }, token: GeneralService, providedIn: "root" });
