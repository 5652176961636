<swe-pageheader [header]="header"><span swe-hits *ngIf="header.length>0">&nbsp;(#{{id}})</span></swe-pageheader>
<div class="text-md-center">
  <div class="btn-group">
    <button class="btn btn-swe" (click)="prev()" *ngIf="currentindex>0"><i class="fas fa-fw fa-arrow-left"></i></button>
    <span class="my-2 mx-3" *ngIf="othertimereports.length>1">{{currentindex+1}}/{{othertimereports.length}}</span>
    <button class="btn btn-swe" (click)="next()" *ngIf="currentindex<othertimereports.length-1"><i class="fas fa-fw fa-arrow-right"></i></button>
  </div>
</div>
<div class="alert alert-danger" *ngIf="othertimereports && othertimereports[currentindex].type==3">
  {{languageService.getItem(1047)}}
</div>
<div class="row py-2">
  <div class="col-12 col-md-4">
    <swe-timereportdata [id]="id" [userid]="userid" [(type)]="othertimereports[currentindex].type" [othertimereports]="othertimereports" [open]="cards.data" (headerChange)="changeHeader($event)" (idChange)="changeId($event)"></swe-timereportdata>
  </div>
</div>
