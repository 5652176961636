/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "../_directives/image.directive";
import * as i4 from "../_services/data.service";
import * as i5 from "./document.component";
import * as i6 from "../_services/language.service";
import * as i7 from "../_services/alert.service";
import * as i8 from "../_services/setting.service";
var styles_DocumentComponent = [];
var RenderType_DocumentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DocumentComponent, data: {} });
export { RenderType_DocumentComponent as RenderType_DocumentComponent };
function View_DocumentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "i", [["class", "fas fa-swe fa-times fa-pull-right swe-click ml-2"]], null, [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showupload = false) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = ((_co.closerhover = true) !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = ((_co.closerhover = false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "swe-grayed-out": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fas fa-swe fa-times fa-pull-right swe-click ml-2"; var currVal_1 = _ck(_v, 3, 0, _co.closerhover); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_DocumentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "input", [["class", "form-control"], ["type", "file"]], [[1, "aria-label", 0], [8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changefile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; var currVal_1 = _co.disabled; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DocumentComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i0.ElementRef, i0.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.Value; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.Value; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.Value; _ck(_v, 3, 0, currVal_2); }); }
function View_DocumentComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "select", [["class", "form-control"]], [[1, "aria-label", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.name = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i2.SelectControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i0.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_4)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.name; _ck(_v, 3, 0, currVal_8); var currVal_9 = _co.archive; _ck(_v, 7, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; var currVal_1 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 5).ngClassValid; var currVal_6 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_DocumentComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "fas ", _co.changestatus, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DocumentComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "span", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "text-decoration": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["class", "swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_6)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.markedasdelete); _ck(_v, 2, 0, currVal_0); var currVal_2 = (_co.writeAccess && _co.toggable); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.name; _ck(_v, 5, 0, currVal_1); }); }
function View_DocumentComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-3x fa-image"]], null, null, null, null, null))], null, null); }
function View_DocumentComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img", [["sweImage", ""]], [[8, "alt", 0]], null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i3.ImageDirective, [i4.DataService, i0.ElementRef], { imageFolder: [0, "imageFolder"], imageFile: [1, "imageFile"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.folder; var currVal_2 = _co.document; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.languageService.getItem(195), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DocumentComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "i", [["class", "fas fa-swe fa-upload swe-click ml-2"], ["style", "vertical-align:top;"]], [[1, "title", 0]], [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showupload = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = ((_co.uploadhover = true) !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = ((_co.uploadhover = false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "swe-grayed-out": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fas fa-swe fa-upload swe-click ml-2"; var currVal_2 = _ck(_v, 3, 0, _co.uploadhover); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(337); _ck(_v, 0, 0, currVal_0); }); }
function View_DocumentComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "i", [["class", "fas fa-swe fa-trash swe-click ml-2"], ["style", "vertical-align:top;"]], [[1, "title", 0]], [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = ((_co.deletehover = true) !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = ((_co.deletehover = false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "swe-grayed-out": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fas fa-swe fa-trash swe-click ml-2"; var currVal_2 = _ck(_v, 3, 0, _co.deletehover); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(882); _ck(_v, 0, 0, currVal_0); }); }
function View_DocumentComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_8)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_9)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_10)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_11)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.showupload && (!_co.document || (_co.document.length == 0))); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((!_co.showupload && _co.document) && (_co.document.length > 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.showupload && !_co.disabled); _ck(_v, 6, 0, currVal_2); var currVal_3 = (((!_co.showupload && _co.document) && (_co.document.length > 0)) && !_co.disabled); _ck(_v, 8, 0, currVal_3); }, null); }
function View_DocumentComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_DocumentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_7)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentComponent_12)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.writeAccess && _co.showupload); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.writeAccess && (_co.showupload || (!_co.showimage && !_co.showdocument))); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.archive && (_co.archive.length > 0)); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((!_co.hidedownload && _co.old) && (_co.old.length > 0)); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.showimage; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.showdocument; _ck(_v, 12, 0, currVal_5); }, null); }
export function View_DocumentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-document", [], null, null, null, View_DocumentComponent_0, RenderType_DocumentComponent)), i0.ɵdid(1, 638976, null, 0, i5.DocumentComponent, [i6.LanguageService, i7.AlertService, i4.DataService, i8.SettingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentComponentNgFactory = i0.ɵccf("swe-document", i5.DocumentComponent, View_DocumentComponent_Host_0, { model: "model", label: "label", disabled: "disabled", changed: "changed", clientOnly: "clientOnly", encoding: "encoding", archive: "archive", allowedPrefix: "allowedPrefix", folder: "folder", document: "document", showimage: "showimage", showdocument: "showdocument", hidedownload: "hidedownload", reload: "reload", writeAccess: "writeAccess" }, { modelChange: "modelChange" }, []);
export { DocumentComponentNgFactory as DocumentComponentNgFactory };
