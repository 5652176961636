<swe-pageheader [header]="languageService.getItem(574)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row py-2">
  <!--From-->
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(563)" [open]="true" [hasCollapse]="settingService.isDevice(['xs','sm'])" [hasRefresh]="false">
      <swe-element [type]="'System.Time'" [label]="languageService.getItem(564)" [statusLabel]="1" [(model)]="rule.FromTime"></swe-element>
      <swe-element [type]="'System.RadioList'" [label]="languageService.getItem(964)" [container]="{extra:'FromStart'}" [statusLabel]="1" [(model)]="rule.FromTimeStart" [items]="fromstart"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(565)" [statusLabel]="1" [(model)]="rule.FromDatePrefix" [items]="dayprefixes"></swe-element>
      <swe-card [header]="languageService.getItem(566)" [open]="true" [hasCollapse]="false" [hasRefresh]="false" [color]="'swe'">
        <swe-element [type]="'System.List'" [label]="languageService.getItem(567)" [statusLabel]="1" [(model)]="rule.FromWeekday" [items]="weekdays"></swe-element>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(568)" [statusLabel]="1" [(model)]="rule.FromHoliday" [items]="holidays"></swe-element>
        <swe-element [type]="'System.Date'" [label]="languageService.getItem(569)" [statusLabel]="1" [(model)]="rule.FromDate"></swe-element>
      </swe-card>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(553)" [statusLabel]="1" [(model)]="rule.FromHolidayExceptions" [items]="blackholidays" [container]="{isbit:true}"></swe-element>
      <button class="btn btn-primary btn-block" (click)="save()" *ngIf="permissionService.permissions.Overtime>1"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
      <swe-confirm *ngIf="rule.Id>0 && permissionService.permissions.Overtime>2" [body]="languageService.getItem(577)" (acceptChange)="delete()">
        <button class="btn btn-danger btn-block mt-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </swe-confirm>
    </swe-card>
</div>
  <!--To-->
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(570)" [open]="true" [hasCollapse]="settingService.isDevice(['xs','sm'])" [hasRefresh]="false">
      <swe-element [type]="'System.Time'" [label]="languageService.getItem(564)" [statusLabel]="1" [(model)]="rule.ToTime"></swe-element>
      <swe-element [type]="'System.RadioList'" [label]="languageService.getItem(964)" [container]="{extra:'ToEnd'}" [statusLabel]="1" [(model)]="rule.ToTimeEnd" [items]="toend"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(565)" [statusLabel]="1" [(model)]="rule.ToDatePrefix" [items]="dayprefixes"></swe-element>
      <swe-card [header]="languageService.getItem(566)" [open]="true" [hasCollapse]="false" [hasRefresh]="false" [color]="'swe'">
        <swe-element [type]="'System.List'" [label]="languageService.getItem(567)" [statusLabel]="1" [(model)]="rule.ToWeekday" [items]="weekdays"></swe-element>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(568)" [statusLabel]="1" [(model)]="rule.ToHoliday" [items]="holidays"></swe-element>
        <swe-element [type]="'System.Date'" [label]="languageService.getItem(569)" [statusLabel]="1" [(model)]="rule.ToDate"></swe-element>
      </swe-card>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(553)" [statusLabel]="1" [(model)]="rule.ToHolidayExceptions" [items]="blackholidays" [container]="{isbit:true}"></swe-element>
      <button class="btn btn-primary btn-block" (click)="save()" *ngIf="permissionService.permissions.Overtime>1"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
      <swe-confirm *ngIf="rule.Id>0 && permissionService.permissions.Overtime>2" [body]="languageService.getItem(577)" (acceptChange)="delete()">
        <button class="btn btn-danger btn-block mt-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </swe-confirm>
    </swe-card>
  </div>
  <!--Other-->
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(576)" [open]="true" [hasCollapse]="true" [hasRefresh]="false">
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(571)" [statusLabel]="1" [(model)]="rule.Repeat"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="languageService.getItem(572)" [statusLabel]="1" [(model)]="rule.Prio"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(953)" [statusLabel]="1" [(model)]="rule.ContractId" [items]="contracts" [optional]="true" [disabled]="contractid>0"></swe-element>
      <swe-element [type]="'System.LevelSearch'" [label]="languageService.getItem(14)" [statusLabel]="1" [(model)]="rule.LevelId" [(display)]="rule.LevelName" [disabled]="contractid>0"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(578)" [statusLabel]="1" [(model)]="rule.GroupId" [items]="groups"></swe-element>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(613)" [statusLabel]="1" [(model)]="rule.Type" [items]="types" [container]="{isbit:true}"></swe-element>
      <button class="btn btn-primary btn-block" (click)="save()" *ngIf="permissionService.permissions.Overtime>1"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
      <swe-confirm *ngIf="rule.Id>0 && permissionService.permissions.Overtime>2" [body]="languageService.getItem(577)" (acceptChange)="delete()">
        <button class="btn btn-danger btn-block mt-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </swe-confirm>
    </swe-card>
  </div>
</div>
