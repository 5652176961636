/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../layout/pageheader.component.ngfactory";
import * as i2 from "../layout/pageheader.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./details/objecttoobjectdata.component.ngfactory";
import * as i6 from "./details/objecttoobjectdata.component";
import * as i7 from "../_services/language.service";
import * as i8 from "../_services/permission.service";
import * as i9 from "../_services/general.service";
import * as i10 from "../_services/data.service";
import * as i11 from "../_services/alert.service";
import * as i12 from "../_services/property.service";
import * as i13 from "../_services/setting.service";
import * as i14 from "./objecttoobjectdetail.component";
var styles_ObjectToObjectDetailComponent = [];
var RenderType_ObjectToObjectDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ObjectToObjectDetailComponent, data: {} });
export { RenderType_ObjectToObjectDetailComponent as RenderType_ObjectToObjectDetailComponent };
export function View_ObjectToObjectDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "swe-pageheader", [], null, null, null, i1.View_PageHeaderComponent_0, i1.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageHeaderComponent, [i3.Location, i4.ActivatedRoute, i4.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["swe-hits", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\u00A0(#", ")"])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "col-12 col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "swe-objecttoobjectdata", [], null, null, null, i5.View_ObjectToObjectDataComponent_0, i5.RenderType_ObjectToObjectDataComponent)), i0.ɵdid(7, 704512, null, 0, i6.ObjectToObjectDataComponent, [i7.LanguageService, i8.PermissionService, i9.GeneralService, i10.DataService, i11.AlertService, i12.PropertyService, i13.SettingService, i3.Location, i4.Router], { id: [0, "id"], category: [1, "category"], header: [2, "header"], general: [3, "general"], open: [4, "open"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.id; var currVal_3 = _co.category; var currVal_4 = _co.headerdata; var currVal_5 = _co.general; var currVal_6 = _co.cards.data; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.id; _ck(_v, 3, 0, currVal_1); }); }
export function View_ObjectToObjectDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-objecttoobjectdetail", [], null, null, null, View_ObjectToObjectDetailComponent_0, RenderType_ObjectToObjectDetailComponent)), i0.ɵdid(1, 114688, null, 0, i14.ObjectToObjectDetailComponent, [i9.GeneralService, i13.SettingService, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ObjectToObjectDetailComponentNgFactory = i0.ɵccf("app-objecttoobjectdetail", i14.ObjectToObjectDetailComponent, View_ObjectToObjectDetailComponent_Host_0, {}, {}, []);
export { ObjectToObjectDetailComponentNgFactory as ObjectToObjectDetailComponentNgFactory };
