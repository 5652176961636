/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@agm/core";
import * as i2 from "../../../node_modules/@agm/core/agm-core.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "./maps.component";
import * as i5 from "../_services/language.service";
var styles_MapsComponent = ["agm-map[_ngcontent-%COMP%] { height: 300px; }"];
var RenderType_MapsComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_MapsComponent, data: {} });
export { RenderType_MapsComponent as RenderType_MapsComponent };
function View_MapsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "agm-marker", [], null, [[null, "markerClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("markerClick" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(6144, null, i1.FitBoundsAccessor, null, [i1.AgmMarker]), i0.ɵdid(2, 1720320, null, 1, i1.AgmMarker, [i1.MarkerManager], { latitude: [0, "latitude"], longitude: [1, "longitude"], clickable: [2, "clickable"] }, { markerClick: "markerClick" }), i0.ɵqud(603979776, 2, { infoWindow: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.marker.lat; var currVal_1 = _co.marker.lng; var currVal_2 = !_co.disabled; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MapsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { searchElementRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["search", 1]], null, 0, "input", [["class", "form-control"], ["type", "text"]], [[8, "placeholder", 0], [8, "disabled", 0]], [[null, "keydown.enter"]], function (_v, en, $event) { var ad = true; if (("keydown.enter" === en)) {
        var pd_0 = ($event.preventDefault() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 14, "agm-map", [], [[2, "sebm-google-map-container", null]], [[null, "mapReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapReady" === en)) {
        var pd_0 = (_co.mapReadyHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AgmMap_0, i2.RenderType_AgmMap)), i0.ɵprd(4608, null, i1.MarkerManager, i1.MarkerManager, [i1.GoogleMapsAPIWrapper, i0.NgZone]), i0.ɵprd(4608, null, i1.CircleManager, i1.CircleManager, [i1.GoogleMapsAPIWrapper, i0.NgZone]), i0.ɵprd(4608, null, i1.DataLayerManager, i1.DataLayerManager, [i1.GoogleMapsAPIWrapper, i0.NgZone]), i0.ɵprd(4608, null, i1.InfoWindowManager, i1.InfoWindowManager, [i1.GoogleMapsAPIWrapper, i0.NgZone, i1.MarkerManager]), i0.ɵprd(4608, null, i1.KmlLayerManager, i1.KmlLayerManager, [i1.GoogleMapsAPIWrapper, i0.NgZone]), i0.ɵprd(4608, null, i1.LayerManager, i1.LayerManager, [i1.GoogleMapsAPIWrapper]), i0.ɵprd(4608, null, i1.PolygonManager, i1.PolygonManager, [i1.GoogleMapsAPIWrapper, i0.NgZone]), i0.ɵprd(4608, null, i1.PolylineManager, i1.PolylineManager, [i1.GoogleMapsAPIWrapper, i0.NgZone]), i0.ɵprd(4608, null, i1.RectangleManager, i1.RectangleManager, [i1.GoogleMapsAPIWrapper, i0.NgZone]), i0.ɵprd(512, null, i1.GoogleMapsAPIWrapper, i1.GoogleMapsAPIWrapper, [i1.MapsAPILoader, i0.NgZone]), i0.ɵprd(512, null, i1.ɵa, i1.ɵa, [i1.MapsAPILoader]), i0.ɵdid(14, 770048, null, 0, i1.AgmMap, [i0.ElementRef, i1.GoogleMapsAPIWrapper, i0.PLATFORM_ID, i1.ɵa, i0.NgZone], { longitude: [0, "longitude"], latitude: [1, "latitude"] }, { mapReady: "mapReady" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MapsComponent_1)), i0.ɵdid(16, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.position.lng; var currVal_4 = _co.position.lat; _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_5 = _co.marker; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.languageService.getItem(13), ""); var currVal_1 = _co.disabled; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = true; _ck(_v, 2, 0, currVal_2); }); }
export function View_MapsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-maps", [], null, null, null, View_MapsComponent_0, RenderType_MapsComponent)), i0.ɵdid(1, 245760, null, 0, i4.MapsComponent, [i5.LanguageService, i0.NgZone, i1.MapsAPILoader], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapsComponentNgFactory = i0.ɵccf("swe-maps", i4.MapsComponent, View_MapsComponent_Host_0, { model: "model", disabled: "disabled" }, { modelChange: "modelChange" }, []);
export { MapsComponentNgFactory as MapsComponentNgFactory };
