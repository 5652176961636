import { Component, Input, OnChanges, Output, EventEmitter, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { PropertyService } from '../../_services/property.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ViewCacheService } from '../../_services/viewcache.service';



@Component({
  selector: 'swe-bookingdata',
  templateUrl: './bookingdata.component.html'
})
export class BookingDataComponent implements OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;
  @Input() userid: number = 0;
  @Input() levelid: number = 0;
  @Output() bookingdataChange = new EventEmitter<any>();
  @ViewChild('sweSaveElement', { static: false }) saveElement: ElementRef;

  private unsubscribe$: any[] = [
    new Subject<void>(),
    new Subject<void>()
  ];
  private _booking: any;
  private _loading: boolean;
  private _levelreset: boolean;
  private _user: string = "";
  private _checkuser: boolean = true;
  private _checknotificationemail: boolean = this.permissionService.permissions.NotifyOnBookEmail == 2 ? false : true;
  private _checknotificationsms: boolean = this.permissionService.permissions.NotifyOnBookSms == 2 ? false : true;
  private _slots: number = 1;
  private _isReload: boolean;
  //Series
  private _series: any[] = [];
  private _isSerie: boolean;
  private _serieOption: number = this.permissionService.permissions.SerieCopyAsDefault ? 2 : 1;
  private _endOption: number = 1;
  private _includeUsers: boolean;
  //Activities
  private _activities: any[] = [];
  private _tmpcounter: number = -1;
  //Split Shift
  private _splittime: Date;
  //Message
  private _owner: any = null;
  private _hasCell: boolean = false;
  private _hasEmail: boolean = false;
  //Validation
  private _validation: any;
  private _coreChanged: any;
  
  

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private propertyService: PropertyService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService,
    private dateTimeService: DateTimeService,
    private viewCacheService: ViewCacheService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {

    settingService.onViewRefresh$
      .pipe(takeUntil(this.unsubscribe$[0]))
      .subscribe((refresh) => {

        if (refresh.type == 'bookingusers_save') {
          this.load();
        }
        else if (refresh.type == 'bookingusers_delete') {
          this.load();
        }
        else if (refresh.type == 'availableusers_save') {
          this.load();
        }

      });

    settingService.onSave$
      .pipe(takeUntil(this.unsubscribe$[1]))
      .subscribe((e) => {
        //Save
        this.saveWithSerieConfirm();
      }); 
  }

  ngOnDestroy() {
    this.unsubscribe$.forEach((unsubscribe) => {
      unsubscribe.next();
      unsubscribe.complete();
    });
  }


  ngOnChanges() {
    if (this.open) {
      if (this.levelid) {
        this.load(this.levelid);
      }
      else {
        this.load();
      }
    }
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get booking() {
    return this._booking;
  }
  public get levelreset() {
    return this._levelreset;
  }
  public get user() {
    return this._user;
  }
  public get checkuser() {
    return this._checkuser;
  }
  public set checkuser(val) {
    this._checkuser = val;
  }
  public get checknotificationemail() {
    return this._checknotificationemail;
  }
  public set checknotificationemail(val) {
    this._checknotificationemail = val;
  }
  public get checknotificationsms() {
    return this._checknotificationsms;
  }
  public set checknotificationsms(val) {
    this._checknotificationsms = val;
  }
  public get header() {
    if (typeof this._booking != 'undefined') {
      return this.propertyService.getProperty(this._booking.HeaderProperty);
    }
    return {};
  }
  public get max() {
    if (typeof this._booking != 'undefined') {
      return this.propertyService.getProperty(this._booking.MaxProperty);
    }
    return {};
  }
  public get slots() {
    return this._slots;
  }
  public set slots(val) {
    this._slots = val;
  }
  public get isReload() {
    return this._isReload;
  }
  public set isReload(val) {
    this._isReload = val;
  }
  //Series
  public get series() {
    return this._series;
  }
  public get isSerie() {
    return this._isSerie;
  }
  public set isSerie(val) {
    this._isSerie = val;
  }
  public get serieOption() {
    return this._serieOption;
  }
  public set serieOption(val) {
    this._serieOption = val;
  }
  public get endOption() {
    return this._endOption;
  }
  public set endOption(val) {
    this._endOption = val;
  }
  public get includeUsers() {
    return this._includeUsers;
  }
  public set includeUsers(val) {
    this._includeUsers = val;
  }
  public get serieOptionList() {
    return [
      { Id: 1, Name: 252 },
      { Id: 2, Name: 253 }
    ];
  }
  public get serieTypeList() {
    return [
      { Id: 1, Name: this.languageService.getItem(256) },
      { Id: 2, Name: this.languageService.getItem(257) },
      { Id: 3, Name: this.languageService.getItem(258) },
      { Id: 4, Name: this.languageService.getItem(259) },
      { Id: 5, Name: this.languageService.getItem(260) },
      { Id: 6, Name: this.languageService.getItem(261) },
      { Id: 7, Name: this.languageService.getItem(262) },
      { Id: 8, Name: this.languageService.getItem(263) },
      { Id: 9, Name: this.languageService.getItem(264) },
      { Id: 10, Name: this.languageService.getItem(265) }
    ];
  }
  public get serieDayList() {
    return [
      { Id: 2, Name: this.languageService.getItem(209) },
      { Id: 4, Name: this.languageService.getItem(210) },
      { Id: 8, Name: this.languageService.getItem(211) },
      { Id: 16, Name: this.languageService.getItem(212) },
      { Id: 32, Name: this.languageService.getItem(213) },
      { Id: 64, Name: this.languageService.getItem(214) },
      { Id: 1, Name: this.languageService.getItem(215) }
    ];
  }
  //Activities
  public get activities() {
    return this._activities;
  }
  //Split Shift
  public get splittime() {
    return this._splittime;
  }
  public set splittime(val) {
    this._splittime = val;
  }
  //Container
  public get container() {
    return {
      isbit: true
    };
  }
  //Message
  public get hasCell() {
    return this._hasCell;
  }
  public get hasEmail() {
    return this._hasEmail;
  }
  public get coreChanged() {
    return this._coreChanged;
  }
  //Validation
  public get validation() {
    return this._validation;
  }




  //Methods
  public manageCoreChange(idx, val) {
    let item = this._coreChanged[idx];
    if (item != null) {
      item.Changed = (item.Original != null && item.Original != val);
    }
  }
  public saveWithSerieConfirm() {
    if (this._booking.SaveAccess > 1) {
      let element = this.saveElement.nativeElement;
      element.click();
    }
  }
  public profiledone() {
    this.saveWithSerieConfirm();
  }
  public levelchosen(e) {
    if (this.permissionService.permissions.ReloadProfile && e > 0) {
      this._loading = true;

      let url = '/api/v1/bookings/' + this.id;
      if (e > 0) {
        url += '/level/' + e;
      }

      this.dataService.tokenRequest(url, 'GET')
        .subscribe(res => {
          if (res) {
            this._booking.Access = res.Access;
            this._booking.Filled = res.Filled;
            this._booking.Max = res.Max;
            this._booking.Profile = res.Profile;

            if (e == 0) {
              //Storage
              let storage = localStorage.getItem('level');
              if (!storage) {
                storage = this.permissionService.permissions.BookingLevel;
              }
              if (storage) {
                let storageArr = storage.split('|');
                this._booking.LevelId = storageArr[0];
                if (storageArr.length > 1) {
                  this._booking.LevelName = storageArr[1];
                }
                if (storageArr.length > 2) {
                  this._booking.LevelPath = storageArr[2];
                }
              }
            }
            else if (e > 0 && e != res.LevelId) {
              //Changed Level on existing Shift
              this.dataService.tokenRequest('/api/v1/levels/' + e, 'GET')
                .subscribe(resLevel => {

                  this._booking.LevelId = resLevel.Id;
                  this._booking.LevelName = resLevel.Name;
                  this._booking.LevelPath = resLevel.Path;
                });
            }
            else {
              //Changed Level on new Shift
              this._booking.LevelId = res.LevelId;
              this._booking.LevelName = res.LevelName;
              this._booking.LevelPath = res.LevelPath;
            }
          }

          this._loading = false;
        });
    }
  }
  public load(levelid:number = 0) {

    this.resetValidation();

    this._isReload = true;
    setTimeout(() => {
      this._isReload = false;
    }, 500);

    this._loading = true;

    let url = '/api/v1/bookings/' + this.id;
    if (levelid > 0) {
      url += '/level/' + levelid;
    }

    this.dataService.tokenRequest(url, 'GET')
      .subscribe(res => {
        if (res) {

          if (this.userid > 0 && res.InstantBookAccess > 0) {
            this.dataService.tokenRequest('/api/v1/users/' + this.userid, 'GET')
              .subscribe(resUser => {

                this._user = resUser.Name;
              },
                (error) => {
                  this.userid = 0;
                }); 
          }
          else {
            this.userid = 0;
            this._user = '';
          }

          if (res.UserId > 0) {
            this.preload(res.UserId);
          }

          /*Booking*/
          if (res.Id > 0) {
            let hourdiff = (new Date(res.End).getTime() - new Date(res.Start).getTime()) / (1000 * 60 * 60);
            hourdiff -= (res.Break / 60);

            res.ShiftLength = '(' + this.generalService.formatdecimal(hourdiff) + 'h)';
          }
          else {
            if (this.levelid > 0) {
              //Get
              this.dataService.tokenRequest('/api/v1/levels/' + this.levelid, 'GET')
                .subscribe(resLevel => {

                  res.LevelId = resLevel.Id;
                  res.LevelName = resLevel.Name;
                  res.LevelPath = resLevel.Path;
                },
                  (error) => {
                    this.levelid = 0;
                  });
            }
            else {
              //Storage
              let storage = localStorage.getItem('level');
              if (!storage) {
                storage = this.permissionService.permissions.BookingLevel;
              }
              if (storage) {
                let storageArr = storage.split('|');
                res.LevelId = storageArr[0];
                if (storageArr.length > 1) {
                  res.LevelName = storageArr[1];
                }
                if (storageArr.length > 2) {
                  res.LevelPath = storageArr[2];
                }
              }
            }

            let start: any = this.viewCacheService.get('booking_start');
            if (start) {
              let defaultstart = new Date(res.Start);
              let viewstart = start.value;
              if (viewstart.getHours() > 0) {
                res.Start = this.dateTimeService.formatWithTimeZone(new Date(viewstart.getFullYear(), viewstart.getMonth(), viewstart.getDate(), viewstart.getHours(), viewstart.getMinutes()));
              }
              else {
                res.Start = this.dateTimeService.formatWithTimeZone(new Date(viewstart.getFullYear(), viewstart.getMonth(), viewstart.getDate(), defaultstart.getHours(), defaultstart.getMinutes()));
              }
            }
            let end: any = this.viewCacheService.get('booking_end');
            if (end) {
              let defaultend = new Date(res.End);
              let viewend = end.value;
              if (viewend.getHours() > 0) {
                res.End = this.dateTimeService.formatWithTimeZone(new Date(viewend.getFullYear(), viewend.getMonth(), viewend.getDate(), viewend.getHours(), viewend.getMinutes()));
              }
              else {
                res.End = this.dateTimeService.formatWithTimeZone(new Date(viewend.getFullYear(), viewend.getMonth(), viewend.getDate() - 1, defaultend.getHours(), defaultend.getMinutes()));
              }
            }
          }
          /********/

          /*Serie*/
          if (res.Serie.Id > 0) {
            this._isSerie = true;
            this._endOption = new Date(res.Serie.End) > new Date(1970, 1, 1) ? 1 : 0;
          }
          else {
            this._isSerie = false;
            this._endOption = 1;
            res.Serie = {
              Id: 0,
              Days: Math.pow(2, new Date(res.Start).getDay()),
              Times: 1,
              End: this.dateTimeService.lastDayInWeek(new Date(res.Start))
            };
          }
          /********/

          //Split Shift
          this._splittime = new Date(res.End);

          this._booking = res;
          this._loading = false;

          this._coreChanged = {
            LevelId: { Original: this._booking.Level, Changed: false },
            Status: { Original: this._booking.Status, Changed: false },
            Break: { Original: this._booking.Break, Changed: false }
          };


          if (this._booking.Id == 0) {
            this.levelchosen(this._booking.LevelId);
          }

          this.bookingdataChange.emit({
            Id: this._booking.Id,
            RemindAccess: this._booking.RemindAccess,
            Status: this._booking.Status,
            Slots: this._booking.Slots,
            Filled: this._booking.Filled,
            Max: this._booking.Max,
            SerieId: this._booking.Serie ? this._booking.Serie.Id : 0,
            Created: this._booking.Created,
            Start: this._booking.Start,
            End: this._booking.End,
            UsersAccess: this._booking.UsersAccess,
            LogAccess: this._booking.LogAccess,
            UserId: this._booking.UserId,
            AffectAllOnly: this._booking.AffectAllOnly,
            RemoveATK: this._booking.RemoveATK
          });
        }
      });
  }
  public save(option: number = 1, exclude: boolean = false) {
    
    if (!this.validatedatetime() || !this.validatebreak()) { return; }
    if (!this.validatecorevalues()) { return; }
    if (!this.propertyService.validate(this._booking.Profile)) { return; }

    let verb = 'POST';
    let path = '/api/v1/bookings/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;

      if (option > 1) {
        path += '/series/' + option;
      }
    }

    //Manage Break
    if (this.permissionService.permissions.MultipleBreak) {
      for (let i = 0; i < this._booking.Breaks.length; i++) {
        if (new Date(this._booking.Breaks[i].BreakStart) <= new Date(1970, 1, 1) || this._booking.Breaks[i].Break <= 0) {
          if (this.permissionService.permissions.BreakStart || this._booking.Breaks[i].Break <= 0) {
            this._booking.Breaks.splice(i, 1);
            i--;
          }
          else {
            this._booking.Breaks[i].BreakStart = '0001-01-01T00:00:00Z';
          }
        }
      }
    }
    if (new Date(this._booking.BreakStart) <= new Date(1970, 1, 1) || this._booking.Break <= 0) {
      this._booking.BreakStart = '0001-01-01T00:00:00Z';
    }
    

    let dto: any = {
      Start: this._booking.Start,
      End: this._booking.End,
      BreakStart: this._booking.BreakStart,
      Break: this._booking.Break,
      Breaks: this._booking.Breaks,
      Header: this._booking.Header,
      Max: this._booking.Max,
      Profile: this._booking.Profile,
      Serie: this._booking.Serie,
      RemoveATK: this._booking.RemoveATK
    };

    //Exclude when copy
    if (!exclude) {
      if (this.permissionService.permissions.HideLevels) {
        dto["LevelId"] = -1;
      }
      else {
        dto["LevelId"] = this._booking.LevelId;
      }
      dto["UserId"] = this._booking.UserId;
      if (this.permissionService.permissions.HideStatus) {
        dto["Status"] = 20;
      }
      else {
        dto["Status"] = this._booking.Status;
      }
      dto["Auto"] = this._booking.Auto;
      dto["EmploymentPlan"] = this._booking.EmploymentPlan;
    }

    //Hidden User
    if (this.userid > 0 && this._checkuser) {
      dto["BookId"] = this.userid;
      dto["ManualNotifyEmail"] = this._checknotificationemail;
      dto["ManualNotifySms"] = this._checknotificationsms;
    }

    //Series
    if (this._isSerie && this._booking.Serie.Id == 0) {
      dto["Serie"]["IncludeUsers"] = this._includeUsers;
      dto["Serie"]["Action"] = this._serieOption;
      if (this._endOption == 0) {
        dto["Serie"]["End"] = new Date('0001-01-01T00:00:00Z');
      }
      else {
        dto["Serie"]["Times"] = 0;
      }
    }

    if (this._booking.Id == 0) {
      dto["Activities"] = this._activities.filter(function (activity) { return activity.Id < 0; });
      if (dto["Activities"] != null) {
        let bStart = this._booking.Start;
        let bEnd = this._booking.End;
        for (let i = 0; i < dto["Activities"].length; i++) {
          let aStart = dto["Activities"][i].Start;
          let aEnd = dto["Activities"][i].End;
          let startOutsideSpan = (aStart < bStart || aStart > bEnd);
          let endOutsideSpan = (aEnd < bStart || aEnd > bEnd);
          if (startOutsideSpan || endOutsideSpan) {
            this.alertService.Add({ message: this.languageService.getItem(740), type: 'danger' });
            return;
          }
        }
      }
    }

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });
          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
          else {
            this.load();
            this.loadSeries();
          }
        }
      });
  }
  public copy() {

    if (!this.validatedatetime() || !this.validatebreak(true)) { return; }
    if (!this.validatecorevalues()) { return; }
    if (!this.propertyService.validate(this._booking.Profile)) { return; }

    //Manage Break
    if (this.permissionService.permissions.MultipleBreak) {
      for (let i = 0; i < this._booking.Breaks.length; i++) {
        if (new Date(this._booking.Breaks[i].BreakStart) <= new Date(1970, 1, 1) || this._booking.Breaks[i].Break <= 0) {
          if (this.permissionService.permissions.BreakStart || this._booking.Breaks[i].Break <= 0) {
            this._booking.Breaks.splice(i, 1);
            i--;
          }
          else {
            this._booking.Breaks[i].BreakStart = '0001-01-01T00:00:00Z';
          }
        }
      }
    }
    if (new Date(this._booking.BreakStart) <= new Date(1970, 1, 1) || this._booking.Break <= 0) {
      this._booking.BreakStart = '0001-01-01T00:00:00Z';
    }
   
    let dto: any = {
      CopyFrom: this.id,
      Start: this._booking.Start,
      End: this._booking.End,
      BreakStart: this._booking.BreakStart,
      Break: this._booking.Break,
      Breaks: this._booking.Breaks,
      Header: this._booking.Header,
      Max: this._booking.Max,
      LevelId: this._booking.LevelId,
      UserId: this._booking.UserId,
      Auto: this._booking.Auto,
      EmploymentPlan: this._booking.EmploymentPlan,
      Status: this._booking.Status,
      Profile: this._booking.Profile,
      RemoveATK: this._booking.RemoveATK
    };

    this.dataService.tokenRequest('/api/v1/bookings/', 'POST', dto, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });
          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
          else {
            this.load();
          }
        }
      });
  }
  public delete(option: number = 1) {

    let series = '';
    if (option > 1) {
      series = '/series/' + option;
    }

    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + series, 'DELETE', {}, 'text')
      .subscribe(res => {
        if (res) {

          this.alertService.Add({ message: res, type: 'success' });

          this.location.back();
        }
      });
  }
  //Serie
  public loadSeries() {
    if (this._booking.Serie && this._booking.Serie.Id > 0) {

      this._series = [];

      this.dataService.tokenRequest('/api/v1/bookings/series/' + this._booking.Serie.Id, 'GET')
        .subscribe(res => {
          if (res) {

            res.forEach(serie => {

              let start = new Date(serie.Start);
              let end = new Date(serie.End);

              serie.dateheader = this.dateTimeService.header(start, end, false);

              this._series.push(serie);
            });
          }
        });
    }
  }
  public deleteSerie() {

    this._booking.Serie.Id = -1;

    this.save();
  }
  public openSerie(id, e) {

    if (this._booking.Id != id) {
      e.stopPropagation();

      let url = '/bookings/' + id;

      if (e.ctrlKey || e.metaKey) {
        window.open(url, '_blank');
      }
      else {
        this.router.navigate([url]);
      }
    }
  }
  //Activities
  public loadActivities(e) {
    if (this.permissionService.permissions.Activity > 0) {

      this._activities = [];

      this.dataService.tokenRequest('/api/v1/bookings/' + this._booking.Id + '/activities', 'GET')
        .subscribe(res => {
          if (res) {

            res.forEach(activity => {

              let start = new Date(activity.Start);
              let end = new Date(activity.End);

              if (!this.permissionService.permissions.ShiftTime) {
                end = new Date(end.getTime() + (1 * 24 * 60 * 60 * 1000));
              }

              activity.dateheader = this.dateTimeService.header(start, end, false);

              this._activities.push(activity);
            });

          }

          this._activities.unshift(this.newActivity());
          if (this._activities.length > 1) {
            this._activities[0].Start = this._activities[this._activities.length - 1].End;
          }
        });
    }
  }
  public editActivity(activity, e) {
    if (this.permissionService.permissions.Activity > 1) {
      e.stopPropagation();

      activity.isedit = !activity.isedit;
    }
  }
  public saveActivity(activity, e) {
    if (activity.TypeId == 0) {
      //Take first
      activity.TypeId = this.generalService.activitytypes[0].Id;
    }

    let aStart = new Date(activity.Start);
    let aEnd = new Date(activity.End);
    let bStart = new Date(this._booking.Start);
    let bEnd = new Date(this._booking.End);
    let startOutsideSpan = (aStart < bStart || aStart > bEnd);
    let endOutsideSpan = (aEnd < bStart || aEnd > bEnd);
    if (startOutsideSpan || endOutsideSpan) {
      this.alertService.Add({ message: this.languageService.getItem(740), type: 'danger' });
      return;
    }


    activity.BookingId = this._booking.Id;

    if (this._booking.Id > 0) {
      //Booking exits
      let id = activity.Id;
      let verb = 'POST';
      var path = '/api/v1/activities/'
      if (id > 0) {
        verb = 'PUT';
        path += id;
      }

      this.dataService.tokenRequest(path, verb, activity, 'text')
        .subscribe(res => {

          activity.isedit = false;

          this.loadActivities(e);

        });
    }
    else if (this._booking.Id == 0) {
      //Booking not saved yet
      activity.isedit = false;

      if (activity.Id == 0) {
        let tmpActivity = Object.assign({}, activity); //Copy
        tmpActivity.Id = this._tmpcounter;
        this._tmpcounter--;

        let start = new Date(tmpActivity.Start);
        let end = new Date(tmpActivity.End);

        if (!this.permissionService.permissions.ShiftTime) {
          end = new Date(end.getTime() + (1 * 24 * 60 * 60 * 1000));
        }

        tmpActivity.dateheader = this.dateTimeService.header(start, end, false);

        let activitytype = this.generalService.activitytypes.filter(function (a) { return a.Id == activity.TypeId; });
        if (activitytype) {
          tmpActivity.Color = activitytype[0].Color;
        }

        this._activities.push(tmpActivity);

        if (this._activities.length > 1) {
          this._activities[0].Start = this._activities[this._activities.length - 1].End;
        }
      }
      else {
        let start = new Date(activity.Start);
        let end = new Date(activity.End);

        if (!this.permissionService.permissions.ShiftTime) {
          end = new Date(end.getTime() + (1 * 24 * 60 * 60 * 1000));
        }

        activity.dateheader = this.dateTimeService.header(start, end, false);

        let activitytype = this.generalService.activitytypes.filter(function (a) { return a.Id == activity.TypeId; });
        if (activitytype) {
          activity.Color = activitytype[0].Color;
        }
      }
    }
  }
  public deleteActivity(activity, e) {

    if (this._booking.Id > 0) {
      //Booking exits
      this.dataService.tokenRequest('/api/v1/activities/' + activity.Id, 'DELETE', {}, 'text')
        .subscribe(res => {

          activity.isedit = false;

          this.loadActivities(e);

        });
    }
    else {
      //Booking not saved yet
      let index = this._activities.indexOf(activity);
      if (index > -1) {
        this._activities.splice(index, 1);
      }
    }
  }
  public bookMyself(option: number = 1, type) {

    if (type == 3 && this.permissionService.permissions.DetailWhenAbscent) {
      //Abscent
      let url = '/timereports/' + this._booking.MySelfTimereport + '/user/' + this.permissionService.user.Id + '/type/' + type;

      this.router.navigate([url]);
      return;
    }

    let filter = {
      Type: type,
      Slots: this._slots,
      ManualNotify: true,
      ManualNotifyEmail: this._checknotificationemail,
      ManualNotifySms: this._checknotificationsms
    };

    if (option > 1) {
      filter["Series"] = option;
    }

    let remindsms = this.permissionService.permissions.RemindSms;
    if (remindsms > 1) {
      let start = new Date(this._booking.Start);
      let remind = this.dateTimeService.formatWithTimeZone(
        new Date(start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          start.getHours(),
          start.getMinutes() - this.permissionService.permissions.RemindDelay));
      filter["Remind"] = remind;
    }

    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users/' + this.permissionService.user.Id, 'POST', filter, 'text')
      .subscribe((res) => {

        this.alertService.Add({ message: res, type: 'success' });

        if (this.permissionService.permissions.SaveAfterBook) {
          this.save();
        }
        else {
          this.load();
        }

      });

  }
  public unbookMyself(option: number = 1, type) {

    let notify = 0;
    if (this._checknotificationsms) {
      notify |= 1;
    }
    if (this._checknotificationemail) {
      notify |= 2;
    }

    let extra = '/notify/' + notify;
    
    if (option > 1) {
      extra += '/series/' + option;
      extra += '/type/' + type;
    }

    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users/' + this.permissionService.user.Id + extra, 'DELETE', {}, 'text')
      .subscribe(res => {

        this.alertService.Add({ message: res, type: 'success' });

        this.load();
      });

  }
  public send(msgtype, e) {
    e.stopPropagation();

    if (this._owner != null) {

      let url = '/messages/send/';

      if (msgtype == 1) {
        url += 'email/id/' + this.id;
        url += '/serie/0/body/42/subject/43';
      }
      else if (msgtype == 3) {
        url += 'sms/id/' + this.id;
        url += '/serie/0/body/42';
      }

      let userId = this._booking.UserId;

      this.viewCacheService.add('message_users', [this._owner]);
      this.viewCacheService.add('message_disableTo', true);

      this.router.navigate([url]);
    }

  }
  public manageDateTime(e) {

    if (this.permissionService.permissions.BreakStart) {

      if (this.permissionService.permissions.MultipleBreak) {

        for (let i = 0; i < this._booking.Breaks.length; i++) {
          if (new Date(this._booking.Breaks[i].BreakStart) > new Date(1970, 1, 1)) {
            this._booking.Breaks[i].BreakStart = this.adjustbreakstart(new Date(this._booking.Start), new Date(this._booking.End), new Date(this._booking.Breaks[i].BreakStart));
          }
        }

      }
      else {

        if (new Date(this._booking.BreakStart) > new Date(1970, 1, 1)) {
          this._booking.BreakStart = this.adjustbreakstart(new Date(this._booking.Start), new Date(this._booking.End), new Date(this._booking.BreakStart));
        }
      }
    }

    //Split Shift
    this._splittime = new Date(this._booking.End);
  }
  public splitShift() {

    if (!this.validatesplit()) { return; }

    let dto = {
      'Split': this._splittime
    }

    this.dataService.tokenRequest('/api/v1/bookings/split/' + this._booking.Id, 'POST', dto, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });
          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
        }
      });
  }


  //Functions
  private adjustbreakstart(start: Date, end: Date, breakstart:Date) {

    let newBreakStart: Date = breakstart;

    if ((newBreakStart < start || end < newBreakStart)) {
      //Adjust to start date
      newBreakStart = new Date(start.getFullYear(), start.getMonth(), start.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
      if ((newBreakStart < start || end < newBreakStart)) {
        //Adjust to end date
        newBreakStart = new Date(end.getFullYear(), end.getMonth(), end.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
        if ((newBreakStart < start || end < newBreakStart)) {
          //Can't adjust
          newBreakStart = breakstart;
        }
      }
    }

    return this.dateTimeService.formatWithTimeZone(newBreakStart);
  }
  private preload(userId) {
    
    this.dataService.tokenRequest('/api/v1/users/' + userId + '/small', 'GET')
      .subscribe((res) => {

        this._owner = res;
        this._hasCell = this._owner.Cell.length > 0;
        this._hasEmail = this._owner.Email.length > 0;
      });
  }
  private validatebreak(isCopy:boolean = false) {

    let breaks: any[] = [{ Break: this._booking.Break, BreakStart: new Date(this._booking.BreakStart) }];
    if (this.permissionService.permissions.MultipleBreak) {
      breaks = this._booking.Breaks;
    }

    let res = true;
    for (let i = 0; i < breaks.length; i++) {
      let breakObj = breaks[i];

      if (this.permissionService.permissions.BreakStart && breakObj.Break > 0) {
        if (breakObj.BreakStart == null) {
          this.alertService.Add({ type: 'danger', message: this.languageService.getItem(787) });

          res = false;
          break;
        }
        else if (!isCopy && (breakObj.BreakStart < new Date(this._booking.Start) || new Date(this._booking.End) < breakObj.BreakStart)) {
          this.alertService.Add({ type: 'danger', message: this.languageService.getItem(788) });

          res = false;
          break;
        }
        else if ((new Date(breakObj.BreakStart).getTime() + (breakObj.Break * 60 * 1000)) > new Date(this._booking.End).getTime()) {
          this.alertService.Add({ type: 'danger', message: this.languageService.getItem(789) });

          res = false;
          break;
        }
      }
      else if (this.permissionService.permissions.BreakStart && breakObj.Break == 0) {
        breakObj.BreakStart = null;
      }

    }

    return this._validation.break = res;
  }
  private validatedatetime() {

    let start = new Date(this._booking.Start);
    let end = new Date(this._booking.End);

    /*Manage ShiftTime*/
    if (!this.permissionService.permissions.ShiftTime) {

      start = new Date(start.getFullYear(), start.getMonth(), start.getDate());
      end = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    }

    /*Test*/
    return this._validation.datetime = this.dateTimeService.validation(start, end);
  }
  private validatecorevalues() {

    //Header
    let header = this.propertyService.getPropertyByCoreValue('BookingHeader');
    if (header) {
      this._validation.header = this.propertyService.validate([{ Name: header.Name, Value: this._booking.Header, Property: header.Id, Access: 2 }]);
      if (!this._validation.header) {
        return false;
      }
    }

    return true;
  }
  private validatesplit() {

    let start = new Date(this._booking.Start);
    let end = new Date(this._booking.End);
    let split = new Date(this._splittime);

    /*Test*/
    if (start < split && split < end) {
      return true;
    }
    else {
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1031) });
      return false;
    }
  }
  private resetValidation() {
    this._validation = {
      datetime: true,
      break: true,
      header: true
    };
  }

  private newActivity() {
    return {
      Id: 0,
      Start: this._booking.Start,
      End: this._booking.End,
      TypeId: 0,
      Text: '',
      BookingId: 0,
      isedit: false
    };
  }
}
