/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./table.component";
import * as i3 from "../_services/language.service";
var styles_TableComponent = [];
var RenderType_TableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableComponent, data: {} });
export { RenderType_TableComponent as RenderType_TableComponent };
function View_TableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "td", [["style", "border-top:0; border-bottom:0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "font-italic"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(996); _ck(_v, 5, 0, currVal_0); }); }
export function View_TableComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.table && (_co.table.length == 0)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-table", [], null, null, null, View_TableComponent_0, RenderType_TableComponent)), i0.ɵdid(1, 638976, null, 0, i2.TableComponent, [i3.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableComponentNgFactory = i0.ɵccf("swe-table", i2.TableComponent, View_TableComponent_Host_0, { data: "data", loading: "loading" }, {}, ["*"]);
export { TableComponentNgFactory as TableComponentNgFactory };
