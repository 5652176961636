/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layout/pageheader.component.ngfactory";
import * as i2 from "../../layout/pageheader.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "../../layout/card.component.ngfactory";
import * as i6 from "../../layout/card.component";
import * as i7 from "../../_services/language.service";
import * as i8 from "../../element/properties.component.ngfactory";
import * as i9 from "../../element/properties.component";
import * as i10 from "../../_services/property.service";
import * as i11 from "../../_services/permission.service";
import * as i12 from "../../_services/datetime.service";
import * as i13 from "../../_services/setting.service";
import * as i14 from "../../_services/menu.service";
import * as i15 from "../../element/confirm.component.ngfactory";
import * as i16 from "../../element/confirm.component";
import * as i17 from "./multiobjects.component";
import * as i18 from "../../_services/general.service";
import * as i19 from "../../_services/data.service";
import * as i20 from "../../_services/list.service";
import * as i21 from "../../_services/viewcache.service";
import * as i22 from "../../_services/alert.service";
var styles_MultiObjectsComponent = [];
var RenderType_MultiObjectsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MultiObjectsComponent, data: {} });
export { RenderType_MultiObjectsComponent as RenderType_MultiObjectsComponent };
export function View_MultiObjectsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-pageheader", [], null, null, null, i1.View_PageHeaderComponent_0, i1.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageHeaderComponent, [i3.Location, i4.ActivatedRoute, i4.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 15, "div", [["class", "row d-block py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 14, "div", [["class", "col-12 col-md-4 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 13, "swe-card", [], null, null, null, i5.View_CardComponent_0, i5.RenderType_CardComponent)), i0.ɵdid(5, 114688, null, 0, i6.CardComponent, [i7.LanguageService], { header: [0, "header"], open: [1, "open"], hasCollapse: [2, "hasCollapse"], hasRefresh: [3, "hasRefresh"] }, null), (_l()(), i0.ɵeld(6, 0, null, 3, 1, "swe-properties", [], null, null, null, i8.View_PropertiesComponent_0, i8.RenderType_PropertiesComponent)), i0.ɵdid(7, 114688, null, 0, i9.PropertiesComponent, [i7.LanguageService, i10.PropertyService, i11.PermissionService, i12.DateTimeService, i13.SettingService, i14.MenuService], { label: [0, "label"], statusLabel: [1, "statusLabel"], items: [2, "items"], properties: [3, "properties"] }, null), (_l()(), i0.ɵeld(8, 0, null, 3, 4, "swe-confirm", [], null, [[null, "acceptChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("acceptChange" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_ConfirmComponent_0, i15.RenderType_ConfirmComponent)), i0.ɵdid(9, 638976, null, 0, i16.ConfirmComponent, [i7.LanguageService], { body: [0, "body"] }, { acceptChange: "acceptChange" }), (_l()(), i0.ɵeld(10, 0, null, 0, 2, "button", [["class", "btn btn-primary btn-block mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "i", [["class", "fas fa-fw fa-save"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["\u00A0", ""])), (_l()(), i0.ɵeld(13, 0, null, 3, 4, "swe-confirm", [], null, [[null, "acceptChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("acceptChange" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_ConfirmComponent_0, i15.RenderType_ConfirmComponent)), i0.ɵdid(14, 638976, null, 0, i16.ConfirmComponent, [i7.LanguageService], { body: [0, "body"] }, { acceptChange: "acceptChange" }), (_l()(), i0.ɵeld(15, 0, null, 0, 2, "button", [["class", "btn btn-danger btn-block mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "i", [["class", "fas fa-fw fa-trash"]], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(410); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.header, ""); var currVal_2 = true; var currVal_3 = false; var currVal_4 = false; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.languageService.getItem(415); var currVal_6 = 2; var currVal_7 = _co.chosenproperties; var currVal_8 = _co.properties; _ck(_v, 7, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.confirmsave(); _ck(_v, 9, 0, currVal_9); var currVal_11 = _co.languageService.getItem(777); _ck(_v, 14, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.languageService.getItem(778); _ck(_v, 12, 0, currVal_10); var currVal_12 = _co.languageService.getItem(779); _ck(_v, 17, 0, currVal_12); }); }
export function View_MultiObjectsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-multiobjects", [], null, null, null, View_MultiObjectsComponent_0, RenderType_MultiObjectsComponent)), i0.ɵdid(1, 114688, null, 0, i17.MultiObjectsComponent, [i7.LanguageService, i11.PermissionService, i18.GeneralService, i19.DataService, i20.ListService, i21.ViewCacheService, i22.AlertService, i13.SettingService, i3.Location, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultiObjectsComponentNgFactory = i0.ɵccf("swe-multiobjects", i17.MultiObjectsComponent, View_MultiObjectsComponent_Host_0, {}, {}, []);
export { MultiObjectsComponentNgFactory as MultiObjectsComponentNgFactory };
