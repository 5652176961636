/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../layout/card.component.ngfactory";
import * as i3 from "../../layout/card.component";
import * as i4 from "../../_services/language.service";
import * as i5 from "../../layout/list.component.ngfactory";
import * as i6 from "../../layout/list.component";
import * as i7 from "../../layout/pageheader.component.ngfactory";
import * as i8 from "../../layout/pageheader.component";
import * as i9 from "@angular/router";
import * as i10 from "./integrations.component";
import * as i11 from "../../_services/permission.service";
import * as i12 from "../../_services/data.service";
import * as i13 from "../../_services/alert.service";
import * as i14 from "../../_services/setting.service";
var styles_IntegrationsComponent = [];
var RenderType_IntegrationsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IntegrationsComponent, data: {} });
export { RenderType_IntegrationsComponent as RenderType_IntegrationsComponent };
function View_IntegrationsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-swe"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.run(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-sync"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(878); _ck(_v, 2, 0, currVal_0); }); }
function View_IntegrationsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(879); _ck(_v, 1, 0, currVal_0); }); }
function View_IntegrationsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "list-group-item list-group-item-action d-flex flex-wrap swe-click"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "swe-disabled-block": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "col-12 col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "col-12 col-md-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IntegrationsComponent_3)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IntegrationsComponent_4)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "list-group-item list-group-item-action d-flex flex-wrap swe-click"; var currVal_1 = _ck(_v, 3, 0, !_v.context.$implicit.Active); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = !_v.context.$implicit.IsRunning; _ck(_v, 10, 0, currVal_4); var currVal_5 = _v.context.$implicit.IsRunning; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.Name; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.Desc; _ck(_v, 7, 0, currVal_3); }); }
function View_IntegrationsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "swe-card", [], null, [[null, "refreshChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("refreshChange" === en)) {
        var pd_0 = (_co.load() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i3.CardComponent, [i4.LanguageService], { open: [0, "open"], hasCollapse: [1, "hasCollapse"], hasRefresh: [2, "hasRefresh"] }, { refreshChange: "refreshChange" }), (_l()(), i0.ɵeld(2, 0, null, 3, 3, "swe-list", [], null, null, null, i5.View_ListComponent_0, i5.RenderType_ListComponent)), i0.ɵdid(3, 638976, null, 0, i6.ListComponent, [i4.LanguageService], { data: [0, "data"], loading: [1, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_IntegrationsComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.integrations; var currVal_4 = _co.isloading; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = _co.integrations; _ck(_v, 5, 0, currVal_5); }, null); }
export function View_IntegrationsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-pageheader", [], null, null, null, i7.View_PageHeaderComponent_0, i7.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i8.PageHeaderComponent, [i1.Location, i9.ActivatedRoute, i9.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IntegrationsComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(877); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.permissionService.permissions.Integration > 0); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_IntegrationsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-integrations", [], null, null, null, View_IntegrationsComponent_0, RenderType_IntegrationsComponent)), i0.ɵdid(1, 114688, null, 0, i10.IntegrationsComponent, [i4.LanguageService, i11.PermissionService, i12.DataService, i13.AlertService, i14.SettingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationsComponentNgFactory = i0.ɵccf("app-integrations", i10.IntegrationsComponent, View_IntegrationsComponent_Host_0, {}, {}, []);
export { IntegrationsComponentNgFactory as IntegrationsComponentNgFactory };
