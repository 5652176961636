<div (dragover)="prepareScroll($event)">
  <swe-timelineheader [data]="data" [settings]="settings" [all]="all" (groupbyChange)="changedByGroupBy($event)" (sumoptionsChange)="changedBySumOptions($event)"></swe-timelineheader>
  <ng-container *ngFor="let row of rows">
    <div [ngStyle]="{'overflow': (row.GroupById == settings.detail) ? 'unset' : 'hidden'}" class="" [nopadding]="true" [index]="3" [topadjustmentclass]="settings.showhour? 'pt-4' : ''" sweFixedHeader *ngIf="row.GroupById==0&&settings.emptyRowPinned">
      <swe-timelinerow [row]="row" [dofilter]="dofilter" [notifyemail]="notifyemail" [notifysms]="notifysms" [settings]="settings" (settingsChange)="changedBySettings($event)"></swe-timelinerow>
    </div>
    <div [ngStyle]="{'overflow': (row.GroupById == settings.detail) ? 'unset' : 'hidden'}" *ngIf="row.GroupById!=0||!settings.emptyRowPinned">
      <swe-timelinerow [row]="row" [dofilter]="dofilter" [notifyemail]="notifyemail" [notifysms]="notifysms" [settings]="settings" (settingsChange)="changedBySettings($event)"></swe-timelinerow>
    </div>
  </ng-container>
  <div class="list-group-item" *ngIf="more&&!dofilter">
    <button class="btn btn-block btn-primary" (click)="changedByMore()">{{languageService.getItem(57)}}</button>
  </div>
</div>


<div class="swe-scroll-spot up" id="ScrollUp" (dragover)="doScroll($event)" *ngIf="scrollup">
  <i class="fas fa-fw fa-3x fa-arrow-up"></i>
</div>
<div class="swe-scroll-spot down" id="ScrollDown" (dragover)="doScroll($event)" *ngIf="scrolldown">
  <i class="fas fa-fw fa-3x fa-arrow-down"></i>
</div>
