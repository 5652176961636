import { Component, Input, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';

@Component({
  selector: 'swe-infotext',
  templateUrl: './infotext.component.html',
})
export class InfoTextComponent implements OnInit {

  @Input() text: string = '';
  @Input() preventdrowning: boolean = false;
  @Input() showtitle: boolean = false;

  private _showhelp: boolean = false;
  private _title: string = '';

  constructor(
    public languageService: LanguageService
  ) {
    
  }

  ngOnInit() {
    if (this.showtitle) {
      this._title = this.text;
    }
    else {
      this._title = this.languageService.getItem(530);
    }
  }


  /*Properties*/
  public get showhelp(){
    return this._showhelp;
  }
  public set showhelp(val) {
    this._showhelp = val;
  }
  public get title() {
    return this._title;
  }




  /*Methods*/



  /*Functions*/

}
