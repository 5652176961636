<swe-card [header]="languageService.getItem(376)" [hasRefresh]="false" [hasCollapse]="true">
  <swe-element [type]="'System.String'" [label]="languageService.getItem(379)" [statusLabel]="1" [(model)]="message.Type" [access]="1"></swe-element>
  <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(377)" [statusLabel]="1" [(model)]="message.CreatedTime" [access]="1"></swe-element>
  <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(378)" [statusLabel]="1" [(model)]="message.SendTime" [access]="1"></swe-element>
  <swe-element [type]="'System.String'" [label]="languageService.getItem(322)" [statusLabel]="1" [(model)]="message.FullAddress" [access]="1"></swe-element>
  <swe-element [type]="'System.String'" [label]="languageService.getItem(327)" [statusLabel]="1" [(model)]="message.Subject" [access]="1" *ngIf="message.Subject&&message.Subject.length>0"></swe-element>
  <swe-element [type]="'System.TextArea'" [label]="languageService.getItem(328)" [statusLabel]="1" [(model)]="message.Body" [access]="1"></swe-element>
  <div class="form-group mt-3">
    <div *ngFor="let attachment of message.AttachmentList">
      <a class="text-primary swe-click" (click)="download(attachment)"><i class="fas fa-fw fa-swe fa-paperclip"></i>&nbsp;{{attachment.Original}}</a>
    </div>
  </div>
</swe-card>
