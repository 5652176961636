<swe-card header="{{languageService.getItem(241)}}" [(open)]="open" (refreshChange)="load()" (openChange)="load()">
  <span swe-rightcommand></span>
  <swe-datepager [type]="'booking'" (dateChange)="load()" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <div class="row py-2" *ngIf="!loading">
    <div class="col-12 col-md-6">
      <canvas baseChart
              [data]="shiftdata"
              [labels]="shiftlabels"
              [colors]="shiftcolors"
              [options]="shiftoptions"
              [chartType]="charttype"
              [plugins]="chartPlugins">
      </canvas>
    </div>
    <div class="col-12 col-md-6">
      <canvas baseChart
              [data]="smsdata"
              [labels]="smslabels"
              [colors]="smscolors"
              [options]="smsoptions"
              [chartType]="charttype"
              [plugins]="chartPlugins">
      </canvas>
    </div>
  </div>
</swe-card>
