import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { DataService } from '../../_services/data.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { AlertService } from '../../_services/alert.service';
import { Location } from '@angular/common';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';


@Component({
  selector: 'swe-multiusermarkeddates',
  templateUrl: './multiusermarkeddates.component.html'
})
export class MultiUserMarkedDatesComponent implements OnInit {

  private _status: number = 0;
  private _properties: any[] = [];
  private _chosenproperties: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private settingService: SettingService,
    private location: Location) {

    //Reset
    settingService.initView();
  }

  ngOnInit() {
    this.load();
  }


  //Properties
  public get status() {
    return this._status;
  }
  public set status(val:number) {
    this._status = val;
  }
  public get properties() {
    return this._properties;
  }
  public get chosenproperties() {
    return this._chosenproperties;
  }




  //Methods
  public confirmsave() {
    let confirmtext = '';

    //Be sure that this is an integer
    let iStatus = parseInt(this._status.toString());

    if (iStatus > 0) {
      let statusname = '';
      let statusObj = this.listService.find(this.generalService.markeddatetypes, 'Id', iStatus);
      if (statusObj) {
        statusname = statusObj.Name;
      }

      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(427).replace('{0}', statusname);
    }

    if (this._chosenproperties.length > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(428).replace('{0}', this._chosenproperties.length);
    }
    
    return this.languageService.getItem(673).replace('{0}', confirmtext);
  }
  public save() {
    let usermarkeddates:any = this.viewCacheService.get('multi_usermarkeddates');

    if (usermarkeddates) {

      let profile: any[] = [];
      this._chosenproperties.forEach(property => {
        profile.push({ Property: property.Id, Value: property.Val });
      });

      let filter = {
        IdList: usermarkeddates.value,
        Status: this._status,
        Profiles: profile
      };

      this.dataService.tokenRequest('/api/v1/usermarkeddates/multi', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(671), type: 'danger' });
    }
  }
  public delete() {
    let usermarkeddates: any = this.viewCacheService.get('multi_usermarkeddates');

    if (usermarkeddates) {
      let filter = {
        IdList: usermarkeddates.value
      };

      this.dataService.tokenRequest('/api/v1/usermarkeddates/multi/delete', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(671), type: 'danger' });
    }
  }



  //Functions
  private load() {

    this.dataService.tokenRequest('/api/v1/properties/type/multiusermarkeddate', 'GET', {})
      .subscribe((res) => {

        let groupbyList: any[] = [];
        res.forEach((item) => {
          let groupby = this.listService.find(groupbyList, 'Name', item.Category);
          if (groupby) {
            groupby.Items.push({ Id: item.Id, Name: item.Name });
          }
          else {
            groupbyList.push({ Name: item.Category, Items: [{ Id: item.Id, Name: item.Name }] });
          }
        });

        this._properties = groupbyList;
      });
  }

}
