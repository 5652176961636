<div (click)="open($event)">
  <!-- Trigger modal -->
  <ng-content>
  </ng-content>
</div>

<!-- Modal -->
<div class="modal fade" [ngClass]="{'d-block show': visible}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{header}}</h5>
        <a href="javascript:void(0);" class="swe-no-link" (click)="close()" *ngIf="!hideClose"><i class="fas fa-fw fa-times fa-pull-right"></i></a>
      </div>
      <div class="modal-body">
        {{body}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="accept()" *ngIf="isAlert" #btnAccept>{{okText}}</button>
        <button type="button" class="btn btn-primary" (click)="accept()" *ngIf="!isAlert" #btnAccept>{{yesText}}</button>
        <button type="button" class="btn btn-secondary" (click)="decline()" *ngIf="!isAlert">{{noText}}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="visible"></div>
