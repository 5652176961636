import * as i0 from "@angular/core";
import * as i1 from "./list.service";
export class ViewCacheService {
    constructor(listService) {
        this.listService = listService;
        this._cache = [];
    }
    //Methods
    get(name) {
        let item = this.listService.find(this._cache, 'name', name, true);
        return item;
    }
    add(name, value) {
        let item = this.listService.find(this._cache, 'name', name);
        if (item) {
            item.value = value;
        }
        else {
            this._cache.push({ name: name, value: value });
        }
    }
}
ViewCacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ViewCacheService_Factory() { return new ViewCacheService(i0.ɵɵinject(i1.ListService)); }, token: ViewCacheService, providedIn: "root" });
export class ViewCacheItem {
}
