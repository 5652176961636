/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./adminrule.component";
import * as i3 from "../_services/language.service";
import * as i4 from "../_services/setting.service";
import * as i5 from "../_services/permission.service";
import * as i6 from "@angular/router";
var styles_AdminRuleComponent = [];
var RenderType_AdminRuleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminRuleComponent, data: {} });
export { RenderType_AdminRuleComponent as RenderType_AdminRuleComponent };
function View_AdminRuleComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goto(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_AdminRuleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "swe-adminmode-text alert-warning"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminRuleComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rulelist; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AdminRuleComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "d-inline-block"]], null, null, null, null, null))], null, null); }
function View_AdminRuleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "i", [["class", "fas fa-fw fa-swe fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showrulelist = !_co.showrulelist) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "fa-thumbtack text-warning": 0, "fa-times": 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminRuleComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminRuleComponent_4)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fas fa-fw fa-swe fa-pull-right swe-click"; var currVal_1 = _ck(_v, 4, 0, !_co.showrulelist, _co.showrulelist); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.showrulelist; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.preventdrowning; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_AdminRuleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminRuleComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.settingService.adminmode && _co.permissionService.user.IsSuper); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AdminRuleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-adminrule", [], null, null, null, View_AdminRuleComponent_0, RenderType_AdminRuleComponent)), i0.ɵdid(1, 114688, null, 0, i2.AdminRuleComponent, [i3.LanguageService, i4.SettingService, i5.PermissionService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminRuleComponentNgFactory = i0.ɵccf("swe-adminrule", i2.AdminRuleComponent, View_AdminRuleComponent_Host_0, { rulelist: "rulelist", class: "class", preventdrowning: "preventdrowning" }, {}, []);
export { AdminRuleComponentNgFactory as AdminRuleComponentNgFactory };
