import * as i0 from "@angular/core";
import * as i1 from "./data.service";
import * as i2 from "./alert.service";
import * as i3 from "./language.service";
export class PropertyService {
    constructor(dataService, alertService, languageService) {
        this.dataService = dataService;
        this.alertService = alertService;
        this.languageService = languageService;
        this._propertiesAll = [];
        this.getPropertiesAll();
    }
    /*Properties*/
    get propertiesAll() {
        return this._propertiesAll;
    }
    getProperty(id) {
        let property = {};
        for (var i = 0; i < this._propertiesAll.length; i++) {
            if (this._propertiesAll[i].Id == id) {
                property = this._propertiesAll[i];
                break;
            }
        }
        return property;
    }
    getPropertyValueDependency(id) {
        let property = {};
        for (var i = 0; i < this._propertiesAll.length; i++) {
            if (this._propertiesAll[i].DependencyId == id) {
                property = this._propertiesAll[i];
                break;
            }
        }
        return property;
    }
    getPropertyByCoreValue(core) {
        let property = null;
        for (var i = 0; i < this._propertiesAll.length; i++) {
            if (this._propertiesAll[i].Core == core) {
                property = this._propertiesAll[i];
                break;
            }
        }
        return property;
    }
    reload() {
        this.getPropertiesAll();
    }
    /*Validation*/
    validate(profileList) {
        let res = true;
        profileList.forEach((profile) => {
            profile.Invalid = false;
            if (res && profile.Access > 1) {
                let property = this.getProperty(profile.Property);
                if (!property.IsSecret && !property.IsOptional && (profile.Value == null || profile.Value.length == 0)) {
                    //Not optional
                    this.alertService.Add({ message: this.languageService.getItem(66).replace('{0}', profile.Name), type: 'danger' });
                    res = false;
                    profile.Invalid = true;
                }
                else if (property.RegEx.length > 0 && profile.Value != null && profile.Value.length > 0 && profile.Value.match(property.RegEx) == null) {
                    //Wrong format
                    let msg = this.languageService.getItem(67).replace('{0}', profile.Name);
                    if (property.Description && property.Description.length > 0) {
                        msg += ' ' + this.languageService.getItem(960).replace('{0}', property.Description);
                    }
                    this.alertService.Add({ message: msg, type: 'danger' });
                    res = false;
                    profile.Invalid = true;
                }
            }
        });
        return res;
    }
    validateEmail(email, name = '') {
        if (email != null && email.length > 0 && email.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$') == null) {
            //Wrong format
            let msg = this.languageService.getItem(67).replace('{0}', name);
            this.alertService.Add({ message: msg, type: 'danger' });
            return false;
        }
        return true;
    }
    //Methods
    getPropertiesByType(type) {
        if (this._propertiesAll.length == 0) {
            return null;
        }
        let properties = [];
        this._propertiesAll.forEach((property) => {
            if (property['CatType'] == type) {
                properties.push(property);
            }
        });
        return properties;
    }
    /*Functions*/
    getPropertiesAll() {
        this.dataService.basicRequest('/api/v1/properties', 'GET').subscribe(res => {
            if (res) {
                this._propertiesAll = res;
            }
        });
    }
}
PropertyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PropertyService_Factory() { return new PropertyService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.AlertService), i0.ɵɵinject(i3.LanguageService)); }, token: PropertyService, providedIn: "root" });
