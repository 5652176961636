/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../layout/pageheader.component.ngfactory";
import * as i2 from "../layout/pageheader.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./details/timereportdata.component.ngfactory";
import * as i6 from "./details/timereportdata.component";
import * as i7 from "../_services/language.service";
import * as i8 from "../_services/data.service";
import * as i9 from "../_services/alert.service";
import * as i10 from "../_services/setting.service";
import * as i11 from "../_services/permission.service";
import * as i12 from "../_services/general.service";
import * as i13 from "../_services/datetime.service";
import * as i14 from "../_services/list.service";
import * as i15 from "../_services/property.service";
import * as i16 from "./timereportdetail.component";
import * as i17 from "../_services/viewcache.service";
var styles_TimereportDetailComponent = [];
var RenderType_TimereportDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimereportDetailComponent, data: {} });
export { RenderType_TimereportDetailComponent as RenderType_TimereportDetailComponent };
function View_TimereportDetailComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["swe-hits", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u00A0(#", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 1, 0, currVal_0); }); }
function View_TimereportDetailComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-swe"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-arrow-left"]], null, null, null, null, null))], null, null); }
function View_TimereportDetailComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "my-2 mx-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", "/", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentindex + 1); var currVal_1 = _co.othertimereports.length; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TimereportDetailComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-swe"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-arrow-right"]], null, null, null, null, null))], null, null); }
function View_TimereportDetailComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(1047); _ck(_v, 1, 0, currVal_0); }); }
export function View_TimereportDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "swe-pageheader", [], null, null, null, i1.View_PageHeaderComponent_0, i1.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageHeaderComponent, [i3.Location, i4.ActivatedRoute, i4.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_TimereportDetailComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "text-md-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimereportDetailComponent_2)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimereportDetailComponent_3)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimereportDetailComponent_4)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimereportDetailComponent_5)), i0.ɵdid(13, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "div", [["class", "col-12 col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "swe-timereportdata", [], null, [[null, "typeChange"], [null, "headerChange"], [null, "idChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("typeChange" === en)) {
        var pd_0 = ((_co.othertimereports[_co.currentindex].type = $event) !== false);
        ad = (pd_0 && ad);
    } if (("headerChange" === en)) {
        var pd_1 = (_co.changeHeader($event) !== false);
        ad = (pd_1 && ad);
    } if (("idChange" === en)) {
        var pd_2 = (_co.changeId($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_TimereportDataComponent_0, i5.RenderType_TimereportDataComponent)), i0.ɵdid(17, 704512, null, 0, i6.TimereportDataComponent, [i7.LanguageService, i8.DataService, i9.AlertService, i10.SettingService, i11.PermissionService, i12.GeneralService, i13.DateTimeService, i14.ListService, i15.PropertyService, i4.ActivatedRoute, i4.Router, i3.Location], { id: [0, "id"], othertimereports: [1, "othertimereports"], open: [2, "open"], userid: [3, "userid"], type: [4, "type"] }, { typeChange: "typeChange", headerChange: "headerChange", idChange: "idChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.header.length > 0); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.currentindex > 0); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.othertimereports.length > 1); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.currentindex < (_co.othertimereports.length - 1)); _ck(_v, 11, 0, currVal_4); var currVal_5 = (_co.othertimereports && (_co.othertimereports[_co.currentindex].type == 3)); _ck(_v, 13, 0, currVal_5); var currVal_6 = _co.id; var currVal_7 = _co.othertimereports; var currVal_8 = _co.cards.data; var currVal_9 = _co.userid; var currVal_10 = _co.othertimereports[_co.currentindex].type; _ck(_v, 17, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
export function View_TimereportDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-timereportdetail", [], null, null, null, View_TimereportDetailComponent_0, RenderType_TimereportDetailComponent)), i0.ɵdid(1, 114688, null, 0, i16.TimereportDetailComponent, [i7.LanguageService, i10.SettingService, i17.ViewCacheService, i4.Router, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimereportDetailComponentNgFactory = i0.ɵccf("app-timereportdetail", i16.TimereportDetailComponent, View_TimereportDetailComponent_Host_0, {}, {}, []);
export { TimereportDetailComponentNgFactory as TimereportDetailComponentNgFactory };
