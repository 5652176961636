import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { ListService } from '../../_services/list.service';
import { SettingService } from '../../_services/setting.service';
import { KeyValue } from '@angular/common';



@Component({
  selector: 'app-obrules',
  templateUrl: './obrules.component.html'
})
export class OBRulesComponent implements OnInit {

  private _rules: any = {};
  private _ruleList: any[] = [];
  private _loading: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private listService: ListService,
    private router: Router,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {

    this.load();
  }



  //Properties
  public get rules() {
    return this._rules;
  }
  public get ruleList() {
    return this._ruleList;
  }
  public get isloading() {
    return this._loading;
  }

  //Comparer
  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }


  //Methods
  public load() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/overtime/groups', 'Get', {})
      .subscribe((groups) => { 

        let grouplist: any[] = [];
        groups.forEach((group) => {
          grouplist.push({ Id: group.Key, Name: group.Value, Sort: group.Extra });
        });

        this.dataService.tokenRequest('/api/v1/overtime', 'Get', {})
          .subscribe((res) => {

            this._ruleList = res;
            this._rules = this.listService.groupByInt(res, 'GroupId', grouplist);

            this._loading = false;
          });
      });
  }
  public goto(id: number) {
    this.router.navigate(['/admin/overtime', id]);
  }





}
