/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./confirmserie.component";
import * as i3 from "../_services/language.service";
var styles_ConfirmSerieComponent = [];
var RenderType_ConfirmSerieComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmSerieComponent, data: {} });
export { RenderType_ConfirmSerieComponent as RenderType_ConfirmSerieComponent };
function View_ConfirmSerieComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "modal-backdrop fade show"]], null, null, null, null, null))], null, null); }
export function View_ConfirmSerieComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { focusElement: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "d-inline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(3, 0, null, null, 17, "div", [["class", "modal fade"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(6, { "d-block show": 0 }), (_l()(), i0.ɵeld(7, 0, null, null, 13, "div", [["class", "modal-dialog modal-dialog-centered modal-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 12, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["class", "swe-no-link"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["class", "fas fa-fw fa-times fa-pull-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 6, "div", [["class", "modal-body text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, [[1, 0], ["btnAccept", 1]], null, 1, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept(1, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept(2, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(17, null, ["", ""])), (_l()(), i0.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept(3, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(19, null, ["", ""])), (_l()(), i0.ɵeld(20, 0, null, null, 0, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmSerieComponent_1)), i0.ɵdid(22, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal fade"; var currVal_1 = _ck(_v, 6, 0, _co.visible); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_7 = _co.visible; _ck(_v, 22, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.affectAllOnly; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.languageService.getItem(406); _ck(_v, 15, 0, currVal_3); var currVal_4 = _co.affectAllOnly; _ck(_v, 16, 0, currVal_4); var currVal_5 = _co.languageService.getItem(407); _ck(_v, 17, 0, currVal_5); var currVal_6 = _co.languageService.getItem(408); _ck(_v, 19, 0, currVal_6); }); }
export function View_ConfirmSerieComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-confirmserie", [], null, null, null, View_ConfirmSerieComponent_0, RenderType_ConfirmSerieComponent)), i0.ɵdid(1, 638976, null, 0, i2.ConfirmSerieComponent, [i3.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmSerieComponentNgFactory = i0.ɵccf("swe-confirmserie", i2.ConfirmSerieComponent, View_ConfirmSerieComponent_Host_0, { affectAllOnly: "affectAllOnly", isSerie: "isSerie", isvisible: "isvisible" }, { acceptChange: "acceptChange" }, ["*"]);
export { ConfirmSerieComponentNgFactory as ConfirmSerieComponentNgFactory };
