<swe-pageheader [header]="languageService.getItem(289)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<div class="swe-fill-to-edge swe-print-remove">
  <!--DateTime Pager-->
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <!--Reports-->
  <div class="btn-group mx-1 align-top" [attr.title]="languageService.getItem(289)">
    <button [attr.aria-label]="languageService.getItem(289)" class="btn btn-swe dropdown" (click)="reportmenu=!reportmenu">
      <span>{{settingService.report.name.length > 0 ? settingService.report.name : languageService.getItem(308)}}&nbsp;<i class="fas fa-caret-down"></i></span>
    </button>
    <button [attr.aria-label]="languageService.getItem(883)" class="btn btn-swe" (click)="editmenu=!editmenu">
      <span><i class="fas fa-fw fa-edit" [ngClass]="{'swe-grayed-out': editmenu}"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': reportmenu }">
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ReportNew" (click)="changereport({Id: 0})"><i class="fas fa-fw" [ngClass]="{'fa-check': settingService.report.id==0}"></i>{{languageService.getItem(308)}}</li>
      <ng-container *ngFor="let group of reports | keyvalue">
        <li class="dropdown-divider"></li>
        <li class="dropdown-header">{{group.value.header}}</li>
        <li class="dropdown-item swe-click" *ngFor="let item of group.value.items" (click)="changereport(item)"><i class="fas fa-fw" [ngClass]="{'fa-check': settingService.report.id==item.Id}"></i>{{item.Name}}</li>
      </ng-container>
    </ul>
  </div>
  <!--All Button-->
  <div class="btn-group ml-1 align-top" *ngIf="all">
    <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
  </div>
  <!--Active Filters-->
  <swe-filteritems></swe-filteritems>
</div>
<!--Report table-->
<div class="row py-2">
  <div class="col-12 col-md-4" *ngIf="editmenu">
    <swe-card [header]="settingService.report.name.length > 0 ? settingService.report.name : languageService.getItem(308)" (refreshChange)="search(false)" [hasCollapse]="false" [hasSave]="true" (saveChange)="save()">
      <span swe-rightcommand>
        <i class="fas fa-fw fa-times fa-pull-right swe-click" (click)="editmenu=false"></i>
        <i class="fas fa-fw fa-external-link-alt fa-pull-right swe-click" (click)="goto()" *ngIf="settingService.report.id>0"></i>
      </span>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(307)" [(model)]="settingService.report.name" [disabled]="!editpermissions" [type]="'System.String'"></swe-element>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(861)" [(model)]="settingService.report.description" [disabled]="!editpermissions" [type]="'System.TextArea'"></swe-element>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(306)" [(model)]="settingService.report.main" (modelChange)="manageExcludes()" [items]="maintypes" [type]="'System.List'"></swe-element>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(156)" [(model)]="settingService.report.reports" [items]="reportproperties" [disabled]="!editpermissions" [type]="'System.GroupByMultiList'" [container]="{max: 20}"></swe-element>
      <swe-element [statusLabel]="1" [label]="languageService.getItem(833)" [(model)]="settingService.report.visibility" [items]="reportvisibility" [disabled]="settingService.report.id>0" [type]="'System.GroupByList'"></swe-element>
      <swe-card [header]="languageService.getItem(359)" [color]="'swe'" [hasCollapse]="true" [hasRefresh]="false" [open]="false">
        <swe-element [statusLabel]="1" [label]="languageService.getItem(274)" [(model)]="settingService.report.groupby" [items]="groupbyproperties" [disabled]="!editpermissions" [type]="'System.List'" [optional]="true"></swe-element>
        <swe-element [statusLabel]="1" [label]="languageService.getItem(948)" [(model)]="settingService.report.excludeobject" [disabled]="!editpermissions" [items]="excludetypes" [container]="{isbit:true, min: 3}" [type]="'System.MultiList'"></swe-element>
      </swe-card>
      <div>
        <i class="small">{{languageService.getItem(704)}}</i>
        <button class="btn btn-primary btn-block mb-2" (click)="save()"><i class="fas fa-fw fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-primary btn-block mb-2" (click)="copy()" *ngIf="settingService.report.id>0&&permissionService.permissions.ReportNew"><i class="fas fa-fw fa-copy"></i>&nbsp;{{languageService.getItem(835)}}</button>
        <swe-confirm *ngIf="settingService.report.id>0" [body]="languageService.getItem(767)" (acceptChange)="delete()">
          <button class="btn btn-danger btn-block mb-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <div class="swe-print-full-width" [ngClass]="{'col-12': !editmenu, 'col-md-8': editmenu}">
    <swe-card [header]="settingService.report.description" [hasCollapse]="false" (refreshChange)="search(false)" [fitcontent]="true">
      <span class="swe-print-add" swe-rightcommand>
        &nbsp;{{interval()}}
      </span>
      <div class="d-inline swe-print-remove" swe-rightcommand>
        <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="open(0, $event)" *ngIf="createvisible" [attr.title]="languageService.getItem(687)"></i>
        <div class="btn-group mr-2 float-right" *ngIf="templates.length>0">
          <div class="d-inline-flex">
            <i class="fas fa-fw fa-caret-down swe-click" (click)="downloadmenu=!downloadmenu;$event.stopPropagation()" [attr.title]="languageService.getItem(852)"></i>
            <ul class="dropdown-menu dropdown-menu-right" [ngClass]="{'show': downloadmenu }">
              <li class="dropdown-item swe-click" (click)="download(0, $event)"><a><i class="fas fa-fw fa-download"></i>&nbsp;CSV</a></li>
              <li class="dropdown-item swe-click" *ngFor="let template of templates" (click)="download(template.Key, $event)"><a><i class="fas fa-fw fa-download"></i>&nbsp;{{template.Value}}</a></li>
            </ul>
          </div>
        </div>
        <i class="fas fa-fw fa-download fa-pull-right swe-click" (click)="download(0, $event)" [attr.title]="languageService.getItem(851)"></i>
        <div class="btn-group float-right">
          <div class="d-inline-flex">
            <i class="fas fa-fw fa-sliders-h swe-click" [ngClass]="{'swe-grayed-out': !downloadsettingmenu}" (click)="downloadsettingmenu=!downloadsettingmenu;$event.stopPropagation()" [attr.title]="languageService.getItem(770)"></i>
            <ul class="dropdown-menu dropdown-menu-right" [ngClass]="{'show': downloadsettingmenu }">
              <li class="dropdown-item swe-click" *ngFor="let enc of encodings" (click)="changeencoding(enc.Id, $event)"><a><i class="fas fa-fw" [ngClass]="{'fa-check': encoding==enc.Id}"></i>{{enc.Name}}</a></li>
              <li class="dropdown-divider"></li>
              <li class="dropdown-item swe-click" *ngFor="let del of delimeters" (click)="changedelimeter(del.Id, $event)"><a><i class="fas fa-fw" [ngClass]="{'fa-check': delimeter==del.Id}"></i>{{del.Name}}</a></li>
            </ul>
          </div>
        </div>
        <i class="fas fa-fw fa-print fa-pull-right swe-click mr-2" (click)="print()"></i>
      </div>
      <div *ngIf="graphvisible.length>0">
        <canvas baseChart
                [datasets]="chartdata"
                [labels]="chartlabels"
                [options]="chartoptions"
                [legend]="chartlegend"
                [chartType]="charttype"
                height="50">
        </canvas>
      </div>
      <div class="no-table-responsive">
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <th style="width:90px;" *ngIf="hascheckicon||hasicons">
                <div class="btn-group" style="vertical-align:top;" *ngIf="hascheckicon">
                  <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe dropdown" (click)="allchecked=!allchecked;listutility.checkall(allchecked)">
                    <span><i class="fas fa-check"></i></span>
                  </button>
                  <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe dropdown" (click)="actionmenu=!actionmenu" *ngIf="listutility.ischecked">
                    <span><i class="fas fa-fw fa-ellipsis-v"></i></span>
                  </button>
                  <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
                    <li class="dropdown-item swe-click"><a (click)="action('multi')"><i class="fas fa-fw fa-clone"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
                    <li class="dropdown-item swe-click" *ngIf="settingService.report.main==0&&permissionService.permissions.MultiTimereport>0"><a (click)="multitimereport()"><i class="fas fa-fw fa-clock"></i>&nbsp;{{languageService.getItem(52)}}</a></li>
                    <li class="dropdown-divider" *ngIf="(settingService.report.main==0&&(permissionService.permissions.Email>0||permissionService.permissions.Sms>0))||(settingService.report.main==1&&permissionService.permissions.Email>0)"></li>
                    <li class="dropdown-item swe-click" *ngIf="(settingService.report.main==0||settingService.report.main==1)&&permissionService.permissions.Email>0"><a (click)="send(1)"><i class="fas fa-fw fa-envelope"></i>&nbsp;{{languageService.getItem(321)}}</a></li>
                    <li class="dropdown-item swe-click" *ngIf="settingService.report.main==0&&permissionService.permissions.Sms>0"><a (click)="send(3)"><i class="fas fa-fw fa-mobile-alt"></i>&nbsp;{{languageService.getItem(320)}}</a></li>
                  </ul>
                </div>
              </th>
              <ng-container *ngFor="let header of headers;index as i">
                <th class="swe-click" *ngIf="i>3" (click)="sort(header)">
                  {{header.property.Name}}
                  <i class="fas fa-fw fa-chart-bar" *ngIf="header.property.Graph>0" [ngClass]="{'swe-grayed-out': (header.property.Graph==1)}" (click)="graph(header,$event)"></i>
                  <i class="fas fa-fw fa-caret-down" *ngIf="header.property.Sort==1"></i>
                  <i class="fas fa-fw fa-caret-up" *ngIf="header.property.Sort==2"></i>
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr class="swe-click" *ngFor="let row of rows;index as j" (click)="open(row[0], $event)">
              <td style="width:90px;" *ngIf="hascheckicon||hasicons" (click)="hascheckicon && listutility.toggle(row, $event)" [ngClass]="{'swe-active-cell swe-first-cell': j==activerow}">
                <i class="fas fa-swe fa-check-circle" *ngIf="row.checked"></i>
                <span *ngIf="hasicons && !row.checked && row[2]">
                  <i class="{{iconclass}}" *ngFor="let iconclass of row[2].split('|')"></i>
                </span>
              </td>
              <ng-container *ngFor="let cell of row;index as i">
                <td *ngIf="i>3" [ngClass]="{'swe-active-cell': (j==activerow), 'swe-first-cell': (j==activerow&&!hascheckicon&&!hasicons&&i==4), 'swe-last-cell': (j==activerow&&i==row.length-1)}">
                  <ng-container *ngIf="i==4">
                    <!--WCAG tab been rows-->
                    <a class="swe-no-link swe-no-focus" href="javascript:void(0);" (click)="open(row[0], $event)" (focus)="activerow=j" (blur)="activerow=-1"><swe-reportcell [data]="cell" [header]="headers[i]" [bold]="settingService.report.groupby>0"></swe-reportcell></a>
                  </ng-container>
                  <ng-container *ngIf="i>4">
                    <swe-reportcell [data]="cell" [header]="headers[i]"></swe-reportcell>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </tbody>
          <tfoot *ngIf="!loading">
            <tr>
              <td style="width:90px;" *ngIf="hascheckicon||hasicons">&nbsp;</td>
              <ng-container *ngFor="let cellsum of sum; index as i">
                <td *ngIf="i>3">
                  <div><b>{{cellsum}}</b></div>
                  <div *ngIf="cellsum"><b>&mu;&nbsp;{{generalService.formatdecimal(cellsum/sumlength[i])}}</b></div>
                </td>
              </ng-container>
            </tr>
          </tfoot>
        </table>
        <button class="btn btn-block btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </div>
    </swe-card>
  </div>
</div>
