<swe-pageheader [header]="languageService.getItem(1009)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="true" (refreshChange)="load()" *ngIf="permissionService.permissions.Templates>0">
      <span swe-rightcommand *ngIf="permissionService.permissions.Templates>1">
        <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="goto(0)"></i>
      </span>
      <swe-list [data]="templates" [loading]="isloading">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let template of templates" (click)="goto(template.Id)">
          <div class="col-12 col-md-6">{{template.Name}}</div>
          <div class="col-12 col-md-6">{{template.LevelName}}</div>
        </a>
      </swe-list>
    </swe-card>
  </div>
</div>
