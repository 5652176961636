<swe-pageheader [header]="languageService.getItem(120)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="text-md-center">
  <div class="btn-group">
    <button class="btn btn-swe" (click)="prev()" *ngIf="currentindex>0"><i class="fas fa-fw fa-arrow-left"></i></button>
    &nbsp;
    <button class="btn btn-swe" (click)="next()" *ngIf="currentindex<otherrules.length-1"><i class="fas fa-fw fa-arrow-right"></i></button>
  </div>
</div>
<div *ngIf="setting">
  <pre>{{setting.Info}}</pre>
</div>
<div class="row d-block py-2">
  <!--General data-->
  <div class="col-12 col-md-4 float-left">
    <swe-card [header]="languageService.getItem(139)" [open]="true" [hasRefresh]="false" [hasCollapse]="settingService.isDevice(['xs','sm'])" [hasSave]="true" (saveChange)="save(id)">
      <swe-element [type]="'System.String'" [label]="languageService.getItem(140)" [statusLabel]="2" [(model)]="rule.Desc"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(141)" [statusLabel]="2" [(model)]="rule.Keyword" [items]="keywordList" (modelChange)="keywordChange($event)"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(517)" [statusLabel]="2" [(model)]="rule.Category" [items]="categoryList" *ngIf="rule.Keyword&&(rule.Keyword.indexOf('SweObject')>-1||rule.Keyword.indexOf('SweHasObjects')>-1)"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(142)" [statusLabel]="2" [(model)]="rule.Access" [items]="accessList"></swe-element>
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(143)" [statusLabel]="2" [(model)]="rule.OnlyYou" *ngIf="setting&&setting.ShowMyself"></swe-element>
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(144)" [statusLabel]="2" [(model)]="rule.InclUnauth" *ngIf="setting&&setting.ShowLevel"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(145)" [statusLabel]="2" [(model)]="rule.Child" [items]="childList" *ngIf="setting&&setting.ShowLevel"></swe-element>
      <div>
        <button class="btn btn-primary btn-block" (click)="save(id)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-success btn-block" (click)="save(0)" *ngIf="id>0"><i class="fas fa-copy"></i>&nbsp;{{languageService.getItem(206)}}</button>
        <button class="btn btn-danger btn-block" (click)="delete()" *ngIf="id>0"><i class="fas fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </div>
    </swe-card>
  </div>
  <!--Logged In User-->
  <div class="col-12 col-md-4 float-left">
    <swe-card [header]="languageService.getItem(150)" [open]="true" [hasRefresh]="false" [hasSave]="true" (saveChange)="save(id)">
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(82)" [statusLabel]="2" [(model)]="rule.Role" [items]="roleList" *ngIf="!isloading"></swe-element>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(151)" [statusLabel]="2" [(model)]="rule.LevelGroup" [items]="levelgroupList" *ngIf="!isloading"></swe-element>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(3)" [statusLabel]="2" [(model)]="rule.Level" [items]="levelList" [container]="{max:25}" *ngIf="!isloading"></swe-element>
      <div>
        <button class="btn btn-primary btn-block" (click)="save(id)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-success btn-block" (click)="save(0)" *ngIf="id>0"><i class="fas fa-copy"></i>&nbsp;{{languageService.getItem(206)}}</button>
        <button class="btn btn-danger btn-block" (click)="delete()" *ngIf="id>0"><i class="fas fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </div>
    </swe-card>
  </div>
  <!--Affected User-->
  <div class="col-12 col-md-4 float-left" *ngIf="setting&&(setting.ShowRole||setting.ShowLevel)">
    <swe-card [header]="languageService.getItem(152)" [open]="true" [hasRefresh]="false" [hasSave]="true" (saveChange)="save(id)">
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(153)" [statusLabel]="2" [(model)]="rule.OtherRole" [items]="roleList" *ngIf="setting.ShowRole"></swe-element>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(154)" [statusLabel]="2" [(model)]="rule.OtherLevelGroup" [items]="levelgroupList" *ngIf="setting.ShowLevel"></swe-element>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(155)" [statusLabel]="2" [(model)]="rule.OtherLevel" [items]="levelList" [container]="{max:25}" *ngIf="setting.ShowLevel"></swe-element>
      <div>
        <button class="btn btn-primary btn-block" (click)="save(id)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-success btn-block" (click)="save(0)" *ngIf="id>0"><i class="fas fa-copy"></i>&nbsp;{{languageService.getItem(206)}}</button>
        <button class="btn btn-danger btn-block" (click)="delete()" *ngIf="id>0"><i class="fas fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </div>
    </swe-card>
  </div>
  <!--Properties-->
  <div class="col-12 col-md-4 float-left" *ngIf="setting&&setting.ShowProperty">
    <swe-card [header]="languageService.getItem(156)" [open]="true" [hasRefresh]="false" [hasSave]="true" (saveChange)="save(id)">
      <swe-element [type]="'System.GroupByMultiList'" [label]="languageService.getItem(156)" [statusLabel]="2" [(model)]="rule.Property" [items]="groupbyList" [container]="{max:30}"></swe-element>
      <div>
        <button class="btn btn-primary btn-block" (click)="save(id)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-success btn-block" (click)="save(0)" *ngIf="id>0"><i class="fas fa-copy"></i>&nbsp;{{languageService.getItem(206)}}</button>
        <button class="btn btn-danger btn-block" (click)="delete()" *ngIf="id>0"><i class="fas fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </div>
    </swe-card>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-left] on columns-->
