/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./radio.component";
import * as i3 from "../_services/language.service";
var styles_RadioComponent = [];
var RenderType_RadioComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RadioComponent, data: {} });
export { RenderType_RadioComponent as RenderType_RadioComponent };
function View_RadioComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "form-check"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "input", [["class", "form-check-input swe-click"], ["type", "radio"]], [[8, "disabled", 0], [8, "name", 0], [8, "id", 0], [8, "value", 0], [8, "checked", 0], [1, "aria-label", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.manageChange(_v.context.$implicit.Id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "bg-warning": 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "label", [["class", "form-check-label swe-click"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = "form-check-input swe-click"; var currVal_7 = _ck(_v, 4, 0, _co.isChanged); _ck(_v, 3, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = i0.ɵinlineInterpolate(2, "", _co.extra, "_", _co.label, ""); var currVal_2 = i0.ɵinlineInterpolate(4, "", _co.extra, "_", _co.labelNoWhiteSpace, "_", _v.context.$implicit.Name, "_", _v.context.$implicit.Id, ""); var currVal_3 = _v.context.$implicit.Id; var currVal_4 = (_v.context.$implicit.Id == _co.model); var currVal_5 = _co.label; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = i0.ɵinlineInterpolate(4, "", _co.extra, "_", _co.labelNoWhiteSpace, "_", _v.context.$implicit.Name, "_", _v.context.$implicit.Id, ""); _ck(_v, 5, 0, currVal_8); var currVal_9 = _co.languageService.getItem(_v.context.$implicit.Name); _ck(_v, 6, 0, currVal_9); }); }
export function View_RadioComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RadioComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-radio", [], null, null, null, View_RadioComponent_0, RenderType_RadioComponent)), i0.ɵdid(1, 49152, null, 0, i2.RadioComponent, [i3.LanguageService], null, null)], null, null); }
var RadioComponentNgFactory = i0.ɵccf("swe-radio", i2.RadioComponent, View_RadioComponent_Host_0, { label: "label", model: "model", items: "items", changed: "changed", disabled: "disabled", extra: "extra" }, { modelChange: "modelChange" }, []);
export { RadioComponentNgFactory as RadioComponentNgFactory };
