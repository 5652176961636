<swe-card header="LAS" [color]="'swe'" [(open)]="open" [hasCollapse]="true" [hasRefresh]="false"  (openChange)="search()">
  <div class="mb-3">
    <swe-element [type]="'System.Date'" [label]="'LAS'" [statusLabel]="0" [(model)]="date"></swe-element>
    <button class="btn btn-primary btn-block" (click)="search()">{{languageService.getItem(13)}}</button>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>AVA</th>
          <th>VIK</th>
          <th>AVAVIK</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="las">
          <td>{{las.Ava}}</td>
          <td>{{las.Vik}}</td>
          <td>{{las.AvaVik}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</swe-card>
