/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./details/contractobrules.component.ngfactory";
import * as i2 from "./details/contractobrules.component";
import * as i3 from "../../_services/language.service";
import * as i4 from "../../_services/permission.service";
import * as i5 from "../../_services/setting.service";
import * as i6 from "../../_services/list.service";
import * as i7 from "../../_services/data.service";
import * as i8 from "@angular/router";
import * as i9 from "./details/contracttimerules.component.ngfactory";
import * as i10 from "./details/contracttimerules.component";
import * as i11 from "./details/contracttimebank.component.ngfactory";
import * as i12 from "./details/contracttimebank.component";
import * as i13 from "../../_services/general.service";
import * as i14 from "./details/contractlas.component.ngfactory";
import * as i15 from "./details/contractlas.component";
import * as i16 from "../../layout/pageheader.component.ngfactory";
import * as i17 from "../../layout/pageheader.component";
import * as i18 from "@angular/common";
import * as i19 from "./details/contractdata.component.ngfactory";
import * as i20 from "./details/contractdata.component";
import * as i21 from "../../_services/alert.service";
import * as i22 from "./contractdetail.component";
var styles_ContractDetailComponent = [];
var RenderType_ContractDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContractDetailComponent, data: {} });
export { RenderType_ContractDetailComponent as RenderType_ContractDetailComponent };
function View_ContractDetailComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-md-8 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "swe-contractobrules", [], null, null, null, i1.View_ContractOBRulesComponent_0, i1.RenderType_ContractOBRulesComponent)), i0.ɵdid(2, 114688, null, 0, i2.ContractOBRulesComponent, [i3.LanguageService, i4.PermissionService, i5.SettingService, i6.ListService, i7.DataService, i8.Router], { id: [0, "id"], access: [1, "access"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = _co.contractdata.OBAccess; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ContractDetailComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-md-8 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "swe-contracttimerules", [], null, null, null, i9.View_ContractTimeRulesComponent_0, i9.RenderType_ContractTimeRulesComponent)), i0.ɵdid(2, 114688, null, 0, i10.ContractTimeRulesComponent, [i3.LanguageService, i4.PermissionService, i5.SettingService, i7.DataService, i8.Router], { id: [0, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContractDetailComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-md-4 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "swe-contracttimebank", [], null, null, null, i11.View_ContractTimeBankComponent_0, i11.RenderType_ContractTimeBankComponent)), i0.ɵdid(2, 114688, null, 0, i12.ContractTimeBankComponent, [i3.LanguageService, i4.PermissionService, i5.SettingService, i7.DataService, i13.GeneralService], { id: [0, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contractdata.TimeBankId; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContractDetailComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-md-4 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "swe-contractlas", [], null, null, null, i14.View_ContractLasComponent_0, i14.RenderType_ContractLasComponent)), i0.ɵdid(2, 114688, null, 0, i15.ContractLasComponent, [i3.LanguageService, i4.PermissionService, i5.SettingService, i7.DataService, i13.GeneralService], { id: [0, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contractdata.LasId; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ContractDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "swe-pageheader", [], null, null, null, i16.View_PageHeaderComponent_0, i16.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i17.PageHeaderComponent, [i18.Location, i8.ActivatedRoute, i8.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["swe-hits", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" \u00A0(#", ") "])), (_l()(), i0.ɵeld(4, 0, null, null, 11, "div", [["class", "row d-block py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "col-12 col-md-4 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "swe-contractdata", [], null, [[null, "contractdataChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contractdataChange" === en)) {
        var pd_0 = (_co.manageContractdata($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_ContractDataComponent_0, i19.RenderType_ContractDataComponent)), i0.ɵdid(7, 114688, null, 0, i20.ContractDataComponent, [i3.LanguageService, i4.PermissionService, i5.SettingService, i13.GeneralService, i7.DataService, i21.AlertService, i8.Router, i18.Location], { id: [0, "id"] }, { contractdataChange: "contractdataChange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContractDetailComponent_1)), i0.ɵdid(9, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContractDetailComponent_2)), i0.ɵdid(11, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContractDetailComponent_3)), i0.ɵdid(13, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContractDetailComponent_4)), i0.ɵdid(15, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(953); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.id; _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.contractdata && (_co.contractdata.OBAccess > 0)); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.contractdata && (_co.contractdata.ATKAccess > 0)); _ck(_v, 11, 0, currVal_4); var currVal_5 = ((_co.permissionService.permissions.TimeBank && _co.contractdata) && (_co.contractdata.TimeBankAccess > 0)); _ck(_v, 13, 0, currVal_5); var currVal_6 = ((_co.permissionService.permissions.Las && _co.contractdata) && (_co.contractdata.LasAccess > 0)); _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.id; _ck(_v, 3, 0, currVal_1); }); }
export function View_ContractDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-contractdetail", [], null, null, null, View_ContractDetailComponent_0, RenderType_ContractDetailComponent)), i0.ɵdid(1, 114688, null, 0, i22.ContractDetailComponent, [i3.LanguageService, i4.PermissionService, i5.SettingService, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractDetailComponentNgFactory = i0.ɵccf("app-contractdetail", i22.ContractDetailComponent, View_ContractDetailComponent_Host_0, {}, {}, []);
export { ContractDetailComponentNgFactory as ContractDetailComponentNgFactory };
