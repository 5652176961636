import * as i0 from "@angular/core";
import * as i1 from "./data.service";
export class LanguageService {
    constructor(data) {
        this.data = data;
        this.languages = [];
        this.data.basicRequest('/api/v1/general/languages', 'GET').subscribe(res => {
            if (res) {
                this.languages = res;
            }
        });
    }
    reloadByCulture(culture) {
        this.data.basicRequest('/api/v1/general/languages/' + culture, 'GET').subscribe(res => {
            if (res) {
                this.languages = res;
            }
        });
    }
    getItem(index) {
        if (index < this.languages.length) {
            return this.languages[index].Text;
        }
        return "";
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.DataService)); }, token: LanguageService, providedIn: "root" });
