import * as i0 from "@angular/core";
import * as i1 from "./language.service";
import * as i2 from "./alert.service";
import * as i3 from "@angular/common";
export class DateTimeService {
    constructor(languageService, alertService, datePipe) {
        this.languageService = languageService;
        this.alertService = alertService;
        this.datePipe = datePipe;
        //Class members;
        this._onesecond = 1000;
        this._oneminute = (60 * this._onesecond);
        this._onehour = (60 * this._oneminute);
        this._oneday = (24 * this._onehour);
        this._oneweek = (7 * this._oneday);
    }
    //Formats
    isValid(date) {
        return date instanceof Date && !isNaN(date.getTime());
    }
    format(date, _format) {
        //if (!(date instanceof Date)) {
        //  //Not date object but perhaps a datetime string
        //  date = new Date(date);
        //}
        //if (this.isValid(date)) {
        if (_format && _format.length > 0) {
            return this.datePipe.transform(date, _format);
        }
        else {
            return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
        }
        //}
        //return null;
    }
    formatWithTimeZone(date) {
        return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ssZ').replace(/([+\-]\d\d)(\d\d)$/, "$1:$2"); //IE and Safari fix
    }
    //Validation
    validation(start, end, equalNotAllowed = false) {
        if (typeof start == "undefined" || start == null) {
            this.alertService.Add({ message: this.languageService.getItem(420), type: 'danger' });
            return false;
        }
        else if (typeof end == "undefined" || end == null) {
            this.alertService.Add({ message: this.languageService.getItem(421), type: 'danger' });
            return false;
        }
        else {
            if (!equalNotAllowed && new Date(start) > new Date(end)) {
                this.alertService.Add({ message: this.languageService.getItem(422), type: 'danger' });
                return false;
            }
            if (equalNotAllowed && new Date(start) >= new Date(end)) {
                this.alertService.Add({ message: this.languageService.getItem(422), type: 'danger' });
                return false;
            }
        }
        return true;
    }
    //Weeks
    weekdayname(date, length) {
        let name = [this.languageService.getItem(394),
            this.languageService.getItem(388),
            this.languageService.getItem(389),
            this.languageService.getItem(390),
            this.languageService.getItem(391),
            this.languageService.getItem(392),
            this.languageService.getItem(393)][date.getDay()];
        if (typeof name == "undefined") {
            name = '';
        }
        if (typeof length != "undefined" && length > 0) {
            name = name.substring(0, length);
        }
        return name;
    }
    ;
    weeknumber(date) {
        //Week 1 contain January 4
        let jan4 = new Date(date.getFullYear(), 0, 4);
        //First monday
        let mon1 = this.firstDayInWeek(jan4);
        //Monday in current week
        let monDate = this.firstDayInWeek(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
        //Diff
        let diff = monDate.getTime() - mon1.getTime();
        let weekNumber = 0;
        if (diff / this._oneweek >= 52 || diff / this._oneweek <= 0) {
            //Weeknumber 53 or 1
            //Use 1 jan next year and test day of week
            let jan1 = new Date(date.getFullYear() + 1, 0, 1);
            if (jan1.getDay() > 0 && jan1.getDay() < 5) {
                //Monday to Thursday
                weekNumber = 1;
            }
            else {
                //Friday to Sunday
                weekNumber = 53;
            }
        }
        else {
            //Weeknumber
            weekNumber = Math.floor(diff / this._oneweek) + 1;
        }
        return weekNumber;
    }
    ;
    //Months
    monthname(date, length) {
        let name = this.monthnames[date.getMonth()];
        if (typeof name == "undefined") {
            name = '';
        }
        if (typeof length != "undefined" && length > 0) {
            name = name.substring(0, length);
        }
        return name;
    }
    monthnamebynumber(number, length) {
        let name = this.monthnames[number];
        if (typeof name == "undefined") {
            name = '';
        }
        if (typeof length != "undefined" && length > 0) {
            name = name.substring(0, length);
        }
        return name;
    }
    //Add
    addWeeks(date, weeks) {
        return new Date(date.getTime() + this._oneweek * weeks);
    }
    addDays(date, days) {
        return new Date(date.getTime() + this._oneday * days);
    }
    addHours(date, hours) {
        return new Date(date.getTime() + this._onehour * hours);
    }
    addMinutes(date, minutes) {
        return new Date(date.getTime() + this._oneminute * minutes);
    }
    addSeconds(date, seconds) {
        return new Date(date.getTime() + this._onesecond * seconds);
    }
    //First
    firstDayInWeek(date) {
        while (date.getDay() != 1) {
            date = this.addDays(date, -1);
        }
        return date;
    }
    firstDayInMonth(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }
    firstDayInQuarter(date) {
        var month = 0;
        if (date.getMonth() > 8) {
            month = 9;
        }
        else if (date.getMonth() > 5) {
            month = 6;
        }
        else if (date.getMonth() > 2) {
            month = 3;
        }
        return new Date(date.getFullYear(), month, 1);
    }
    //Last
    lastDayInWeek(date) {
        while (date.getDay() != 0) {
            date = this.addDays(date, 1);
        }
        return date;
    }
    lastDayInMonth(date) {
        var firstinnextmonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        return new Date(firstinnextmonth.getFullYear(), firstinnextmonth.getMonth(), firstinnextmonth.getDate() - 1);
    }
    lastDayInQuarter(date) {
        var month = 2;
        if (date.getMonth() > 8) {
            month = 11;
        }
        else if (date.getMonth() > 5) {
            month = 8;
        }
        else if (date.getMonth() > 2) {
            month = 5;
        }
        var firstinnextmonth = new Date(date.getFullYear(), month + 1, 1);
        return new Date(firstinnextmonth.getFullYear(), firstinnextmonth.getMonth(), firstinnextmonth.getDate() - 1);
    }
    //Header
    header(start, end, onlytime = false) {
        let header = '';
        let onemonth = (31 * 24 * 60 * 60 * 1000);
        let startoffset = start.getTimezoneOffset();
        let endoffset = end.getTimezoneOffset();
        let tmp_oneday = this._oneday;
        if (startoffset != endoffset) {
            tmp_oneday += (endoffset - startoffset) * 60 * 1000;
        }
        let wholedays = (start.getHours() == 0 && start.getMinutes() == 0 && end.getHours() == 0 && end.getMinutes() == 0);
        let endForEqual = wholedays ? new Date(end.getTime() - this._onesecond) : end;
        let equalyear = (start.getFullYear() == endForEqual.getFullYear());
        let equalmonth = equalyear && (start.getMonth() == endForEqual.getMonth());
        let equalday = equalmonth && (start.getDate() == endForEqual.getDate());
        if (wholedays) {
            //Day event
            if ((end.getTime() - start.getTime()) == tmp_oneday) {
                //One day
                header = '';
                if (!onlytime) {
                    header += this.weekdayname(start, 2);
                    header += ' ';
                }
                header += start.getDate();
                header += ' ';
                header += this.monthname(start).substring(0, 3);
            }
            else {
                //Many days
                end = new Date(end.getTime() - tmp_oneday);
                header = '';
                if (!onlytime) {
                    header += this.weekdayname(start, 2);
                    header += ' ';
                }
                header += start.getDate();
                if (!equalmonth) {
                    header += ' ';
                    header += this.monthname(start).substring(0, 3);
                }
                if (!equalyear && ((end.getTime() - start.getTime()) > onemonth)) {
                    header += ' ';
                    header += start.getFullYear();
                }
                header += ' - ';
                header += end.getDate();
                header += ' ';
                header += this.monthname(end).substring(0, 3);
                if ((end.getTime() - start.getTime()) > onemonth) {
                    header += ' ';
                    header += end.getFullYear();
                }
            }
        }
        else {
            //Time event
            if (onlytime && equalday) {
                header = this.format(start, 'HH:mm') + ' - ' + this.format(end, 'HH:mm');
            }
            else {
                header = '';
                /*Date and Month*/
                if (!onlytime) {
                    header += this.weekdayname(start, 2);
                    header += ' ';
                    header += start.getDate();
                    header += ' ';
                    header += this.monthname(start).substring(0, 3);
                    /*Year*/
                    if ((end.getTime() - start.getTime()) > onemonth) {
                        header += ' ';
                        header += start.getFullYear();
                    }
                    header += ' ';
                }
                /*Time (start)*/
                header += this.format(start, 'HH:mm');
                header += ' - ';
                /*Date and Month (if different)*/
                if (!equalday) {
                    header += end.getDate();
                    header += ' ';
                    header += this.monthname(end).substring(0, 3);
                }
                /*Year (if different)*/
                if (!equalyear && ((end.getTime() - start.getTime()) > onemonth)) {
                    header += ' ';
                    header += end.getFullYear();
                }
                /*Time (end)*/
                header += ' ';
                header += this.format(end, 'HH:mm');
            }
        }
        return header;
    }
    //Properties
    get onesecond() { return this._onesecond; }
    get oneminute() { return this._oneminute; }
    get onehour() { return this._onehour; }
    get oneday() { return this._oneday; }
    get oneweek() { return this._oneweek; }
    get monthnames() {
        let monthnames = [
            this.languageService.getItem(216),
            this.languageService.getItem(217),
            this.languageService.getItem(218),
            this.languageService.getItem(219),
            this.languageService.getItem(220),
            this.languageService.getItem(221),
            this.languageService.getItem(222),
            this.languageService.getItem(223),
            this.languageService.getItem(224),
            this.languageService.getItem(225),
            this.languageService.getItem(226),
            this.languageService.getItem(227)
        ];
        return monthnames;
    }
}
DateTimeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateTimeService_Factory() { return new DateTimeService(i0.ɵɵinject(i1.LanguageService), i0.ɵɵinject(i2.AlertService), i0.ɵɵinject(i3.DatePipe)); }, token: DateTimeService, providedIn: "root" });
