import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../_services/authentication.service";
import * as i3 from "../_services/permission.service";
export class AuthGuard {
    constructor(router, route, authenticationService, permissionService) {
        this.router = router;
        this.route = route;
        this.authenticationService = authenticationService;
        this.permissionService = permissionService;
    }
    canActivate(route, state) {
        const isauth = this.authenticationService.isAuth;
        if (isauth) {
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.PermissionService)); }, token: AuthGuard, providedIn: "root" });
