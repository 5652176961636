<nav class="navbar navbar-dark bg-dark swe-topmenu">
  <button type="button" class="navbar-toggler" [ngClass]="{'d-none d-md-block': menuService.ShowSearch}" (click)="menuService.toggleMenu()" *ngIf="authenticationService.isAuth&&!menuService.ShowMenu" accesskey="3" #sweFocusElement>
    <span><i class="fas fa-bars"></i></span>
    <span class="sr-only">Meny</span>
  </button>
  <span class="navbar-text mr-auto">
  </span>
  <div class="dropdown mr-3" [ngClass]="{'d-none d-md-block': menuService.ShowMenu||menuService.ShowSearch}" *ngIf="alertService.History.length>0&&authenticationService.isAuth">
    <i class="fa-fw fa-2x fa-bell fa-inverse pt-1 swe-click" [ngClass]="{'fas': historynew, 'far': !historynew}" (click)="toggleHistory()"></i>
    <div class="dropdown-menu dropdown-menu-right px-2 mt-2" style="width:600px;" [ngClass]="{'show': historymenu}">
      <div class="list-group-item list-group-item-{{alert.type}} swe-alert-text" *ngFor="let alert of alertService.History">
        <i class="fas fa-trash fa-pull-right swe-click" (click)="manageHistory(alert)"></i>
        {{alert['time']}}&nbsp;{{alert.message}}
      </div>
    </div>
  </div>
  <div class="dropdown mr-3" [ngClass]="{'d-none d-md-block': menuService.ShowMenu||menuService.ShowSearch}" *ngIf="authenticationService.isAuth&&permissionService.isimpersonate()>0">
    <i class="fas fa-fw fa-2x fa-user-secret fa-inverse pt-1 swe-click" (click)="toggle()"></i>
    <div class="dropdown-menu dropdown-menu-right p-2 mt-2 text-center" [ngClass]="{'show': impersonatemenu}">
      <swe-element [type]="'System.Password'" [label]="'Pin'" [statusLabel]="3" [(model)]="pincode" (keyup)="keyup($event)" [setFocus]="pinfocus"></swe-element>
    </div>
  </div>
  <div class="dropdown mr-3" [ngClass]="{'d-none d-md-block': menuService.ShowMenu||menuService.ShowSearch}" *ngIf="authenticationService.isAuth">
    <img (click)="profilemenu=!profilemenu;impersonatemenu=false;" class="swe-circle-image swe-click" alt="{{permissionService.user.Firstname}} {{permissionService.user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="permissionService.user.Image" imageWidth="32" imageHeight="32" *ngIf="permissionService.user&&permissionService.user.Image&&permissionService.user.Image.length>0" />
    <i class="fas fa-fw fa-2x fa-user fa-inverse pt-1 swe-click" (click)="profilemenu=!profilemenu;impersonatemenu=false;" *ngIf="!permissionService.user||!permissionService.user.Image||permissionService.user.Image.length==0"></i>
    <div class="dropdown-menu dropdown-menu-right p-2 mt-2 text-center" style="min-width:200px;" [ngClass]="{'show': profilemenu}">
      <div>
        <i class="far fa-user fa-3x" *ngIf="!permissionService.user||!permissionService.user.Image||permissionService.user.Image.length==0"></i>
        <img alt="{{permissionService.user.Firstname}} {{permissionService.user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="permissionService.user.Image" *ngIf="permissionService.user&&permissionService.user.Image&&permissionService.user.Image.length>0" />
      </div>
      <div class="py-1" style="white-space:nowrap;"><span *ngIf="permissionService.user">{{permissionService.user.Firstname}} {{permissionService.user.Lastname}}</span></div>
      <button class="btn btn-primary btn-block " (click)="goto('/users/' + permissionService.user.Id)"><i class="fas fa-user"></i>&nbsp;{{languageService.getItem(103)}}</button>
      <button class="btn btn-primary btn-block " (click)="goto('/help')" *ngIf="permissionService.permissions&&permissionService.permissions.Help>0"><i class="fas fa-question"></i>&nbsp;{{languageService.getItem(742)}}</button>
      <button class="btn btn-primary btn-block " (click)="goto('/login?logout=1')"><i class="fas fa-sign-out-alt"></i>&nbsp;{{languageService.getItem(5)}}</button>
    </div>
  </div>
  <button type="button" class="navbar-toggler" [ngClass]="{'d-none d-md-block': menuService.ShowMenu}" (click)="menuService.toggleSearch()" *ngIf="authenticationService.isAuth&&settingService.visible>0&&!menuService.ShowSearch" accesskey="4" #sweFocusElement2>
    <span><i class="fas fa-search"></i></span>
    <span class="sr-only">Filtrering</span>
  </button>
</nav>


