/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layout/card.component.ngfactory";
import * as i2 from "../../layout/card.component";
import * as i3 from "../../_services/language.service";
import * as i4 from "@angular/common";
import * as i5 from "../../layout/list.component.ngfactory";
import * as i6 from "../../layout/list.component";
import * as i7 from "../../layout/pageheader.component.ngfactory";
import * as i8 from "../../layout/pageheader.component";
import * as i9 from "@angular/router";
import * as i10 from "./templates.component";
import * as i11 from "../../_services/permission.service";
import * as i12 from "../../_services/data.service";
import * as i13 from "../../_services/setting.service";
var styles_TemplatesComponent = [];
var RenderType_TemplatesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TemplatesComponent, data: {} });
export { RenderType_TemplatesComponent as RenderType_TemplatesComponent };
function View_TemplatesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["swe-rightcommand", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-plus fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goto(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_TemplatesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "list-group-item list-group-item-action d-flex flex-wrap swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goto(_v.context.$implicit.Id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.Name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.LevelName; _ck(_v, 4, 0, currVal_1); }); }
function View_TemplatesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "swe-card", [], null, [[null, "refreshChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("refreshChange" === en)) {
        var pd_0 = (_co.load() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CardComponent_0, i1.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i2.CardComponent, [i3.LanguageService], { open: [0, "open"], hasCollapse: [1, "hasCollapse"], hasRefresh: [2, "hasRefresh"] }, { refreshChange: "refreshChange" }), (_l()(), i0.ɵand(16777216, null, 2, 1, null, View_TemplatesComponent_2)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, 3, 3, "swe-list", [], null, null, null, i5.View_ListComponent_0, i5.RenderType_ListComponent)), i0.ɵdid(5, 638976, null, 0, i6.ListComponent, [i3.LanguageService], { data: [0, "data"], loading: [1, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_TemplatesComponent_3)), i0.ɵdid(7, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = false; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.permissionService.permissions.Templates > 1); _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.templates; var currVal_5 = _co.isloading; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_6 = _co.templates; _ck(_v, 7, 0, currVal_6); }, null); }
export function View_TemplatesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-pageheader", [], null, null, null, i7.View_PageHeaderComponent_0, i7.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i8.PageHeaderComponent, [i4.Location, i9.ActivatedRoute, i9.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplatesComponent_1)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(1009); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.permissionService.permissions.Templates > 0); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TemplatesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-templates", [], null, null, null, View_TemplatesComponent_0, RenderType_TemplatesComponent)), i0.ɵdid(1, 114688, null, 0, i10.TemplatesComponent, [i3.LanguageService, i11.PermissionService, i12.DataService, i13.SettingService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplatesComponentNgFactory = i0.ɵccf("app-templates", i10.TemplatesComponent, View_TemplatesComponent_Host_0, {}, {}, []);
export { TemplatesComponentNgFactory as TemplatesComponentNgFactory };
