import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { DataService } from '../../_services/data.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { AlertService } from '../../_services/alert.service';
import { Location } from '@angular/common';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';
import { DateTimeService } from '../../_services/datetime.service';


@Component({
  selector: 'swe-multibookings',
  templateUrl: './multibookings.component.html'
})
export class MultiBookingsComponent implements OnInit {

  private _chosenbookings: any;
  private _start: Date;
  private _end: Date;
  private _status: number = 0;
  private _autooption: number = 0;
  private _autooptions: any[] = [];
  private _employmentoption: number = 0;
  private _employmentoptions: any[] = [];
  private _bookedusers: any[] = [];
  private _cancelusers: any[] = [];
  private _bookingtypeoption: number = 1;
  private _bookingtypeoptions: any[] = [];
  private _availableusers: any[] = [];
  private _removeremindsms: boolean;
  private _properties: any[] = [];
  private _chosenproperties: any[] = [];
  private _timereportproperties: any[] = [];
  private _chosentimereportproperties: any[] = [];
  private _checknotificationcancellationemail: boolean = this.permissionService.permissions.NotifyOnCancellationEmail == 1 ? true : false;
  private _checknotificationcancellationsms: boolean = this.permissionService.permissions.NotifyOnCancellationSms == 1 ? true : false;
  private _checknotificationemail: boolean = this.permissionService.permissions.NotifyOnBookEmail == 1 ? true : false;
  private _checknotificationsms: boolean = this.permissionService.permissions.NotifyOnBookSms == 1 ? true : false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private settingService: SettingService,
    private dateTimeService: DateTimeService,
    private location: Location) {

    //Reset
    settingService.initView();
  }

  ngOnInit() {
    this._chosenbookings = this.viewCacheService.get('multi_bookings');

    this._autooptions = [{ Id: 0, Name: 465 }, { Id: 1, Name: 190 }, { Id: 2, Name: 146 }];
    this._employmentoptions = [{ Id: 0, Name: 465 }, { Id: 1, Name: 190 }, { Id: 2, Name: 146 }];
    this._bookingtypeoptions = [{ Id: 1, Name: this.languageService.getItem(476) }];
    if (this.permissionService.permissions.Replaceable) { this._bookingtypeoptions.push({ Id: 0, Name: this.languageService.getItem(423) }); }
    if (this.permissionService.permissions.Standby > 0) { this._bookingtypeoptions.push({ Id: 2, Name: this.languageService.getItem(279) }); }
    if (this.permissionService.permissions.Abscent > 0) { this._bookingtypeoptions.push({ Id: 3, Name: this.languageService.getItem(280) }); }

    this.load();
  }


  //Properties
  public get start() {
    return this._start;
  }
  public set start(val) {
    this._start = val;
  }
  public get end() {
    return this._end;
  }
  public set end(val) {
    this._end = val;
  }
  public get status() {
    return this._status;
  }
  public set status(val:number) {
    this._status = val;
  }
  public get autooption() {
    return this._autooption;
  }
  public set autooption(val) {
    this._autooption = val;
  }
  public get autooptions() {
    return this._autooptions;
  }
  public get employmentoption() {
    return this._employmentoption;
  }
  public set employmentoption(val) {
    this._employmentoption = val;
  }
  public get employmentoptions() {
    return this._employmentoptions;
  }
  public get bookedusers() {
    return this._bookedusers;
  }
  public get cancelusers() {
    return this._cancelusers;
  }
  public set cancelusers(val) {
    this._cancelusers = val;
  }
  public get bookingtypeoption() {
    return this._bookingtypeoption;
  }
  public set bookingtypeoption(val) {
    this._bookingtypeoption = val;
  }
  public get bookingtypeoptions() {
    return this._bookingtypeoptions;
  }
  public get availableusers() {
    return this._availableusers;
  }
  public get removeremindsms() {
    return this._removeremindsms;
  }
  public set removeremindsms(val) {
    this._removeremindsms = val;
  }
  public get properties() {
    return this._properties;
  }
  public get chosenproperties() {
    return this._chosenproperties;
  }
  public get timereportproperties() {
    return this._timereportproperties;
  }
  public get chosentimereportproperties() {
    return this._chosentimereportproperties;
  }
  public get checknotificationcancellationemail() {
    return this._checknotificationcancellationemail;
  }
  public set checknotificationcancellationemail(val) {
    this._checknotificationcancellationemail = val;
  }
  public get checknotificationcancellationsms() {
    return this._checknotificationcancellationsms;
  }
  public set checknotificationcancellationsms(val) {
    this._checknotificationcancellationsms = val;
  }
  public get checknotificationemail() {
    return this._checknotificationemail;
  }
  public set checknotificationemail(val) {
    this._checknotificationemail = val;
  }
  public get checknotificationsms() {
    return this._checknotificationsms;
  }
  public set checknotificationsms(val) {
    this._checknotificationsms = val;
  }




  //Methods
  public getStatus() {
    let list = [];

    this.generalService.bookingstatus.forEach((item) => {

      if (item.Id < 40) {
        list.push(item);
      }

    });

    return list;
  }
  public userChosen(e) {
    let index = this._availableusers.map(function (user) { return user.Id; }).indexOf(e.item.Id);

    if (index == -1) {
      e.item.Type = this._bookingtypeoption;
      this._availableusers.push(e.item);
    }
  }
  public removeuser(item, e) {
    let index = this._availableusers.indexOf(item);
    if (index > -1) {
      this._availableusers.splice(index, 1);
    }

    e.stopPropagation();
  }
  public confirmsave() {
    let confirmtext = '';

    //Be sure that this is an integer
    let iStatus = parseInt(this._status.toString());

    if (this.start && this.end) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(932).replace('{0}', this.dateTimeService.format(this.start, 'HH:mm')).replace('{1}', this.dateTimeService.format(this.end, 'HH:mm'));
    }

    if (iStatus > 0) {
      let statusname = '';
      let statusObj = this.listService.find(this.generalService.bookingstatus, 'Id', iStatus);
      if (statusObj) {
        statusname = statusObj.Name;
      }

      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(427).replace('{0}', statusname);
    }

    if (this._autooption > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(696);
    }

    if (this._employmentoption > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(730);
    }

    if (this._cancelusers.length > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(429).replace('{0}', this._cancelusers.length);
    }

    if (this._availableusers.length > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(437).replace('{0}', this._availableusers.length);
    }

    if (this._removeremindsms) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(919);
    }

    if (this._chosenproperties.length > 0 || this._chosentimereportproperties.length > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(428).replace('{0}', this._chosenproperties.length + this._chosentimereportproperties.length);
    }
    
    return this.languageService.getItem(416).replace('{0}', confirmtext);
  }
  public save() {

    if (this._chosenbookings) {

      let profile: any[] = [];
      this._chosenproperties.forEach(property => {
        profile.push({ Property: property.Id, Value: property.Val });
      });
      this._chosentimereportproperties.forEach(property => {
        profile.push({ Property: property.Id, Value: property.Val });
      });

      let filter = {
        IdList: this._chosenbookings.value,
        Status: this._status,
        Cancellations: this._cancelusers,
        Bookings: this._availableusers,
        Profiles: profile,
        Auto: this._autooption,
        Employment: this._employmentoption,
        RemoveSms: this._removeremindsms,
        ManualNotifyCancellationEmail: this._checknotificationcancellationemail,
        ManualNotifyCancellationSms: this._checknotificationcancellationsms,
        ManualNotifyEmail: this._checknotificationemail,
        ManualNotifySms: this._checknotificationsms 
      };

      if (this._start) {
        filter["Start"] = this._start;
      }
      if (this._end) {
        filter["End"] = this._end;
      }

      this.dataService.tokenRequest('/api/v1/bookings/multi', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(373), type: 'danger' });
    }
  }
  public delete() {
    
    if (this._chosenbookings) {
      let filter = {
        IdList: this._chosenbookings.value
      };

      this.dataService.tokenRequest('/api/v1/bookings/multi/delete', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(373), type: 'danger' });
    }
  }



  //Functions
  private load() {

    if (this._chosenbookings) {

      let filter = {
        IdList: this._chosenbookings.value
      }

      this.dataService.tokenRequest('/api/v1/bookings/users', 'POST', filter)
        .subscribe((res) => {

          this._bookedusers = [];

          res.forEach((user) => {

            let displayname = user.Firstname + ' ' + user.Lastname;
            if (user.Username && user.Username.length > 0) {
              displayname += ' (' + user.Username + ')';
            }

            this._bookedusers.push({Id: user.Id, Name: displayname});
          });
        });
    }


    this.dataService.tokenRequest('/api/v1/properties/type/multishift', 'GET', {})
      .subscribe((res) => {

        let groupbyList: any[] = [];
        let timereportList: any[] = [];

        res.forEach((item) => {
          if (item.CatType == 'BookingUser') {
            let timereport = this.listService.find(timereportList, 'Name', item.Category);
            if (timereport) {
              timereport.Items.push({ Id: item.Id, Name: item.Name });
            }
            else {
              timereportList.push({ Name: item.Category, Items: [{ Id: item.Id, Name: item.Name }] });
            }
          }
          else {
            let groupby = this.listService.find(groupbyList, 'Name', item.Category);
            if (groupby) {
              groupby.Items.push({ Id: item.Id, Name: item.Name });
            }
            else {
              let category = item.Category;
              if (item.CatType == 'User') {
                //Competence
                category = this.languageService.getItem(1019);
              }
              groupbyList.push({ Name: category, Items: [{ Id: item.Id, Name: item.Name }] });
            }
          }
        });

        this._properties = groupbyList;
        this._timereportproperties = timereportList;
      });
  }

}
