<div>
  <i class="fas fa-swe fa-times fa-pull-right swe-click ml-2" *ngIf="writeAccess&&showupload" (click)="showupload=false" [ngClass]="{'swe-grayed-out': closerhover}" (mouseover)="closerhover=true" (mouseout)="closerhover=false"></i>
  <input [attr.aria-label]="label" type="file" class="form-control" [disabled]="disabled" (change)="changefile($event)" *ngIf="writeAccess&&(showupload||(!showimage&&!showdocument))" />
  <select [attr.aria-label]="label" class="form-control" *ngIf="archive&&archive.length > 0" [(ngModel)]="name">
    <option *ngFor="let file of archive" [value]="file.Value">{{file.Value}}</option>
  </select>
  <span *ngIf="!hidedownload&&old&&old.length > 0" [ngStyle]="{'text-decoration': markedasdelete}"><a class="swe-click" (click)="download()">{{name}}</a>&nbsp;<i class="fas {{changestatus}}" (click)="toggle()" *ngIf="writeAccess&&toggable"></i></span>
  <div *ngIf="showimage">
    <i class="fas fa-3x fa-image" *ngIf="!showupload&&(!document||document.length==0)"></i>
    <img alt="{{languageService.getItem(195)}}" *ngIf="!showupload&&document&&document.length>0" sweImage [imageFolder]="folder" [imageFile]="document" />
    <i class="fas fa-swe fa-upload swe-click ml-2" style="vertical-align:top;" *ngIf="!showupload&&!disabled" (click)="showupload=true" [attr.title]="languageService.getItem(337)" [ngClass]="{'swe-grayed-out': uploadhover}" (mouseover)="uploadhover=true" (mouseout)="uploadhover=false"></i>
    <i class="fas fa-swe fa-trash swe-click ml-2" style="vertical-align:top;" *ngIf="!showupload&&document&&document.length>0&&!disabled" [attr.title]="languageService.getItem(882)" (click)="delete()" [ngClass]="{'swe-grayed-out': deletehover}" (mouseover)="deletehover=true" (mouseout)="deletehover=false"></i>
  </div>
  <div *ngIf="showdocument">
    
  </div>
</div>
