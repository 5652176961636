import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { ListService } from '../_services/list.service';
import { GeneralService } from '../_services/general.service';


@Component({
  selector: 'app-userdetail',
  templateUrl: './userdetail.component.html'
})
export class UserDetailComponent implements OnInit, OnDestroy {

  private _id: number;
  private _userdata: any = {}; //Sharing UserData between components
  private _objecttoobjects: any[] = [];
  

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private settingService: SettingService,
    private dataService: DataService,
    private listService: ListService,
    private generalService: GeneralService,
    private route: ActivatedRoute) {
    
  }

  ngOnDestroy() {
    this.dataService.tokenRequest('/api/v1/users/' + this._id + '/close', 'POST')
      .subscribe(res => {

      });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.settingService.lastuser = this._id;

      this.load();
    });
  }

  /*Properties*/
  public get cards() {
    return {
      data: this.settingService.isDevice(['md', 'lg', 'xl']),
      employmentplans: false,
      bookings: false,
      availability: false,
      belongings: false,
      statistics: false,
      icalendar: false,
      contract: false,
      logs: false,
      systemlogs: false,
      messagelogs: false
    };
  }
  public get id() {
    return this._id;
  }
  public get userdata() {
    return this._userdata;
  }
  public get objecttoobjects() {
    return this._objecttoobjects;
  }




  /*Methods*/
  public manageUserdata(data) {
    this._userdata = data;
  }


  //Functions
  private load() {

    this._objecttoobjects = this.listService.formatArray(this.generalService.propertycategories, ['User'], 'Type');

  }
}
