import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { GeneralService } from '../../_services/general.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { AlertService } from '../../_services/alert.service';


@Component({
  selector: 'swe-useremployments',
  templateUrl: './useremployments.component.html'
})
export class UserEmploymentsComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() open: boolean;
  @Input() userdata: any;

  private _loading: boolean = false;
  private _employmenttypes: any[] = [];
  private _employmentcategories: any[] = [];
  private _employments: any[] = [];

  constructor(
    public languageService: LanguageService,
    public generalService: GeneralService,
    public dateTimeService: DateTimeService,
    private dataService: DataService,
    private alertService: AlertService
  ) {

  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    if (this.open) {
      this.loademployments();
    }
  }



  //Properties
  public get loading() {
    return this._loading;
  }
  public get employmenttypes() {
    return this._employmenttypes;
  }
  public get employmentcategories() {
    return this._employmentcategories;
  }
  public get employments() {
    return this._employments;
  }



  //Methods
  public loademployments() {
    if (this.open) {

      this.dataService.tokenRequest('/api/v1/users/' + this.id + '/employments', 'GET', {})
        .subscribe((res) => {
          this._employments = res;

          if (this.userdata.EmploymentAccess > 1) {
            this._employments.push({
              Id: 0,
              Start: null,
              End: null,
              Degree: 0
            });
          }

        });

    }
  }
  public saveemployment(employment) {

    if (employment.Start == null || employment.Start.length == 0) {
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1008) });
      return;
    }
    if (employment.End == null || employment.End.length == 0) {
      employment.End = this.dateTimeService.formatWithTimeZone(new Date(2100, 0, 1));
    }

    if (new Date(employment.Start) > new Date(employment.End)) {
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(662) });
      return;
    }

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/employments', 'PUT', employment, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.loademployments();
      });

  }
  public deleteemployment(employmentid) {

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/employments/' + employmentid, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.loademployments();
      });

  }


  //Functions
  private init() {

    this.generalService.employmenttypes.forEach((employmenttype) => {

      this._employmenttypes.push({ Id: employmenttype.Key, Name: employmenttype.Value });

    });

    this.generalService.employmentcategories.forEach((employmentcategory) => {

      this._employmentcategories.push({ Id: employmentcategory.Key, Name: employmentcategory.Value });

    });
  }
}
