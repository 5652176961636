import { Observable, forkJoin, of } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
import * as i2 from "./setting.service";
export class PermissionService {
    constructor(dataService, settingService) {
        this.dataService = dataService;
        this.settingService = settingService;
    }
    resolve() {
        return forkJoin(this.loadPermissions(), this.loadUser()).pipe(take(1), switchMap(res => {
            return res;
        }));
    }
    //Methods
    isLocked(value) {
        if (this._permissions != null && this._permissions.SearchLocked != null) {
            return this._permissions.SearchLocked.indexOf(value) > -1;
        }
        return true;
    }
    isimpersonate() {
        let originaluser = localStorage.getItem('originaluser');
        return originaluser ? originaluser : 0;
    }
    hasObject(id) {
        if (this._permissions != null && this._permissions.Objects != null) {
            let objPermission = this._permissions.Objects.filter(function (o) { return o.Key == id; });
            if (objPermission && objPermission.length > 0) {
                return objPermission[0].Value;
            }
        }
        return 0;
    }
    //Reload
    reload() {
        return forkJoin(this.reloadPermissions(), this.reloadUser()).pipe(take(1), switchMap(res => {
            return res;
        }));
    }
    reloadPermissions() {
        this._permissions = null;
        return this.loadPermissions();
    }
    reloadUser() {
        this._user = null;
        return this.loadUser();
    }
    //Properties
    get permissions() {
        return this._permissions;
    }
    get unauthpermissions() {
        if (!this._unauthpermissions) {
            this._unauthpermissions = {};
            this.dataService.basicRequest('/api/v1/general/permissions/unauthorize', 'GET').subscribe(res => {
                if (res) {
                    this._unauthpermissions = res;
                }
            });
        }
        return this._unauthpermissions;
    }
    get user() {
        return this._user;
    }
    loadPermissions() {
        if (!this._permissions) {
            this._permissions = {};
            return this.dataService.tokenRequest('/api/v1/general/permissions', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._permissions = res;
                    let searchavailable = Math.pow(2, res.SearchAvailable);
                    //Defaults
                    this.settingService.defaultbooking.start = this.settingService.booking.start = new Date(res.SearchStart);
                    this.settingService.defaultbooking.end = this.settingService.booking.end = new Date(res.SearchEnd);
                    this.settingService.defaultbooking.shifttype = this.settingService.booking.shifttype = res.SearchShiftType;
                    this.settingService.defaultbooking.reservation = this.settingService.booking.reservation = res.SearchReservation;
                    this.settingService.defaultbooking.personal = this.settingService.booking.personal = res.SearchOnlyYours;
                    this.settingService.defaultbooking.availability = this.settingService.booking.availability = ((res.AvailableType & searchavailable) == searchavailable) ? res.SearchAvailable : 0;
                    this.settingService.defaulttimeline.groupby = this.settingService.timeline.groupby = res.SearchGroupby;
                    this.settingService.defaulttimeline.exactrendering = this.settingService.timeline.exactrendering = res.SearchShowExact;
                    this.settingService.defaulttimeline.includereferences = this.settingService.timeline.includereferences = res.SearchShowRef;
                    this.settingService.defaulttimeline.timelineshow = this.settingService.timeline.timelineshow = res.SearchTimelineShow;
                    this.settingService.defaulttimeline.sumoptions = this.settingService.timeline.sumoptions = (res.ChartObjectDefault) ? 1 : 0;
                    this.settingService.defaulttimeline.markedhide = this.settingService.timeline.markedhide = (res.HideMarked);
                    this.settingService.defaultbooking.showemptyrows = this.settingService.timeline.showemptyrows = res.SearchShowEmptyRows;
                    //Check watchdog
                    this.settingService.checkWatchdog();
                }
                return of(this._permissions);
            }));
        }
        else {
            return of(this._permissions);
        }
    }
    loadUser() {
        if (!this._user) {
            this._user = {};
            return this.dataService.tokenRequest('/api/v1/general/user', 'GET').pipe(take(1), switchMap(res => {
                if (res) {
                    this._user = res;
                }
                return of(this._user);
            }));
        }
        else {
            return of(this._user);
        }
    }
}
PermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.SettingService)); }, token: PermissionService, providedIn: "root" });
