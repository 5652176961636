<swe-pageheader [header]="languageService.getItem(712)"></swe-pageheader>
<swe-datepager [type]="'holiday'" (dateChange)="load()" [disabled]="loading" [hideTime]="true"></swe-datepager>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="false">
      <span swe-rightcommand>
        <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="goto(0)"></i>
      </span>
      <swe-list [data]="holidays" [loading]="loading">
        <a class="list-group-item list-group-item-action d-flex swe-click" [ngClass]="{'list-group-item-danger': holiday.IsRed, 'list-group-item-warning': (!holiday.IsRed && holiday.Id==0), 'swe-disabled': holiday.Id==0}" *ngFor="let holiday of holidays" (click)="permissionService.permissions.Holiday>1 && holiday.Id>0 && goto(holiday.Id)">
          <div class="col-5 col-md-2">{{holiday.Date}}</div>
          <div class="col-7 col-md-10 d-flex flex-wrap">
            <div class="col-12 col-md-6">{{holiday.Name}}</div>
            <div class="col-12 col-md-6">{{holiday.Desc}}</div>
          </div>
        </a>
      </swe-list>
    </swe-card>
  </div>
</div>
