<div class="d-none d-md-inline-block align-top">
  <!--Visible for medium and larger devices-->
  <swe-datepicker [changed]="startChanged" [(model)]="start" (modelChange)="startHasChanged()" [label]="labelStart" *ngIf="!hideDate" [disabled]="disabled" [alwaysshowcontents]="alwaysshowcontents"></swe-datepicker>
  <swe-timepicker [changed]="startChanged" [(model)]="start" (modelChange)="startHasChanged()" [label]="labelStart" *ngIf="!hideTime" [disabled]="disabled" [hideSeconds]="hideSeconds"></swe-timepicker>
  <div class="d-inline-block align-middle p-1" *ngIf="!hideEnd">-</div>
  <swe-timepicker [changed]="endChanged" [(model)]="end" (modelChange)="endHasChanged()" [label]="labelEnd" *ngIf="!hideEnd&&!hideTime" [disabled]="disabled" [hideSeconds]="hideSeconds"></swe-timepicker>
  <swe-datepicker [changed]="endChanged" [(model)]="end" (modelChange)="endHasChanged()" [label]="labelEnd" *ngIf="!hideEnd&&!hideDate" [disabled]="disabled" [alwaysshowcontents]="alwaysshowcontents"></swe-datepicker>
  <div class="d-inline-block align-middle p-1"><i class="fas fa-trash swe-click" *ngIf="hideEnd&&!disabled&&!hideReset" (click)="reset()"></i></div>
</div>
<div class="d-inline-block d-md-none align-top">
  <!--Visible for x-small and small devices-->
  <div>
    <input [attr.aria-label]="labelStart" type="datetime-local" class="form-control" [ngClass]="{'bg-warning': startChanged}" [(ngModel)]="startAsISO" (ngModelChange)="startHasChanged()" *ngIf="!hideDate&&!hideTime" [disabled]="disabled" />
    <input [attr.aria-label]="labelStart" type="date" class="form-control" [ngClass]="{'bg-warning': startChanged}" [(ngModel)]="startAsDateString" (ngModelChange)="startHasChanged()" *ngIf="!hideDate&&hideTime" [disabled]="disabled" />
    <input [attr.aria-label]="labelStart" type="time" class="form-control" [ngClass]="{'bg-warning': startChanged}" [(ngModel)]="startAsTimeString" (ngModelChange)="startHasChanged()" *ngIf="!hideTime&&hideDate" [disabled]="disabled" />
  </div>
  <div *ngIf="!hideEnd">
    <input [attr.aria-label]="labelEnd" type="datetime-local" class="form-control" [ngClass]="{'bg-warning': endChanged}" [(ngModel)]="endAsISO" (ngModelChange)="endHasChanged()" *ngIf="!hideDate&&!hideTime" [disabled]="disabled" />
    <input [attr.aria-label]="labelEnd" type="date" class="form-control" [ngClass]="{'bg-warning': endChanged}" [(ngModel)]="endAsDateString" (ngModelChange)="endHasChanged()" *ngIf="!hideDate&&hideTime" [disabled]="disabled" />
    <input [attr.aria-label]="labelEnd" type="time" class="form-control" [ngClass]="{'bg-warning': endChanged}" [(ngModel)]="endAsTimeISO" (ngModelChange)="endHasChanged()" *ngIf="!hideTime&&hideDate" [disabled]="disabled" />
  </div>
</div>
