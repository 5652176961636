<swe-pageheader [header]="languageService.getItem(410)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-left">
    <swe-card header="{{languageService.getItem(4)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
      <!--Time-->
      <div class="form-group">
        <label>{{languageService.getItem(931)}}</label>
        <div>
          <swe-datetime [(start)]="start" [(end)]="end" [labelStart]="languageService.getItem(931)" [hideDate]="true"></swe-datetime>
        </div>
      </div>
      <!--Status-->
      <swe-element [(model)]="status" [label]="languageService.getItem(413)" [statusLabel]="2" [items]="getStatus()" [optional]="true" [type]="'System.List'"></swe-element>
      <!--Auto-->
      <swe-element [(model)]="autooption" [label]="languageService.getItem(695)" [statusLabel]="2" [items]="autooptions" [type]="'System.RadioList'" *ngIf="permissionService.permissions.AutoBook>1"></swe-element>
      <!--EmploymentPlan-->
      <swe-element [(model)]="employmentoption" [label]="languageService.getItem(698)" [statusLabel]="2" [items]="employmentoptions" [type]="'System.RadioList'" *ngIf="permissionService.permissions.EmploymentPlan>1"></swe-element>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)+' (' + languageService.getItem(4) + ')'" [statusLabel]="2" [properties]="properties" [items]="chosenproperties"></swe-properties>
      <!--Buttons-->
      <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
        <button class="btn btn-primary btn-block mb-2"><i class="fas fa-fw fa-clone"></i>&nbsp;{{languageService.getItem(411)}}</button>
      </swe-confirm>
      <swe-confirm [body]="languageService.getItem(417)" (acceptChange)="delete()">
        <button class="btn btn-danger btn-block mb-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(412)}}</button>
      </swe-confirm>
    </swe-card>
  </div>
  <div class="col-12 col-md-4 float-left">
    <swe-card header="{{languageService.getItem(2)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false" *ngIf="bookedusers.length>0||permissionService.permissions.BookingUsersAccess>0||availableusers.length>0">
      <!--Cancel Users-->
      <swe-element [bottomMargin]="0" [(model)]="cancelusers" [label]="languageService.getItem(414)" [statusLabel]="2" [items]="bookedusers" [type]="'System.MultiList'" *ngIf="bookedusers.length>0"></swe-element>
      <div class="btn-group mb-3" *ngIf="bookedusers.length>0">
        <!--Email Notification-->
        <button [attr.aria-label]="languageService.getItem(920)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationcancellationemail=!checknotificationcancellationemail" *ngIf="permissionService.permissions.NotifyOnCancellationEmail>0">
          <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationcancellationemail, 'fa-check-square': checknotificationcancellationemail}"></i>
          <i class="fas fa-fw fa-swe fa-envelope"></i>
        </button>
        <!--SMS Notification-->
        <button [attr.aria-label]="languageService.getItem(921)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationcancellationsms=!checknotificationcancellationsms" *ngIf="permissionService.permissions.NotifyOnCancellationSms>0">
          <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationcancellationsms, 'fa-check-square': checknotificationcancellationsms}"></i>
          <i class="fas fa-fw fa-swe fa-mobile-alt"></i>
        </button>
      </div>
      <!--Book Users-->
      <ng-container *ngIf="permissionService.permissions.BookingUsersAccess>0">
        <swe-element [bottomMargin]="0" [(model)]="bookingtypeoption" [label]="languageService.getItem(1039)" [statusLabel]="2" [items]="bookingtypeoptions" [type]="'System.List'"></swe-element>
        <swe-usersearch [bottomMargin]="3" (modelChange)="userChosen($event)" [onlyone]="false" [label]="languageService.getItem(435)"></swe-usersearch>
        <div class="list-group" *ngIf="availableusers.length>0">
          <div class="list-group-item" *ngFor="let user of availableusers">
            <i class="fas fa-border fa-user" *ngIf="user.Type==1" [attr.title]="languageService.getItem(476)"></i>
            <i class="fas fa-border fa-retweet" *ngIf="user.Type==0" [attr.title]="languageService.getItem(423)"></i>
            <i class="fas fa-border fa-street-view" *ngIf="user.Type==2" [attr.title]="languageService.getItem(279)"></i>
            <i class="fas fa-border fa-ban" *ngIf="user.Type==3" [attr.title]="languageService.getItem(280)"></i>
            {{user.Firstname}} {{user.Lastname}}<span *ngIf="user.Username.length>0">&nbsp;({{user.Username}})</span>
            <i class="fas fa-fw fa-swe fa-times fa-pull-right swe-click" (click)="removeuser(user,$event)"></i>
          </div>
        </div>
      </ng-container>
      <div class="btn-group mb-3" *ngIf="availableusers.length>0">
        <!--Email Notification-->
        <button [attr.aria-label]="languageService.getItem(920)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationemail=!checknotificationemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
          <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationemail, 'fa-check-square': checknotificationemail}"></i>
          <i class="fas fa-fw fa-swe fa-envelope"></i>
        </button>
        <!--SMS Notification-->
        <button [attr.aria-label]="languageService.getItem(921)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationsms=!checknotificationsms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
          <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationsms, 'fa-check-square': checknotificationsms}"></i>
          <i class="fas fa-fw fa-swe fa-mobile-alt"></i>
        </button>
      </div>
      <!--Remove RemindSMS-->
      <swe-element [(model)]="removeremindsms" [label]="languageService.getItem(918)" [statusLabel]="2" [type]="'System.Boolean'" *ngIf="permissionService.permissions.RemindSms"></swe-element>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)+' (' + languageService.getItem(1040) + ')'" [statusLabel]="2" [properties]="timereportproperties" [items]="chosentimereportproperties" *ngIf="permissionService.permissions.BookingUsersAccess>0"></swe-properties>
      <!--Buttons-->
      <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
        <button class="btn btn-primary btn-block mb-2"><i class="fas fa-fw fa-clone"></i>&nbsp;{{languageService.getItem(411)}}</button>
      </swe-confirm>
      <swe-confirm [body]="languageService.getItem(417)" (acceptChange)="delete()">
        <button class="btn btn-danger btn-block mb-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(412)}}</button>
      </swe-confirm>
    </swe-card>
  </div>
</div>

