<div class="col-12 col-md-9 float-left">
  <div class="swe-row">
    <div class="col-12 col-md-4 float-left">
      <swe-card header="{{languageService.getItem(76) + todaystat}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
        <span swe-rightcommand><i class="fas fa-fw fa-external-link-alt fa-pull-right swe-click" (click)="goto(1)" *ngIf="permissionService.permissions.Bookings>0"></i></span>
        <canvas baseChart
                [data]="today"
                [labels]="labels"
                [colors]="colors"
                [options]="options"
                [chartType]="charttype">
        </canvas>
      </swe-card>
    </div>
    <div class="col-12 col-md-4 float-left">
      <swe-card header="{{languageService.getItem(77) + currentweekstat}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
        <span swe-rightcommand><i class="fas fa-fw fa-external-link-alt fa-pull-right swe-click" (click)="goto(2)" *ngIf="permissionService.permissions.Bookings>0"></i></span>
        <canvas baseChart
                [data]="currentweek"
                [labels]="labels"
                [colors]="colors"
                [options]="options"
                [chartType]="charttype">
        </canvas>
      </swe-card>
    </div>
    <div class="col-12 col-md-4 float-left">
      <swe-card header="{{languageService.getItem(78) + nextweekstat}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
        <span swe-rightcommand><i class="fas fa-fw fa-external-link-alt fa-pull-right swe-click" (click)="goto(3)" *ngIf="permissionService.permissions.Bookings>0"></i></span>
        <canvas baseChart
                [data]="nextweek"
                [labels]="labels"
                [colors]="colors"
                [options]="options"
                [chartType]="charttype">
        </canvas>
      </swe-card>
    </div>
  </div>
</div>
