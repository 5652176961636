import { Component, Input, EventEmitter, Output, OnInit, ElementRef, ViewChild, OnChanges } from '@angular/core';

import { LanguageService } from '../_services/language.service';

@Component({
  selector: 'swe-confirmserie',
  templateUrl: './confirmserie.component.html',
})
export class ConfirmSerieComponent implements OnInit, OnChanges {
  @Output() acceptChange = new EventEmitter<any>();
  @Input() affectAllOnly: boolean = false;
  @Input() isSerie: boolean = false;
  @Input() isvisible: boolean = false;
  @ViewChild('btnAccept', { static: false }) focusElement: ElementRef;
  
  private _visible: boolean = false;

  constructor(public languageService: LanguageService) {

  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.isvisible) {
      if (this.isSerie) {
        this._visible = true;
        this.manageFocus();
      }
      else {
        this.acceptChange.emit(1);
      }
    }
  }

  //Properties
  public get visible() {
    return this._visible;
  }


  //Methods
  public open(e) {
    e.stopPropagation();

    if (this.isSerie) {
      this._visible = true;
      this.manageFocus();
    }
    else {
      this.acceptChange.emit(1);
    }
  }
  public close() {
    this._visible = false;
  }
  public accept(index: number, e) {

    e.stopPropagation();

    this.acceptChange.emit(index);
    this._visible = false;
  }


  //Functions
  private manageFocus() {
    setTimeout(() => {
      if (this.focusElement) {
        let element = this.focusElement.nativeElement;
        element.focus();
        if (element.select) {
          element.select();
        }
      }
    }, 0); //Create a macrotask that will run in the next VM turn
  }

}
