<swe-pageheader [header]="languageService.getItem(114)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="reload()">
      <span swe-rightcommand>
        <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="open($event, 0)"></i>
      </span>
      <div [class]="'list-group-item'" sweFixedHeader>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="{{languageService.getItem(736)}}" [(ngModel)]="settingService.searchcriteriarules" (keydown)="filter()" />
          <button class="btn btn-swe input-group-append"><i class="fas fa-times swe-click input-group-addon" (click)="resetfilter()"></i></button>
        </div>
      </div>
      <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-danger': rule.Access=='Delete','list-group-item-warning': rule.Access=='Write','list-group-item-success': rule.Access=='Read'}" *ngFor="let rule of rules" (click)="open($event, rule.Id, rule.Keyword)">
        <div class="col-5 col-md-2 d-flex flex-wrap">
          <div class="col-12" *ngFor="let belonging of rule.Belonging">
            {{belonging}}
          </div>
        </div>
        <div class="col-7 col-md-10 d-flex flex-wrap">
          <div class="col-12 col-md-3">{{rule.Desc}}</div>
          <div class="col-12 col-md-3">{{rule.Keyword}}</div>
          <div class="col-4 col-md-1">{{rule.Access}}</div>
          <div class="col-4 col-md-1"><i class="fas fa-2x fa-sitemap" *ngIf="rule.Child>1"></i><i class="fas fa-2x fa-child" *ngIf="rule.Child==1"></i></div>
          <div class="col-4 col-md-1"><i class="fas fa-2x fa-user" *ngIf="rule.OnlyYou"></i></div>
          <div class="col-4 col-md-1"><i class="fas fa-2x fa-unlock" *ngIf="rule.InclUnauth"></i></div>
          <div class="col-12 col-md-2">{{rule.LastMod}}</div>
        </div>
      </a>
    </swe-card>
  </div>
</div>
