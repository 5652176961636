/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@valor-software/ng2-charts";
import * as i2 from "../../layout/card.component.ngfactory";
import * as i3 from "../../layout/card.component";
import * as i4 from "../../_services/language.service";
import * as i5 from "../../layout/datepager.component.ngfactory";
import * as i6 from "../../layout/datepager.component";
import * as i7 from "../../_services/datetime.service";
import * as i8 from "../../_services/setting.service";
import * as i9 from "@angular/common";
import * as i10 from "./userstatistics.component";
import * as i11 from "../../_services/data.service";
import * as i12 from "../../_services/alert.service";
import * as i13 from "../../_services/property.service";
import * as i14 from "@angular/router";
var styles_UserStatisticsComponent = [];
var RenderType_UserStatisticsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserStatisticsComponent, data: {} });
export { RenderType_UserStatisticsComponent as RenderType_UserStatisticsComponent };
function View_UserStatisticsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i0.ɵdid(3, 999424, null, 0, i1.BaseChartDirective, [i0.ElementRef, i1.ThemeService], { data: [0, "data"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"], plugins: [5, "plugins"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i0.ɵdid(6, 999424, null, 0, i1.BaseChartDirective, [i0.ElementRef, i1.ThemeService], { data: [0, "data"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"], plugins: [5, "plugins"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shiftdata; var currVal_1 = _co.shiftlabels; var currVal_2 = _co.shiftoptions; var currVal_3 = _co.charttype; var currVal_4 = _co.shiftcolors; var currVal_5 = _co.chartPlugins; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.smsdata; var currVal_7 = _co.smslabels; var currVal_8 = _co.smsoptions; var currVal_9 = _co.charttype; var currVal_10 = _co.smscolors; var currVal_11 = _co.chartPlugins; _ck(_v, 6, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, null); }
export function View_UserStatisticsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "swe-card", [], null, [[null, "openChange"], [null, "refreshChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openChange" === en)) {
        var pd_0 = ((_co.open = $event) !== false);
        ad = (pd_0 && ad);
    } if (("refreshChange" === en)) {
        var pd_1 = (_co.load() !== false);
        ad = (pd_1 && ad);
    } if (("openChange" === en)) {
        var pd_2 = (_co.load() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i3.CardComponent, [i4.LanguageService], { header: [0, "header"], open: [1, "open"] }, { openChange: "openChange", refreshChange: "refreshChange" }), (_l()(), i0.ɵeld(2, 0, null, 2, 0, "span", [["swe-rightcommand", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, 3, 1, "swe-datepager", [], null, [[null, "dateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dateChange" === en)) {
        var pd_0 = (_co.load() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DatePagerComponent_0, i5.RenderType_DatePagerComponent)), i0.ɵdid(4, 770048, null, 0, i6.DatePagerComponent, [i7.DateTimeService, i4.LanguageService, i8.SettingService], { hideTime: [0, "hideTime"], type: [1, "type"], disabled: [2, "disabled"] }, { dateChange: "dateChange" }), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_UserStatisticsComponent_1)), i0.ɵdid(6, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.languageService.getItem(241), ""); var currVal_1 = _co.open; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = true; var currVal_3 = "booking"; var currVal_4 = _co.loading; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = !_co.loading; _ck(_v, 6, 0, currVal_5); }, null); }
export function View_UserStatisticsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-userstatistics", [], null, null, null, View_UserStatisticsComponent_0, RenderType_UserStatisticsComponent)), i0.ɵdid(1, 770048, null, 0, i10.UserStatisticsComponent, [i4.LanguageService, i11.DataService, i12.AlertService, i8.SettingService, i13.PropertyService, i14.ActivatedRoute, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserStatisticsComponentNgFactory = i0.ɵccf("swe-userstatistics", i10.UserStatisticsComponent, View_UserStatisticsComponent_Host_0, { id: "id", open: "open" }, {}, []);
export { UserStatisticsComponentNgFactory as UserStatisticsComponentNgFactory };
