import { Component, Input, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';
import { Router } from '@angular/router';
import { PermissionService } from '../_services/permission.service';

@Component({
  selector: 'swe-adminrule',
  templateUrl: './adminrule.component.html',
})
export class AdminRuleComponent implements OnInit {

  @Input() rulelist: string[] = [];
  @Input() class: string = 'd-inline';
  @Input() preventdrowning: boolean = false;

  private _showrulelist: boolean = false;

  constructor(
    public languageService: LanguageService,
    public settingService: SettingService,
    public permissionService: PermissionService,
    private router: Router
  ) {
    
  }

  ngOnInit() {
    
  }


  /*Properties*/
  public get showrulelist(){
    return this._showrulelist;
  }
  public set showrulelist(val) {
    this._showrulelist = val;
  }




  /*Methods*/
  public goto(rule, e) {

    e.stopPropagation();

    let url = '/systemadmin/rules';

    if (e.ctrlKey || e.metaKey) {
      window.open(url + '?r=' + rule, '_blank');
    }
    else {
      this.router.navigate([url], { queryParams: { r: rule } });
    }
  }


  /*Functions*/

}
