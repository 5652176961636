<swe-pageheader [header]="languageService.getItem(876)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-left">
    <swe-card [header]="languageService.getItem(479)" [open]="true" [hasCollapse]="false" [hasRefresh]="false">
      <swe-element [type]="'System.Boolean'" [label]="'DebugMode'" [statusLabel]="1" [(model)]="debugmode" (modelChange)="changeDebug()"></swe-element>
      <button class="btn btn-primary btn-block" (click)="clearcache()"><i class="fas fa-trash"></i>&nbsp;{{languageService.getItem(115)}}</button>
    </swe-card>
  </div>
  <div class="col-12 col-md-4 float-left" [ngClass]="{'col-md-4': table.Cols==1,'col-md-8': table.Cols==2, 'col-md-12': table.Cols==3}" *ngFor="let table of tables">
    <swe-card [header]="table.DisplayName" [open]="false" [hasCollapse]="true" [hasRefresh]="true" (openChange)="getTable(table)" (refreshChange)="getTable(table)">
      <div *ngIf="table.Description.length>0">
        <pre>{{table.Description}}</pre>
      </div>
      <div class="table-responsive">
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <th></th>
              <th *ngFor="let header of table.headers">{{header.Name}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr [ngClass]="{'bg-danger': row.IsDeleted,'swe-disabled-block': !row.IsActive}" *ngFor="let row of table.rows">
              <td><span *ngIf="row.Id>0">{{row.Id}}</span></td>
              <td style="white-space:nowrap;" *ngFor="let property of row.Properties; index as i" [ngSwitch]="property.Type">
                <swe-element [type]="'System.List'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getItems(table, property.Name)" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" *ngSwitchCase="'System.List'"></swe-element>
                <swe-element [type]="'System.GroupByList'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getGroupByItems(table, property.Name)" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" *ngSwitchCase="'System.GroupByList'"></swe-element>
                <swe-element [type]="'System.MultiList'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getItems(table, property.Name)" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" *ngSwitchCase="'System.MultiList'"></swe-element>
                <swe-element [type]="'System.LevelSearch'" [label]="property.Name + '_' + i" [(model)]="property.Value" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" *ngSwitchCase="'System.Level'"></swe-element>
                <swe-element [type]="'System.TextArea'" [label]="property.Name + '_' + i" [(model)]="property.Value" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" [allownull]="true" [container]="{resize: 'auto'}" *ngSwitchCase="'System.Text'"></swe-element>
                <span *ngSwitchCase="'System.Label'">{{property.Value}}</span>
                <swe-element [type]="property.Type" [label]="property.Name + '_' + i" [(model)]="property.Value" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" [allownull]="true" *ngSwitchDefault></swe-element>
              </td>
              <td><i class="fas fa-fw fa-swe swe-click" [ngClass]="{'fa-trash': !row.IsDeleted, 'fa-undo': row.IsDeleted}" *ngIf="row.Id>0" (click)="row.IsDeleted=!row.IsDeleted"></i></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td *ngFor="let property of table.footer.Properties" [ngSwitch]="property.Type">
                <swe-element [type]="'System.List'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getItems(table, property.Name)" *ngSwitchCase="'System.List'"></swe-element>
                <swe-element [type]="'System.GroupByList'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getGroupByItems(table, property.Name)" *ngSwitchCase="'System.GroupByList'"></swe-element>
                <swe-element [type]="'System.MultiList'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getItems(table, property.Name)" *ngSwitchCase="'System.MultiList'"></swe-element>
                <swe-element [type]="'System.LevelSearch'" [label]="property.Name + '_' + i" [(model)]="property.Value" *ngSwitchCase="'System.Level'"></swe-element>
                <swe-element [type]="'System.TextArea'" [label]="property.Name + '_' + i" [(model)]="property.Value" *ngSwitchCase="'System.Text'"></swe-element>
                <span *ngSwitchCase="'System.Label'">{{property.Value}}</span>
                <swe-element [type]="property.Type" [label]="property.Name + '_' + i" [(model)]="property.Value" [allownull]="true" *ngSwitchDefault></swe-element>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div>
        <button class="btn btn-block btn-primary" (click)="save(table)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
      </div>
    </swe-card>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-left] on columns-->
