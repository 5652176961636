import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { LanguageService } from '../_services/language.service';

@Component({
  selector: 'swe-list',
  templateUrl: 'list.component.html',
})
export class ListComponent implements OnInit, OnChanges {
  @Input() data: any[];
  @Input() loading: boolean;

  private _list: any[] = null;

  constructor(public languageService: LanguageService) {

  }

  ngOnInit() {
    this._list = null;
  }

  ngOnChanges() {

    if (typeof this.loading != 'undefined') {
      if (this.loading) {
        this._list = null;
      }
      else {
        this._list = this.data;
      }
    }
    else {
      this._list = null;

      let timeout = setTimeout(() => {
        this._list = this.data;
      }, 500);
    }

  }

  /*Properties*/
  public get list() { return this._list; }
  

  /*Methods*/

}
