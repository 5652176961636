import { Directive, Input, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[sweFixedHeader]'
})
export class FixedHeaderDirective implements OnInit {

  @Input() class: string = '';
  @Input() stickyTop: number = -1;
  @Input() index: number = 1;
  @Input() inactive: boolean = false;
  @Input() nopadding: boolean = false;
  @Input() topadjustmentclass: string = '';

  private _inactiveclass: string = ''
  private _activeclass: string = ''

  constructor(
    private element: ElementRef
  ) {

  }

  ngOnInit() {
    if (!this.inactive) {
      //Inactive class
      this._inactiveclass = 'sticky-top swe-sticky-top';
      if (this.index > 1) {
        this._inactiveclass += '-' + this.index;
      }
      if (this.stickyTop && this.stickyTop > -1) {
        if (this.stickyTop > 0) {
          this._inactiveclass += '-' + this.stickyTop;
        }
      }
      if (this.class && this.class.length > 0) {
        this._inactiveclass += ' ' + this.class;
      }
      //Activ class
      this._activeclass = this._inactiveclass;
      if (!this.class || this.class.length == 0 || this.class == 'swe-fill-to-edge') {
        this._activeclass += ' scroll-active';
        if (!this.nopadding) {
          this._activeclass += ' p-1';
        }
        if (this.topadjustmentclass && this.topadjustmentclass.length > 0) {
          this._activeclass += ' ' + this.topadjustmentclass;
        }
      }

      //Set init class
      this.element.nativeElement.className = this._inactiveclass;
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (!this.inactive) {
      if (document.documentElement.scrollTop > 0) {
        //Activ class
        this.element.nativeElement.className = this._activeclass;
      }
      else {
        //Inactive class
        this.element.nativeElement.className = this._inactiveclass;
      }
    }
  }

}
