<div class="card shadow mb-2 {{bordercolor}}" [ngStyle]="{'width': (fitcontent ? 'max-content' : 'unset'), 'min-width': (fitcontent ? '100%' : 'unset')}">
  <div class="card-body p-md-3">
    <h2 class="card-title" [ngClass]="{'mb-0':!isOpen, 'swe-click': hasCollapse}" (click)="hasCollapse&&toggle()">
      <ng-content select="[swe-leftcommand]"></ng-content>
      <span [ngClass]="{'swe-big-header': bigheader}">{{header}}&nbsp;</span>
      <span *ngFor="let tab of tabs" class="mr-3 swe-click" [ngClass]="{'swe-grayed-out': tab.id!=active}" (click)="change(tab.id,$event)">{{tab.name}}</span>
      <ng-content select="[swe-hits]"></ng-content>
      <span class="swe-print-remove" *ngIf="hasCollapse"><a href="javascript:void(0);" class="swe-no-link float-right" [attr.title]="header" #sweFocusElement><i class="fas fa-fw fa-pull-right" [ngClass]="{'fa-chevron-right': !isOpen,'fa-chevron-down': isOpen}"></i></a></span>
      <span class="swe-print-remove" *ngIf="hasRefresh&&isOpen" (click)="refresh($event)"><i class="fas fa-fw fa-sync fa-pull-right swe-click" [ngClass]="{'fa-spin': isRefreshing}" [attr.title]="languageService.getItem(995)"></i></span>
      <span class="swe-print-remove" *ngIf="hasSave&&isOpen" (click)="save($event)"><i class="fas fa-fw fa-save fa-pull-right swe-click" [attr.title]="languageService.getItem(16)"></i></span>
      <ng-content select="[swe-rightcommand]" *ngIf="isOpen"></ng-content>
    </h2>
    <div class="bg-transparent" *ngIf="isOpen">
      <ng-content></ng-content>
    </div>
  </div>
</div>
