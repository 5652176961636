<swe-card header="{{languageService.getItem(681)}}" [color]="'swe'" [(open)]="open" (refreshChange)="search(false)" (openChange)="search(false)">
  <span swe-hits *ngIf="open">&nbsp;({{hits}}<span *ngIf="more">+</span>)</span>
  <div class="btn-group ml-1 align-top" *ngIf="permissionService.permissions.ReplySms && messages.length>0 && type=='booking'">
    <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe dropdown" (click)="listchecked=!listchecked;listutility.checkall(listchecked)">
      <span><i class="fas fa-check"></i></span>
    </button>
    <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe dropdown" (click)="actionmenu=!actionmenu" *ngIf="listutility.ischecked">
      <span><i class="fas fa-fw fa-ellipsis-v"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
      <li class="dropdown-item swe-click"><a (click)="resend()"><i class="fas fa-fw fa-retweet"></i>&nbsp;{{languageService.getItem(768)}}</a></li>
    </ul>
  </div>
  <swe-list [data]="messages" [loading]="loading">
    <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': message.checked}" *ngFor="let message of messages" (click)="goto(message.MessageId, message.Category)">
      <ng-container *ngIf="type=='booking'">
        <div class="col-3 col-md-1" (click)="listutility.toggle(message, $event)">
          <span class="fa-stack fa-swe-letter-circle-small" *ngIf="message.checked">
            <i class="fas fa-check-circle fa-stack-2x"></i>
          </span>
          <span class="fa-stack fa-swe-letter-circle-small" *ngIf="!message.checked">
            <i class="fas fa-circle fa-stack-2x"></i>
            <strong class="fa-stack-1x fa-swe-letter">{{message.DisplayName[0]}}</strong>
          </span>
        </div>
        <div class="col-9 col-md-11 d-flex flex-wrap">
          <div class="col-12 col-md-1"><span *ngIf="message.MsgType==6">{{message.ReplyCode}}</span></div>
          <div class="col-12 col-md-2">{{dateTimeService.format(message.SendTime,'yyyy-MM-dd HH:mm:ss')}}</div>
          <div class="col-12 col-md-2">{{message.From}}</div>
          <div class="col-12 col-md-2">{{message.DisplayName}}</div>
          <div class="col-12 col-md-2">{{message.Status}}</div>
          <div class="col-12 col-md-3">
            <div *ngFor="let msg of message.Reply.split('|'); index as i" [ngClass]="{'alert-warning': i > 0}" [attr.title]="msg">{{msg.substr(0,10)}}<span *ngIf="msg.length>10">...</span></div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="type!='booking'">
        <div class="col-12 col-md-2">{{dateTimeService.format(message.SendTime,'yyyy-MM-dd HH:mm:ss')}}</div>
        <div class="col-12 col-md-2">{{message.From}}</div>
        <div class="col-12 col-md-2">{{message.DisplayName}}</div>
        <div class="col-12 col-md-3" [attr.title]="message.Body">
          <span class="font-weight-bold" *ngIf="message.Subject.length>0">{{message.Subject}} </span>
          <span>{{message.Body.substring(0,50)}}</span>
          <span *ngIf="message.Body.length>50">...</span>
        </div>
        <div class="col-12 col-md-1">{{message.Type}}</div>
        <div class="col-12 col-md-1">{{message.Status}}</div>
        <div class="col-12 col-md-1"><i class="fas fa-paperclip" *ngIf="message.Attachments.length>0"></i></div>
      </ng-container>
    </a>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </swe-list>
</swe-card>
