/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layout/element.component.ngfactory";
import * as i2 from "../../layout/element.component";
import * as i3 from "../../_services/authentication.service";
import * as i4 from "../../_services/permission.service";
import * as i5 from "../../_services/property.service";
import * as i6 from "../../_services/datetime.service";
import * as i7 from "../../_services/list.service";
import * as i8 from "../../_services/language.service";
import * as i9 from "@angular/common";
import * as i10 from "../../layout/card.component.ngfactory";
import * as i11 from "../../layout/card.component";
import * as i12 from "../../layout/pageheader.component.ngfactory";
import * as i13 from "../../layout/pageheader.component";
import * as i14 from "@angular/router";
import * as i15 from "./systemsettings.component";
import * as i16 from "../../_services/data.service";
import * as i17 from "../../_services/alert.service";
import * as i18 from "../../_services/general.service";
import * as i19 from "../../_services/setting.service";
var styles_SystemSettingsComponent = [];
var RenderType_SystemSettingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SystemSettingsComponent, data: {} });
export { RenderType_SystemSettingsComponent as RenderType_SystemSettingsComponent };
function View_SystemSettingsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; if (("modelChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.Value = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ElementComponent_0, i1.RenderType_ElementComponent)), i0.ɵdid(1, 638976, null, 0, i2.ElementComponent, [i3.AuthenticationService, i4.PermissionService, i5.PropertyService, i6.DateTimeService, i7.ListService, i8.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], items: [3, "items"], model: [4, "model"] }, { modelChange: "modelChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.Name; var currVal_1 = 2; var currVal_2 = "System.List"; var currVal_3 = _co.getList(_v.parent.context.$implicit); var currVal_4 = _v.parent.context.$implicit.Value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_SystemSettingsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; if (("modelChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.Value = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ElementComponent_0, i1.RenderType_ElementComponent)), i0.ɵdid(1, 638976, null, 0, i2.ElementComponent, [i3.AuthenticationService, i4.PermissionService, i5.PropertyService, i6.DateTimeService, i7.ListService, i8.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], items: [3, "items"], model: [4, "model"], container: [5, "container"] }, { modelChange: "modelChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.Name; var currVal_1 = 2; var currVal_2 = "System.MultiList"; var currVal_3 = _co.getList(_v.parent.context.$implicit); var currVal_4 = _v.parent.context.$implicit.Value; var currVal_5 = _co.container(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_SystemSettingsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; if (("modelChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.Value = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ElementComponent_0, i1.RenderType_ElementComponent)), i0.ɵdid(1, 638976, null, 0, i2.ElementComponent, [i3.AuthenticationService, i4.PermissionService, i5.PropertyService, i6.DateTimeService, i7.ListService, i8.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"] }, { modelChange: "modelChange" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.Name; var currVal_1 = 2; var currVal_2 = _v.parent.context.$implicit.Type; var currVal_3 = _v.parent.context.$implicit.Value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_SystemSettingsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i9.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SystemSettingsComponent_3)), i0.ɵdid(3, 278528, null, 0, i9.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i9.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SystemSettingsComponent_4)), i0.ɵdid(5, 278528, null, 0, i9.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i9.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SystemSettingsComponent_5)), i0.ɵdid(7, 16384, null, 0, i9.NgSwitchDefault, [i0.ViewContainerRef, i0.TemplateRef, i9.NgSwitch], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.Type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "System.List"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "System.MultiList"; _ck(_v, 5, 0, currVal_2); }, null); }
function View_SystemSettingsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "col-12 col-md-4 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "swe-card", [], null, [[null, "saveChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveChange" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_CardComponent_0, i10.RenderType_CardComponent)), i0.ɵdid(2, 114688, null, 0, i11.CardComponent, [i8.LanguageService], { header: [0, "header"], open: [1, "open"], hasCollapse: [2, "hasCollapse"], hasRefresh: [3, "hasRefresh"], hasSave: [4, "hasSave"] }, { saveChange: "saveChange" }), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_SystemSettingsComponent_2)), i0.ɵdid(4, 278528, null, 0, i9.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, 3, 2, "button", [["class", "btn btn-block btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-save"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\u00A0", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.Header; var currVal_1 = false; var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.Settings; _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.languageService.getItem(16); _ck(_v, 7, 0, currVal_6); }); }
export function View_SystemSettingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-pageheader", [], null, null, null, i12.View_PageHeaderComponent_0, i12.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i13.PageHeaderComponent, [i9.Location, i14.ActivatedRoute, i14.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "row d-block py-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SystemSettingsComponent_1)), i0.ɵdid(4, 278528, null, 0, i9.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(113); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.settings; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_SystemSettingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-systemsettings", [], null, null, null, View_SystemSettingsComponent_0, RenderType_SystemSettingsComponent)), i0.ɵdid(1, 245760, null, 0, i15.SystemSettingsComponent, [i8.LanguageService, i4.PermissionService, i16.DataService, i17.AlertService, i18.GeneralService, i19.SettingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SystemSettingsComponentNgFactory = i0.ɵccf("app-systemsettings", i15.SystemSettingsComponent, View_SystemSettingsComponent_Host_0, {}, {}, []);
export { SystemSettingsComponentNgFactory as SystemSettingsComponentNgFactory };
