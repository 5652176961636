import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./menu.service";
export class SettingService {
    //Constructor
    constructor(datePipe, menuService) {
        this.datePipe = datePipe;
        this.menuService = menuService;
        //Events
        this.onSave$ = new EventEmitter();
        this.onRefresh$ = new EventEmitter();
        this.onViewRefresh$ = new EventEmitter();
        //Defaults
        this._visible = 0; //bitflag
        this._categories = [];
        this._filtercategory = 0; //Objects
        this._watchdog = [];
        this._difflist = [];
        this._defaultdatetimespan = 1;
        this._defaultbooking = { start: this.todayStart(), end: this.todayEnd(), shifttype: '-1', reservation: '0', personal: '0', availability: -1, levelgroups: [] };
        this._defaulttimeline = { groupby: 0, timelineshow: 1, includereferences: false, exactrendering: true, showemptyrows: true, sumoptions: 0, markedhide: false };
        this._defaultuser = { roles: [], levelgroups: [], availabilitystart: this.todayStart(), availabilityend: this.todayStart() };
        this._defaultlevel = { levelgroups: [] };
        this._defaultmessage = { start: this.todayStart(), end: this.todayEnd() };
        this._defaultlog = { start: this.todayStart(), end: this.todayEnd() };
        this._defaultproperty = {};
        this._defaultreport = { visibility: 1 };
        this._defaultholiday = { start: this.yearStart(), end: this.yearEnd() };
        this._lastbooking = 0;
        this._lastuser = 0;
        this._lastlevel = 0;
        this._lastobject = 0;
        this._lasttimereport = 0;
        this._lastproperty = 0;
        this._searchcriteriarules = '';
        this._pinned = false;
        this._adminmode = false;
        this.defaultGeneral();
        this.defaultBooking();
        this.defaultTimeline();
        this.defaultUser();
        this.defaultLevel();
        this.defaultMessage();
        this.defaultLog();
        this.defaultProperty();
        this.defaultReport();
        this.defaultHoliday();
        this.initWatchdog();
    }
    //Properties
    get general() {
        return this._general;
    }
    get booking() {
        return this._booking;
    }
    get defaultbooking() {
        return this._defaultbooking;
    }
    get timeline() {
        return this._timeline;
    }
    get defaulttimeline() {
        return this._defaulttimeline;
    }
    get user() {
        return this._user;
    }
    get defaultuser() {
        return this._defaultuser;
    }
    get level() {
        return this._level;
    }
    get defaultlevel() {
        return this._defaultlevel;
    }
    get message() {
        return this._message;
    }
    get log() {
        return this._log;
    }
    get property() {
        return this._property;
    }
    get report() {
        return this._report;
    }
    get holiday() {
        return this._holiday;
    }
    get visible() {
        return this._visible;
    }
    get hidden() {
        let hide = false;
        return hide;
    }
    get difflist() {
        return this._difflist.filter(diff => { return this.isVisible(diff.visible) && !this.isHidden(diff.type, diff.key); });
    }
    get lastbooking() { return this._lastbooking; }
    set lastbooking(value) { this._lastbooking = value; }
    get lastuser() { return this._lastuser; }
    set lastuser(value) { this._lastuser = value; }
    get lastlevel() { return this._lastlevel; }
    set lastlevel(val) { this._lastlevel = val; }
    get lastobject() { return this._lastobject; }
    set lastobject(val) { this._lastobject = val; }
    get lasttimereport() { return this._lasttimereport; }
    set lasttimereport(val) { this._lasttimereport = val; }
    get lastproperty() { return this._lastproperty; }
    set lastproperty(val) { this._lastproperty = val; }
    get searchcriteriarules() { return this._searchcriteriarules; }
    set searchcriteriarules(val) { this._searchcriteriarules = val; }
    get pinned() {
        return this._pinned;
    }
    set pinned(val) {
        this._pinned = val;
    }
    get adminmode() {
        return this._adminmode;
    }
    isDevice(size) {
        let res = false;
        if (size) {
            size.forEach((item) => {
                if (!res) {
                    if (item == 'xs') {
                        res = (window.innerWidth < 576);
                    }
                    if (item == 'sm') {
                        res = (window.innerWidth >= 576 && window.innerWidth < 768);
                    }
                    if (item == 'md') {
                        res = (window.innerWidth >= 768 && window.innerWidth < 992);
                    }
                    if (item == 'lg') {
                        res = (window.innerWidth >= 992 && window.innerWidth < 1200);
                    }
                    if (item == 'xl') {
                        res = (window.innerWidth >= 1200);
                    }
                }
            });
        }
        return res;
    }
    //View filter visibility-------------------------------------------
    initView(visible = 0, categories = [], hiddenList = [], filtercategory = 0) {
        this._visible = visible;
        this._categories = categories;
        this._filtercategory = filtercategory;
        let hidden = {};
        hiddenList.forEach((filter) => {
            hidden[filter.type] = hidden[filter.type] || {};
            hidden[filter.type][filter.key] = true;
        });
        this._hidden = hidden;
        if (visible == 0) {
            this.menuService.closeSearch();
        }
        else if (this._pinned) {
            this.menuService.openSearch();
        }
    }
    isVisible(bit) {
        return ((this._visible & bit) == bit);
    }
    isHidden(type, key) {
        return (this._hidden[type] && this._hidden[type][key]);
    }
    hasCategory(category) {
        return this._categories.indexOf(category) > -1;
    }
    get filtercategory() {
        return this._filtercategory;
    }
    //-----------------------------------------------------------------
    //Resets-----------------------------------------------------------
    reset(doRefresh = true) {
        this.defaultBooking();
        this.defaultTimeline();
        this.defaultUser();
        this.defaultLevel();
        this.defaultMessage();
        this.defaultLog();
        this.defaultProperty();
        this.defaultHoliday();
        this.check();
        if (doRefresh) {
            this.refresh();
        }
        if (!this._pinned) {
            this.menuService.closeSearch();
        }
    }
    resetBooking(e) {
        if (e) {
            e.stopPropagation();
        }
        this.defaultBooking();
        this.check();
    }
    resetTimeline(e) {
        if (e) {
            e.stopPropagation();
        }
        this.defaultTimeline();
        this.check();
    }
    resetUser(e) {
        if (e) {
            e.stopPropagation();
        }
        this.defaultUser();
        this.check();
    }
    resetLevel(e) {
        if (e) {
            e.stopPropagation();
        }
        this.defaultLevel();
        this.check();
    }
    resetMessage(e) {
        if (e) {
            e.stopPropagation();
        }
        this.defaultMessage();
        this.check();
    }
    resetLog(e) {
        if (e) {
            e.stopPropagation();
        }
        this.defaultLog();
        this.check();
    }
    resetProperty(e) {
        if (e) {
            e.stopPropagation();
        }
        this.defaultProperty();
        this.check();
    }
    resetReport() {
        this.defaultReport();
    }
    resetItem(item) {
        if (Array.isArray(item.original)) {
            this[item.type][item.key] = [...item.original];
        }
        else {
            this[item.type][item.key] = item.original;
        }
        this.refresh();
    }
    //-----------------------------------------------------------------
    //Methods
    keyEvent(e) {
        if (e.ctrlKey || e.metaKey) {
            if (e.keyCode == 83) {
                //Key S
                this.onSave$.emit();
            }
            else if (e.altKey && e.keyCode == 82) {
                //Key Alt+R
                this._adminmode = !this._adminmode;
            }
        }
    }
    refresh() {
        this.check();
        this.onRefresh$.emit(true);
    }
    viewrefresh(type, data = null) {
        this.onViewRefresh$.emit({ type: type, data: data });
    }
    checkWatchdog() {
        this.check();
    }
    isDiff(type, key) {
        let diff = false;
        for (let i = 0; i < this._difflist.length; i++) {
            if (this._difflist[i].type == type && this._difflist[i].key == key) {
                diff = true;
                break;
            }
        }
        return diff;
    }
    getDiff(type, key) {
        let diff;
        for (let i = 0; i < this._difflist.length; i++) {
            if (this._difflist[i].type == type && this._difflist[i].key == key) {
                diff = this._difflist[i].value;
                break;
            }
        }
        return diff;
    }
    levelList(type) {
        if (type == 1) {
            //User
            return this.user.levels.filter(function (level) { return level.active; }).map(function (level) { return level.Id; }).join();
        }
        else if (type == 2) {
            //Booking
            return this.booking.levels.filter(function (level) { return level.active; }).map(function (level) { return level.Id; }).join();
        }
        else if (type == 3) {
            //Level
            return this.level.levels.filter(function (level) { return level.active; }).map(function (level) { return level.Id; }).join();
        }
    }
    userList() {
        return this.user.users.filter(function (user) { return user.active; }).map(function (user) { return user.Id; }).join();
    }
    start(type) {
        if (type == 'booking') {
            //Booking
            return this.adjustDate(this.booking.start);
        }
        else if (type == 'usermarkeddate') {
            //UserMarkedDate
            return this.adjustDate(this.user.availabilitystart);
        }
        else if (type == 'message') {
            //Message
            return this.adjustDate(this.message.start);
        }
        else if (type == 'log') {
            //Log
            return this.adjustDate(this.log.start);
        }
    }
    end(type) {
        if (type == 'booking') {
            //Booking
            return this.adjustDate(this.booking.end, true);
        }
        else if (type == 'usermarkeddate') {
            //UserMarkedDate
            return this.adjustDate(this.user.availabilityend, true);
        }
        else if (type == 'message') {
            //Message
            return this.adjustDate(this.message.end);
        }
        else if (type == 'log') {
            //Log
            return this.adjustDate(this.log.end);
        }
    }
    /*Base64 to Blob*/
    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        let byteCharacters = atob(b64Data);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    //Functions
    defaultGeneral() {
        this._general = {
            datetimespan: this._defaultdatetimespan
        };
    }
    defaultBooking() {
        this._booking = {
            start: this._defaultbooking.start,
            end: this._defaultbooking.end,
            intervaltype: '0',
            weekdays: [],
            status: [],
            personal: this._defaultbooking.personal,
            shifttype: this._defaultbooking.shifttype,
            reservation: this._defaultbooking.reservation,
            replaceableasnoslot: false,
            checkin: '0',
            bookingtype: [],
            owner: 0,
            availability: this._defaultbooking.availability,
            activitytypes: [],
            levelgroups: [...this._defaultbooking.levelgroups],
            levels: []
        };
    }
    defaultTimeline() {
        this._timeline = {
            groupby: this._defaulttimeline.groupby,
            timelineshow: this._defaulttimeline.timelineshow,
            timelinedisabled: false,
            bookingmode: false,
            multimode: false,
            includereferences: this._defaulttimeline.includereferences,
            showprofile: false,
            exactrendering: this._defaulttimeline.exactrendering,
            showgraph: false,
            showemptyrows: this._defaulttimeline.showemptyrows,
            precision: 0,
            calendarexpand: false,
            sumoptions: this._defaulttimeline.sumoptions,
            markedhide: this._defaulttimeline.markedhide,
            templateIdList: ''
        };
    }
    defaultUser() {
        this._user = {
            search: '',
            users: [],
            roles: [...this._defaultuser.roles],
            activity: '1',
            availability: '0',
            availabilitystart: this._defaultuser.availabilitystart,
            availabilityend: this._defaultuser.availabilityend,
            nobelonging: 0,
            bookingtype: '0',
            levelgroups: [...this._defaultuser.levelgroups],
            levels: []
        };
    }
    defaultLevel() {
        this._level = {
            levelgroups: [...this._defaultlevel.levelgroups],
            levels: []
        };
    }
    defaultMessage() {
        this._message = {
            start: this._defaultmessage.start,
            end: this._defaultmessage.end,
            address: '',
            subject: '',
            body: '',
            messagetype: [],
            messagestatus: []
        };
    }
    defaultLog() {
        this._log = {
            start: this._defaultlog.start,
            end: this._defaultlog.end,
            logobject: [],
            logtype: [],
            showauto: false
        };
    }
    defaultProperty() {
        this._property = {
            properties: [],
            useor: false
        };
    }
    defaultReport() {
        this._report = {
            id: 0,
            main: 0,
            name: '',
            description: '',
            reports: [],
            groupby: 0,
            visibility: this._defaultreport.visibility,
            excludeobject: 0
        };
    }
    defaultHoliday() {
        this._holiday = {
            start: this._defaultholiday.start,
            end: this._defaultholiday.end
        };
    }
    initWatchdog() {
        this._watchdog = [
            //Bookings
            { type: 'booking', key: 'intervaltype', language: 817, visible: 1, locked: 0, icon: '', original: null },
            { type: 'booking', key: 'weekdays', language: 933, visible: 1, locked: 0, icon: '', original: null },
            { type: 'booking', key: 'status', language: 15, visible: 1, locked: 3, icon: '', original: null },
            { type: 'booking', key: 'personal', language: 12, visible: 1, locked: 4, icon: '', original: null },
            { type: 'booking', key: 'shifttype', language: 699, visible: 1, locked: 7, icon: '', original: null },
            { type: 'booking', key: 'reservation', language: 160, visible: 1, locked: 5, icon: '', original: null },
            { type: 'booking', key: 'replaceableasnoslot', language: 545, visible: 1, locked: 0, icon: '', original: null },
            { type: 'booking', key: 'checkin', language: 72, visible: 1, locked: 6, icon: '', original: null },
            { type: 'booking', key: 'bookingtype', language: 475, visible: 1, locked: 0, icon: '', original: null },
            { type: 'booking', key: 'owner', language: 654, visible: 1, locked: 0, icon: '', original: null },
            { type: 'booking', key: 'levelgroups', language: 298, visible: 1, locked: 0, icon: 'fa-calendar', original: null },
            { type: 'booking', key: 'levels', language: 14, visible: 1, locked: 0, icon: 'fa-calendar', original: null },
            //Users
            { type: 'user', key: 'search', language: 59, visible: 2, locked: 21, icon: '', original: null },
            { type: 'user', key: 'users', language: 59, visible: 2, locked: 21, icon: '', original: null },
            { type: 'user', key: 'roles', language: 82, visible: 2, locked: 22, icon: '', original: null },
            { type: 'user', key: 'activity', language: 231, visible: 2, locked: 23, icon: '', original: '0' },
            { type: 'user', key: 'availability', language: 173, visible: 2, locked: 13, icon: '', original: null },
            { type: 'user', key: 'nobelonging', language: 104, visible: 2, locked: 24, icon: '', original: null },
            { type: 'user', key: 'bookingtype', language: 4, visible: 2, locked: 14, icon: '', original: null },
            { type: 'user', key: 'levelgroups', language: 298, visible: 2, locked: 0, icon: 'fa-users', original: null },
            { type: 'user', key: 'levels', language: 14, visible: 2, locked: 0, icon: 'fa-users', original: null },
            //Levels
            { type: 'level', key: 'levelgroups', language: 298, visible: 4, locked: 0, icon: '', original: null },
            { type: 'level', key: 'levels', language: 14, visible: 4, locked: 0, icon: '', original: null },
            //Messages
            { type: 'message', key: 'address', language: 375, visible: 128, locked: 0, icon: '', original: null },
            { type: 'message', key: 'subject', language: 327, visible: 128, locked: 0, icon: '', original: null },
            { type: 'message', key: 'body', language: 328, visible: 128, locked: 0, icon: '', original: null },
            { type: 'message', key: 'messagetype', language: 379, visible: 128, locked: 0, icon: '', original: null },
            { type: 'message', key: 'messagestatus', language: 15, visible: 128, locked: 0, icon: '', original: null },
            //Logs
            { type: 'log', key: 'logobject', language: 405, visible: 256, locked: 0, icon: '', original: null },
            { type: 'log', key: 'logtype', language: 404, visible: 256, locked: 0, icon: '', original: null },
            { type: 'log', key: 'showauto', language: 805, visible: 256, locked: 0, icon: '', original: null },
            //Properties
            { type: 'property', key: 'properties', language: 156, visible: 16, locked: 0, icon: '', original: null },
        ];
        this._watchdog.forEach(item => {
            let value = this[item.type][item.key];
            if (item.original == null) {
                if (Array.isArray(value)) {
                    item.original = [...value]; //Copy Array
                }
                else {
                    item.original = value;
                }
            }
        });
    }
    check() {
        this._difflist = [];
        this._watchdog.forEach(item => {
            let current = this[item.type][item.key];
            if (Array.isArray(current)) {
                //Array
                if (!this.compareArray(item.original, current)) {
                    this._difflist.push({ type: item.type, key: item.key, language: item.language, value: current, visible: item.visible, locked: item.locked, icon: item.icon, original: item.original });
                }
            }
            else {
                //Value
                if (item.original != current) {
                    this._difflist.push({ type: item.type, key: item.key, language: item.language, value: current, visible: item.visible, locked: item.locked, icon: item.icon, original: item.original });
                }
            }
        });
    }
    compareArray(master, slave) {
        if (master.length != slave.length) {
            return false;
        }
        else {
            for (let mIndex in master) {
                var mItem = master[mIndex];
                var found = false;
                for (var sIndex in slave) {
                    let sItem = slave[sIndex];
                    found = true;
                    break;
                }
                if (!found) {
                    return false;
                }
            }
        }
        return true;
    }
    adjustDate(date, isend = false) {
        let newdate = new Date(date);
        if (isend) {
            newdate = new Date(newdate.getTime() + (1000 * 60 * 60 * 24));
        }
        let dateWithTimeZone = this.datePipe.transform(newdate, 'yyyy-MM-ddTHH:mm:ssZ').replace(/([+\-]\d\d)(\d\d)$/, "$1:$2");
        return dateWithTimeZone;
    }
    todayStart() {
        let today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }
    todayEnd() {
        let today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    }
    yearStart() {
        let today = new Date();
        return new Date(today.getFullYear(), 0, 1);
    }
    yearEnd() {
        let today = new Date();
        return new Date(today.getFullYear(), 11, 31);
    }
}
SettingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingService_Factory() { return new SettingService(i0.ɵɵinject(i1.DatePipe), i0.ɵɵinject(i2.MenuService)); }, token: SettingService, providedIn: "root" });
