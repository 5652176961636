<swe-card header="{{languageService.getItem(51)}}" [(open)]="open" (refreshChange)="search()" (openChange)="search()">
  <span swe-rightcommand><i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="goto(0,$event)" *ngIf="userdata.UserMarkedDateAccess>1"></i></span>
  <div class="btn-group">
    <swe-calendar [(model)]="current" (modelChange)="manageDate()" [items]="usermarkeddates" [hasWeeknumbers]="true" [disabled]="loading||userdata.UserMarkedDateAccess<2" (chosenChange)="manageChosen($event)"></swe-calendar>
  </div>
  <div class="row py-2">
    <div class="col-12">
      <div class="btn-group mr-1">
        <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="listchecked=!listchecked;listutility.checkall(listchecked)" *ngIf="usermarkeddates.length>0">
          <span><i class="fas fa-check"></i></span>
        </button>
        <swe-confirm [body]="languageService.getItem(194)" (acceptChange)="deleteAll($event)">
          <button [attr.aria-label]="languageService.getItem(17)" class="btn btn-swe" *ngIf="listutility.ischecked">
            <span><i class="fas fa-fw fa-trash"></i></span>
          </button>
        </swe-confirm>
      </div>
      <swe-list [data]="usermarkeddates" [loading]="loading">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'swe-disabled': userdata.UserMarkedDateAccess<2}" *ngFor="let umd of usermarkeddates" (click)="goto(umd.Id,$event)">
          <div class="col-3 col-md-1" (click)="listutility.toggle(umd, $event)">
            <span class="fa-stack fa-swe-letter-circle-small" *ngIf="umd.checked">
              <i class="fas fa-check-circle fa-stack-2x"></i>
            </span>
            <img class="swe-circle-image" alt="{{umd.Name}}" sweImage imageFolder="userimages" [imageFile]="umd.Image" imageWidth="32" imageHeight="32" *ngIf="!umd.checked&&umd.Image.length>0" />
            <span class="fa-stack fa-swe-letter-circle-small" *ngIf="!umd.checked&&umd.Image.length==0">
              <i class="fas fa-circle fa-stack-2x"></i>
              <span class="fa-stack-1x fa-stack-1x fa-swe-letter">{{umd.Name[0]}}</span>
            </span>
          </div>
          <div class="col-9 col-md-11 d-flex flex-wrap">
            <div class="col-12 col-md-3">{{umd.dateheader}}</div>
            <div class="col-12 col-md-6">{{umd.Comment}}</div>
            <div class="col-12 col-md-3"><div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': umd.Color}"></div>&nbsp;{{umd.Type}}</div>
          </div>
        </a>
      </swe-list>

    </div>
  </div>
</swe-card>
