<ng-container *ngFor="let groupby of groupedby | keyvalue : originalOrder">
  <swe-card [header]="groupby.value.header" [color]="'swe'" [open]="false" [hasRefresh]="false" *ngIf="groupby.value.header.length>0">
    <div [ngClass]="{'d-none': !visibledependency(profile.Property)}" *ngFor="let profile of groupby.value.items">
      <swe-element [objectId]="id" [propertyId]="profile.Property" [access]="profile.Access" [markRequired]="true" [statusLabel]="statusLabel" [label]="profile.Name" [(model)]="profile.Value" (modelChange)="valuedependency($event, profile.Property)" (enterChange)="done()" [container]="profile.Container" [(changed)]="profile.Changed" [invalid]="profile.Invalid" [profileList]="model">
      </swe-element>
    </div>
  </swe-card>
  <ng-container *ngIf="groupby.value.header.length==0">
    <div [ngClass]="{'d-none': !visibledependency(profile.Property)}" *ngFor="let profile of groupby.value.items">
      <swe-element [objectId]="id" [propertyId]="profile.Property" [access]="profile.Access" [markRequired]="true" [statusLabel]="statusLabel" [label]="profile.Name" [(model)]="profile.Value" (modelChange)="valuedependency($event, profile.Property)" (enterChange)="done()" [container]="profile.Container" [(changed)]="profile.Changed" [invalid]="profile.Invalid" [profileList]="model">
      </swe-element>
    </div>
  </ng-container>
</ng-container>

<div class="form-group" *ngIf="showIsNotOptionalText">* = {{languageService.getItem(65)}}</div>
