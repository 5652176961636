<swe-card header="{{languageService.getItem(953)}}" [(open)]="open" [hasRefresh]="false">
  <div class="mb-3">
    <swe-element [type]="'System.List'" [label]="languageService.getItem(715)" [statusLabel]="1" [(model)]="userdata.ContractId" [items]="contracts" [disabled]="userdata.ContractAccess<2" [optional]="true"></swe-element>
    <swe-element [type]="'System.List'" [label]="languageService.getItem(958)" [statusLabel]="1" [(model)]="userdata.BreakpointWeekday" [items]="weekdays" [disabled]="userdata.ContractAccess<2"></swe-element>
    <swe-element [type]="'System.List'" [label]="languageService.getItem(959)" [statusLabel]="1" [(model)]="userdata.BreakpointHour" [items]="hours" [disabled]="userdata.ContractAccess<2"></swe-element>
    <swe-confirm *ngIf="userdata.ContractAccess>1" [isConfirm]="userdata.ContractId!=oldContractId" [body]="languageService.getItem(963)" (acceptChange)="save()">
      <button class="btn btn-primary btn-block">{{languageService.getItem(16)}}</button>
    </swe-confirm>
  </div>
  <swe-usertimebank [id]="id" [(open)]="cards.timebank" *ngIf="userdata.TimeBankId>0&&userdata.TimeBankAccess>0"></swe-usertimebank>
  <swe-userlas [id]="id" [(open)]="cards.las" *ngIf="userdata.LasId>0&&userdata.LasAccess>0"></swe-userlas>
  <swe-useremployments [id]="id" [(open)]="cards.employment" [userdata]="userdata" *ngIf="(userdata.TimeBankId>0||userdata.LasId>0)&&userdata.EmploymentAccess>0"></swe-useremployments>
</swe-card>
