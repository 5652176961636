import * as i0 from "@angular/core";
export class TokenService {
    /*Properties*/
    get authBasic() {
        return "Basic " + btoa("SparrowWeb:SparrowWeb");
    }
    get authBearer() {
        let authtoken = localStorage.getItem('authtoken');
        if (authtoken != null && authtoken.length > 0) {
            return "Bearer " + authtoken;
        }
        return "";
    }
    /*Methods*/
    saveTokens(res) {
        localStorage.setItem('isauth', res.IsAuth);
        localStorage.setItem('isotp', res.IsOtp);
        localStorage.setItem('otptoken', res.OtpToken);
        localStorage.setItem('authtoken', res.AuthToken);
        localStorage.setItem('refreshtoken', res.RefreshToken);
        localStorage.setItem('version', res.Version);
        localStorage.setItem('originaluser', res.OriginalUser);
    }
    getToken(name) {
        let token = localStorage.getItem(name);
        if (!token) {
            token = '';
        }
        return token;
    }
    setToken(name, value) {
        if (value != null && value.length > 0) {
            localStorage.setItem(name, value);
        }
    }
    refreshTokens(res) {
        localStorage.setItem('authtoken', res.AuthToken);
        localStorage.setItem('refreshtoken', res.RefreshToken);
    }
    clearTokens() {
        localStorage.removeItem('isauth');
        localStorage.removeItem('isotp');
        localStorage.removeItem('otptoken');
        localStorage.removeItem('authtoken');
        localStorage.removeItem('refreshtoken');
    }
}
TokenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenService_Factory() { return new TokenService(); }, token: TokenService, providedIn: "root" });
