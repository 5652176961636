<swe-pageheader [header]="languageService.getItem(877)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="load()" *ngIf="permissionService.permissions.Integration>0">
      <swe-list [data]="integrations" [loading]="isloading">
        <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'swe-disabled-block': !integration.Active}" *ngFor="let integration of integrations">
          <div class="col-12 col-md-4">{{integration.Name}}</div>
          <div class="col-12 col-md-6">{{integration.Desc}}</div>
          <div class="col-12 col-md-2">
            <button class="btn btn-swe" *ngIf="!integration.IsRunning" (click)="run(integration)"><i class="fas fa-fw fa-sync"></i>{{languageService.getItem(878)}}</button>
            <span *ngIf="integration.IsRunning">{{languageService.getItem(879)}}</span>
          </div>
        </div>
      </swe-list>
    </swe-card>
  </div>
</div>
