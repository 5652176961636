/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../layout/element.component.ngfactory";
import * as i2 from "../layout/element.component";
import * as i3 from "../_services/authentication.service";
import * as i4 from "../_services/permission.service";
import * as i5 from "../_services/property.service";
import * as i6 from "../_services/datetime.service";
import * as i7 from "../_services/list.service";
import * as i8 from "../_services/language.service";
import * as i9 from "@angular/common";
import * as i10 from "./break.component";
var styles_BreakComponent = [];
var RenderType_BreakComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BreakComponent, data: {} });
export { RenderType_BreakComponent as RenderType_BreakComponent };
function View_BreakComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "d-inline-block align-top mr-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.modelStart = $event) !== false);
        ad = (pd_0 && ad);
    } if (("modelChange" === en)) {
        var pd_1 = (_co.manageBreakStart($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ElementComponent_0, i1.RenderType_ElementComponent)), i0.ɵdid(2, 638976, null, 0, i2.ElementComponent, [i3.AuthenticationService, i4.PermissionService, i5.PropertyService, i6.DateTimeService, i7.ListService, i8.LanguageService], { label: [0, "label"], type: [1, "type"], model: [2, "model"], changed: [3, "changed"], disabled: [4, "disabled"], container: [5, "container"] }, { modelChange: "modelChange" }), i0.ɵpod(3, { hideSeconds: 0, hideReset: 1, markChanges: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(786); var currVal_1 = _co.type; var currVal_2 = _co.modelStart; var currVal_3 = _co.startChanged; var currVal_4 = _co.disabledStart; var currVal_5 = _ck(_v, 3, 0, true, true, _co.markChanges); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_BreakComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-swe"]], [[1, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetBreak() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "i", [["class", "fas fa-trash swe-click"]], null, null, null, null, null)), i0.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "swe-grayed-out": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fas fa-trash swe-click"; var currVal_2 = _ck(_v, 4, 0, (_co.model == (0 - 1))); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(915); _ck(_v, 0, 0, currVal_0); }); }
function View_BreakComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "align-middle small ml-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(930); _ck(_v, 1, 0, currVal_0); }); }
export function View_BreakComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "d-inline-flex"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.change() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakComponent_1)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "d-inline-block w-25"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.model = $event) !== false);
        ad = (pd_0 && ad);
    } if (("modelChange" === en)) {
        var pd_1 = (_co.manageBreak($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ElementComponent_0, i1.RenderType_ElementComponent)), i0.ɵdid(5, 638976, null, 0, i2.ElementComponent, [i3.AuthenticationService, i4.PermissionService, i5.PropertyService, i6.DateTimeService, i7.ListService, i8.LanguageService], { label: [0, "label"], type: [1, "type"], model: [2, "model"], changed: [3, "changed"], disabled: [4, "disabled"], container: [5, "container"] }, { modelChange: "modelChange" }), i0.ɵpod(6, { markChanges: 0 }), (_l()(), i0.ɵeld(7, 0, null, null, 6, "div", [["class", "d-inline-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-swe"], ["style", "color:black;cursor:unset;"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakComponent_2)), i0.ɵdid(11, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakComponent_3)), i0.ɵdid(13, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.permissionService.permissions.BreakStart; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.languageService.getItem(786); var currVal_2 = "System.Int32"; var currVal_3 = _co.model; var currVal_4 = _co.changed; var currVal_5 = _co.disabled; var currVal_6 = _ck(_v, 6, 0, _co.markChanges); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = (_co.reset && !_co.disabled); _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.hasBreakInfo; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_7 = true; _ck(_v, 8, 0, currVal_7); var currVal_8 = _co.languageService.getItem(786); _ck(_v, 9, 0, currVal_8); }); }
export function View_BreakComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-break", [], null, null, null, View_BreakComponent_0, RenderType_BreakComponent)), i0.ɵdid(1, 114688, null, 0, i10.BreakComponent, [i4.PermissionService, i8.LanguageService, i6.DateTimeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BreakComponentNgFactory = i0.ɵccf("swe-break", i10.BreakComponent, View_BreakComponent_Host_0, { model: "model", disabled: "disabled", modelStart: "modelStart", disabledStart: "disabledStart", minStart: "minStart", maxStart: "maxStart", reset: "reset", hasBreakInfo: "hasBreakInfo", markChanges: "markChanges", changed: "changed", startChanged: "startChanged" }, { modelChange: "modelChange", modelStartChange: "modelStartChange" }, []);
export { BreakComponentNgFactory as BreakComponentNgFactory };
