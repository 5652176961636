/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../layout/card.component.ngfactory";
import * as i3 from "../../layout/card.component";
import * as i4 from "../../_services/language.service";
import * as i5 from "../../element/adminrule.component.ngfactory";
import * as i6 from "../../element/adminrule.component";
import * as i7 from "../../_services/setting.service";
import * as i8 from "../../_services/permission.service";
import * as i9 from "@angular/router";
import * as i10 from "./homecheckin.component";
import * as i11 from "../../_services/data.service";
import * as i12 from "../../_services/alert.service";
import * as i13 from "../../_services/datetime.service";
var styles_HomeCheckInComponent = [];
var RenderType_HomeCheckInComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HomeCheckInComponent, data: {} });
export { RenderType_HomeCheckInComponent as RenderType_HomeCheckInComponent };
function View_HomeCheckInComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-fw fa-swe fa-sign-in-alt fa-pull-right"]], [[1, "title", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(455); _ck(_v, 0, 0, currVal_0); }); }
function View_HomeCheckInComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nextcheckin.Header; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeCheckInComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nextcheckin.Desc; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeCheckInComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeCheckInComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeCheckInComponent_4)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.nextcheckin.Header.length > 0); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.nextcheckin.Desc.length > 0); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(938); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.nextcheckin.datetimeheader; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.nextcheckin.Level; _ck(_v, 7, 0, currVal_2); }); }
function View_HomeCheckInComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nextcheckout.Header; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeCheckInComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nextcheckout.Desc; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeCheckInComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeCheckInComponent_6)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeCheckInComponent_7)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.nextcheckout.Header.length > 0); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.nextcheckout.Desc.length > 0); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(939); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.nextcheckout.datetimeheader; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.nextcheckout.Level; _ck(_v, 7, 0, currVal_2); }); }
export function View_HomeCheckInComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "swe-card", [], null, null, null, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i3.CardComponent, [i4.LanguageService], { header: [0, "header"], open: [1, "open"], hasCollapse: [2, "hasCollapse"], hasRefresh: [3, "hasRefresh"] }, null), (_l()(), i0.ɵeld(2, 0, null, 2, 2, "span", [["swe-rightcommand", ""]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeCheckInComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, 3, 2, "swe-adminrule", [], null, null, null, i5.View_AdminRuleComponent_0, i5.RenderType_AdminRuleComponent)), i0.ɵdid(6, 114688, null, 0, i6.AdminRuleComponent, [i4.LanguageService, i7.SettingService, i8.PermissionService, i9.Router], { rulelist: [0, "rulelist"] }, null), i0.ɵpad(7, 1), (_l()(), i0.ɵeld(8, 0, null, 3, 5, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeCheckInComponent_2)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["class", "fas fa-fw fa-sign-in-alt"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["\u00A0", ""])), (_l()(), i0.ɵeld(14, 0, null, 3, 5, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HomeCheckInComponent_5)), i0.ɵdid(16, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "i", [["class", "fas fa-fw fa-sign-out-alt"]], null, null, null, null, null)), (_l()(), i0.ɵted(19, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.languageService.getItem(72), ""); var currVal_1 = true; var currVal_2 = false; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.permissionService.user.IsCheckedIn; _ck(_v, 4, 0, currVal_4); var currVal_5 = _ck(_v, 7, 0, "SweCheckInLevels"); _ck(_v, 6, 0, currVal_5); var currVal_6 = (_co.nextcheckin.Id > 0); _ck(_v, 10, 0, currVal_6); var currVal_8 = (_co.nextcheckout.Id > 0); _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.languageService.getItem(73); _ck(_v, 13, 0, currVal_7); var currVal_9 = _co.languageService.getItem(74); _ck(_v, 19, 0, currVal_9); }); }
export function View_HomeCheckInComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-homecheckin", [], null, null, null, View_HomeCheckInComponent_0, RenderType_HomeCheckInComponent)), i0.ɵdid(1, 114688, null, 0, i10.HomeCheckInComponent, [i8.PermissionService, i4.LanguageService, i11.DataService, i12.AlertService, i13.DateTimeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeCheckInComponentNgFactory = i0.ɵccf("swe-homecheckin", i10.HomeCheckInComponent, View_HomeCheckInComponent_Host_0, {}, {}, []);
export { HomeCheckInComponentNgFactory as HomeCheckInComponentNgFactory };
