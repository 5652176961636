import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class AlertService {
    constructor(datePipe) {
        this.datePipe = datePipe;
        this._alerts = [];
        this._history = [];
        this._bigtext = false;
        this._all = false;
        //Events
        this.onNewHistory$ = new EventEmitter();
    }
    set bigtext(val) { this._bigtext = val; }
    set all(val) { this._all = val; }
    Add(alert) {
        if (this._bigtext) {
            alert["bigtext"] = true;
        }
        if (alert.message && alert.message.length > 0) {
            if (this._alerts.length > 0 && this._alerts[this._alerts.length - 1].message == alert.message && !this._all) {
                //Don't alert twice in a row
                console.log(alert.message);
            }
            else {
                alert['time'] = this.datePipe.transform(new Date(), 'HH:mm'); //Can't use dateTimeService (forever loop)
                this._alerts.push(alert);
            }
            let timeout = setTimeout(() => {
                let a = this._alerts.shift();
                if (!a['hide']) {
                    this.manageHistory(a);
                }
            }, alert.type == 'danger' ? 10000 : 5000);
        }
    }
    Pop(alert) {
        var index = -1;
        //to find alert from queue of active alerts
        for (var i = 0; i < this._alerts.length; i++) {
            if (this._alerts[i] == alert) {
                index = i;
                alert['hide'] = true;
                this.manageHistory(alert);
                break;
            }
        }
        //if (index != -1) {
        //  this._alerts.splice(index, 1);
        //}
        return this._alerts;
    }
    PopHistory(alert) {
        var index = -1;
        //to find alert from queue of active alerts
        for (var i = 0; i < this._history.length; i++) {
            if (this._history[i] == alert) {
                index = i;
                break;
            }
        }
        if (index != -1) {
            this._history.splice(index, 1);
        }
        return this._history;
    }
    get Alerts() {
        return this._alerts;
    }
    get History() {
        return this._history;
    }
    ReActivate() {
        let tmp = this._alerts;
        this._alerts = [];
        this._alerts = tmp;
    }
    //Functions
    manageHistory(alert) {
        if (this._history.length > 10) {
            //Only save 10 alert in history
            this._history.pop();
        }
        this._history.unshift(alert);
        this.onNewHistory$.emit(true);
    }
}
AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(i0.ɵɵinject(i1.DatePipe)); }, token: AlertService, providedIn: "root" });
