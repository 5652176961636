import { Component, OnChanges, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { DateTimeService } from '../../_services/datetime.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { AlertService } from '../../_services/alert.service';
import { GeneralService } from '../../_services/general.service';
import { CalendarItem } from '../../element/calendar.component';
import { ListUtility } from '../../_utilities/list.utility';



@Component({
  selector: 'swe-useravailability',
  templateUrl: './useravailability.component.html'
})
export class UserAvailabilityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;
  @Input() userdata: any;

  private unsubsribe$ = new Subject<void>();
  private _current: Date = new Date();//this.settingService.booking.start;
  private _start: Date = this.dateTimeService.firstDayInMonth(this._current);
  private _end: Date = this.dateTimeService.addDays(this.dateTimeService.lastDayInMonth(this._current), 1);
  private _usermarkeddates = [];
  private _loading: boolean = false;
  //private _checkcounter: number = 0;
  //private _ischecked: boolean = false;
  private _listutility: ListUtility = new ListUtility();

  constructor(public languageService: LanguageService, private dataService: DataService, private dateTimeService: DateTimeService, private settingService: SettingService, public permissionService: PermissionService, private alertService: AlertService, private generalService: GeneralService, private router: Router) {
    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    this._listutility.dataaccessname = 'MarkAccess';
    this._listutility.dataaccess = 3;
  }

  ngOnChanges() {
    if (this.open) {
      this.search();
    }
  }

  /*Properties*/
  public get usermarkeddates() {
    return this._usermarkeddates;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get current(): Date {
    return this._current;
  }
  public set current(val: Date) {
    this._current = val;
  }
  //public get ischecked() {
  //  return this._ischecked;
  //}
  public get start() {
    return this._start;
  }
  public get end() {
    return this._end;
  }
  public get listutility() {
    return this._listutility;
  }
  

  /*Methods*/
  public search() {

    if (!this.open) { return; }

    if (!this.dateTimeService.validation(this._start, this._end)) { return; }

    this._usermarkeddates = [];

    let filter = {
      UserId: this.id,
      Start: this.dateTimeService.formatWithTimeZone(this.dateTimeService.firstDayInWeek(this._start)),
      End: this.dateTimeService.formatWithTimeZone(this.dateTimeService.addDays(this.dateTimeService.lastDayInWeek(this._end), 1))
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/usermarkeddates/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          let items = [];
          res.UserMarkedDates.forEach(usermarkeddate => {

            let start = new Date(usermarkeddate.Start);
            let end = new Date(usermarkeddate.End);

            usermarkeddate.dateheader = this.dateTimeService.header(start, end, false);

            items.push(usermarkeddate);
          });

          this._usermarkeddates = items;
          this._listutility.rows = this._usermarkeddates;

          this._loading = false;
        }
      });
  }
  public manageDate() {
    this._start = this.dateTimeService.firstDayInMonth(this._current);
    this._end = this.dateTimeService.addDays(this.dateTimeService.lastDayInMonth(this._current), 1);

    this.search();
  }
  public manageChosen(item) {

    this._loading = true;

    let dto = {
      Id: item.id,
      Date: this.dateTimeService.formatWithTimeZone(item.date),
      Type: item.type,
      UserId: this.id
    };

    this.dataService.tokenRequest('/api/v1/usermarkeddates/toggle', 'POST', dto, 'text')
      .subscribe(res => {

        this._current = item.date;
        this.manageDate();

        this._loading = false;
      }, (error) => {
          this._loading = false;
      });
  }
  public goto(id: number, e) {

    if (this.userdata.UserMarkedDateAccess < 2) { return; }

    e.stopPropagation();

    let url = '/usermarkeddates/' + id;
    url += '/user/' + this.id;
    
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public deleteAll(e) {
    let usermarkeddateList = [];

    this._usermarkeddates.forEach((usermarkeddate) => {

      if (usermarkeddate.checked && usermarkeddate.MarkAccess > 2) {

        usermarkeddateList.push(usermarkeddate.Id);
      }

    });

    let filter = {
      IdList: usermarkeddateList
    };

    this.dataService.tokenRequest('/api/v1/usermarkeddates/multi/delete', 'POST', filter, 'text')
      .subscribe((res) => {
        this.search();
        this.alertService.Add({ type: 'success', message: res });
        this._listutility.ischecked = false;
      });
  }


  //Check Users
  //public toggle(row, e) {

  //  e.stopPropagation();

  //  if (row.MarkAccess < 3) {
  //    return;
  //  }

  //  row.checked = !row.checked;

  //  this._checkcounter = this._checkcounter + (row.checked ? 1 : -1);

  //  this._ischecked = this._checkcounter > 0;
  //}
  //public checkall(option) {
  //  this._usermarkeddates.forEach((umd) => {
  //    if (umd.Access > 1) {
  //      umd.checked = option;
  //    }
  //  });

  //  this._checkcounter = option ? this._usermarkeddates.length : 0;
  //  this._ischecked = option;
  //}
  
            

  //Functions
  

}
