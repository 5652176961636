import { Component, Input, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { GeneralService } from '../../_services/general.service';
import { SettingService } from '../../_services/setting.service';
import { AlertService } from '../../_services/alert.service';
import { DataService } from '../../_services/data.service';

@Component({
  selector: 'swe-usercontract',
  templateUrl: './usercontract.component.html'
})
export class UserContractComponent implements OnInit {
  @Input() id: number;
  @Input() open: boolean;
  @Input() userdata: any;

  private _contracts: any[] = [];
  private _weekdays: any[] = [];
  private _hours: any[] = [];
  private _oldContractId: number = 0;

  constructor(
    public languageService: LanguageService,
    private settingService: SettingService,
    private dataService: DataService,
    private alertService: AlertService,
    private generalService: GeneralService
  ) {

  }

  ngOnInit() {
    this.init();
  }



  /*Properties*/
  public get cards() {
    return {
      timebank: false,
      las: false,
      employment: false
    };
  }
  public get contracts() {
    return this._contracts;
  }
  public get weekdays() {
    return this._weekdays;
  }
  public get hours() {
    return this._hours;
  }
  public get oldContractId() {
    return this._oldContractId;
  }



  //Methods
  public save() {

    let dto = {
      Id: this.userdata.ContractId ? this.userdata.ContractId : 0,
      BreakpointWeekday: this.userdata.BreakpointWeekday,
      BreakpointHour: this.userdata.BreakpointHour
    };

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/contracts', 'PUT', dto, 'text')
      .subscribe((res) => {

        this.settingService.viewrefresh('usercontract_save');

        this.alertService.Add({ type: 'success', message: res });
      });

  }


  //Functions
  private init() {

    this._oldContractId = this.userdata.ContractId;

    this.generalService.contracts.forEach((contract) => {

      this._contracts.push({ Id: contract.Key, Name: contract.Value });

    });

    this._weekdays = [
      { Id: -1, Name: "-" },
      { Id: 0, Name: this.languageService.getItem(209) },
      { Id: 1, Name: this.languageService.getItem(210) },
      { Id: 2, Name: this.languageService.getItem(211) },
      { Id: 3, Name: this.languageService.getItem(212) },
      { Id: 4, Name: this.languageService.getItem(213) },
      { Id: 5, Name: this.languageService.getItem(214) },
      { Id: 6, Name: this.languageService.getItem(215) }
    ];

    this._hours = [
      { Id: -1, Name: "--:--" },
    ];
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this._hours.push({ Id: i, Name: "0" + i + ":00" });
      }
      else {
        this._hours.push({ Id: i, Name: i + ":00" });
      }
    }
  }
}
