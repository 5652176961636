/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./break.component.ngfactory";
import * as i3 from "./break.component";
import * as i4 from "../_services/permission.service";
import * as i5 from "../_services/language.service";
import * as i6 from "../_services/datetime.service";
import * as i7 from "./breaklist.component";
var styles_BreakListComponent = [];
var RenderType_BreakListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BreakListComponent, data: {} });
export { RenderType_BreakListComponent as RenderType_BreakListComponent };
function View_BreakListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "swe-no-link"], ["href", "javascript:void(0);"]], [[1, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-minus-circle"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(17); _ck(_v, 0, 0, currVal_0); }); }
function View_BreakListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-fw"]], null, null, null, null, null))], null, null); }
function View_BreakListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "d-inline-block"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakListComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakListComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.reset || (_co.model.length > 1)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.model.length < 2); _ck(_v, 4, 0, currVal_1); }, null); }
function View_BreakListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "swe-break", [], null, [[null, "modelChange"], [null, "modelStartChange"]], function (_v, en, $event) { var ad = true; if (("modelChange" === en)) {
        var pd_0 = ((_v.context.$implicit.Break = $event) !== false);
        ad = (pd_0 && ad);
    } if (("modelStartChange" === en)) {
        var pd_1 = ((_v.context.$implicit.BreakStart = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_BreakComponent_0, i2.RenderType_BreakComponent)), i0.ɵdid(2, 114688, null, 0, i3.BreakComponent, [i4.PermissionService, i5.LanguageService, i6.DateTimeService], { model: [0, "model"], disabled: [1, "disabled"], modelStart: [2, "modelStart"], disabledStart: [3, "disabledStart"], minStart: [4, "minStart"], maxStart: [5, "maxStart"], markChanges: [6, "markChanges"] }, { modelChange: "modelChange", modelStartChange: "modelStartChange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakListComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.Break; var currVal_1 = (_co.disabled || _co.isRedo); var currVal_2 = _v.context.$implicit.BreakStart; var currVal_3 = (_co.disabledStart || _co.isRedo); var currVal_4 = _co.minStart; var currVal_5 = _co.maxStart; var currVal_6 = _co.markChanges; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = !_co.disabled; _ck(_v, 4, 0, currVal_7); }, null); }
function View_BreakListComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "align-middle small mr-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(982); _ck(_v, 1, 0, currVal_0); }); }
function View_BreakListComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "swe-no-link"], ["href", "javascript:void(0);"]], [[1, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "i", [["class", "fas fa-fw fa-redo"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "fa-spin fa-spin-reverse": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fas fa-fw fa-redo"; var currVal_2 = _ck(_v, 4, 0, _co.isRedo); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(981); _ck(_v, 0, 0, currVal_0); }); }
function View_BreakListComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "swe-no-link ml-2"], ["href", "javascript:void(0);"]], [[1, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-plus-circle"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(100); _ck(_v, 0, 0, currVal_0); }); }
function View_BreakListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "d-inline-block"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakListComponent_6)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakListComponent_7)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakListComponent_8)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.reset && (_co.model.length == 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.reset && (_co.model.length == 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.model.length == 0) || !_co.onlyOne); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_BreakListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "d-inline-block"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakListComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreakListComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.disabled; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BreakListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-breaklist", [], null, null, null, View_BreakListComponent_0, RenderType_BreakListComponent)), i0.ɵdid(1, 114688, null, 0, i7.BreakListComponent, [i4.PermissionService, i5.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BreakListComponentNgFactory = i0.ɵccf("swe-breaklist", i7.BreakListComponent, View_BreakListComponent_Host_0, { markChanges: "markChanges", model: "model", modelStart: "modelStart", disabled: "disabled", disabledStart: "disabledStart", minStart: "minStart", maxStart: "maxStart", reset: "reset", hasBreakInfo: "hasBreakInfo", onlyOne: "onlyOne" }, { modelChange: "modelChange" }, []);
export { BreakListComponentNgFactory as BreakListComponentNgFactory };
