import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LanguageService } from '../_services/language.service';

@Component({
  selector: 'swe-card',
  templateUrl: 'card.component.html',
})
export class CardComponent implements OnInit {
  @Input() header: string = '';
  @Input() color: string = '';
  @Input() open: boolean = true;
  @Output() openChange = new EventEmitter<any>();
  @Input() hasCollapse: boolean = true;
  @Input() hasRefresh: boolean = true;
  @Output() refreshChange = new EventEmitter<any>();
  @Input() tabs: Tab[] = [];
  @Input() active: number = 0;
  @Output() activeChange = new EventEmitter<any>();
  @Input() stickyTop: number = -1;
  @Input() focus: boolean = false;
  @Input() fitcontent: boolean = false;
  @Input() bigheader: boolean = false;
  //Save
  @Input() hasSave: boolean = false;
  @Output() saveChange = new EventEmitter<any>();

  @ViewChild('sweFocusElement', { static: false }) focusElement: ElementRef;

  private _refreshing: boolean = false;
  private _bordercolor: string = 'border-light';
  private _titlecolor: string = '';
  private _stickyTopCss: string = '';

  constructor(public languageService: LanguageService) {

  }

  ngOnInit() {
    if (this.color && this.color.length > 0) {
      this._bordercolor = 'border-' + this.color;
      this._titlecolor = 'text-' + this.color;
    }

    if (this.stickyTop && this.stickyTop > -1) {
      this._stickyTopCss = "sticky-top swe-sticky-top";
      if (this.stickyTop > 0) {
        this._stickyTopCss += "-" + this.stickyTop;
      }
    }

    if (this.focus) {
      setTimeout(() => {
        if (this.focusElement) {
          let element = this.focusElement.nativeElement;
          element.focus();
        }
      }, 0); //Create a macrotask that will run in the next VM turn
    }
  }


  /*Properties*/
  public get isOpen(): boolean {
    return this.open;
  }
  public get isRefreshing(): boolean {
    return this._refreshing;
  }
  public get bordercolor(): string {
    return this._bordercolor;
  }
  public get titlecolor(): string {
    return this._titlecolor;
  }
  public get stickyTopCss(): string {
    return this._stickyTopCss;
  }


  /*Methods*/
  public toggle() {
    this.open = !this.open;
    this.openChange.emit(this.open);
  }
  public refresh(e) {

    e.stopPropagation();

    this._refreshing = true;
    this.refreshChange.emit(true);

    setTimeout(() => {
      this._refreshing = false;
    }, 1000);
  }
  public save(e) {

    this.saveChange.emit(e);
    e.stopPropagation();
  }
  public change(index: number, e) {

    e.stopPropagation();

    if (this.active != index) {
      this.active = index;
      this.activeChange.emit(this.active);
    }
  }
}

export class Tab {
  public id: number;
  public name: number;
}
