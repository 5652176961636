import { Component, Input, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { DateTimeService } from '../../_services/datetime.service';

@Component({
  selector: 'swe-userlas',
  templateUrl: './userlas.component.html'
})
export class UserLasComponent implements OnInit {
  @Input() id: number;
  @Input() open: boolean;
  @Input() userdata: any;

  private _las: any = {};
  private _date: Date;

  constructor(
    public languageService: LanguageService,
    private dataService: DataService,
    private alertService: AlertService,
    private dateTimeService: DateTimeService
  ) {

  }

  ngOnInit() {
    let d = new Date();
    this._date = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
  }



  /*Properties*/
  public get las() {
    return this._las;
  }
  public get date() {
    return this._date;
  }
  public set date(val) {
    this._date = val;
  }



  //Methods
  public search() {

    this._las = {};

    let dto: any = {
      Date: this.dateTimeService.formatWithTimeZone(this._date)
    };

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/las', 'POST', dto)
      .subscribe(res => {
        this._las = res;  
      });
  }


  //Functions
  
}
