/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../layout/pageheader.component.ngfactory";
import * as i3 from "../../layout/pageheader.component";
import * as i4 from "@angular/router";
import * as i5 from "../../layout/datepager.component.ngfactory";
import * as i6 from "../../layout/datepager.component";
import * as i7 from "../../_services/datetime.service";
import * as i8 from "../../_services/language.service";
import * as i9 from "../../_services/setting.service";
import * as i10 from "../../layout/card.component.ngfactory";
import * as i11 from "../../layout/card.component";
import * as i12 from "../../layout/list.component.ngfactory";
import * as i13 from "../../layout/list.component";
import * as i14 from "./holidays.component";
import * as i15 from "../../_services/permission.service";
import * as i16 from "../../_services/data.service";
var styles_HolidaysComponent = [];
var RenderType_HolidaysComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HolidaysComponent, data: {} });
export { RenderType_HolidaysComponent as RenderType_HolidaysComponent };
function View_HolidaysComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "a", [["class", "list-group-item list-group-item-action d-flex swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((((_co.permissionService.permissions.Holiday > 1) && (_v.context.$implicit.Id > 0)) && _co.goto(_v.context.$implicit.Id)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "list-group-item-danger": 0, "list-group-item-warning": 1, "swe-disabled": 2 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "col-5 col-md-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "col-7 col-md-10 d-flex flex-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "col-12 col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "list-group-item list-group-item-action d-flex swe-click"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.IsRed, (!_v.context.$implicit.IsRed && (_v.context.$implicit.Id == 0)), (_v.context.$implicit.Id == 0)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.Date; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.Name; _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.Desc; _ck(_v, 10, 0, currVal_4); }); }
export function View_HolidaysComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-pageheader", [], null, null, null, i2.View_PageHeaderComponent_0, i2.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i3.PageHeaderComponent, [i1.Location, i4.ActivatedRoute, i4.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "swe-datepager", [], null, [[null, "dateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dateChange" === en)) {
        var pd_0 = (_co.load() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DatePagerComponent_0, i5.RenderType_DatePagerComponent)), i0.ɵdid(3, 770048, null, 0, i6.DatePagerComponent, [i7.DateTimeService, i8.LanguageService, i9.SettingService], { hideTime: [0, "hideTime"], type: [1, "type"], disabled: [2, "disabled"] }, { dateChange: "dateChange" }), (_l()(), i0.ɵeld(4, 0, null, null, 9, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 7, "swe-card", [], null, null, null, i10.View_CardComponent_0, i10.RenderType_CardComponent)), i0.ɵdid(7, 114688, null, 0, i11.CardComponent, [i8.LanguageService], { open: [0, "open"], hasCollapse: [1, "hasCollapse"], hasRefresh: [2, "hasRefresh"] }, null), (_l()(), i0.ɵeld(8, 0, null, 2, 1, "span", [["swe-rightcommand", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "fas fa-fw fa-plus fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goto(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, 3, 3, "swe-list", [], null, null, null, i12.View_ListComponent_0, i12.RenderType_ListComponent)), i0.ɵdid(11, 638976, null, 0, i13.ListComponent, [i8.LanguageService], { data: [0, "data"], loading: [1, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_HolidaysComponent_1)), i0.ɵdid(13, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(712); _ck(_v, 1, 0, currVal_0); var currVal_1 = true; var currVal_2 = "holiday"; var currVal_3 = _co.loading; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = true; var currVal_5 = false; var currVal_6 = false; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.holidays; var currVal_8 = _co.loading; _ck(_v, 11, 0, currVal_7, currVal_8); var currVal_9 = _co.holidays; _ck(_v, 13, 0, currVal_9); }, null); }
export function View_HolidaysComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-holidays", [], null, null, null, View_HolidaysComponent_0, RenderType_HolidaysComponent)), i0.ɵdid(1, 114688, null, 0, i14.HolidaysComponent, [i8.LanguageService, i15.PermissionService, i16.DataService, i9.SettingService, i7.DateTimeService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HolidaysComponentNgFactory = i0.ɵccf("app-holidays", i14.HolidaysComponent, View_HolidaysComponent_Host_0, {}, {}, []);
export { HolidaysComponentNgFactory as HolidaysComponentNgFactory };
