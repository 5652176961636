/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../layout/element.component.ngfactory";
import * as i3 from "../layout/element.component";
import * as i4 from "../_services/authentication.service";
import * as i5 from "../_services/permission.service";
import * as i6 from "../_services/property.service";
import * as i7 from "../_services/datetime.service";
import * as i8 from "../_services/list.service";
import * as i9 from "../_services/language.service";
import * as i10 from "./properties.component";
import * as i11 from "../_services/setting.service";
import * as i12 from "../_services/menu.service";
var styles_PropertiesComponent = [];
var RenderType_PropertiesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PropertiesComponent, data: {} });
export { RenderType_PropertiesComponent as RenderType_PropertiesComponent };
function View_PropertiesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "label", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLabelClass(); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.label; _ck(_v, 3, 0, currVal_1); }); }
function View_PropertiesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; if (("modelChange" === en)) {
        var pd_0 = ((_v.parent.parent.context.$implicit.Option = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ElementComponent_0, i2.RenderType_ElementComponent)), i0.ɵdid(1, 638976, null, 0, i3.ElementComponent, [i4.AuthenticationService, i5.PermissionService, i6.PropertyService, i7.DateTimeService, i8.ListService, i9.LanguageService], { label: [0, "label"], type: [1, "type"], items: [2, "items"], model: [3, "model"], bottomMargin: [4, "bottomMargin"] }, { modelChange: "modelChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(478); var currVal_1 = "System.List"; var currVal_2 = _v.parent.parent.context.$implicit.Options; var currVal_3 = _v.parent.parent.context.$implicit.Option; var currVal_4 = 0; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_PropertiesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-trash fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.Id = $event) !== false);
        ad = (pd_0 && ad);
    } if (("modelChange" === en)) {
        var pd_1 = (_co.propertyChosen($event, _v.parent.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ElementComponent_0, i2.RenderType_ElementComponent)), i0.ɵdid(3, 638976, null, 0, i3.ElementComponent, [i4.AuthenticationService, i5.PermissionService, i6.PropertyService, i7.DateTimeService, i8.ListService, i9.LanguageService], { label: [0, "label"], type: [1, "type"], items: [2, "items"], model: [3, "model"], bottomMargin: [4, "bottomMargin"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PropertiesComponent_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"], [null, "enterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.Val = $event) !== false);
        ad = (pd_0 && ad);
    } if (("enterChange" === en)) {
        var pd_1 = (_co.refresh() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ElementComponent_0, i2.RenderType_ElementComponent)), i0.ɵdid(7, 638976, null, 0, i3.ElementComponent, [i4.AuthenticationService, i5.PermissionService, i6.PropertyService, i7.DateTimeService, i8.ListService, i9.LanguageService], { label: [0, "label"], type: [1, "type"], items: [2, "items"], model: [3, "model"] }, { modelChange: "modelChange", enterChange: "enterChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(478); var currVal_1 = "System.GroupByList"; var currVal_2 = _co.properties; var currVal_3 = _v.parent.context.$implicit.Id; var currVal_4 = 0; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.showOptions; _ck(_v, 5, 0, currVal_5); var currVal_6 = _co.languageService.getItem(478); var currVal_7 = _v.parent.context.$implicit.Type; var currVal_8 = _co.manageReload(_v.parent.context.$implicit); var currVal_9 = _v.parent.context.$implicit.Val; _ck(_v, 7, 0, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_PropertiesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PropertiesComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.Disabled; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PropertiesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "form-group"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "row": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PropertiesComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(8, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PropertiesComponent_2)), i0.ɵdid(10, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "span", [["class", "swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["class", "fas fa-fw fa-plus-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "form-group"; var currVal_1 = _ck(_v, 3, 0, (_co.cols > 0)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.statusLabel > 0); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.getInputClass(); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.items; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.languageService.getItem(100); _ck(_v, 13, 0, currVal_5); }); }
export function View_PropertiesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-properties", [], null, null, null, View_PropertiesComponent_0, RenderType_PropertiesComponent)), i0.ɵdid(1, 114688, null, 0, i10.PropertiesComponent, [i9.LanguageService, i6.PropertyService, i5.PermissionService, i7.DateTimeService, i11.SettingService, i12.MenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PropertiesComponentNgFactory = i0.ɵccf("swe-properties", i10.PropertiesComponent, View_PropertiesComponent_Host_0, { label: "label", statusLabel: "statusLabel", items: "items", properties: "properties", showOptions: "showOptions" }, { itemsChange: "itemsChange" }, []);
export { PropertiesComponentNgFactory as PropertiesComponentNgFactory };
