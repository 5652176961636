<swe-pageheader [header]="languageService.getItem(742)"></swe-pageheader>
<div class="row  py-2" *ngIf="permissionService.permissions.Help>0">
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(743)" [open]="true" [hasCollapse]="true" [hasRefresh]="false" *ngIf="permissionService.permissions.Help>0">
      <!--Shift Colors-->
      <div class="mb-4">
          <label>{{languageService.getItem(755)}}</label>
          <div class="form-group"><i class="fas fa-border alert-danger border-danger">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(752)}}</span></div>
          <div class="form-group"><i class="fas fa-border alert-warning border-warning">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(753)}}</span></div>
          <div class="form-group"><i class="fas fa-border alert-success border-success">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(754)}}</span></div>
      </div>
      <!--Shift Status-->
      <div class="mb-4">
          <label>{{languageService.getItem(756)}}</label>
          <div class="form-group"><i class="far fa-border fa-square"></i><span>&nbsp;=&nbsp;{{languageService.getItem(23)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-share-square"></i><span>&nbsp;=&nbsp;{{languageService.getItem(24)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-key"></i><span>&nbsp;=&nbsp;{{languageService.getItem(25)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-clock"></i><span>&nbsp;=&nbsp;{{languageService.getItem(26)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-check"></i><span>&nbsp;=&nbsp;{{languageService.getItem(27)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-lock"></i><span>&nbsp;=&nbsp;{{languageService.getItem(28)}}</span></div>
      </div>
      <!--Other Shift Icons-->
      <div class="mb-4">
          <label>{{languageService.getItem(757)}}</label>
          <div class="form-group"><i class="fas fa-border fa-retweet"></i><span>&nbsp;=&nbsp;{{languageService.getItem(423)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-street-view"></i><span>&nbsp;=&nbsp;{{languageService.getItem(279)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-ban"></i><span>&nbsp;=&nbsp;{{languageService.getItem(280)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-eye"></i><span>&nbsp;=&nbsp;{{languageService.getItem(339)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-link"></i><span>&nbsp;=&nbsp;{{languageService.getItem(203)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-list-ul"></i><span>&nbsp;=&nbsp;{{languageService.getItem(741)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-comment"></i><span>&nbsp;=&nbsp;{{languageService.getItem(538)}}</span></div>
          <div class="form-group"><i class="far fa-border fa-comment"></i><span>&nbsp;=&nbsp;{{languageService.getItem(205)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-shield-alt"></i><span>&nbsp;=&nbsp;{{languageService.getItem(698)}}</span></div>
      </div>
      <!--Timereport Icons-->
      <div class="mb-4">
          <label>{{languageService.getItem(858)}}</label>
          <div class="form-group"><i class="fas fa-swe fa-sign-in-alt swe-grayed-out"></i>&nbsp;<i class="fas fa-swe fa-sign-out-alt swe-grayed-out"></i><span>&nbsp;=&nbsp;{{languageService.getItem(749)}}</span></div>
          <div class="form-group"><i class="fas fa-swe fa-sign-in-alt"></i>&nbsp;<i class="fas fa-swe fa-sign-out-alt swe-grayed-out"></i><span>&nbsp;=&nbsp;{{languageService.getItem(750)}}</span></div>
          <div class="form-group"><i class="fas fa-swe fa-sign-in-alt"></i>&nbsp;<i class="fas fa-swe fa-sign-out-alt"></i><span>&nbsp;=&nbsp;{{languageService.getItem(751)}}</span></div>
          <div class="form-group"><i class="fas fa-border fa-clock swe-grayed-out"></i>&nbsp;<i class="fas fa-border fa-check swe-grayed-out"></i>&nbsp;<i class="fas fa-border fa-lock swe-grayed-out"></i>&nbsp;=&nbsp;{{languageService.getItem(177)}}</div>
          <div class="form-group"><i class="fas fa-border fa-clock"></i>&nbsp;<i class="fas fa-border fa-check swe-grayed-out"></i>&nbsp;<i class="fas fa-border fa-lock swe-grayed-out"></i>&nbsp;=&nbsp;{{languageService.getItem(26)}}</div>
          <div class="form-group"><i class="fas fa-border fa-clock"></i>&nbsp;<i class="fas fa-border fa-check"></i>&nbsp;<i class="fas fa-border fa-lock swe-grayed-out"></i>&nbsp;=&nbsp;{{languageService.getItem(27)}}</div>
          <div class="form-group"><i class="fas fa-border fa-clock"></i>&nbsp;<i class="fas fa-border fa-check"></i>&nbsp;<i class="fas fa-border fa-lock"></i>&nbsp;=&nbsp;{{languageService.getItem(28)}}</div>
      </div>
      <!--Other Icons-->
      <div class="mb-4">
        <label>{{languageService.getItem(758)}}</label>
        <div class="form-group"><i class="fas fa-swe fa-calendar-plus"></i><span>&nbsp;=&nbsp;{{languageService.getItem(100) + ' ' + languageService.getItem(107)}}</span></div>
        <div class="form-group"><i class="fas fa-swe fa-calendar-check"></i><span>&nbsp;=&nbsp;{{languageService.getItem(100) + ' ' + languageService.getItem(110)}}</span></div>
        <div class="form-group"><i class="fas fa-swe fa-user-plus"></i><span>&nbsp;=&nbsp;{{languageService.getItem(100) + ' ' + languageService.getItem(2)}}</span></div>
        <div class="form-group"><i class="fas fa-swe fa-newspaper"></i><span>&nbsp;=&nbsp;{{languageService.getItem(100) + ' ' + languageService.getItem(131)}}</span></div>
        <div class="form-group"><i class="fas fa-swe fa-search"></i><span>&nbsp;=&nbsp;{{languageService.getItem(744)}}</span></div>
        <div class="form-group"><i class="fas fa-swe fa-search-minus"></i><span>&nbsp;=&nbsp;{{languageService.getItem(745)}}</span></div>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(840)" [open]="true" [hasCollapse]="true" [hasRefresh]="false" *ngIf="permissionService.permissions.Help>0">
      <div class="list-group">
        <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let document of documents">
          <div class="col-9 col-md-11">
              <a class="text-primary swe-click" (click)="download(document.Value)">{{document.Value}}</a>
          </div>
          <div class="col-3 col-md-1">
              <span *ngIf="permissionService.permissions.Help>2" class="swe-click" (click)="delete(document.Value)"><i class="fas fa-fw fa-swe fa-trash"></i></span>
          </div>
        </div>
      </div>
      <div class="form-group mt-3" *ngIf="permissionService.permissions.Help>1">
        <swe-element [bottomMargin]="0" [type]="'Internetplatsen.Document'" [label]="languageService.getItem(853)" [statusLabel]="0" [(model)]="file" [container]="documentcontainer()"></swe-element>
        <button class="btn btn-primary btn-block mt-1" (click)="upload()"><i class="fas fa-upload"></i>&nbsp;{{languageService.getItem(853)}}</button>
      </div>
    </swe-card>
  </div>
</div>
