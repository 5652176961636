import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { PermissionService } from '../_services/permission.service';
import { LanguageService } from '../_services/language.service';
import { GeneralService } from '../_services/general.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { DateTimeService } from '../_services/datetime.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { ListUtility } from '../_utilities/list.utility';


@Component({
  selector: 'app-objects',
  templateUrl: './objects.component.html'
})
export class ObjectsComponent implements OnInit, OnDestroy {

  private unsubsribe$ = new Subject<void>();
  private _category: number = 0;
  private _header: string = '';
  private _hits: number = 0;
  private _objects: any[] = [];
  private _loading: boolean = false;
  private _more: boolean = false;
  private _multiple: number = 1;
  //private _checkcounter: number = 0;
  //private _ischecked: boolean = false;
  private _actionmenu: boolean = false;
  private _listutility: ListUtility = new ListUtility();

  constructor(
    public authenticationService: AuthenticationService,
    public permissionService: PermissionService,
    public languageService: LanguageService,
    public settingService: SettingService,
    private generalService: GeneralService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private viewCacheService: ViewCacheService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });

  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      //Category
      this._category = Number(params.get('category'));
      //Header
      let found: boolean = false;
      this.generalService.propertycategories.forEach((item) => {
        if (!found) {
          if (item.Key == this._category) {
            this._header = item.Value;
            found = true;
          }
        }
      });

      this.settingService.initView(16 | 512, ['Object'], [], this._category);

      this._listutility.hasdataaccess = false;

      this.search();
    });
  }


  //Properties
  public get category() {
    return this._category;
  }
  public get header() {
    return this._header;
  }
  public get hits() {
    return this._hits;
  }
  public get objects() {
    return this._objects;
  }
  public get isloading() {
    return this._loading;
  }
  public get more() {
    return this._more;
  }
  //public get ischecked() {
  //  return this._ischecked;
  //}
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  public get listutility() {
    return this._listutility;
  }



  //Methods
  public search(getmore:boolean=false) {

    if (this.permissionService.hasObject(this.category) <= 0) { return; }

    this._more = false;

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._objects = [];
    }

    let filter = {
      Category: this._category,
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor,
      Top: 25,
      Multiple: this._multiple
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/objects/search', 'POST', filter)
      .subscribe((res) => {

        res.Objects.forEach((item) => {

          item.LastModified = this.dateTimeService.format(item.LastModified, null);

          this._objects.push(item);
        });

        this._listutility.rows = this._objects;
        this._hits = this._objects.length;
        this._more = res.More;

        this._loading = false;
      });
  }
  public open(id, e) {
    this.settingService.lastobject = id;

    e.stopPropagation();

    let url = '/objects/' + this._category + '/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  //public toggle(object, e) {
  //  e.stopPropagation();

  //  object.checked = !object.checked;

  //  this._checkcounter = this._checkcounter + (object.checked ? 1 : -1);

  //  this._ischecked = this._checkcounter > 0;
  //}
  //public checkall(option) {
  //  this._objects.forEach((object) => {
  //    object.checked = option;
  //  });

  //  this._checkcounter = option ? this._objects.length : 0;
  //  this._ischecked = option;
  //}
  public action = function (option, e) {

    let list = [];

    this._objects.forEach((object) => {

      if (object.checked) {

        list.push(object.Id);
      }

    });

    if (list.length > 0) {
      this.viewCacheService.add('multi_objects', list);

      this.router.navigate(['/objects/action/' + option + '/' + this._category]);
    }

    e.stopPropagation();
  }

}
