<swe-pageheader [header]="languageService.getItem(953)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="true" (refreshChange)="load()" *ngIf="permissionService.permissions.Contract>0">
      <span swe-rightcommand *ngIf="permissionService.permissions.Contract>1">
        <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="goto(0)"></i>
      </span>
      <swe-list [data]="contracts" [loading]="isloading">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let contract of contracts" (click)="goto(contract.Id)">
          <div class="col-12 col-md-12">{{contract.Name}}</div>
        </a>
      </swe-list>
    </swe-card>
  </div>
</div>
