/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./element.component.ngfactory";
import * as i3 from "./element.component";
import * as i4 from "../_services/authentication.service";
import * as i5 from "../_services/permission.service";
import * as i6 from "../_services/property.service";
import * as i7 from "../_services/datetime.service";
import * as i8 from "../_services/list.service";
import * as i9 from "../_services/language.service";
import * as i10 from "./card.component.ngfactory";
import * as i11 from "./card.component";
import * as i12 from "./profile.component";
var styles_ProfileComponent = [];
var RenderType_ProfileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileComponent, data: {} });
export { RenderType_ProfileComponent as RenderType_ProfileComponent };
function View_ProfileComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { "d-none": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"], [null, "enterChange"], [null, "changedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_v.context.$implicit.Value = $event) !== false);
        ad = (pd_0 && ad);
    } if (("modelChange" === en)) {
        var pd_1 = (_co.valuedependency($event, _v.context.$implicit.Property) !== false);
        ad = (pd_1 && ad);
    } if (("enterChange" === en)) {
        var pd_2 = (_co.done() !== false);
        ad = (pd_2 && ad);
    } if (("changedChange" === en)) {
        var pd_3 = ((_v.context.$implicit.Changed = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_ElementComponent_0, i2.RenderType_ElementComponent)), i0.ɵdid(5, 638976, null, 0, i3.ElementComponent, [i4.AuthenticationService, i5.PermissionService, i6.PropertyService, i7.DateTimeService, i8.ListService, i9.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], model: [2, "model"], changed: [3, "changed"], invalid: [4, "invalid"], propertyId: [5, "propertyId"], access: [6, "access"], markRequired: [7, "markRequired"], container: [8, "container"], objectId: [9, "objectId"], profileList: [10, "profileList"] }, { modelChange: "modelChange", changedChange: "changedChange", enterChange: "enterChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, !_co.visibledependency(_v.context.$implicit.Property)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.Name; var currVal_2 = _co.statusLabel; var currVal_3 = _v.context.$implicit.Value; var currVal_4 = _v.context.$implicit.Changed; var currVal_5 = _v.context.$implicit.Invalid; var currVal_6 = _v.context.$implicit.Property; var currVal_7 = _v.context.$implicit.Access; var currVal_8 = true; var currVal_9 = _v.context.$implicit.Container; var currVal_10 = _co.id; var currVal_11 = _co.model; _ck(_v, 5, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_ProfileComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "swe-card", [], null, null, null, i10.View_CardComponent_0, i10.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i11.CardComponent, [i9.LanguageService], { header: [0, "header"], color: [1, "color"], open: [2, "open"], hasRefresh: [3, "hasRefresh"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_ProfileComponent_3)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.value.header; var currVal_1 = "swe"; var currVal_2 = false; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.value.items; _ck(_v, 3, 0, currVal_4); }, null); }
function View_ProfileComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { "d-none": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "swe-element", [], null, [[null, "modelChange"], [null, "enterChange"], [null, "changedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_v.context.$implicit.Value = $event) !== false);
        ad = (pd_0 && ad);
    } if (("modelChange" === en)) {
        var pd_1 = (_co.valuedependency($event, _v.context.$implicit.Property) !== false);
        ad = (pd_1 && ad);
    } if (("enterChange" === en)) {
        var pd_2 = (_co.done() !== false);
        ad = (pd_2 && ad);
    } if (("changedChange" === en)) {
        var pd_3 = ((_v.context.$implicit.Changed = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_ElementComponent_0, i2.RenderType_ElementComponent)), i0.ɵdid(5, 638976, null, 0, i3.ElementComponent, [i4.AuthenticationService, i5.PermissionService, i6.PropertyService, i7.DateTimeService, i8.ListService, i9.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], model: [2, "model"], changed: [3, "changed"], invalid: [4, "invalid"], propertyId: [5, "propertyId"], access: [6, "access"], markRequired: [7, "markRequired"], container: [8, "container"], objectId: [9, "objectId"], profileList: [10, "profileList"] }, { modelChange: "modelChange", changedChange: "changedChange", enterChange: "enterChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, !_co.visibledependency(_v.context.$implicit.Property)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.Name; var currVal_2 = _co.statusLabel; var currVal_3 = _v.context.$implicit.Value; var currVal_4 = _v.context.$implicit.Changed; var currVal_5 = _v.context.$implicit.Invalid; var currVal_6 = _v.context.$implicit.Property; var currVal_7 = _v.context.$implicit.Access; var currVal_8 = true; var currVal_9 = _v.context.$implicit.Container; var currVal_10 = _co.id; var currVal_11 = _co.model; _ck(_v, 5, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_ProfileComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileComponent_5)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.value.items; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProfileComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.value.header.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.value.header.length == 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProfileComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["* = ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(65); _ck(_v, 1, 0, currVal_0); }); }
export function View_ProfileComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ProfileComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i1.KeyValuePipe, [i0.KeyValueDiffers]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.groupedby, _co.originalOrder)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showIsNotOptionalText; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProfileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-profile", [], null, null, null, View_ProfileComponent_0, RenderType_ProfileComponent)), i0.ɵdid(1, 114688, null, 0, i12.ProfileComponent, [i9.LanguageService, i8.ListService, i6.PropertyService, i7.DateTimeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileComponentNgFactory = i0.ɵccf("swe-profile", i12.ProfileComponent, View_ProfileComponent_Host_0, { model: "model", statusLabel: "statusLabel", id: "id" }, { modelChange: "modelChange" }, []);
export { ProfileComponentNgFactory as ProfileComponentNgFactory };
