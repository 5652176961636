import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';



@Component({
  selector: 'app-obruledetail',
  templateUrl: './obruledetail.component.html'
})
export class OBRuleDetailComponent implements OnInit {

  private _id: number = 0;
  private _contractid: number = 0;
  private _rule: any = {};
  private _dayprefixes: any[] = [];
  private _weekdays: any[] = [];
  private _holidays: any[] = [];
  private _blackholidays: any[] = [];
  private _groups: any[] = [];
  private _types: any[] = [];
  private _contracts: any[] = [];
  private _fromstart: any[] = [];
  private _toend: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private alertService: AlertService,
    private generalService: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    //Reset
    settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this._contractid = Number(params.get('contractId'));

      this.init();
      this.load();
    });
  }


  ngOnInit() {

  }



  //Properties
  public get id() {
    return this._id;
  }
  public get contractid() {
    return this._contractid;
  }
  public get rule() {
    return this._rule;
  }
  public get dayprefixes() {
    return this._dayprefixes;
  }
  public get weekdays() {
    return this._weekdays;
  }
  public get holidays() {
    return this._holidays;
  }
  public get blackholidays() {
    return this._blackholidays;
  }
  public get groups() {
    return this._groups;
  }
  public get types() {
    return this._types;
  }
  public get contracts() {
    return this._contracts;
  }
  public get fromstart() {
    return this._fromstart;
  }
  public get toend() {
    return this._toend;
  }
  




  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/overtime/' + this._id, 'GET', {})
      .subscribe((res) => {

        if (this._id <= 0) {
          res.ContractId = this._contractid;
        }

        this._rule = res;
      });

  }
  public save() {

    let verb = 'Post';
    let path = '/api/v1/overtime/'
    if (this._id > 0) {
      verb = 'Put';
      path += this._id;
    }

    if (!this._rule.ContractId) {
      this._rule.ContractId = 0;
    }

    if (this._rule.Type == 0) {
      this._rule.Type = 1;
    }

    this.dataService.tokenRequest(path, verb, this._rule, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location').replace('system/overtime', 'admin/overtime')], { replaceUrl: true });
        }

        this.load();
      });

  };

  public delete() {

    this.dataService.tokenRequest('/api/v1/overtime/' + this._id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  };
  



  //Functions
  private init() {

    this.generalService.contracts.forEach((contract) => {

      this._contracts.push({ Id: contract.Key, Name: contract.Value });

    });

    //Day prefix
    this.dataService.tokenRequest('/api/v1/overtime/dayprefixes', 'GET', {})
      .subscribe((res) => {

        this._dayprefixes = [];
        res.forEach((item) => {
          this._dayprefixes.push({Id: item.Key, Name: item.Value});
        });
      });

    //Weekdays
    this.dataService.tokenRequest('/api/v1/overtime/weekdays', 'GET', {})
      .subscribe((res) => {

        this._weekdays = [];
        res.forEach((item) => {
          this._weekdays.push({ Id: item.Key, Name: item.Value });
        });
      });

    //Holidays
    this.dataService.tokenRequest('/api/v1/overtime/holidays', 'GET', {})
      .subscribe((res) => {

        this._holidays = [];
        this._blackholidays = [];
        res.forEach((item) => {
          this._holidays.push({ Id: item.Key, Name: item.Value });
          if (item.Extra == '0') {
            this._blackholidays.push({ Id: item.Key, Name: item.Value });
          }
        });
      });

    //Groups
    this.dataService.tokenRequest('/api/v1/overtime/groups', 'GET', {})
      .subscribe((res) => {

        this._groups = [];
        res.forEach((item) => {
          this._groups.push({ Id: item.Key, Name: item.Value });
        });
      });

    this._types = [];
    this._types.push({ Id: 1, Name: this.languageService.getItem(1004) });
    this._types.push({ Id: 2, Name: this.languageService.getItem(1005) });

    //From Start
    this._fromstart.push({ Id: 0, Name: 965 });
    this._fromstart.push({ Id: 1, Name: 966 });

    //To End
    this._toend.push({ Id: 0, Name: 965 });
    this._toend.push({ Id: 1, Name: 967 });
  }

}
