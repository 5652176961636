<swe-card header="{{languageService.getItem(30)}}" [(open)]="open" (refreshChange)="load(true)" (openChange)="load()" *ngIf="bookingdata&&bookingdata.Id>0">
  <span swe-hits>&nbsp;({{bookingdata.Filled}}/{{bookingdata.Max}})</span>
  <swe-infotext [text]="languageService.getItem(533)" [preventdrowning]="true"></swe-infotext>
  <div class="btn-group" *ngIf="bookingusers.length>0&&markaccess">
    <!--CheckAll-->
    <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="userchecked=!userchecked;listutility.checkall(userchecked)">
      <span><i class="fas fa-check"></i></span>
    </button>
    <!--Functionlist-->
    <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe dropdown" (click)="usermenu=!usermenu" *ngIf="listutility.ischecked">
      <span><i class="fas fa-fw fa-ellipsis-v"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': usermenu && listutility.ischecked}">
      <li class="dropdown-item swe-click" *ngIf="bookingdata.Status<60&&bookoption(1)">
        <swe-confirmserie (acceptChange)="savelist($event, 1)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
          <i class="fas fa-fw fa-user-plus"></i>&nbsp;{{languageService.getItem(281)}}
        </swe-confirmserie>
      </li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Replaceable&&bookingdata.Status<60&&bookoption(0)">
        <swe-confirmserie (acceptChange)="savelist($event, 0)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
          <i class="fas fa-fw fa-retweet"></i>&nbsp;{{languageService.getItem(423)}}
        </swe-confirmserie>
      </li>
      <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.Standby==2||permissionService.permissions.Standby==1&&bookingdata.Filled==bookingdata.Max)&&bookingdata.Status<60&&bookoption(2)">
        <swe-confirmserie (acceptChange)="savelist($event, 2)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
          <i class="fas fa-fw fa-user"></i>&nbsp;{{languageService.getItem(282)}}
        </swe-confirmserie>
      </li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Abscent>0&&bookingdata.Status<60&&bookoption(3)">
        <swe-confirmserie (acceptChange)="savelist($event, 3)" [isSerie]="bookingdata.SerieId>0&&!permissionService.permissions.DetailWhenAbscent" [affectAllOnly]="bookingdata.AffectAllOnly">
          <i class="fas fa-fw fa-user"></i>&nbsp;{{languageService.getItem(283)}}
        </swe-confirmserie>
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item swe-click" *ngIf="bookingdata.Status<60">
        <swe-confirmserie (acceptChange)="deletelist($event)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
          <i class="fas fa-fw fa-user-minus"></i>&nbsp;{{languageService.getItem(305)}}
        </swe-confirmserie>
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Email>0"><a (click)="send(1, $event)"><i class="fas fa-fw fa-envelope"></i>&nbsp;{{languageService.getItem(321)}}</a></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Sms>0"><a (click)="send(3, $event)"><i class="fas fa-fw fa-mobile-alt"></i>&nbsp;{{languageService.getItem(320)}}</a></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ReplySms"><a (click)="send(6, $event)"><i class="fas fa-fw fa-mobile-alt"></i>&nbsp;{{languageService.getItem(178)}}</a></li>
      <li class="dropdown-item swe-click" *ngIf="bookingdata.RemindAccess&&permissionService.permissions.Sms>0"><a (click)="addmessagelist(5)"><i class="fas fa-fw fa-plus"></i>&nbsp;{{languageService.getItem(828)}}</a></li>
      <li class="dropdown-item swe-click" *ngIf="bookingdata.RemindAccess&&permissionService.permissions.Sms>0"><a (click)="removemessagelist(5)"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(684)}}</a></li>
    </ul>
  </div>
  <!--Notifications-->
  <div class="btn-group ml-2" *ngIf="bookingusers.length>0&&markaccess">
    <!--Email-->
    <button [attr.aria-label]="languageService.getItem(920)" [attr.title]="languageService.getItem(920)" class="btn btn-swe" (click)="checknotificationemail=!checknotificationemail" *ngIf="permissionService.permissions.NotifyOnCancellationEmail>0">
      <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationemail, 'fa-check-square': checknotificationemail}"></i>
      <i class="fas fa-fw fa-swe fa-envelope"></i>
    </button>
    <!--SMS-->
    <button [attr.aria-label]="languageService.getItem(921)" [attr.title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationsms=!checknotificationsms" *ngIf="permissionService.permissions.NotifyOnCancellationSms>0">
      <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationsms, 'fa-check-square': checknotificationsms}"></i>
      <i class="fas fa-fw fa-swe fa-mobile"></i>
    </button>
  </div>

  <ng-container *ngIf="permissionService.permissions.BookingProfile&&settingService.isDevice(['md','lg','xl'])">
    <!--Table-->
    <swe-table [data]="bookingusers" [loading]="loading">
      <table class="table">
        <ng-container *ngFor="let groupby of groupedby | keyvalue">
          <tbody>
            <tr class="bg-light" *ngIf="groupby.value.header.length>0">
              <th style="border-top:0; border-bottom:0;" [attr.colspan]="(groupby.value.items[0].Profile.length + 3)">{{groupby.value.header}} ({{groupby.value.items.length}})</th>
            </tr>
            <tr>
              <td style="border-top:0; border-bottom:0;"></td>
              <td style="border-top:0; border-bottom:0;"></td>
              <ng-container *ngIf="groupby.value">
                <th style="border-top:0; border-bottom:0;" class="swe-click" *ngFor="let header of groupby.value.items[0].Profile" (click)="sort(header.Property)">
                  {{propertyService.getProperty(header.Property).Name}}
                  <i class="fas fa-caret-up" *ngIf="sortId==header.Property"></i>
                  <i class="fas fa-caret-down" *ngIf="(sortId*-1)==header.Property"></i>
                </th>
              </ng-container>
              <td style="border-top:0; border-bottom:0;"></td>
            </tr>
          
          
            <ng-container *ngFor="let user of groupby.value.items">
              <tr class="swe-click" [ngClass]="{'list-group-item-info': user.checked, 'list-group-item-danger': user.Warning&&!user.checked, 'list-group-item-warning': user.Access==1&&!user.checked}" [attr.title]="getTitle(user)" *ngIf="!user.Warning">
                <td style="min-width:50px;" class="swe-click" [ngStyle]="{'cursor': user.MarkAccess>0 ? 'pointer':'not-allowed'}" (click)="user.MarkAccess>0&&listutility.toggle(user,$event)" (mouseover)="user.hover=true" (mouseout)="user.hover=false">
                  <span class="fa-stack fa-swe-letter-circle-small" [ngClass]="{'d-none': !(user.checked||(user.MarkAccess>0&&user.hover))}">
                    <i class="fas fa-check-circle fa-stack-2x"></i>
                  </span>
                  <span class="fa-stack fa-swe-letter-circle-small" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length==0)}">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <strong class="fa-stack-1x fa-swe-letter">{{user.Firstname[0]}}</strong>
                  </span>
                  <span class="ml-1" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length>0)}">
                    <img class="swe-circle-image" alt="{{user.Firstname}} {{user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="user.Image" imageWidth="32" imageHeight="32">
                  </span>
                  <span class="align-middle">
                    <i class="fas fa-comment fa-2x" *ngIf="user.HasSms" [attr.title]="languageService.getItem(538)"></i>
                  </span>
                  <span class="fa-stack fa-swe-letter-circle-small" *ngIf="permissionService.permissions.Slots">
                    <i class="fas fa-circle-o fa-stack-2x"></i>
                    <strong class="fa-stack-1x fa-swe-letter-o">{{user.Slots}}</strong>
                  </span>
                </td>
                <td style="width:110px;">
                  <span class="align-middle" *ngIf="bookingdata.Status<60 && !user.checked">
                    <swe-confirmserie (acceptChange)="save($event,user,1)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly" *ngIf="user.Access>1&&groupby.key>0">
                      <a href="javascript:void(0);" class="swe-no-link swe-no-focus" (focus)="activerow=user.Id" (blur)="activerow=-1" [attr.title]="languageService.getItem(281) + ' ' +  user.Firstname + ' ' + user.Lastname"><i class="fas fa-fw fa-2x fa-plus-circle fa-pull-right swe-click" [ngClass]="{'swe-grayed-out': user.hover3||(activerow==user.Id)}" (mouseover)="user.hover3=true" (mouseout)="user.hover3=false"></i></a>
                    </swe-confirmserie>
                    <swe-confirmserie (acceptChange)="delete($event,user)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly" *ngIf="user.Access>2">
                      <a href="javascript:void(0);" class="swe-no-link swe-no-focus" (focus)="activerow=user.Id" (blur)="activerow=-1" [attr.title]="languageService.getItem(305) + ' ' +  user.Firstname + ' ' + user.Lastname"><i class="fas fa-fw fa-2x fa-minus-circle fa-pull-right swe-click" [ngClass]="{'swe-grayed-out': user.hover2||(activerow==user.Id)}" (mouseover)="user.hover2=true" (mouseout)="user.hover2=false"></i></a>
                    </swe-confirmserie>
                  </span>
                </td>
                <td *ngFor="let profile of user.Profile" (click)="openuser(user, $event)" [ngSwitch]="profile.Type">
                  <span *ngSwitchDefault>{{profile.FormatValue}}</span>
                  <span *ngSwitchCase="'System.Boolean'"><i class="far fa-fw" [ngClass]="{'fa-check-square': profile.FormatValue, 'fa-square': !profile.FormatValue}"></i></span>
                </td>
                <td>
                  <span class="align-middle">
                    <i class="fas fa-fw fa-clock fa-2x fa-pull-right" *ngIf="user.Status==40" [attr.title]="languageService.getItem(333)"></i>
                    <i class="fas fa-fw fa-check fa-2x fa-pull-right" *ngIf="user.Status==50" [attr.title]="languageService.getItem(334)"></i>
                    <i class="fas fa-fw fa-lock fa-2x fa-pull-right" *ngIf="user.Status==60" [attr.title]="languageService.getItem(335)"></i>
                  </span>
                  <span class="align-middle">
                    <i class="fas fa-fw fa-edit fa-2x fa-pull-right swe-click" *ngIf="!user.checked&&user.UserAccess>0" [attr.title]="languageService.getItem(862)" (click)="opentimereport(user,$event)"></i>
                  </span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </ng-container>
      </table>
    </swe-table>
  </ng-container>
  <ng-container *ngIf="!permissionService.permissions.BookingProfile||settingService.isDevice(['xs','sm'])">
    <!--List-->
    <swe-list [data]="bookingusers" [loading]="loading">
      <div *ngFor="let groupby of groupedby | keyvalue">
        <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light" *ngIf="groupby.value.header.length>0">
          <h2>{{groupby.value.header}} ({{groupby.value.items.length}})</h2>
        </a>
        <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': user.checked}" *ngFor="let user of groupby.value.items;index as i">
          <div class="col-4 col-md-1" (click)="user.MarkAccess>0&&listutility.toggle(user,$event)" (mouseover)="settingService.isDevice(['md','lg','xl'])&&user.hover=true" (mouseout)="settingService.isDevice(['md','lg','xl'])&&user.hover=false">
            <span class="fa-stack fa-swe-letter-circle-small" [ngClass]="{'d-none': !(user.checked||(user.MarkAccess>0&&user.hover))}">
              <i class="fas fa-check-circle fa-stack-2x"></i>
            </span>
            <span class="fa-stack fa-swe-letter-circle-small" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length==0)}">
              <i class="fas fa-circle fa-stack-2x"></i>
              <span class="fa-stack-1x fa-swe-letter">{{user.Firstname[0]}}</span>
            </span>
            <span class="ml-1" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length>0)}">
              <img class="swe-circle-image" alt="{{user.Firstname}} {{user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="user.Image" imageWidth="32" imageHeight="32">
            </span>
            <span class="align-middle">
              <i class="fas fa-comment fa-swe" *ngIf="user.HasSms" [attr.title]="languageService.getItem(538)"></i>
            </span>
          </div>
          <div class="col-2 col-md-1">
            <span class="align-middle" *ngIf="bookingdata.Status<60 && !user.checked">
              <swe-confirmserie (acceptChange)="save($event,user,1)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly" *ngIf="user.Access>1&&groupby.key>0">
                <a href="javascript:void(0);" class="swe-no-link swe-no-focus" (focus)="activerow=user.Id" (blur)="activerow=-1" [attr.title]="languageService.getItem(281) + ' ' +  user.Firstname + ' ' + user.Lastname"><i class="fas fa-fw fa-2x fa-plus-circle fa-pull-right swe-click" [ngClass]="{'swe-grayed-out': user.hover3||(activerow==user.Id)}" (mouseover)="user.hover3=true" (mouseout)="user.hover3=false"></i></a>
              </swe-confirmserie>
              <swe-confirmserie (acceptChange)="delete($event,user)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly" *ngIf="user.Access>2">
                <a href="javascript:void(0);" class="swe-no-link swe-no-focus" (focus)="activerow=user.Id" (blur)="activerow=-1" [attr.title]="languageService.getItem(305) + ' ' +  user.Firstname + ' ' + user.Lastname"><i class="fas fa-fw fa-2x fa-minus-circle fa-pull-right swe-click" [ngClass]="{'swe-grayed-out': user.hover2||(activerow==user.Id)}" (mouseover)="user.hover2=true" (mouseout)="user.hover2=false"></i></a>
              </swe-confirmserie>
            </span>
          </div>
          <div class="col-6 col-md-8 swe-click" (click)="openuser(user, $event)">
            <span class="fa-stack fa-swe-letter-circle-small" *ngIf="permissionService.permissions.Slots">
              <i class="fas fa-circle-o fa-stack-2x"></i>
              <strong class="fa-stack-1x fa-swe-letter-o">{{user.Slots}}</strong>
            </span>
            {{user.Firstname}} {{user.Lastname}}
          </div>
          <div class="col-12 col-md-2">
            <span class="align-middle">
              <i class="fas fa-fw fa-clock fa-2x fa-pull-right" *ngIf="user.Status==40" [attr.title]="languageService.getItem(333)"></i>
              <i class="fas fa-fw fa-check fa-2x fa-pull-right" *ngIf="user.Status==50" [attr.title]="languageService.getItem(334)"></i>
              <i class="fas fa-fw fa-lock fa-2x fa-pull-right" *ngIf="user.Status==60" [attr.title]="languageService.getItem(335)"></i>
            </span>
            <span class="align-middle">
              <i class="fas fa-fw fa-edit fa-2x fa-pull-right swe-click" *ngIf="!user.checked&&user.UserAccess>0" [attr.title]="languageService.getItem(862)" (click)="opentimereport(user,$event)"></i>
            </span>
          </div>
        </div>
      </div>
    </swe-list>
  </ng-container>
</swe-card>
