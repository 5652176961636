<swe-pageheader [header]="languageService.getItem(636)"></swe-pageheader>
<div class="row py-2" *ngIf="permissionService.permissions.Import>0">
  <div class="col-12 col-md-6">
    <swe-card [header]="languageService.getItem(641)" [open]="true" [hasCollapse]="true" [hasRefresh]="false" [hasSave]="permissionService.permissions.Import>0&&body.length>0" (saveChange)="save()">
      <swe-element [type]="'Internetplatsen.Document'" [label]="languageService.getItem(642)" [statusLabel]="2" [container]="{ clientOnly: true, encoding: encoding, allowedPrefix: 'csv, txt', reload: reloadfile }" [(model)]="file" (modelChange)="parse($event)"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(643)" [statusLabel]="2" [optional]="false" [(model)]="encoding" [items]="encodings" (modelChange)="reload($event)"></swe-element>
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(644)" [statusLabel]="2" [(model)]="headerAsFirstRow" (modelChange)="parse($event)"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(645)" [statusLabel]="2" [optional]="false" [(model)]="delimeter" [items]="delimeters" (modelChange)="parse($event)"></swe-element>
    </swe-card>
  </div>
  <div class="col-12 col-md-6">
    <swe-card [header]="languageService.getItem(649)" [open]="true" [hasCollapse]="true" [hasRefresh]="false">
      <swe-element [type]="'System.List'" [label]="languageService.getItem(650)" [statusLabel]="2" [optional]="false" [(model)]="dataobject" [items]="dataobjects"></swe-element>
      <swe-element [type]="'System.GroupByList'" [label]="languageService.getItem(651)" [statusLabel]="2" [optional]="true" [(model)]="matchproperty" [items]="matchproperties"></swe-element>
    </swe-card>
  </div>
</div>
<div class="row py-2" *ngIf="permissionService.permissions.Import>0&&body.length>0">
  <div class="col-12">
    <swe-card [header]="languageService.getItem(636)" [open]="true" [hasCollapse]="true" [hasRefresh]="false">
      <div class="table-responsive">
        <table class="table table-sm table-bordered">
          <thead *ngIf="header.length>0">
            <tr>
              <th [ngClass]="{'swe-disabled-block': header[i].Id==0}" *ngFor="let headeritem of header; index as i">
                <i>{{headeritem.Name}}</i>
                <swe-element [type]="'System.GroupByList'" [label]="headeritem.Name||header[i].Id" [statusLabel]="0" [optional]="false" [(model)]="headeritem.Id" [items]="properties"></swe-element>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr [ngClass]="{'list-group-item-danger': (errors[i]&&errors[i].length>0),'list-group-item-success': success}" [attr.title]="errors[i]" *ngFor="let row of body; index as i">
              <td [ngClass]="{'swe-disabled-block': header[j]&&header[j].Id==0}" *ngFor="let column of row; index as j">
                {{column}}
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-block btn-primary" (click)="save()" [disabled]="issaving"><i class="fas fa-fw fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
      </div>
    </swe-card>
  </div>
</div>
