import { Component, Input, EventEmitter, Output, OnDestroy, NgZone, OnInit, ElementRef, ViewChild } from '@angular/core';

import { MapsAPILoader } from '@agm/core';

import { LanguageService } from '../_services/language.service';



@Component({
  selector: 'swe-maps',
  templateUrl: './maps.component.html',
  styles: ['agm-map { height: 300px; }']
})
export class MapsComponent implements OnInit, OnDestroy {
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() disabled: boolean = false;
  @ViewChild('search', { static: false }) searchElementRef: ElementRef;



  private _geocoder: any;
  private _map: any;
  private _mapClickListener: any;
  private _position: any = { lat: 59.33599107056162, lng: 18.0615234375 };
  private _marker: any;


  
  constructor(
    public languageService: LanguageService,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader) {

  }


  public mapReadyHandler(map: google.maps.Map): void {
    
    if (!this.disabled) {
      this._map = map;
      this._mapClickListener = this._map.addListener('click', (e: google.maps.MouseEvent) => {
        this.ngZone.run(() => {

          this._marker = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
          };

          this.getAddress();

          this.model = this._marker.lat + ',' + this._marker.lng;
          this.modelChange.emit(this.model);
        });
      });
    }
  }

  public ngOnInit() {
    if (this.model) {

      let pos: any[] = this.model.split(',');

      this.ngZone.run(() => {
        this._position = {
          lat: parseFloat(pos[0]),
          lng: parseFloat(pos[1])
        };

        this._marker = {
          lat: parseFloat(pos[0]),
          lng: parseFloat(pos[1])
        };

        
      });
    }

    this.mapsAPILoader.load().then(() => {

      this._geocoder = new google.maps.Geocoder;
      this.getAddress();

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude
          this._marker = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          };

          this.model = this._marker.lat + ',' + this._marker.lng;
          this.modelChange.emit(this.model);
        });
      });
    });
  }

  public ngOnDestroy(): void {
    if (this._mapClickListener) {
      this._mapClickListener.remove();
    }
  }




  /*Properties*/
  public get position() {
    return this._position;
  }
  public get marker() {
    return this._marker;
  }



  /*Methods*/
  public remove() {
    this._marker = null;
    this.model = '';
    this.modelChange.emit(this.model);
  }



  /*Functions*/
  private getAddress() {
    if (this._marker) {
      this._geocoder.geocode({ 'location': { lat: this._marker.lat, lng: this._marker.lng } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.searchElementRef.nativeElement.value = results[0].formatted_address;
          }
          else {
            console.log('No results found');
          }
        } else {
          console.log('Geocoder failed due to: ' + status);
        }

      });
    }
  }
}
