import { Component, OnChanges, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ListService } from '../../_services/list.service';
import { PropertyService } from '../../_services/property.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'swe-usermarkeddatedata',
  templateUrl: './usermarkeddatedata.component.html'
})
export class UserMarkedDateDataComponent implements OnChanges, OnDestroy {
  @Input() id: number;
  @Input() userId: number;
  @Input() open: boolean;
  @ViewChild('sweSaveElement', { static: false }) saveElement: ElementRef;

  private unsubscribe$: any[] = [
    new Subject<void>()
  ];
  private _usermarkeddate: any;
  private _loading: boolean;
  private _hiddenuser: string = '';
  private _repeatdays: number;
  private _repeatend: Date;
  private _createnew: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {

    settingService.onSave$
      .pipe(takeUntil(this.unsubscribe$[0]))
      .subscribe((e) => {
        //Save
        this.save();
      }); 
  }

  ngOnDestroy() {
    this.unsubscribe$.forEach((unsubscribe) => {
      unsubscribe.next();
      unsubscribe.complete();
    });
  }

  ngOnChanges() {
    if (this.open) {
      this._createnew = (this.id == 0);
      this.load();
    }
    if (this.userId > 0) {
      this.dataService.tokenRequest('/api/v1/users/' + this.userId, 'GET')
        .subscribe(res => {
          if (res) {

            this._hiddenuser = res.Name;
          }
        });
    }
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get usermarkeddate() {
    return this._usermarkeddate;
  }
  public get hiddenuser() {
    return this._hiddenuser;
  }
  public get serieDayList() {
    return [
      { Id: 2, Name: this.languageService.getItem(209) },
      { Id: 4, Name: this.languageService.getItem(210) },
      { Id: 8, Name: this.languageService.getItem(211) },
      { Id: 16, Name: this.languageService.getItem(212) },
      { Id: 32, Name: this.languageService.getItem(213) },
      { Id: 64, Name: this.languageService.getItem(214) },
      { Id: 1, Name: this.languageService.getItem(215) }
    ];
  }
  public get repeatdays() {
    return this._repeatdays;
  }
  public set repeatdays(val) {
    this._repeatdays = val;
  }
  public get repeatend() {
    return this._repeatend;
  }
  public set repeatend(val) {
    this._repeatend = val;
  }
  public get createnew() {
    return this._createnew;
  }
  public set createnew(val) {
    this._createnew = val;
  }



  /*Methods*/
  public profiledone() {
    if (this._usermarkeddate.Access > 1) {
      let element = this.saveElement.nativeElement;
      element.click();
    }
  }
  public load() {

    if (!this.open) { return; }

    this._loading = true;

    this._repeatdays = 0;
    this._repeatend = this.dateTimeService.lastDayInWeek(new Date());

    this.dataService.tokenRequest('/api/v1/usermarkeddates/' + this.id, 'GET')
      .subscribe(res => {
        if (res) {

          this._usermarkeddate = res;

          if (this.id == 0) {
            this.changeColor();
          }

          this._loading = false;
        }
      });
  }
  public save() {

    if (!this.dateTimeService.validation(this._usermarkeddate.Start, this._usermarkeddate.End, true)) { return; }
    if (!this.propertyService.validate(this._usermarkeddate.Profile)) { return; }
    if (!this.validateuser()) { return; }

    let verb = 'POST';
    let path = '/api/v1/usermarkeddates/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;
    }

    let dto: any = {
      Start: this._usermarkeddate.Start,
      End: this._usermarkeddate.End,
      UserId: this.userId > 0 ? this.userId : this._usermarkeddate.UserId,
      Type: this._usermarkeddate.Type,
      Profile: this._usermarkeddate.Profile,
      RepeatEnd: this._repeatend,
      RepeatDays: this._repeatdays
    };

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });
          if (this._createnew) {
            this.router.navigate(['/usermarkeddates/0/user/', this.userId]);
            this.load();
          }
          else if (response.status == 201) {
            let url = response.headers.get('Location');
            if (this.userId > 0) {
              url += '/user/' + this.userId;
            }
            this.router.navigate([url], { replaceUrl: true });
            this.load();
          }
          else {
            this.load();
          }
        }
      });
  }

  public delete() {

    this.dataService.tokenRequest('/api/v1/usermarkeddates/' + this.id, 'DELETE', {}, 'text')
      .subscribe(res => {
        if (res) {

          this.alertService.Add({ message: res, type: 'success' });

          if (this.id == 0) {
            //Might be merged
            this.router.navigateByUrl('/usermarkeddates/0');
          }
          else {
            this.location.back();
          }
        }
      });
  }
  public changeType() {
    this.changeColor();
  }

  public user(id: number, e) {
    this.router.navigate(['/users', id]);
  }


  //Functions
  private changeColor() {
    let item = this.listService.find(this.generalService.markeddatetypes, 'Id', parseInt(this._usermarkeddate.Type));
    if (item) {
      this._usermarkeddate.Color = item.Color;
    }
  }
  private validateuser() {
    if (this.permissionService.permissions.UserMarkedDateSearch > 0 && this.userId == 0 && this._usermarkeddate.id == 0) {
      this.alertService.Add({ message: this.languageService.getItem(735), type: 'danger' });
      return false;
    }

    return true;
  }
}
