<swe-card [header]="languageService.getItem(575)" [open]="false" [hasCollapse]="true" [hasRefresh]="true" refreshChange="load()" (openChange)="load()">
  <span swe-rightcommand *ngIf="permissionService.user.IsSuper">
    <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="goto(0)"></i>
  </span>
  <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let rule of timerules" (click)="goto(rule.Id)" [ngClass]="{'list-group-item-danger': !rule.IsActive}">
    <div class="col-6 col-md-6">{{rule.Desc}}</div>
    <div class="col-6 col-md-6 d-flex flex-wrap">
      <div class="col-12 col-md-4">{{rule.Type == 0 ? languageService.getItem(599) : languageService.getItem(600)}}</div>
      <div class="col-12 col-md-4">{{manageWeekday(rule.BreakWeekday)}}</div>
      <div class="col-12 col-md-4">{{manageHour(rule.BreakHour)}}</div>
    </div>
  </a>
</swe-card>
