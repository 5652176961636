<swe-pageheader [header]="languageService.getItem(317)"></swe-pageheader>
<div class="row  py-2">
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(318)" [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.permissions.Impersonate">
      <swe-element [type]="'System.UserSearch'" [label]="languageService.getItem(59)" [statusLabel]="1" [(model)]="userid"></swe-element>
      <swe-element [type]="'System.Password'" [label]="'Pin'" [statusLabel]="1" [(model)]="pincode" (enterChange)="impersonate()"></swe-element>
      <button class="btn btn-primary btn-block" (click)="impersonate()"><i class="fas fa-user-secret"></i>&nbsp;{{languageService.getItem(318)}}</button>
    </swe-card>
    <swe-card [header]="languageService.getItem(319)" [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.isimpersonate()>0">
      <swe-element [type]="'System.Password'" [label]="'Pin'" [statusLabel]="1" [(model)]="pincode" (enterChange)="impersonate()"></swe-element>
      <button class="btn btn-primary btn-block" (click)="impersonate()"><i class="fas fa-user-secret"></i>&nbsp;{{languageService.getItem(318)}}</button>
    </swe-card>
  </div>
</div>
