import { EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
import * as i2 from "./token.service";
import * as i3 from "./alert.service";
import * as i4 from "./permission.service";
import * as i5 from "./general.service";
import * as i6 from "@angular/router";
export class AuthenticationService {
    /*Constructors*/
    constructor(dataService, tokenService, alertService, permissionService, generalService, router, route) {
        this.dataService = dataService;
        this.tokenService = tokenService;
        this.alertService = alertService;
        this.permissionService = permissionService;
        this.generalService = generalService;
        this.router = router;
        this.route = route;
        //Events
        this.onLoginChange$ = new EventEmitter();
    }
    /*Properties*/
    get isAuth() {
        let isauth = localStorage.getItem('isauth');
        return (typeof isauth != 'undefined' && isauth != null && isauth.toLowerCase() == 'true');
    }
    /*Methods*/
    login(username, password) {
        return this.dataService.basicRequest('/api/v1/security/login', 'POST', { username, password }).pipe(tap(res => {
            if (res) {
                this.tokenService.saveTokens(res);
                this.onLoginChange$.emit(true);
                this.permissionService.reload().subscribe(() => {
                    this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/');
                });
            }
        }));
    }
    logout() {
        if (this.isAuth) {
            this.dataService.tokenRequest('/api/v1/security/logout', 'Post', {})
                .subscribe((res) => {
                this.tokenService.clearTokens();
            });
        }
    }
    redirectToLogin() {
        this.tokenService.clearTokens();
        this.router.navigateByUrl('/login?logout=1');
    }
    useRefreshToken() {
        let data = JSON.stringify(localStorage.getItem('refreshtoken'));
        return this.dataService.basicRequest('/api/v1/security/refreshtoken', 'POST', data);
    }
    //SSO
    loginsso(id) {
        window.open('/api/v1/security/sso/' + id, '_self');
    }
    //Token
    otp(token) {
        this.tokenService.clearTokens();
        this.tokenService.setToken('otptoken', token);
    }
    verify(code) {
        let parameters = {
            Token: this.tokenService.getToken('otptoken'),
            Code: code
        };
        return this.dataService.basicRequest('/api/v1/security/verify', 'POST', parameters).pipe(tap((data) => {
            if (data) {
                //Success login
                this.tokenService.saveTokens(data);
                this.onLoginChange$.emit(true);
                this.permissionService.reload().subscribe(() => {
                    this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/');
                });
            }
        }));
    }
    tokensignin(token, provider) {
        let parameters = {
            Token: token,
            Provider: provider
        };
        this.alertService.ReActivate();
        return this.dataService.basicRequest('/api/v1/security/tokensignin', 'POST', parameters).pipe(tap((data) => {
            if (data) {
                //Success login
                this.tokenService.saveTokens(data);
                this.onLoginChange$.emit(true);
                this.permissionService.reload().subscribe(() => {
                    this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/');
                });
            }
        }));
    }
    //Lost Password
    lostpasswordsend(address) {
        let parameters = {
            Address: address
        };
        return this.dataService.basicRequest('/api/v1/security/lostpassword/', 'POST', parameters, 'text');
    }
    lostpasswordchange(key, password) {
        let parameters = {
            Key: key,
            Password: password
        };
        return this.dataService.basicRequest('/api/v1/security/lostpassword/reset', 'Post', parameters, 'text').pipe(tap((data) => {
            if (data) {
                //Success login
                this.tokenService.saveTokens(data);
                this.permissionService.reload().subscribe(() => {
                    this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/');
                });
            }
        }));
    }
    //Impersonate
    impersonate(id, pincode) {
        let parameters = {
            AuthToken: this.tokenService.authBearer,
            Code: pincode
        };
        return this.dataService.basicRequest('/api/v1/security/impersonate/' + id, 'POST', parameters).pipe(tap((data) => {
            if (data) {
                //Success login
                this.tokenService.saveTokens(data);
                this.onLoginChange$.emit(true);
                this.generalService.reloadRoles().subscribe();
                this.generalService.reloadLevelGroups().subscribe();
                this.generalService.reloadReports().subscribe();
                this.permissionService.reload().subscribe();
            }
        }));
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.TokenService), i0.ɵɵinject(i3.AlertService), i0.ɵɵinject(i4.PermissionService), i0.ɵɵinject(i5.GeneralService), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i6.ActivatedRoute)); }, token: AuthenticationService, providedIn: "root" });
