import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';
import { PropertyService } from '../../_services/property.service';
import { AlertService } from '../../_services/alert.service';
import { DateTimeService } from '../../_services/datetime.service';


@Component({
  selector: 'app-templatedetail',
  templateUrl: './templatedetail.component.html'
})
export class TemplateDetailComponent implements OnInit {

  private _id: number = 0;
  private _template: any = {};
  private _weekdays: any[] = [];
  private _shifttypes: any[] = [];
  private _properties: any[] = [];
  private _newsetting: any = {};
  private _newcompetence: any = {};
  private _loading: boolean = false;
  private _iterations: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private settingService: SettingService,
    private propertyService: PropertyService,
    private alertService: AlertService,
    private dateTimeService: DateTimeService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    //Reset
    this.settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
    });
  }


  ngOnInit() {

    this.init();
    this.load();
  }


  //Properties
  public get id() {
    return this._id;
  }
  public get template() {
    return this._template;
  }
  public get weekdays() {
    return this._weekdays;
  }
  public get shifttypes() {
    return this._shifttypes;
  }
  public get properties() {
    return this._properties;
  }
  public get newsetting() {
    return this._newsetting;
  }
  public get isloading() {
    return this._loading;
  }
  public get iterations() {
    return this._iterations;
  }




  //Methods
  public load() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/bookingtemplates/' + this._id, 'GET', {})
      .subscribe((res) => {

        this._template = res;

        //Get Shift Type if missing----
        let shifttype = this.permissionService.permissions.TemplateShiftType;
        if (shifttype && shifttype > 0) {

          if (this._shifttypes.length == 0) {
            let property = this.propertyService.getProperty(shifttype);
            if (property) {
              this._shifttypes = property.Items;
            }
          }
        }
        //-----------------------------

        if (this._template) {
          this._template.Settings.forEach((setting) => {
            setting.Competences.forEach((competence) => {
              let property = this.propertyService.getProperty(competence.PropertyId);
              if (property) {
                competence.Type = property.Type;
                competence.Items = property.Items;
              }
            });
          });
        }

        this._loading = false;
      });
  }
  public addSetting() {
    if (this._template && this._template.Settings) {
      this._template.Settings.push(this._newsetting);
      this.createNewSetting();
    }
  }
  public deleteSetting(setting) {
    if (setting.Id > 0) {
      //Exists on server
      setting.IsDeleted = !setting.IsDeleted;
    }
    else {
      //Exists only on client => remove
      let index = this._template.Settings.indexOf(setting);
      if (index > -1) {
        this._template.Settings.splice(index, 1);
      }
    }
  }
  public addCompetence(setting) {
    setting.Competences.push(this._newcompetence);
    this.propertyChosen(this._newcompetence.PropertyId, setting, setting.Competences.length - 1);

    this.createNewCompetence();
  }
  public deleteCompetence(setting, competence) {
    if (competence.Id > 0) {
      //Exists on server
      competence.IsDeleted = !competence.IsDeleted;
    }
    else {
      //Exists only on client => remove
      let index = setting.Competences.indexOf(competence);
      if (index > -1) {
        setting.Competences.splice(index, 1);
      }
    }
  }
  public save() {

    let verb = 'Post';
    let path = '/api/v1/bookingtemplates/'
    if (this._id > 0) {
      verb = 'Put';
      path += this._id;
    }

    if (this._template && this._template.Settings) {
      let error = false;
      this._template.Settings.forEach((setting) => {
        setting.IsError = false;

        if (!error) {
          let dStart = new Date(setting.Start);
          let dEnd = new Date(setting.End);
          let compareStart = new Date(1970, 1, 1, dStart.getHours(), dStart.getMinutes(), dStart.getSeconds());
          let compareEnd = new Date(1970, 1, 1, dEnd.getHours(), dEnd.getMinutes(), dEnd.getSeconds());
          if (compareStart > compareEnd) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1024) });
            setting.IsError = error = true;
            return;
          }
          if (setting.Max < setting.Quantity) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1022) });
            setting.IsError = error = true;
            return;
          }
          if (setting.Min > setting.Quantity) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1023) });
            setting.IsError = error = true;
            return;
          }

          if (!setting.Weekdays || setting.Weekdays == 0) {
            setting.Weekdays = 127 //All days
          }
          if (!setting.Iteration || setting.Iteration == 0) {
            setting.Iteration = 1 //Every week
          }

          if (setting.Activity && setting.Activity.length == 0) {
            setting.Activity = 0;
          }
          if (setting.ShiftType && setting.ShiftType.length == 0) {
            setting.ShiftType = 0;
          }
        }
      });

      if (error) {
        return;
      }
    }

    this.dataService.tokenRequest(path, verb, this._template, 'text', 'response')
      .subscribe((response) => {

        if (response) {
          
          this.alertService.Add({ type: 'success', message: response.body });

          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true })
              .finally(() => {
                this.load();
            });
          }
          else {
            this.load();
          }
        }

      });

  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/bookingtemplates/' + this._id, 'Delete', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  }
  public propertyChosen(e, setting, idxCompetence) {

    let property = this.propertyService.getProperty(e);
    if (property) {
      let competence = setting.Competences[idxCompetence];
      if (competence) {
        competence.Type = property.Type;
        competence.Items = property.Items;
        if (competence.Type == 'System.DateTime') {
          competence.Value = this.dateTimeService.formatWithTimeZone(new Date());
        }
        else if (competence.Type == 'System.Date') {
          let now = new Date();
          competence.Value = this.dateTimeService.formatWithTimeZone(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0));
        }
        else if (competence.Type == 'System.Time') {
          competence.Value = this.dateTimeService.formatWithTimeZone(new Date());
        }
        else {
          competence.Value = '';
        }
      }
    }
  }
  public manageReload(filteritem) {
    if (filteritem.ReloadItems) {
      let property = this.propertyService.getProperty(filteritem.Id);
      if (property) {
        filteritem.Items = property.Items;
      }
      filteritem.ReloadItems = false;
    }
    else {
      return filteritem.Items;
    }
  }





  //Functions
  private init() {
    this.createNewSetting();

    this._weekdays = [
      { Id: 2, Name: this.languageService.getItem(209).substring(0,2) },
      { Id: 4, Name: this.languageService.getItem(210).substring(0, 2) },
      { Id: 8, Name: this.languageService.getItem(211).substring(0, 2) },
      { Id: 16, Name: this.languageService.getItem(212).substring(0, 2) },
      { Id: 32, Name: this.languageService.getItem(213).substring(0, 2) },
      { Id: 64, Name: this.languageService.getItem(214).substring(0, 2) },
      { Id: 1, Name: this.languageService.getItem(215).substring(0, 2) }
    ];

    this._iterations = [
      { Id: 0, Name: '' },
      { Id: 1, Name: this.languageService.getItem(363) },
      { Id: 2, Name: this.languageService.getItem(364) },
      { Id: 3, Name: this.languageService.getItem(365) },
      { Id: 4, Name: this.languageService.getItem(366) },
      { Id: 5, Name: this.languageService.getItem(367) },
      { Id: 6, Name: this.languageService.getItem(368) },
      { Id: 7, Name: this.languageService.getItem(369) },
      { Id: 8, Name: this.languageService.getItem(370) },
      { Id: 9, Name: this.languageService.getItem(371) },
      { Id: 10, Name: this.languageService.getItem(372) }
    ];

    this.dataService.tokenRequest('/api/v1/properties/type/competence', 'GET', {})
      .subscribe((list) => {
        this._properties = list;

        this.createNewCompetence();
      });

  }

  private createNewSetting() {
    this._newsetting = {
      WeekNr: 0,
      Quantity: 0,
      Min: 0,
      Max: 0,
      IsActive: true,
      IsEdit: true,
      Competences: []
    };
  }
  private createNewCompetence() {

    let propertyId = 0;
    if (this._properties.length > 0) {
      propertyId = this._properties[0].Id;
    }

    this._newcompetence = {
      PropertyId: propertyId,
      IsEdit: true
    };
  }

}
