import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { PermissionService } from '../_services/permission.service';
import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';

@Component({
  selector: 'swe-break',
  templateUrl: './break.component.html',
})
export class BreakComponent implements OnInit {
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() modelStart: any;
  @Output() modelStartChange = new EventEmitter<any>();
  @Input() disabledStart: boolean;
  @Input() minStart: any;
  @Input() maxStart: any;
  @Input() reset: boolean;
  @Input() hasBreakInfo: boolean;
  @Input() markChanges: boolean;
  @Input() changed: boolean;
  @Input() startChanged: boolean;

  private _type: string;
  private originalstart: any;
  private original: number;

  constructor(
    public permissionService: PermissionService,
    public languageService: LanguageService,
    private dateTimeService: DateTimeService) {

  }

  ngOnInit() {

    this._type = "System.DateTime";
    if (this.minStart && this.maxStart) {
      let min = new Date(this.minStart);
      let max = new Date(this.maxStart);

      //Is diff smaller than 24 hours
      if ((max.getTime() - min.getTime()) < this.dateTimeService.oneday) {
        this._type = "System.Time";
      }

      if (this.modelStart != null) {
        let breakstart = new Date(this.modelStart);
        if (breakstart < new Date(1970, 1, 1)) {
          this.modelStart = null;
        }
        else {
          if (breakstart < min) {
            let datetime = new Date(
              min.getFullYear(),
              min.getMonth(),
              min.getDate(),
              breakstart.getHours(),
              breakstart.getMinutes(),
              breakstart.getSeconds()
            );

            this.modelStart = this.dateTimeService.formatWithTimeZone(datetime);
          }
          else if (breakstart > max) {
            let datetime = new Date(
              max.getFullYear(),
              max.getMonth(),
              max.getDate(),
              breakstart.getHours(),
              breakstart.getMinutes(),
              breakstart.getSeconds()
            );

            this.modelStart = this.dateTimeService.formatWithTimeZone(datetime);
          }
        }
      }
    }

    this.original = this.model;
    this.originalstart = this.modelStart;
  }


  //Properties
  public get type() {
    return this._type;
  }


  //Methods
  public change() {
    //if (!this.modelStart) {
    //  this.modelStart = this.minStart;
    //}
  }
  public resetBreak() {
    this.model = -1;
    this.modelChange.emit(this.model);
  }
  public manageBreakStart(e) {

    if (this.modelStart != null) {
      if (this.minStart && this.maxStart) {
        let min = new Date(this.minStart);
        let max = new Date(this.maxStart);
        let current = new Date(this.modelStart);

        if (min > current || current > max) {
          //Outside min and max
          //Try min date but keep time
          let changed = new Date(min.getFullYear(), min.getMonth(), min.getDate(), current.getHours(), current.getMinutes());
          if (min > changed || changed > max) {
            //Still outside min and max
            //Use max date and keep time
            changed = new Date(max.getFullYear(), max.getMonth(), max.getDate(), current.getHours(), current.getMinutes());
            if (min > changed || changed > max) {
              //Still outside min and max
              //Use min date and time
              changed = new Date(min.getFullYear(), min.getMonth(), min.getDate(), min.getHours(), min.getMinutes());
            }
          }

          this.modelStart = this.dateTimeService.formatWithTimeZone(changed);
        }
      }
    }
    else {
      this.model = 0;
      this.modelChange.emit(this.model);

      this.modelStart = null;
    }

    this.modelStartChange.emit(this.modelStart);
  }
  public manageBreak(e) {

    if (this.model <= 0) {
      this.model = 0;

      this.modelStart = null;
      this.modelStartChange.emit(this.modelStart);
    }

    this.modelChange.emit(this.model);
  }
}
