import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';



@Component({
  selector: 'swe-homeshortcuts',
  templateUrl: './homeshortcuts.component.html',
})
export class HomeShortcutsComponent {

  constructor(public permissionService: PermissionService, public languageService: LanguageService, private router: Router) {

  }

  
  /*Properties*/
  



  /*Methods*/
  public goto(index: number) {
    if (index == 1) {
      this.router.navigate(['/bookings/0']);
    }
    else if (index == 2) {
      this.router.navigate(['/usermarkeddates/0']);
    }
    else if (index == 3) {
      this.router.navigate(['/users/0']);
    }
    else if (index == 4) {
      this.router.navigate(['/levels/0']);
    }
    else if (index == 5) {
      this.router.navigate(['/timereports/0/user/' + this.permissionService.user.Id + '/type/1']);
    }
    else if (index == 6) {
      this.router.navigate(['/timereports/0/user/' + this.permissionService.user.Id + '/type/3']);
    }
    
  }


  //Functions
  

}
