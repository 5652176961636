/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pageheader.component";
import * as i3 from "@angular/router";
var styles_PageHeaderComponent = [];
var RenderType_PageHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageHeaderComponent, data: {} });
export { RenderType_PageHeaderComponent as RenderType_PageHeaderComponent };
function View_PageHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "swe-no-link"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-fw fa-arrow-left"]], null, null, null, null, null))], null, null); }
export function View_PageHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "h1", [["class", "swe-fill-to-edge"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageHeaderComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "ml-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.useHistory; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.header; _ck(_v, 4, 0, currVal_1); }); }
export function View_PageHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-pageheader", [], null, null, null, View_PageHeaderComponent_0, RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageHeaderComponent, [i1.Location, i3.ActivatedRoute, i3.Router], null, null)], null, null); }
var PageHeaderComponentNgFactory = i0.ɵccf("swe-pageheader", i2.PageHeaderComponent, View_PageHeaderComponent_Host_0, { header: "header" }, {}, ["[swe-hits]"]);
export { PageHeaderComponentNgFactory as PageHeaderComponentNgFactory };
