import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { DataService } from '../../_services/data.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { AlertService } from '../../_services/alert.service';
import { Location } from '@angular/common';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';


@Component({
  selector: 'swe-multilevels',
  templateUrl: './multilevels.component.html'
})
export class MultiLevelsComponent implements OnInit {

  private _levelgroup: number = 0;
  private _parent: number = 0;
  private _parentname: string = '';
  private _properties: any[] = [];
  private _chosenproperties: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private settingService: SettingService,
    private location: Location) {

    //Reset
    settingService.initView();
  }

  ngOnInit() {
    this.load();
  }


  //Properties
  public get levelgroup() {
    return this._levelgroup;
  }
  public set levelgroup(val:number) {
    this._levelgroup = val;
  }
  public get parent() {
    return this._parent;
  }
  public set parent(val: number) {
    this._parent = val;
  }
  public get properties() {
    return this._properties;
  }
  public get chosenproperties() {
    return this._chosenproperties;
  }




  //Methods
  public levelChosen(e) {
    this.dataService.tokenRequest('/api/v1/levels/' + e, 'GET', {})
      .subscribe((res) => {
        this._parentname = res.Name;
      });
  }
  public confirmsave() {
    let confirmtext = '';

    //Be sure that this is an integer
    let iLevelgroup = parseInt(this._levelgroup.toString());

    if (iLevelgroup > 0) {
      let levelgroupname = '';
      let levelgroupObj = this.listService.find(this.generalService.levelgroups, 'Id', iLevelgroup);
      if (levelgroupObj) {
        levelgroupname = levelgroupObj.Name;
      }

      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(703).replace('{0}', levelgroupname);
    }

    if (this._parent > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(838).replace('{0}', this._parentname);
    }

    if (this._chosenproperties.length > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(428).replace('{0}', this._chosenproperties.length);
    }
    
    return this.languageService.getItem(659).replace('{0}', confirmtext);
  }
  public save() {
    let levels:any = this.viewCacheService.get('multi_levels');

    if (levels) {

      let profile: any[] = [];
      this._chosenproperties.forEach(property => {
        profile.push({ Property: property.Id, Value: property.Val });
      });

      let filter = {
        IdList: levels.value,
        LevelGroup: this._levelgroup > 0 ? this._levelgroup : -1,
        LevelId: this._parent,
        Profiles: profile
      };

      this.dataService.tokenRequest('/api/v1/levels/multi', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(656), type: 'danger' });
    }
  }
  public delete() {
    let levels: any = this.viewCacheService.get('multi_levels');

    if (levels) {
      let filter = {
        IdList: levels.value
      };

      this.dataService.tokenRequest('/api/v1/levels/multi/delete', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(656), type: 'danger' });
    }
  }


  //Functions
  private load() {

    this.dataService.tokenRequest('/api/v1/properties/type/multilevel', 'GET', {})
      .subscribe((res) => {

        let groupbyList: any[] = [];
        res.forEach((item) => {
          let groupby = this.listService.find(groupbyList, 'Name', item.Category);
          if (groupby) {
            groupby.Items.push({ Id: item.Id, Name: item.Name });
          }
          else {
            groupbyList.push({ Name: item.Category, Items: [{ Id: item.Id, Name: item.Name }] });
          }
        });

        this._properties = groupbyList;
      });
  }

}
