/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../layout/card.component.ngfactory";
import * as i3 from "../layout/card.component";
import * as i4 from "../_services/language.service";
import * as i5 from "./objecttoobjects.component";
import * as i6 from "../_services/authentication.service";
import * as i7 from "../_services/permission.service";
import * as i8 from "../_services/setting.service";
import * as i9 from "../_services/property.service";
import * as i10 from "../_services/general.service";
import * as i11 from "../_services/data.service";
import * as i12 from "../_services/datetime.service";
import * as i13 from "../_services/viewcache.service";
import * as i14 from "@angular/router";
var styles_ObjectToObjectsComponent = [];
var RenderType_ObjectToObjectsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ObjectToObjectsComponent, data: {} });
export { RenderType_ObjectToObjectsComponent as RenderType_ObjectToObjectsComponent };
function View_ObjectToObjectsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["+"]))], null, null); }
function View_ObjectToObjectsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["swe-hits", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u00A0(", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [")"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.more; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hits; _ck(_v, 1, 0, currVal_0); }); }
function View_ObjectToObjectsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["swe-rightcommand", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-plus fa-pull-right swe-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goto({ Id: 0 }, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_ObjectToObjectsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "th", [["style", "border-top:0; border-bottom:0;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"]))], null, null); }
function View_ObjectToObjectsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "th", [["style", "border-top:0; border-bottom:0;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.propertyService.getProperty(_v.context.$implicit.Property).Name; _ck(_v, 1, 0, currVal_0); }); }
function View_ObjectToObjectsComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.ObjectName; _ck(_v, 1, 0, currVal_0); }); }
function View_ObjectToObjectsComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.FormatValue; _ck(_v, 1, 0, currVal_0); }); }
function View_ObjectToObjectsComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [], [[8, "target", 0], [8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = "_blank"; var currVal_1 = _v.parent.context.$implicit.Url; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.FormatValue; _ck(_v, 2, 0, currVal_2); }); }
function View_ObjectToObjectsComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "i", [["class", "far fa-fw"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "fa-check-square": 0, "fa-square": 1 })], function (_ck, _v) { var currVal_0 = "far fa-fw"; var currVal_1 = _ck(_v, 4, 0, _v.parent.context.$implicit.FormatValue, !_v.parent.context.$implicit.FormatValue); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_ObjectToObjectsComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "td", [], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_11)), i0.ɵdid(3, 16384, null, 0, i1.NgSwitchDefault, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_12)), i0.ɵdid(5, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_13)), i0.ɵdid(7, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.Type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "System.Link"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "System.Boolean"; _ck(_v, 7, 0, currVal_2); }, null); }
function View_ObjectToObjectsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "tr", [["style", "border-top:0; border-bottom:0;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_v.context.$implicit.Access > 1) && _co.goto(_v.context.$implicit, $event)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { "swe-click": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_9)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_10)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (_v.context.$implicit.Access > 1)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.OneToOne; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.Profile; _ck(_v, 7, 0, currVal_2); }, null); }
function View_ObjectToObjectsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_7)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_8)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.objects[0].OneToOne; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.objects[0].Profile; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.objects; _ck(_v, 9, 0, currVal_2); }, null); }
function View_ObjectToObjectsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "swe-card", [], null, [[null, "openChange"], [null, "refreshChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openChange" === en)) {
        var pd_0 = ((_co.open = $event) !== false);
        ad = (pd_0 && ad);
    } if (("refreshChange" === en)) {
        var pd_1 = (_co.load(false) !== false);
        ad = (pd_1 && ad);
    } if (("openChange" === en)) {
        var pd_2 = (_co.load(false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i3.CardComponent, [i4.LanguageService], { header: [0, "header"], open: [1, "open"] }, { openChange: "openChange", refreshChange: "refreshChange" }), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_ObjectToObjectsComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 2, 1, null, View_ObjectToObjectsComponent_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, 3, 2, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.data.Value, ""); var currVal_1 = _co.open; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.open; _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.permissionService.hasObject(_co.data.Key) > 1); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.objects.length > 0); _ck(_v, 8, 0, currVal_4); }, null); }
export function View_ObjectToObjectsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ObjectToObjectsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.permissionService.hasObject(_co.data.Key) > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ObjectToObjectsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-objecttoobjects", [], null, null, null, View_ObjectToObjectsComponent_0, RenderType_ObjectToObjectsComponent)), i0.ɵdid(1, 114688, null, 0, i5.ObjectToObjectsComponent, [i6.AuthenticationService, i7.PermissionService, i4.LanguageService, i8.SettingService, i9.PropertyService, i10.GeneralService, i11.DataService, i12.DateTimeService, i13.ViewCacheService, i14.ActivatedRoute, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ObjectToObjectsComponentNgFactory = i0.ɵccf("swe-objecttoobjects", i5.ObjectToObjectsComponent, View_ObjectToObjectsComponent_Host_0, { open: "open", id: "id", data: "data" }, {}, []);
export { ObjectToObjectsComponentNgFactory as ObjectToObjectsComponentNgFactory };
