import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { KeyValue } from '@angular/common';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { SettingService } from '../../../_services/setting.service';
import { ListService } from '../../../_services/list.service';



@Component({
  selector: 'swe-contractobrules',
  templateUrl: './contractobrules.component.html'
})
export class ContractOBRulesComponent implements OnInit {

  @Input() id: number;
  @Input() access: number = 0;

  private _rules: any = {};
  private _ruleList: any[] = [];
  private _loading: boolean = false;
  
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private listService: ListService,
    private dataService: DataService,
    private router: Router
  ) {

    
    
  }


  ngOnInit() {

  }



  //Properties
  public get rules() {
    return this._rules;
  }
  public get ruleList() {
    return this._ruleList;
  }
  public get isloading() {
    return this._loading;
  }

  //Comparer
  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }


  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/overtime/groups/contracts/' + this.id, 'Get', {})
      .subscribe((groups) => {

        let grouplist: any[] = [];
        groups.forEach((group) => {
          grouplist.push({ Id: group.Key, Name: group.Value, Sort: group.Extra });
        });

        this._loading = true;

        this.dataService.tokenRequest('/api/v1/overtime/contracts/' + this.id, 'Get', {})
          .subscribe((res) => {

            this._ruleList = res;
            this._rules = this.listService.groupByInt(res, 'GroupId', grouplist);

            this._loading = false;
          });
      });
  }
  public goto(obid: number) {
    this.router.navigate(['/admin/overtime/' + obid + '/contract/' + this.id]);
  }
  



  //Functions
  

}
