<swe-card header="{{languageService.getItem(905)}}" [color]="'swe'" [(open)]="open" [hasCollapse]="true" [hasRefresh]="false" (openChange)="loademployments()">
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>{{languageService.getItem(906)}}</th>
          <th>{{languageService.getItem(907)}}</th>
          <th>{{languageService.getItem(908)}}</th>
          <th>{{languageService.getItem(925)}}</th>
          <th *ngIf="employments && employments.length>0 && employments[0].ExternalAccess>0">{{languageService.getItem(517)}}</th>
          <th *ngIf="employments && employments.length>0 && employments[0].ExternalAccess>0"></th>
          <th *ngIf="employments && employments.length>0 && employments[0].ExternalAccess>0"></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let employment of employments">
          <td><swe-element [type]="'System.Date'" [label]="languageService.getItem(906)" [statusLabel]="0" [(model)]="employment.Start"></swe-element></td>
          <td><swe-element [type]="'System.Date'" [label]="languageService.getItem(907)" [statusLabel]="0" [(model)]="employment.End"></swe-element></td>
          <td><swe-element [type]="'System.Double'" [label]="languageService.getItem(908)" [statusLabel]="0" [(model)]="employment.Degree"></swe-element></td>
          <td><swe-element [type]="'System.List'" [label]="languageService.getItem(925)" [statusLabel]="0" [(model)]="employment.Type" [items]="employmenttypes"></swe-element></td>
          <td *ngIf="employment.ExternalAccess>0"><swe-element [type]="'System.List'" [label]="languageService.getItem(925)" [statusLabel]="0" [(model)]="employment.Category" [items]="employmentcategories" [optional]="true" [disabled]="employment.ExternalAccess<2"></swe-element></td>
          <td *ngIf="employment.ExternalAccess>0">{{employment.xCategory}}</td>
          <td *ngIf="employment.ExternalAccess>0">{{employment.xUnique}}</td>
          <td>
            <button class="btn btn-primary btn-block" (click)="saveemployment(employment)" *ngIf="userdata.EmploymentAccess>1"><i class="fas fa-save"></i></button>
          </td>
          <td>
            <button class="btn btn-danger btn-block" (click)="deleteemployment(employment.Id)" *ngIf="employment.Id>0&&userdata.EmploymentAccess>2"><i class="fas fa-trash"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="height:250px;">
      &nbsp;
    </div>
  </div>
</swe-card>
