/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layout/pageheader.component.ngfactory";
import * as i2 from "../../layout/pageheader.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "../../layout/card.component.ngfactory";
import * as i6 from "../../layout/card.component";
import * as i7 from "../../_services/language.service";
import * as i8 from "../../layout/element.component.ngfactory";
import * as i9 from "../../layout/element.component";
import * as i10 from "../../_services/authentication.service";
import * as i11 from "../../_services/permission.service";
import * as i12 from "../../_services/property.service";
import * as i13 from "../../_services/datetime.service";
import * as i14 from "../../_services/list.service";
import * as i15 from "../../element/properties.component.ngfactory";
import * as i16 from "../../element/properties.component";
import * as i17 from "../../_services/setting.service";
import * as i18 from "../../_services/menu.service";
import * as i19 from "../../element/confirm.component.ngfactory";
import * as i20 from "../../element/confirm.component";
import * as i21 from "./multilevels.component";
import * as i22 from "../../_services/general.service";
import * as i23 from "../../_services/data.service";
import * as i24 from "../../_services/viewcache.service";
import * as i25 from "../../_services/alert.service";
var styles_MultiLevelsComponent = [];
var RenderType_MultiLevelsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MultiLevelsComponent, data: {} });
export { RenderType_MultiLevelsComponent as RenderType_MultiLevelsComponent };
export function View_MultiLevelsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-pageheader", [], null, null, null, i1.View_PageHeaderComponent_0, i1.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageHeaderComponent, [i3.Location, i4.ActivatedRoute, i4.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 19, "div", [["class", "row d-block py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 18, "div", [["class", "col-12 col-md-4 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 17, "swe-card", [], null, null, null, i5.View_CardComponent_0, i5.RenderType_CardComponent)), i0.ɵdid(5, 114688, null, 0, i6.CardComponent, [i7.LanguageService], { header: [0, "header"], open: [1, "open"], hasCollapse: [2, "hasCollapse"], hasRefresh: [3, "hasRefresh"] }, null), (_l()(), i0.ɵeld(6, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.levelgroup = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ElementComponent_0, i8.RenderType_ElementComponent)), i0.ɵdid(7, 638976, null, 0, i9.ElementComponent, [i10.AuthenticationService, i11.PermissionService, i12.PropertyService, i13.DateTimeService, i14.ListService, i7.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], items: [3, "items"], optional: [4, "optional"], model: [5, "model"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵeld(8, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.parent = $event) !== false);
        ad = (pd_0 && ad);
    } if (("modelChange" === en)) {
        var pd_1 = (_co.levelChosen($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_ElementComponent_0, i8.RenderType_ElementComponent)), i0.ɵdid(9, 638976, null, 0, i9.ElementComponent, [i10.AuthenticationService, i11.PermissionService, i12.PropertyService, i13.DateTimeService, i14.ListService, i7.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵeld(10, 0, null, 3, 1, "swe-properties", [], null, null, null, i15.View_PropertiesComponent_0, i15.RenderType_PropertiesComponent)), i0.ɵdid(11, 114688, null, 0, i16.PropertiesComponent, [i7.LanguageService, i12.PropertyService, i11.PermissionService, i13.DateTimeService, i17.SettingService, i18.MenuService], { label: [0, "label"], statusLabel: [1, "statusLabel"], items: [2, "items"], properties: [3, "properties"] }, null), (_l()(), i0.ɵeld(12, 0, null, 3, 4, "swe-confirm", [], null, [[null, "acceptChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("acceptChange" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_ConfirmComponent_0, i19.RenderType_ConfirmComponent)), i0.ɵdid(13, 638976, null, 0, i20.ConfirmComponent, [i7.LanguageService], { body: [0, "body"] }, { acceptChange: "acceptChange" }), (_l()(), i0.ɵeld(14, 0, null, 0, 2, "button", [["class", "btn btn-primary btn-block mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "i", [["class", "fas fa-fw fa-clone"]], null, null, null, null, null)), (_l()(), i0.ɵted(16, null, ["\u00A0", ""])), (_l()(), i0.ɵeld(17, 0, null, 3, 4, "swe-confirm", [], null, [[null, "acceptChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("acceptChange" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_ConfirmComponent_0, i19.RenderType_ConfirmComponent)), i0.ɵdid(18, 638976, null, 0, i20.ConfirmComponent, [i7.LanguageService], { body: [0, "body"] }, { acceptChange: "acceptChange" }), (_l()(), i0.ɵeld(19, 0, null, 0, 2, "button", [["class", "btn btn-danger btn-block mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 0, "i", [["class", "fas fa-fw fa-trash"]], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(410); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.languageService.getItem(3), ""); var currVal_2 = true; var currVal_3 = false; var currVal_4 = false; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.languageService.getItem(702); var currVal_6 = 2; var currVal_7 = "System.List"; var currVal_8 = _co.generalService.levelgroups; var currVal_9 = true; var currVal_10 = _co.levelgroup; _ck(_v, 7, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.languageService.getItem(235); var currVal_12 = 2; var currVal_13 = "System.LevelSearch"; var currVal_14 = _co.parent; _ck(_v, 9, 0, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.languageService.getItem(415); var currVal_16 = 2; var currVal_17 = _co.chosenproperties; var currVal_18 = _co.properties; _ck(_v, 11, 0, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_19 = _co.confirmsave(); _ck(_v, 13, 0, currVal_19); var currVal_21 = _co.languageService.getItem(660); _ck(_v, 18, 0, currVal_21); }, function (_ck, _v) { var _co = _v.component; var currVal_20 = _co.languageService.getItem(657); _ck(_v, 16, 0, currVal_20); var currVal_22 = _co.languageService.getItem(658); _ck(_v, 21, 0, currVal_22); }); }
export function View_MultiLevelsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-multilevels", [], null, null, null, View_MultiLevelsComponent_0, RenderType_MultiLevelsComponent)), i0.ɵdid(1, 114688, null, 0, i21.MultiLevelsComponent, [i7.LanguageService, i11.PermissionService, i22.GeneralService, i23.DataService, i14.ListService, i24.ViewCacheService, i25.AlertService, i17.SettingService, i3.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultiLevelsComponentNgFactory = i0.ɵccf("swe-multilevels", i21.MultiLevelsComponent, View_MultiLevelsComponent_Host_0, {}, {}, []);
export { MultiLevelsComponentNgFactory as MultiLevelsComponentNgFactory };
