import { throwError, EMPTY } from 'rxjs';
import { tap, catchError, shareReplay, finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./token.service";
import * as i2 from "./alert.service";
import * as i3 from "@angular/common/http";
export class DataService {
    constructor(tokenService, alertService, http) {
        this.tokenService = tokenService;
        this.alertService = alertService;
        this.http = http;
        this.isloading = false;
        this.islogout = false;
        this._isdebugging = false;
        this.imageCache = {};
        this._culture = null;
    }
    set culture(val) { this._culture = val; }
    basicRequest(url, method, parameter, responsetype = 'json', observe = 'body') {
        let httpOptions = {
            headers: {
                "Authorization": this.tokenService.authBasic,
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Pragma": "no-cache"
            },
            responseType: responsetype,
            observe: observe
        };
        if (this._culture) {
            httpOptions.headers['Accept-Language'] = this._culture;
        }
        return this.sendRequest(url, method, parameter, httpOptions);
    }
    tokenRequest(url, method, parameter, responsetype = 'json', observe = 'body') {
        let httpOptions = {
            headers: {
                "Authorization": this.tokenService.authBearer,
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Pragma": "no-cache"
            },
            responseType: responsetype,
            observe: observe
        };
        if (localStorage.getItem('debug')) {
            httpOptions.headers["Debug"] = 'true';
        }
        if (this._culture) {
            httpOptions.headers['Accept-Language'] = this._culture;
        }
        return this.sendRequest(url, method, parameter, httpOptions);
    }
    imageRequest(url) {
        if (this.imageCache[url]) {
            return this.imageCache[url];
        }
        let httpOptions = {
            headers: {
                "Authorization": this.tokenService.authBearer,
                "Content-Type": "application/json",
            },
            responseType: "blob"
        };
        this.imageCache[url] = this.http.get(url, httpOptions).pipe(shareReplay(1), catchError(error => {
            delete this.imageCache[url];
            return EMPTY;
        }));
        return this.imageCache[url];
    }
    //Properties
    get IsLoading() {
        return this.isloading;
    }
    set IsLoading(val) {
        this.isloading = val;
    }
    get IsLogout() {
        return this.islogout;
    }
    set IsLogout(val) {
        this.islogout = val;
    }
    sendRequest(url, method, parameter, httpOptions) {
        //Delay loading indicator
        let status = { isloading: true };
        let timeout = setTimeout((url) => {
            if (status.isloading && !this.islogout) {
                this.isloading = true;
                console.log('The call takes a long time (' + url + ')');
            }
        }, 2000, [url]);
        if (method.toUpperCase() == "GET") {
            return this.http.get(url, httpOptions).pipe(tap(res => {
                this.success(status);
            }), catchError(error => {
                if (error.status != 401) {
                    return throwError(this.failure(status, error));
                }
                else {
                    return EMPTY;
                }
            }), finalize(() => {
                this.isloading = status.isloading = false;
            }));
        }
        else if (method.toUpperCase() == "POST") {
            return this.http.post(url, parameter, httpOptions).pipe(tap(res => {
                this.success(status);
            }), catchError(error => {
                if (error.status != 401) {
                    return throwError(this.failure(status, error));
                }
                else {
                    return EMPTY;
                }
            }), finalize(() => {
                this.isloading = status.isloading = false;
            }));
        }
        else if (method.toUpperCase() == "PUT") {
            return this.http.put(url, parameter, httpOptions).pipe(tap(res => {
                this.success(status);
            }), catchError(error => {
                if (error.status != 401) {
                    return throwError(this.failure(status, error));
                }
                else {
                    return EMPTY;
                }
            }), finalize(() => {
                this.isloading = status.isloading = false;
            }));
        }
        else if (method.toUpperCase() == "DELETE") {
            return this.http.delete(url, httpOptions).pipe(tap(res => {
                this.success(status);
            }), catchError(error => {
                if (error.status != 401) {
                    return throwError(this.failure(status, error));
                }
                else {
                    return EMPTY;
                }
            }), finalize(() => {
                this.isloading = status.isloading = false;
            }));
        }
    }
    /*Mange Result*/
    success(status) {
        if (localStorage.getItem('debug') && !this._isdebugging) {
            this._isdebugging = true;
            //Delay debug call
            setTimeout(() => {
                this.getdebug();
            }, 1000);
        }
        this.isloading = status.isloading = false;
    }
    failure(status, error) {
        this.isloading = status.isloading = false;
        this.alertService.Add({ message: error.error, type: 'danger' });
        let reason = '';
        if (error.headers) {
            reason = error.headers.get("SweReason");
        }
        if ((reason == null || reason.length == 0)) {
            if (error.error) {
                reason = error.error;
            }
            else {
                reason = error.status;
            }
        }
        return reason || '';
    }
    //Debug
    getdebug() {
        let httpOptions = {
            Authorization: "Bearer " + this.tokenService.authBearer,
            Debug: 'true'
        };
        return this.http.get('/api/v1/security/debug', httpOptions)
            .subscribe((res) => {
            res.forEach((item) => {
                let type = 'info';
                if (item.State == 0) {
                    type = 'info';
                }
                else if (item.State == 1) {
                    type = 'success';
                }
                else if (item.State == 2) {
                    type = 'warning';
                }
                else if (item.State == 3) {
                    type = 'danger';
                }
                this.alertService.Add({ type: type, message: item.Message });
            });
            this._isdebugging = false;
        });
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.TokenService), i0.ɵɵinject(i2.AlertService), i0.ɵɵinject(i3.HttpClient)); }, token: DataService, providedIn: "root" });
