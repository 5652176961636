/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../details/objecttoobjects.component.ngfactory";
import * as i2 from "../details/objecttoobjects.component";
import * as i3 from "../_services/authentication.service";
import * as i4 from "../_services/permission.service";
import * as i5 from "../_services/language.service";
import * as i6 from "../_services/setting.service";
import * as i7 from "../_services/property.service";
import * as i8 from "../_services/general.service";
import * as i9 from "../_services/data.service";
import * as i10 from "../_services/datetime.service";
import * as i11 from "../_services/viewcache.service";
import * as i12 from "@angular/router";
import * as i13 from "@angular/common";
import * as i14 from "../layout/pageheader.component.ngfactory";
import * as i15 from "../layout/pageheader.component";
import * as i16 from "./details/usermarkeddatedata.component.ngfactory";
import * as i17 from "./details/usermarkeddatedata.component";
import * as i18 from "../_services/alert.service";
import * as i19 from "../_services/list.service";
import * as i20 from "./usermarkeddatedetail.component";
var styles_UserMarkedDateDetailComponent = [];
var RenderType_UserMarkedDateDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserMarkedDateDetailComponent, data: {} });
export { RenderType_UserMarkedDateDetailComponent as RenderType_UserMarkedDateDetailComponent };
function View_UserMarkedDateDetailComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-md-8 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "swe-objecttoobjects", [], null, null, null, i1.View_ObjectToObjectsComponent_0, i1.RenderType_ObjectToObjectsComponent)), i0.ɵdid(2, 114688, null, 0, i2.ObjectToObjectsComponent, [i3.AuthenticationService, i4.PermissionService, i5.LanguageService, i6.SettingService, i7.PropertyService, i8.GeneralService, i9.DataService, i10.DateTimeService, i11.ViewCacheService, i12.ActivatedRoute, i12.Router], { open: [0, "open"], id: [1, "id"], data: [2, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.id; var currVal_2 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_UserMarkedDateDetailComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserMarkedDateDetailComponent_2)), i0.ɵdid(2, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UserMarkedDateDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "swe-pageheader", [], null, null, null, i14.View_PageHeaderComponent_0, i14.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i15.PageHeaderComponent, [i13.Location, i12.ActivatedRoute, i12.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["swe-hits", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\u00A0(#", ")"])), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "col-12 col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "swe-usermarkeddatedata", [], null, null, null, i16.View_UserMarkedDateDataComponent_0, i16.RenderType_UserMarkedDateDataComponent)), i0.ɵdid(7, 704512, null, 0, i17.UserMarkedDateDataComponent, [i5.LanguageService, i4.PermissionService, i8.GeneralService, i9.DataService, i18.AlertService, i6.SettingService, i10.DateTimeService, i19.ListService, i7.PropertyService, i12.ActivatedRoute, i12.Router, i13.Location], { id: [0, "id"], userId: [1, "userId"], open: [2, "open"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserMarkedDateDetailComponent_1)), i0.ɵdid(9, 278528, null, 0, i13.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(110); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.id; var currVal_3 = _co.userId; var currVal_4 = _co.cards.data; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.objecttoobjects; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.id; _ck(_v, 3, 0, currVal_1); }); }
export function View_UserMarkedDateDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-usermarkeddatedetail", [], null, null, null, View_UserMarkedDateDetailComponent_0, RenderType_UserMarkedDateDetailComponent)), i0.ɵdid(1, 114688, null, 0, i20.UserMarkedDateDetailComponent, [i5.LanguageService, i6.SettingService, i8.GeneralService, i19.ListService, i12.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserMarkedDateDetailComponentNgFactory = i0.ɵccf("app-usermarkeddatedetail", i20.UserMarkedDateDetailComponent, View_UserMarkedDateDetailComponent_Host_0, {}, {}, []);
export { UserMarkedDateDetailComponentNgFactory as UserMarkedDateDetailComponentNgFactory };
