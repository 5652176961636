<swe-card header="{{title}}" [(open)]="open" (refreshChange)="search(false)" (openChange)="search(false)">
  <span swe-hits *ngIf="sum>0&&!employmentplan">({{generalService.formatdecimal(sum)}}h)</span>
  <span swe-rightcommand>
    <i class="fas fa-fw fa-plus fa-pull-right swe-click" [attr.title]="languageService.getItem(63)" (click)="goto(0,$event)" *ngIf="userdata.BookingAccess>1"></i>
    <i class="fas fa-fw fa-clock fa-pull-right swe-click" [attr.title]="languageService.getItem(1041)" (click)="create(1, $event)" *ngIf="permissionService.permissions.NewTimereport>0"></i>
    <i class="fas fa-fw fa-ban fa-pull-right swe-click" [attr.title]="languageService.getItem(1042)" (click)="create(3, $event)" *ngIf="permissionService.permissions.NewAbscentTimereport>0"></i>
  </span>
  <div class="btn-group mr-2" *ngIf="listutility.toggleaccess&&bookings.length>0">
    <!--CheckAll-->
    <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="bookingchecked=!bookingchecked;listutility.checkall(bookingchecked)">
      <span><i class="fas fa-check"></i></span>
    </button>
    <!--Functionlist-->
    <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe dropdown" (click)="bookingmenu=!bookingmenu" *ngIf="listutility.ischecked">
      <span><i class="fas fa-fw fa-ellipsis-v"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': bookingmenu && listutility.ischecked}">
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiTimereport>0"><a (click)="action('multi')"><i class="fas fa-fw fa-clone"></i>&nbsp;{{languageService.getItem(669)}}</a></li>
    </ul>
  </div>
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <div class="btn-group ml-1 align-top" *ngIf="more">
    <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>
  <ng-container *ngIf="permissionService.permissions.UserBookingProfile&&settingService.isDevice(['md','lg','xl'])">
    <!--Table-->
    <swe-table [data]="bookings" [loading]="loading">
      <table class="table" *ngFor="let groupby of groupedby | keyvalue">
        <thead>
          <tr class="bg-light" *ngIf="groupby.value.header.length>0">
            <th style="border-top:0; border-bottom:0;" [attr.colspan]="(groupby.value.items[0].Profile.length + 2)">
              <span>{{groupby.value.header}}</span>
              <span *ngIf="groupby.key==2&&!employmentplan">&nbsp;({{sumStandby}} h)</span>
              <span *ngIf="groupby.key==3&&!employmentplan">&nbsp;({{sumAbscent}} h)</span>
            </th>
          </tr>
          <tr>
            <td style="border-top:0; border-bottom:0;"></td>
            <ng-container *ngIf="groupby.value">
              <th style="border-top:0; border-bottom:0;" class="swe-click" *ngFor="let header of groupby.value.items[0].Profile" (click)="sort(header.Property)">
                {{propertyService.getProperty(header.Property).Name}}
                <i class="fas fa-caret-up" *ngIf="sortId==header.Property"></i>
                <i class="fas fa-caret-down" *ngIf="(sortId*-1)==header.Property"></i>
              </th>
            </ng-container>
            <td style="border-top:0; border-bottom:0;"></td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let booking of groupby.value.items">
            <tr class="swe-click" [ngClass]="{'list-group-item-success': booking.Diff&&booking.Status>40&&!booking.checked, 'list-group-item-danger': booking.Diff&&booking.Status<50&&!booking.checked, 'list-group-item-warning': booking.Id==settingService.lastbooking&&!booking.checked,'list-group-item-info': booking.checked}">
              <td (click)="booking.MarkAccess>0&&listutility.toggle(booking,$event)">
                <i class="fas fa-swe fa-check-circle" *ngIf="booking.checked"></i>
                <i class="fas fa-border swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status>10" [attr.title]="booking.StatusText" [ngClass]="{'fa-share-square': booking.Status==20,'fa-key': booking.Status==30,'fa-clock': booking.Status==40,'fa-check': booking.Status==50,'fa-lock': booking.Status==60,'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
                <i class="far fa-border fa-square swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status==10" [attr.title]="booking.StatusText" [ngClass]="{'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
                <i class="fas fa-border fa-link" *ngIf="!booking.checked&&booking.Serie>0&&!employmentplan" [attr.title]="languageService.getItem(203)"></i>
                <i class="fas fa-border fa-list-ul" *ngIf="!booking.checked&&booking.HasActivities>0&&!employmentplan" [attr.title]="languageService.getItem(741)"></i>
                <i class="fas fa-border fa-shield-alt" *ngIf="!booking.checked&&booking.EmploymentPlan" [attr.title]="languageService.getItem(698)"></i>
                <i class="far fa-border fa-comment" *ngIf="!booking.checked&&booking.Sms&&!employmentplan" [attr.title]="languageService.getItem(205)"></i>
                <i class="fas fa-border fa-eye" *ngIf="!booking.checked&&booking.IsWatching&&!employmentplan" [attr.title]="languageService.getItem(339)"></i>
                <i class="fas fa-border fa-retweet" *ngIf="!booking.checked&&booking.HasReplaceable&&!employmentplan" [attr.title]="languageService.getItem(423)"></i>
                <i class="fas fa-border fa-street-view" *ngIf="!booking.checked&&booking.HasStandby&&!employmentplan" [attr.title]="languageService.getItem(279)"></i>
                <i class="fas fa-border fa-ban" *ngIf="!booking.checked&&booking.HasAbscent&&!employmentplan" [attr.title]="languageService.getItem(280)"></i>
              </td>
              <td class="swwe-click" *ngFor="let profile of booking.Profile" (click)="goto(booking.Id,$event)" [ngSwitch]="profile.Type">
                <span *ngSwitchDefault>{{profile.FormatValue}}</span>
                <span *ngSwitchCase="'System.Boolean'"><i class="far fa-fw" [ngClass]="{'fa-check-square': profile.FormatValue, 'fa-square': !profile.FormatValue}"></i></span>
              </td>
              <td>
                <i class="fas fa-fw fa-lock fa-swe fa-pull-right" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 60 && !booking.hover3}" *ngIf="booking.CompletedAccess > 0&&!booking.checked" (click)="change(booking, 60, $event)" (mouseover)="booking.hover3=true" (mouseout)="booking.hover3=false" [attr.title]="languageService.getItem(335)"></i>
                <i class="fas fa-fw fa-check fa-swe fa-pull-right" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 50 && !booking.hover2}" *ngIf="booking.ConfirmedAccess > 0&&!booking.checked" (click)="change(booking, 50, $event)" (mouseover)="booking.hover2=true" (mouseout)="booking.hover2=false" [attr.title]="languageService.getItem(334)"></i>
                <i class="fas fa-fw fa-clock fa-swe fa-pull-right" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 40 && !booking.hover1}" *ngIf="booking.TimereportAccess > 0&&!booking.checked" (click)="change(booking, 40, $event)" (mouseover)="booking.hover1=true" (mouseout)="booking.hover1=false" [attr.title]="languageService.getItem(333)"></i>
                <i class="fas fa-fw fa-edit fa-swe fa-pull-right" *ngIf="showedit(booking)&&!booking.checked" (click)="timereport(booking.Timereport, $event)" [attr.title]="languageService.getItem(862)"></i>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </swe-table>
  </ng-container>
  <ng-container *ngIf="!permissionService.permissions.UserBookingProfile||settingService.isDevice(['xs','sm'])">
    <!--List-->
    <swe-list [data]="bookings" [loading]="loading">
      <div *ngFor="let groupby of groupedby | keyvalue">
        <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light" *ngIf="groupby.value.header.length>0">
          <h2>
            <span>{{groupby.value.header}}</span>
            <span *ngIf="groupby.key==2&&!employmentplan">&nbsp;({{sumStandby}} h)</span>
            <span *ngIf="groupby.key==3&&!employmentplan">&nbsp;({{sumAbscent}} h)</span>
          </h2>
        </a>
        <a class="list-group-item list-group-item-action d-flex swe-click" [ngClass]="{'list-group-item-success': booking.Diff&&booking.Status>40&&!booking.checked, 'list-group-item-danger': booking.Diff&&booking.Status<50&&!booking.checked, 'list-group-item-warning': booking.Id==settingService.lastbooking&&!booking.checked,'list-group-item-info': booking.checked}" *ngFor="let booking of groupby.value.items" (click)="goto(booking.Id,$event)">
          <div class="col-2 col-md-1" (click)="booking.MarkAccess>0&&listutility.toggle(booking,$event)">
            <i class="fas fa-swe fa-check-circle" *ngIf="booking.checked"></i>
            <i class="fas fa-border swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status>10" [attr.title]="booking.StatusText" [ngClass]="{'fa-share-square': booking.Status==20,'fa-key': booking.Status==30,'fa-clock': booking.Status==40,'fa-check': booking.Status==50,'fa-lock': booking.Status==60,'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
            <i class="far fa-border fa-square swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status==10" [attr.title]="booking.StatusText" [ngClass]="{'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
            <i class="fas fa-border fa-link" *ngIf="!booking.checked&&booking.Serie>0&&!employmentplan" [attr.title]="languageService.getItem(203)"></i>
            <i class="fas fa-border fa-list-ul" *ngIf="!booking.checked&&booking.HasActivities>0&&!employmentplan" [attr.title]="languageService.getItem(741)"></i>
            <i class="fas fa-border fa-shield-alt" *ngIf="!booking.checked&&booking.EmploymentPlan" [attr.title]="languageService.getItem(698)"></i>
            <i class="far fa-border fa-comment" *ngIf="!booking.checked&&booking.Sms&&!employmentplan" [attr.title]="languageService.getItem(205)"></i>
            <i class="fas fa-border fa-eye" *ngIf="!booking.checked&&booking.IsWatching&&!employmentplan" [attr.title]="languageService.getItem(339)"></i>
            <i class="fas fa-border fa-retweet" *ngIf="!booking.checked&&booking.HasReplaceable&&!employmentplan" [attr.title]="languageService.getItem(423)"></i>
            <i class="fas fa-border fa-street-view" *ngIf="!booking.checked&&booking.HasStandby&&!employmentplan" [attr.title]="languageService.getItem(279)"></i>
            <i class="fas fa-border fa-ban" *ngIf="!booking.checked&&booking.HasAbscent&&!employmentplan" [attr.title]="languageService.getItem(280)"></i>
          </div>
          <div class="col-10 col-md-11 d-flex flex-wrap">
            <div class="col-12 col-md-3">{{booking.dateheader}}</div>
            <div class="col-12 col-md-3">{{booking.Header}}</div>
            <div class="col-12 col-md-3" *ngIf="!permissionService.permissions.HideLevels">{{booking.Level}}</div>
            <div class="col-12 col-md-3">
              <i class="fas fa-fw fa-lock fa-swe fa-pull-right" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 60 && !booking.hover3}" *ngIf="booking.CompletedAccess > 0&&!booking.checked" (click)="change(booking, 60, $event)" (mouseover)="booking.hover3=true" (mouseout)="booking.hover3=false" [attr.title]="languageService.getItem(335)"></i>
              <i class="fas fa-fw fa-check fa-swe fa-pull-right" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 50 && !booking.hover2}" *ngIf="booking.ConfirmedAccess > 0&&!booking.checked" (click)="change(booking, 50, $event)" (mouseover)="booking.hover2=true" (mouseout)="booking.hover2=false" [attr.title]="languageService.getItem(334)"></i>
              <i class="fas fa-fw fa-clock fa-swe fa-pull-right" [ngClass]="{'swe-grayed-out':booking.TimereportStatus < 40 && !booking.hover1}" *ngIf="booking.TimereportAccess > 0&&!booking.checked" (click)="change(booking, 40, $event)" (mouseover)="booking.hover1=true" (mouseout)="booking.hover1=false" [attr.title]="languageService.getItem(333)"></i>
              <i class="fas fa-fw fa-edit fa-swe fa-pull-right" *ngIf="showedit(booking)&&!booking.checked" (click)="timereport(booking.Timereport, $event)" [attr.title]="languageService.getItem(862)"></i>
            </div>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </div>
    </swe-list>
  </ng-container>
</swe-card>
