<!--Alerts-->
<div class="swe-alert swe-alert-md">
  <ng-container *ngFor="let alert of alertService.Alerts">
    <div class="alert alert-{{alert.type}} text-center swe-alert-text swe-click" [ngClass]="{'swe-big-text': alert['bigtext']}" role="alert" (click)="alertService.Pop(alert)" *ngIf="!alert['hide']">
      <i class="fas fa-times fa-pull-right" (click)="alertService.Pop(alert)"></i>
      {{alert.message}}
    </div>
  </ng-container>
</div>

<!--Dataconnection-->
<div class="swe-waiting" *ngIf="dataService.IsLoading">
  <i class="fas fa-spinner fa-9x fa-spin"></i>
</div>
