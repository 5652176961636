import { Component, OnChanges, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; 
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PropertyService } from '../../_services/property.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';


@Component({
  selector: 'swe-userbelongings',
  templateUrl: './userbelongings.component.html'
})
export class UserBelongingsComponent implements OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;

  private unsubsribe$ = new Subject<void>();
  private _levels: any[] = [];
  private _roles: any[] = [];
  private _connections: any[] = [];
  private _availableroles: any[] = [];
  private _role: number = 0;
  private _level: number = 0;
  private _loading: boolean;
  private _edit: boolean = false;
  private _first: boolean = true;

  constructor(public languageService: LanguageService, private dataService: DataService, private alertService: AlertService, private settingService: SettingService, private propertyService: PropertyService, private permissionService: PermissionService, private listService: ListService, private route: ActivatedRoute, private router: Router) {
    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.load();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnChanges() {
    if (this.open) {
      this.load();
    }
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get levels() {
    return this._levels;
  }
  public get roles() {
    return this._roles;
  }
  public get connections() {
    return this._connections;
  }
  public get availableroles() {
    return this._availableroles;
  }
  public get level() {
    return this._level;
  }
  public set level(val) {
    this._level = val;
  }
  public get role() {
    return this._role;
  }
  public set role(val) {
    this._role = val;
  }
  public get edit() {
    return this._edit;
  }
  public set edit(val) {
    this._edit = val;
  }
  public get advanced() {
    return (this.permissionService.permissions.AdvancedBelonging || this._roles.length == 0);    
  }


  /*Methods*/
  public load() {

    if (!this.open) { return; }

    this._loading = true;

    let oldLength = this._levels.length;

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/belongings', 'GET')
      .subscribe(res => {
        if (res) {

          this._levels = res.Levels;
          this._roles = res.Roles;
          this._connections = [];
          Object.keys(res.Connections).forEach((roleId) => {
            let connection = {
              Role: this.listService.find(this._roles, 'Id', parseInt(roleId)),
              Levels: []
            }

            res.Connections[roleId].forEach((levelId) => {
              connection.Levels.push(this.listService.find(this._levels, 'Id', levelId));
            });

            this._connections.push(connection);
          });

          if (!this._first && (oldLength == 0 || this._levels.length == 0)) {
            this.settingService.viewrefresh('userbelonging_save');
          }

          this._loading = false;

          this._first = false;
        }
      });

    this.dataService.tokenRequest('/api/v1/general/roles/user/' + this.id, 'GET')
      .subscribe(res => {

        this._availableroles = res;
      });
  }
  public add() {
    if (this._role > 0 || this.level > 0) {

      this.dataService.tokenRequest('/api/v1/users/' + this.id + '/belongings/roles/' + this._role + '/levels/' + this._level, 'POST', {}, 'text')
        .subscribe(res => {

          this.alertService.Add({ message: res, type: 'success' });
          this.load();

          //this._edit = false;
        });
    }
  }
  public addRole(e) {

    if ((this.permissionService.permissions.AdvancedBelonging || this._roles.length == 0)) { return; }

    let exist = false;

    this._roles.forEach((role) => {
      if (this._role == role.Id) {
        exist = true;
      }
    });

    if (!exist) {
      //Add Role
      this.dataService.tokenRequest('/api/v1/users/' + this.id + '/belongings/roles/' + this._role, 'Post', {}, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.load();

          this._edit = false;
        });
    }

    this._role = 0;
  }
  public addLevel(e) {

    let index = this._levels.map(function (level) { return level.Id; }).indexOf(e.item.Id);
    if (index == -1) {

      this.dataService.tokenRequest('/api/v1/users/' + this.id + '/belongings/roles/0/levels/' + e.item.Id, 'POST', {}, 'text')
        .subscribe(res => {

          this._levels.push(e.item);
          this.listService.sort(this.levels, ['Path', 'Name'], true);

          this.alertService.Add({ message: res, type: 'success' });
          this.load();

          //this._edit = !this.advanced;
        });
    }
  }
  public removeLevel(level) {
    let index = this._levels.indexOf(level);
    if (index > -1) {

      this.dataService.tokenRequest('/api/v1/users/' + this.id + '/belongings/roles/0/levels/' + level.Id, 'DELETE', {}, 'text')
        .subscribe(res => {

          this._levels.splice(index, 1);
          
          this.alertService.Add({ message: res, type: 'success' });

          if (this._levels.length == 0) {
            this.load();
          }
        });
    }
  }
  public removeRole(roleId) {
    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/belongings/roles/' + roleId, 'DELETE', {}, 'text')
      .subscribe(res => {

        this.alertService.Add({ message: res, type: 'success' });
        this.load();
      });
  }
  public remove(roleId, levelId) {

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/belongings/roles/' + roleId + '/levels/' + levelId, 'DELETE', {}, 'text')
      .subscribe(res => {

        this.alertService.Add({ message: res, type: 'success' });
        this.load();
      });
  }
  public showEdit(e) {
    e.stopPropagation();
    this._edit = true;
  }
}
