<swe-card header="{{languageService.getItem(31)}}" [(open)]="open" (refreshChange)="load()" (openChange)="load()">
  <span swe-hits *ngIf="open&&availableusers.length>0">({{notred}}/{{availableusers.length}})</span>
  <swe-infotext [text]="languageService.getItem(531)" [preventdrowning]="true"></swe-infotext>
  <div class="btn-group" *ngIf="availableusers.length>0&&markaccess">
    <!--CheckAll-->
    <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe" (click)="userchecked=!userchecked;listutility.checkall(userchecked)">
      <span><i class="fas fa-check"></i></span>
    </button>
    <!--Functionlist-->
    <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe dropdown" (click)="usermenu=!usermenu" *ngIf="listutility.ischecked">
      <span><i class="fas fa-fw fa-ellipsis-v"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': usermenu && listutility.ischecked}">
      <li class="dropdown-item swe-click" *ngIf="bookingdata.Status<60&&bookoption(1)">
        <swe-confirmserie (acceptChange)="savelist($event, 1)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
          <i class="fas fa-fw fa-user-plus"></i>&nbsp;{{languageService.getItem(281)}}
        </swe-confirmserie>
      </li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Replaceable&&bookingdata.Status<60&&bookoption(0)">
        <swe-confirmserie (acceptChange)="savelist($event, 0)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
          <i class="fas fa-fw fa-retweet"></i>&nbsp;{{languageService.getItem(423)}}
        </swe-confirmserie>
      </li>
      <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.Standby==2||permissionService.permissions.Standby==1&&bookingdata.Filled==bookingdata.Max)&&bookingdata.Status<60&&bookoption(2)">
        <swe-confirmserie (acceptChange)="savelist($event, 2)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
          <i class="fas fa-fw fa-user"></i>&nbsp;{{languageService.getItem(282)}}
        </swe-confirmserie>
      </li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Abscent>0&&!permissionService.permissions.DetailWhenAbscent&&!permissionService.permissions.AbscentWhenBooked&&bookingdata.Status<60&&bookoption(3)">
        <swe-confirmserie (acceptChange)="savelist($event, 3)" [isSerie]="bookingdata.SerieId>0&&!permissionService.permissions.DetailWhenAbscent" [affectAllOnly]="bookingdata.AffectAllOnly">
          <i class="fas fa-fw fa-user"></i>&nbsp;{{languageService.getItem(283)}}
        </swe-confirmserie>
      </li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Email>0"><a (click)="send(1, bookingdata.SerieId, $event)"><i class="fas fa-fw fa-envelope"></i>&nbsp;{{languageService.getItem(321)}}</a></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Sms>0"><a (click)="send(3, bookingdata.SerieId, $event)"><i class="fas fa-fw fa-mobile-alt"></i>&nbsp;{{languageService.getItem(320)}}</a></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ReplySms"><a (click)="send(6, 0, $event)"><i class="fas fa-fw fa-mobile-alt"></i>&nbsp;{{languageService.getItem(178)}}</a></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ReplySms&&bookingdata.SerieId>0"><a (click)="send(6, bookingdata.SerieId, $event)"><i class="fas fa-fw fa-mobile-alt"></i>&nbsp;{{languageService.getItem(196)}}</a></li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiUser>0"><a (click)="action('multi', $event)"><i class="fas fa-fw fa-clone"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
    </ul>
  </div>
  <div class="btn-group ml-2">
    <!--Filter-->
    <button [attr.aria-label]="languageService.getItem(614)" class="btn btn-swe dropdown" (click)="availablefiltermenu=!availablefiltermenu" *ngIf="!listutility.ischecked">
      <span><i class="fas fa-fw fa-filter"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': availablefiltermenu && !listutility.ischecked}">
      <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 2)>=1"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(101)" name="availableoptions" [value]="0" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(101)}}</label></li>
      <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 4)>=2"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(171)" name="availableoptions" [value]="1" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(171)}}</label></li>
      <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 16)>=8"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(344)" name="availableoptions" [value]="3" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(344)}}</label></li>
      <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 8)>=4"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(172)" name="availableoptions" [value]="2" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(172)}}</label></li>
      <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 32)>=16"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(962)" name="availableoptions" [value]="4" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(962)}}</label></li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item swe-click" *ngIf="bookingdata.SerieId>0"><a (click)="affectserie=!affectserie;load();availablefiltermenu=false;"><i class="fas fa-fw" [ngClass]="{'fa-check': affectserie}"></i>{{languageService.getItem(802)}}</a></li>
      <li class="dropdown-item swe-click"><a (click)="exclude=!exclude;availablefiltermenu=false"><i class="fas fa-fw" [ngClass]="{'fa-check':exclude}"></i>{{languageService.getItem(683)}}</a></li>
    </ul>
  </div>
  <div class="btn-group ml-2">
    <!--Email Notification-->
    <button [attr.aria-label]="languageService.getItem(920)" [attr.title]="languageService.getItem(920)" class="btn btn-swe" (click)="checknotificationemail=!checknotificationemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
      <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationemail, 'fa-check-square': checknotificationemail}"></i>
      <i class="fas fa-fw fa-swe fa-envelope"></i>
    </button>
    <!--SMS Notification-->
    <button [attr.aria-label]="languageService.getItem(921)" [attr.title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationsms=!checknotificationsms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
      <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationsms, 'fa-check-square': checknotificationsms}"></i>
      <i class="fas fa-fw fa-swe fa-mobile"></i>
    </button>
  </div>
  <!--Reminder SMS-->
  <div class="btn-group ml-2" *ngIf="bookingdata.RemindAccess">
    <button [attr.aria-label]="languageService.getItem(538)" [attr.title]="languageService.getItem(538)" class="btn btn-swe" (click)="checkremind=!checkremind">
      <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checkremind, 'fa-check-square': checkremind}"></i>
      <i class="fas fa-fw fa-swe fa-comment"></i>
    </button>
    <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(538)" [(model)]="remind" [bottomMargin]="0"></swe-element>
  </div>
  <div class="btn-group ml-2" *ngIf="all">
    <button class="btn btn-danger" (click)="load(false, true)">{{languageService.getItem(340)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>

  <ng-container *ngIf="permissionService.permissions.AvailableProfile&&settingService.isDevice(['md','lg','xl'])">
    <!--Table-->
    <swe-table [data]="availableusers" [loading]="loading">
      <table class="table" *ngIf="availableusers.length>0">
        <thead>
          <tr>
            <td style="border-top:0; border-bottom:0;"></td>
            <td style="border-top:0; border-bottom:0;"></td>
            <th style="border-top:0; border-bottom:0;" class="swe-click" *ngFor="let header of availableusers[0].Profile" (click)="sort(header.Property)">
              {{propertyService.getProperty(header.Property).Name}}
              <i class="fas fa-caret-up" *ngIf="sortId==header.Property"></i>
              <i class="fas fa-caret-down" *ngIf="(sortId*-1)==header.Property"></i>
            </th>
            <td style="border-top:0; border-bottom:0;"></td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let user of availableusers;index as i">
            <tr class="swe-click" [ngClass]="{'list-group-item-info': user.checked, 'list-group-item-danger': user.Warning&&!user.checked, 'list-group-item-warning': user.Access==1&&!user.checked}" *ngIf="!exclude||!user.Warning" [attr.title]="user.WarningTexts">
              <td style="min-width:50px;" class="swe-click" [ngStyle]="{'cursor': user.MarkAccess>0 ? 'pointer':'not-allowed'}" (click)="user.MarkAccess>0&&listutility.toggle(user,$event)" (mouseover)="user.hover=true" (mouseout)="user.hover=false">
                <span class="fa-stack fa-swe-letter-circle-small" [ngClass]="{'d-none': !(user.checked||(user.MarkAccess>0&&user.hover))}">
                  <i class="fas fa-check-circle fa-stack-2x"></i>
                </span>
                <span class="fa-stack fa-swe-letter-circle-small" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length==0)}">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <strong class="fa-stack-1x fa-swe-letter">{{user.Firstname[0]}}</strong>
                </span>
                <span class="ml-1" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length>0)}">
                  <img class="swe-circle-image" alt="{{user.Firstname}} {{user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="user.Image" imageWidth="32" imageHeight="32">
                </span>
                <span class="align-middle">
                  <i class="far fa-comment fa-2x" *ngIf="user.HasSms" [attr.title]="languageService.getItem(205)"></i>
                </span>
              </td>
              <td>
                <span class="align-middle" *ngIf="bookingdata.Status<60 && !user.checked && user.Access>1">
                  <swe-confirmserie (acceptChange)="save($event,user,1)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
                    <a href="javascript:void(0);" class="swe-no-link swe-no-focus" (focus)="activerow=i" (blur)="activerow=-1" [attr.title]="languageService.getItem(281) + ' ' +  user.Firstname + ' ' + user.Lastname"><i class="fas fa-fw fa-2x fa-plus-circle fa-pull-right swe-click" [ngClass]="{'swe-grayed-out': user.hover2||(i==activerow)}" (mouseover)="user.hover2=true" (mouseout)="user.hover2=false"></i></a>
                  </swe-confirmserie>
                </span>
              </td>
              <td *ngFor="let profile of user.Profile" (click)="openuser(user, $event)" [ngSwitch]="profile.Type">
                <span *ngSwitchDefault>{{profile.FormatValue}}</span>
                <span *ngSwitchCase="'System.Boolean'"><i class="far fa-fw" [ngClass]="{'fa-check-square': profile.FormatValue, 'fa-square': !profile.FormatValue}"></i></span>
              </td>
              <td align="right" [attr.title]="user.PriorityTexts">
                <span *ngIf="user.Priority!=0">{{user.Priority}}</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </swe-table>
  </ng-container>
  <ng-container *ngIf="!permissionService.permissions.AvailableProfile||settingService.isDevice(['xs','sm'])">
    <!--List-->
    <swe-list [data]="availableusers" [loading]="loading">
      <ng-container *ngFor="let user of availableusers;index as i">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': user.checked, 'list-group-item-danger': user.Warning&&!user.checked, 'list-group-item-warning': user.Access==1&&!user.checked}" *ngIf="!exclude||!user.Warning">
          <div class="col-4 col-md-1" (click)="user.MarkAccess>0&&listutility.toggle(user,$event)" (mouseover)="settingService.isDevice(['md','lg','xl'])&&user.hover=true" (mouseout)="settingService.isDevice(['md','lg','xl'])&&user.hover=false">
            <span class="fa-stack fa-swe-letter-circle-small" [ngClass]="{'d-none': !(user.checked||(user.MarkAccess>0&&user.hover))}">
              <i class="fas fa-check-circle fa-stack-2x"></i>
            </span>
            <span class="fa-stack fa-swe-letter-circle-small" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length==0)}">
              <i class="fas fa-circle fa-stack-2x"></i>
              <span class="fa-stack-1x fa-swe-letter">{{user.Firstname[0]}}</span>
            </span>
            <span class="ml-1" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length>0)}">
              <img class="swe-circle-image" alt="{{user.Firstname}} {{user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="user.Image" imageWidth="32" imageHeight="32">
            </span>
            <span class="align-middle">
              <i class="far fa-comment fa-2x" *ngIf="user.HasSms" [attr.title]="languageService.getItem(205)"></i>
            </span>
          </div>
          <div class="col-2 col-md-1">
            <span class="align-middle" *ngIf="bookingdata.Status<60 && !user.checked && user.Access>1">
              <swe-confirmserie (acceptChange)="save($event,user,1)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
                <a href="javascript:void(0);" class="swe-no-link swe-no-focus" (focus)="activerow=i" (blur)="activerow=-1" [attr.title]="languageService.getItem(281) + ' ' +  user.Firstname + ' ' + user.Lastname"><i class="fas fa-fw fa-2x fa-plus-circle fa-pull-right swe-click" [ngClass]="{'swe-grayed-out': user.hover2||(activerow==i)}" (mouseover)="user.hover2=true" (mouseout)="user.hover2=false"></i></a>
              </swe-confirmserie>
            </span>
          </div>
          <div class="col-6 col-md-10 swe-click" (click)="openuser(user, $event)">
            {{user.Firstname}} {{user.Lastname}}
          </div>
        </a>
      </ng-container>
      </swe-list>
  </ng-container>
  <button class="btn btn-block btn-primary" *ngIf="more" (click)="load(true)">{{languageService.getItem(57)}}</button>
</swe-card>
