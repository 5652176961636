<swe-pageheader [header]="languageService.getItem(478)">
  <span swe-hits>
    &nbsp;(#{{id}})
    <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="goto(0, category)" *ngIf="id>0"></i>
  </span>
</swe-pageheader>
<div class="row d-block py-2">
  <!--General data-->
  <div class="col-12 col-md-4 float-left">
    <swe-card [header]="languageService.getItem(479)" [open]="true" [hasRefresh]="false">
      <div *ngIf="permissionService.user.IsSuper">
        <swe-element [type]="'System.List'" [label]="languageService.getItem(517)" [statusLabel]="2" [(model)]="property.Category" [items]="categories"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[0]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[0], 'fa-square': !publicaccess[0]}" (click)="publicaccess[0]=!publicaccess[0]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(480)" [statusLabel]="2" [(model)]="property.Name"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[8]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[8], 'fa-square': !publicaccess[8]}" (click)="publicaccess[8]=!publicaccess[8]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Int32'" [label]="languageService.getItem(490)" [statusLabel]="2" [(model)]="property.Sort"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[25]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[25], 'fa-square': !publicaccess[25]}" (click)="publicaccess[25]=!publicaccess[25]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.TextArea'" [label]="languageService.getItem(481)" [statusLabel]="2" [(model)]="property.Comment"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[1]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[1], 'fa-square': !publicaccess[1]}" (click)="publicaccess[1]=!publicaccess[1]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(482)" [statusLabel]="2" [(model)]="property.Description"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[2]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[2], 'fa-square': !publicaccess[2]}" (click)="publicaccess[2]=!publicaccess[2]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(483)" [statusLabel]="2" [(model)]="property.DataType" [items]="datatypes"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[3]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[3], 'fa-square': !publicaccess[3]}" (click)="publicaccess[3]=!publicaccess[3]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Int32'" [label]="languageService.getItem(484)" [statusLabel]="2" [(model)]="property.Length"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[4]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[4], 'fa-square': !publicaccess[4]}" (click)="publicaccess[4]=!publicaccess[4]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(485)" [statusLabel]="2" [(model)]="property.Default"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[5]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[5], 'fa-square': !publicaccess[5]}" (click)="publicaccess[5]=!publicaccess[5]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(486)" [statusLabel]="2" [(model)]="property.RegExp"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[6]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[6], 'fa-square': !publicaccess[6]}" (click)="publicaccess[6]=!publicaccess[6]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(487)" [statusLabel]="2" [(model)]="property.Format"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[7]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[7], 'fa-square': !publicaccess[7]}" (click)="publicaccess[7]=!publicaccess[7]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(488)" [statusLabel]="2" [(model)]="property.Style"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[23]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[23], 'fa-square': !publicaccess[23]}" (click)="publicaccess[23]=!publicaccess[23]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(489)" [statusLabel]="2" [(model)]="property.Attr"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[10]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[10], 'fa-square': !publicaccess[10]}" (click)="publicaccess[10]=!publicaccess[10]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.List'" [label]="languageService.getItem(491)" [statusLabel]="2" [(model)]="property.Group" [items]="groups" [optional]="true"></swe-element>
      </div>
      <div>
        <button class="btn btn-primary btn-block mb-2" (click)="save()" *ngIf="(property.Public>0||permissionService.user.IsSuper)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <swe-confirm *ngIf="id>0&&(property.Public>0||permissionService.user.IsSuper)" [body]="languageService.getItem(1043)" (acceptChange)="delete()">
          <button class="btn btn-danger btn-block"><i class="fas fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <!--Advanced-->
  <div class="col-12 col-md-4 float-left">
    <swe-card [header]="languageService.getItem(492)" [open]="true" [hasRefresh]="false">
      <div *ngIf="permissionService.user.IsSuper||publicaccess[11]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[11], 'fa-square': !publicaccess[11]}" (click)="publicaccess[11]=!publicaccess[11]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(493)" [statusLabel]="2" [(model)]="property.Optional"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[12]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[12], 'fa-square': !publicaccess[12]}" (click)="publicaccess[12]=!publicaccess[12]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(494)" [statusLabel]="2" [(model)]="property.Secret"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[13]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[13], 'fa-square': !publicaccess[13]}" (click)="publicaccess[13]=!publicaccess[13]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(495)" [statusLabel]="2" [(model)]="property.Unique"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[24]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[24], 'fa-square': !publicaccess[24]}" (click)="publicaccess[24]=!publicaccess[24]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(496)" [statusLabel]="2" [(model)]="property.Multiline"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[14]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[14], 'fa-square': !publicaccess[14]}" (click)="publicaccess[14]=!publicaccess[14]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(497)" [statusLabel]="2" [(model)]="property.Track"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[26]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[26], 'fa-square': !publicaccess[26]}" (click)="publicaccess[26]=!publicaccess[26]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(295)" [statusLabel]="2" [(model)]="property.Notification"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper">
        <swe-element [type]="'System.List'" [label]="languageService.getItem(498)" [statusLabel]="2" [(model)]="property.Core" [items]="corevalues"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[15]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[15], 'fa-square': !publicaccess[15]}" (click)="publicaccess[15]=!publicaccess[15]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(499)" [statusLabel]="2" [(model)]="property.VisibleRef"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[16]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[16], 'fa-square': !publicaccess[16]}" (click)="publicaccess[16]=!publicaccess[16]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(500)" [statusLabel]="2" [(model)]="property.VisibleVal"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[17]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[17], 'fa-square': !publicaccess[17]}" (click)="publicaccess[17]=!publicaccess[17]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(501)" [statusLabel]="2" [(model)]="property.ValueRef"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[18]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[18], 'fa-square': !publicaccess[18]}" (click)="publicaccess[18]=!publicaccess[18]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(502)" [statusLabel]="2" [(model)]="property.ValueVal"></swe-element>
      </div>
      <div *ngIf="permissionService.user.IsSuper||publicaccess[19]">
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[19], 'fa-square': !publicaccess[19]}" (click)="publicaccess[19]=!publicaccess[19]" *ngIf="permissionService.user.IsSuper"></i>
        <swe-element [type]="'System.String'" [label]="languageService.getItem(503)" [statusLabel]="2" [(model)]="property.Calc"></swe-element>
      </div>
      <swe-card [header]="languageService.getItem(504)" [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.user.IsSuper||publicaccess[22]">
        <span swe-rightcommand>
          <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[22], 'fa-square': !publicaccess[22]}" (click)="publicaccess[22]=!publicaccess[22]" *ngIf="permissionService.user.IsSuper"></i>
        </span>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(505)" [statusLabel]="2" [(model)]="property.Identity"></swe-element>
        <swe-element [type]="'System.Int32'" [label]="languageService.getItem(506)" [statusLabel]="2" [(model)]="property.IdentityStart"></swe-element>
        <swe-element [type]="'System.Int32'" [label]="languageService.getItem(507)" [statusLabel]="2" [(model)]="property.IdentitySeed"></swe-element>
      </swe-card>
      <div>
        <button class="btn btn-primary btn-block mb-2" (click)="save()" *ngIf="(property.Public>0||permissionService.user.IsSuper)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <swe-confirm *ngIf="id>0&&(property.Public>0||permissionService.user.IsSuper)" [body]="languageService.getItem(1043)" (acceptChange)="delete()">
          <button class="btn btn-danger btn-block"><i class="fas fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <!--Multiple choice-->
  <div class="col-12 float-left" *ngIf="permissionService.user.IsSuper||publicaccess[21]">
    <swe-card [header]="languageService.getItem(508)" [open]="false" [hasRefresh]="false">
      <span swe-rightcommand>
        <i class="far fa-fw fa-2x fa-pull-right swe-click" [ngClass]="{'fa-check-square': publicaccess[21], 'fa-square': !publicaccess[21]}" (click)="publicaccess[21]=!publicaccess[21]" *ngIf="permissionService.user.IsSuper"></i>
      </span>
      <div class="row py-2">
        <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let item of property.Items" [ngClass]="{'list-group-item-danger': item.IsDeleted}">
          <div class="col-6 col-md-3"><div class="list-group-item-heading">{{languageService.getItem(509)}}</div><swe-element [type]="'System.String'" [label]="languageService.getItem(509)" [statusLabel]="0" [(model)]="item.Name"></swe-element></div>
          <div class="col-6 col-md-2"><div class="list-group-item-heading">{{languageService.getItem(510)}}</div><swe-element [type]="'System.Int32'" [label]="languageService.getItem(510)" [statusLabel]="0" [(model)]="item.Value"></swe-element></div>
          <div class="col-6 col-md-2"><div class="list-group-item-heading">{{languageService.getItem(970)}}</div><swe-element [type]="'System.String'" [label]="languageService.getItem(970)" [statusLabel]="0" [(model)]="item.ExternalId"></swe-element></div>
          <div class="col-6 col-md-1"><div class="list-group-item-heading">{{languageService.getItem(511)}}</div><swe-element [type]="'System.Int32'" [label]="languageService.getItem(511)" [statusLabel]="0" [(model)]="item.Sort"></swe-element></div>
          <div class="col-6 col-md-1"><div class="list-group-item-heading">{{languageService.getItem(512)}}</div><swe-element [type]="'System.String'" [label]="languageService.getItem(512)" [statusLabel]="0" [(model)]="item.Image"></swe-element></div>
          <div class="col-6 col-md-2"><div class="list-group-item-heading">{{languageService.getItem(513)}}</div><swe-element [type]="'System.String'" [label]="languageService.getItem(513)" [statusLabel]="0" [(model)]="item.Dependency"></swe-element></div>
          <div class="col-6 col-md-1"><div class="list-group-item-heading">({{item.Id}})</div><i class="fas fa-fw fa-swe fa-trash swe-click" (click)="remove(item)" *ngIf="!item.IsDeleted"></i><i class="fas fa-fw fa-swe fa-undo swe-click" (click)="restore(item)" *ngIf="item.IsDeleted"></i></div>
        </div>
        <div class="swe-click" (click)="add()"><i class="fas fa-plus-circle"></i>&nbsp;{{languageService.getItem(100)}}</div>
      </div>
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(514)" [statusLabel]="2" [(model)]="property.Multiple"></swe-element>
      <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(515)" [statusLabel]="2" [(model)]="property.Images"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="languageService.getItem(516)" [statusLabel]="2" [(model)]="property.ItemsRef"></swe-element>
      <div>
        <button class="btn btn-primary btn-block mb-2" (click)="save()" *ngIf="(property.Public>0||permissionService.user.IsSuper)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <swe-confirm *ngIf="id>0&&(property.Public>0||permissionService.user.IsSuper)" [body]="languageService.getItem(1043)" (acceptChange)="delete()">
          <button class="btn btn-danger btn-block"><i class="fas fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
  <!--Validate Formula-->
  <div class="col-12 float-left" *ngIf="showformula()">
    <swe-card [header]="languageService.getItem(863)" [open]="false" [hasRefresh]="false">
      <swe-element [type]="'System.String'" [label]="languageService.getItem(864)" [statusLabel]="2" [(model)]="formula" (modelChange)="showformulavalues($event)"></swe-element>
      <div class="form-group" *ngFor="let item of formulavalues">
        <swe-element [type]="'System.String'" [label]="item.Key" [statusLabel]="2" [(model)]="item.Value"></swe-element>
      </div>
      <button class="btn btn-primary btn-block" (click)="calculate()"><i class="fas fa-calculator"></i>&nbsp;{{languageService.getItem(865)}}</button>
      <div class="form-group mt-3">
        <div class="alert alert-success" *ngIf="formularesult.length>0">
          <h4>{{languageService.getItem(866)}}</h4>
          <span>{{formularesult}}</span>
        </div>
        <div class="alert alert-danger" *ngIf="formulaerror.length>0">
          <h4>Fel</h4>
          <span>{{formulaerror}}</span>
        </div>
      </div>
      <div class="form-group mt-3 d-flex flex-wrap">
        <!--Operators-->
        <div class="col-12 col-md-4">
          <div class="alert alert-info">
            <h4>{{languageService.getItem(867)}}</h4>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">+</div>
              <div class="col-9 col-md-11">= Plus</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">-</div>
              <div class="col-9 col-md-11">= Minus</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">*</div>
              <div class="col-9 col-md-11">= Times</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">/</div>
              <div class="col-9 col-md-11">= Divide</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">^</div>
              <div class="col-9 col-md-11">= Roof (to the power of, ex. 2^3 = 8)</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">?</div>
              <div class="col-9 col-md-11">= If</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">:</div>
              <div class="col-9 col-md-11">= Else</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">></div>
              <div class="col-9 col-md-11">= Greater than</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1"><</div>
              <div class="col-9 col-md-11">= Less than</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">=</div>
              <div class="col-9 col-md-11">= Equal</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">!</div>
              <div class="col-9 col-md-11">= Not equal</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">~</div>
              <div class="col-9 col-md-11">= NullInvert (if value then Null else value)</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">#</div>
              <div class="col-9 col-md-11">= DateTime format</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">£</div>
              <div class="col-9 col-md-11">= TimeSpan format</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">%</div>
              <div class="col-9 col-md-11">= Contains</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">&#123;</div>
              <div class="col-9 col-md-11">= Starts with</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">&#125;</div>
              <div class="col-9 col-md-11">= Ends with</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">|</div>
              <div class="col-9 col-md-11">= Or</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">&</div>
              <div class="col-9 col-md-11">= And</div>
            </div>
          </div>
        </div>
        <!--Operands-->
        <div class="col-12 col-md-4">
          <div class="alert alert-info">
            <h4>{{languageService.getItem(868)}}</h4>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">´</div>
              <div class="col-9 col-md-11">= Empty string</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-3 col-md-1">¤</div>
              <div class="col-9 col-md-11"> = Null</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="alert alert-info">
            <h4>{{languageService.getItem(869)}}</h4>
            <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(870)" [statusLabel]="2" [(model)]="datetime" (modelChange)="manageDateTime($event)"></swe-element>
            <div class="form-group">
              <div class="alert alert-success" *ngIf="datetimeresult>0">
                <span>{{datetimeresult}}</span>
              </div>
            </div>
            <swe-element [bottomMargin]="0" [type]="'System.List'" [label]="languageService.getItem(621)" [statusLabel]="2" [(model)]="timeinterval" (modelChange)="manageTimeInterval($event)" [items]="timeintervals" [optional]="true"></swe-element>
            <swe-element [type]="'System.List'" [label]="languageService.getItem(621)" [statusLabel]="0" [(model)]="timeunit" (modelChange)="manageTimeInterval($event)" [items]="timeunits"></swe-element>
            <div class="form-group">
              <div class="alert alert-success" *ngIf="timeunitresult>0">
                <span>{{timeunitresult}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </swe-card>
  </div>
</div>
