import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { MenuService } from '../_services/menu.service';
import { AuthenticationService } from '../_services/authentication.service';
import { PermissionService } from '../_services/permission.service';
import { SettingService } from '../_services/setting.service';
import { LanguageService } from '../_services/language.service';
import { AlertService } from '../_services/alert.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html'
})
export class TopMenuComponent implements OnDestroy {

  @ViewChild('sweFocusElement', { static: false }) focusElement: ElementRef;
  @ViewChild('sweFocusElement2', { static: false }) focusElement2: ElementRef;

  private unsubscribe$: any[] = [
    new Subject<void>(),
    new Subject<void>(),
    new Subject<void>()
  ];
  private _profilemenu: boolean = false;
  private _impersonatemenu: boolean = false;
  private _pincode: string = '';
  private _pinfocus: boolean = false;
  private _historymenu: boolean = false;
  private _historynew: boolean = false;

  constructor(
    public menuService: MenuService,
    public authenticationService: AuthenticationService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    public languageService: LanguageService,
    public alertService: AlertService,
    private router: Router
  ) {

    menuService.onMenuChange$
      .pipe(takeUntil(this.unsubscribe$[0]))
      .subscribe((refresh) => {

        if (!refresh) {
          setTimeout(() => {
            if (this.focusElement) {
              let element = this.focusElement.nativeElement;
              element.focus();
            }
          }, 0); //Create a macrotask that will run in the next VM turn
        }

      });

    menuService.onSearchChange$
      .pipe(takeUntil(this.unsubscribe$[1]))
      .subscribe((refresh) => {

        if (!refresh) {
          setTimeout(() => {
            if (this.focusElement2) {
              let element = this.focusElement2.nativeElement;
              element.focus();
            }
          }, 0); //Create a macrotask that will run in the next VM turn
        }

      });

    alertService.onNewHistory$
      .pipe(takeUntil(this.unsubscribe$[2]))
      .subscribe((e) => {
        this._historynew = true;
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.forEach((unsubscribe) => {
      unsubscribe.next();
      unsubscribe.complete();
    });
  }


  //Properties
  public get profilemenu() {
    return this._profilemenu;
  }
  public set profilemenu(val) {
    this._profilemenu = val;
  }
  public get impersonatemenu() {
    return this._impersonatemenu;
  }
  public set impersonatemenu(val) {
    this._impersonatemenu = val;
  }
  public get pincode() {
    return this._pincode;
  }
  public set pincode(val) {
    this._pincode = val;
  }
  public get pinfocus() {
    return this._pinfocus;
  }
  public set pinfocus(val) {
    this._pinfocus = val;
  }
  public get historymenu() {
    return this._historymenu;
  }
  public set historymenu(val) {
    this._historymenu = val;
  }
  public get historynew() {
    return this._historynew;
  }


  //Methods
  public toggle() {
    this._profilemenu = false;
    this._impersonatemenu = !this._impersonatemenu;
    this._pinfocus = this._impersonatemenu;
  }
  public goto(url) {
    this.router.navigateByUrl(url);
    this._profilemenu = false;
  }
  public keyup(e) {
    if (e.keyCode == 13) {
      this.impersonate();
      this._pinfocus = false;
    }
  }
  public impersonate() {

    this.authenticationService.impersonate(this.permissionService.isimpersonate(), this._pincode)
      .subscribe((res) => {

        setTimeout(() => {
          this.alertService.Add({ type: 'success', message: this.languageService.getItem(317) + ': ' + this.permissionService.user.Firstname + ' ' + this.permissionService.user.Lastname });

          let currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        }, 1000);
      });

    this._pincode = '';
    this._impersonatemenu = false;
    this._historymenu = false;
  }
  public toggleHistory() {
    this._profilemenu = false;
    this._impersonatemenu = false;
    this._pinfocus = this._impersonatemenu;
    this._historymenu = !this._historymenu;
    this._historynew = false;
  }
  public manageHistory(alert) {
    this.alertService.PopHistory(alert); 
    if (this.alertService.History.length == 0) {
      this._historymenu = false;
    }
  }
}
