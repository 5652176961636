/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../element/confirm.component.ngfactory";
import * as i2 from "../../element/confirm.component";
import * as i3 from "../../_services/language.service";
import * as i4 from "../../layout/card.component.ngfactory";
import * as i5 from "../../layout/card.component";
import * as i6 from "../../layout/element.component.ngfactory";
import * as i7 from "../../layout/element.component";
import * as i8 from "../../_services/authentication.service";
import * as i9 from "../../_services/permission.service";
import * as i10 from "../../_services/property.service";
import * as i11 from "../../_services/datetime.service";
import * as i12 from "../../_services/list.service";
import * as i13 from "@angular/common";
import * as i14 from "../../layout/pageheader.component.ngfactory";
import * as i15 from "../../layout/pageheader.component";
import * as i16 from "@angular/router";
import * as i17 from "./holidaydetail.component";
import * as i18 from "../../_services/data.service";
import * as i19 from "../../_services/alert.service";
import * as i20 from "../../_services/setting.service";
var styles_HolidayDetailComponent = [];
var RenderType_HolidayDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HolidayDetailComponent, data: {} });
export { RenderType_HolidayDetailComponent as RenderType_HolidayDetailComponent };
function View_HolidayDetailComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-save"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["\u00A0", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(16); _ck(_v, 2, 0, currVal_0); }); }
function View_HolidayDetailComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "swe-confirm", [], null, [[null, "acceptChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("acceptChange" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ConfirmComponent_0, i1.RenderType_ConfirmComponent)), i0.ɵdid(1, 638976, null, 0, i2.ConfirmComponent, [i3.LanguageService], { body: [0, "body"] }, { acceptChange: "acceptChange" }), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "button", [["class", "btn btn-danger btn-block mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-fw fa-trash"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["\u00A0", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(717); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.languageService.getItem(17); _ck(_v, 4, 0, currVal_1); }); }
function View_HolidayDetailComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "col-12 col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "swe-card", [], null, null, null, i4.View_CardComponent_0, i4.RenderType_CardComponent)), i0.ɵdid(3, 114688, null, 0, i5.CardComponent, [i3.LanguageService], { header: [0, "header"], hasCollapse: [1, "hasCollapse"], hasRefresh: [2, "hasRefresh"] }, null), (_l()(), i0.ɵeld(4, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.holiday.Date = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ElementComponent_0, i6.RenderType_ElementComponent)), i0.ɵdid(5, 638976, null, 0, i7.ElementComponent, [i8.AuthenticationService, i9.PermissionService, i10.PropertyService, i11.DateTimeService, i12.ListService, i3.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵeld(6, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.holiday.Name = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ElementComponent_0, i6.RenderType_ElementComponent)), i0.ɵdid(7, 638976, null, 0, i7.ElementComponent, [i8.AuthenticationService, i9.PermissionService, i10.PropertyService, i11.DateTimeService, i12.ListService, i3.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵeld(8, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.holiday.Desc = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ElementComponent_0, i6.RenderType_ElementComponent)), i0.ɵdid(9, 638976, null, 0, i7.ElementComponent, [i8.AuthenticationService, i9.PermissionService, i10.PropertyService, i11.DateTimeService, i12.ListService, i3.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_HolidayDetailComponent_2)), i0.ɵdid(11, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_HolidayDetailComponent_3)), i0.ɵdid(13, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(713); var currVal_1 = false; var currVal_2 = false; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.languageService.getItem(714); var currVal_4 = 1; var currVal_5 = "System.Date"; var currVal_6 = _co.holiday.Date; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.languageService.getItem(715); var currVal_8 = 1; var currVal_9 = "System.String"; var currVal_10 = _co.holiday.Name; _ck(_v, 7, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.languageService.getItem(716); var currVal_12 = 1; var currVal_13 = "System.String"; var currVal_14 = _co.holiday.Desc; _ck(_v, 9, 0, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = (_co.permissionService.permissions.Holiday > 1); _ck(_v, 11, 0, currVal_15); var currVal_16 = ((_co.holiday.Id > 0) && (_co.permissionService.permissions.Holiday > 2)); _ck(_v, 13, 0, currVal_16); }, null); }
export function View_HolidayDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "swe-pageheader", [], null, null, null, i14.View_PageHeaderComponent_0, i14.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i15.PageHeaderComponent, [i13.Location, i16.ActivatedRoute, i16.Router], { header: [0, "header"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["swe-hits", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" \u00A0(#", ") "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HolidayDetailComponent_1)), i0.ɵdid(5, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(712); _ck(_v, 1, 0, currVal_0); var currVal_2 = (_co.permissionService.permissions.Holiday > 0); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.id; _ck(_v, 3, 0, currVal_1); }); }
export function View_HolidayDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-holidaydetail", [], null, null, null, View_HolidayDetailComponent_0, RenderType_HolidayDetailComponent)), i0.ɵdid(1, 114688, null, 0, i17.HolidayDetailComponent, [i3.LanguageService, i9.PermissionService, i18.DataService, i19.AlertService, i16.Router, i16.ActivatedRoute, i13.Location, i20.SettingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HolidayDetailComponentNgFactory = i0.ɵccf("app-holidaydetail", i17.HolidayDetailComponent, View_HolidayDetailComponent_Host_0, {}, {}, []);
export { HolidayDetailComponentNgFactory as HolidayDetailComponentNgFactory };
