import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { ListService } from '../../_services/list.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { SettingService } from '../../_services/setting.service';


@Component({
  selector: 'app-adminproperties',
  templateUrl: './adminproperties.component.html'
})
export class AdminPropertiesComponent implements OnInit {

  private _groupedby: any = {};
  private _properties: any[] = [];
  private _loading: boolean = false;


  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private router: Router,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {

    this.init();

  }



  //Properties
  public get groupedby() {
    return this._groupedby;
  }
  public get properties() {
    return this._properties;
  }
  public get isloading() {
    return this._loading;
  }



  //Methods
  public open(e, id: number, category: number = 0) {

    if (id > 0) {
      let opencards = 0;
      let index = 0;
      Object.values(this._groupedby).forEach((item) => {
        if (item['open']) {
          opencards += Math.pow(2, index);
        }
        index++;
      });

      this.viewCacheService.add('admin_properies_opencards', opencards);
    }

    let url = '/admin/properties/' + id;
    if (category > 0) {
      url += '/category/' + category;
    }

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }



  //Functions
  private init() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/properties/type/edit', 'GET', {})
      .subscribe((res) => {

        this._properties = res;
        this._groupedby = this.listService.groupByInt(res, 'CatTypeId', undefined, 'Category');

        this._loading = false;

        let opencards: any = this.viewCacheService.get('admin_properies_opencards');
        if (opencards != null) {
          let index = 0;
          Object.values(this._groupedby).forEach((item) => {
            let bit = Math.pow(2, index);
            if ((opencards.value & bit) == bit) {
              item['open'] = true;
            }
            index++;
          });
        }
        
      });
  }
}
