import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';


@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html'
})
export class IntegrationsComponent implements OnInit {

  private _integrations: any[] = [];
  private _loading: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {

    this.load();

  }



  //Properties
  public get integrations() {
    return this._integrations;
  }
  public get isloading() {
    return this._loading;
  }




  //Methods
  public load() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/general/integrations', 'GET', {})
      .subscribe((res) => {

        this._integrations = res;

        this._loading = false;
      });
  }
  public run(integration) {

    this.dataService.tokenRequest('/api/v1/general/integrations/' + integration.Id, 'PUT', {}, 'text')
      .subscribe((res) => {

        this.load();

        this.alertService.Add({ type: 'success', message: this.languageService.getItem(880).replace('{0}', integration.Name) });
      });
  }



}
