<swe-pageheader [header]="languageService.getItem(108)"><span swe-hits>&nbsp;(#{{id}})</span></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-left">
    <swe-leveldata [id]="id" [open]="cards.data" (leveldataChange)="manageLeveldata($event)"></swe-leveldata>
  </div>
  <div class="col-12 col-md-8 float-left">
    <swe-levelsettings [id]="id" [(open)]="cards.settings" [leveldata]="leveldata" *ngIf="settingaccess>0"></swe-levelsettings>
  </div>
  <div class="col-12 col-md-8 float-left">
    <swe-levelrelations [id]="id" [(open)]="cards.relations" [leveldata]="leveldata" *ngIf="id>0&&relationaccess>0"></swe-levelrelations>
  </div>
  <div class="col-12 col-md-8 float-left">
    <swe-levelusers [id]="id" [(open)]="cards.users" *ngIf="id>0&&useraccess>0"></swe-levelusers>
  </div>
  <div class="col-12 col-md-4 float-left">
    <swe-levelbookings [id]="id" [(open)]="cards.bookings" *ngIf="id>0&&bookingaccess>0"></swe-levelbookings>
  </div>
  <ng-container *ngFor="let obj of objecttoobjects">
    <div class="col-12 col-md-8 float-left" *ngIf="id>0">
      <swe-objecttoobjects [id]="id" [data]="obj" [open]="false"></swe-objecttoobjects>
    </div>
  </ng-container>
  <div class="col-12 col-md-8 float-left">
    <swe-generallogs [id]="id" [(open)]="cards.systemlogs" [type]="'level'" [data]="leveldata" *ngIf="id>0&&logaccess>0"></swe-generallogs>
  </div>
</div>
