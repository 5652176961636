import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class MenuService {
    constructor() {
        //Events
        this.onMenuChange$ = new EventEmitter();
        this.onSearchChange$ = new EventEmitter();
        /*Menu********************/
        this.showMenu = false;
        /*************************/
        /*Show********************/
        this.showSearch = false;
    }
    openMenu() {
        this.showMenu = true;
        this.onMenuChange$.emit(this.showMenu);
    }
    toggleMenu() {
        this.showMenu = !this.showMenu;
        this.onMenuChange$.emit(this.showMenu);
    }
    closeMenu() {
        this.showMenu = false;
        this.onMenuChange$.emit(this.showMenu);
    }
    get ShowMenu() {
        return this.showMenu;
    }
    openSearch() {
        this.showSearch = true;
        this.onSearchChange$.emit(this.showSearch);
    }
    toggleSearch() {
        this.showSearch = !this.showSearch;
        this.onSearchChange$.emit(this.showSearch);
    }
    closeSearch() {
        this.showSearch = false;
        this.onSearchChange$.emit(this.showSearch);
    }
    get ShowSearch() {
        return this.showSearch;
    }
}
MenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuService_Factory() { return new MenuService(); }, token: MenuService, providedIn: "root" });
