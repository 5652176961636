import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'swe-pageheader',
  templateUrl: 'pageheader.component.html',
})
export class PageHeaderComponent {
  @Input() header: string = '';

  private _useHistory: boolean = false;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.data.subscribe((val) => {
      this._useHistory = val.useHistory;
    });
  }

  /*Properties*/
  public get useHistory() {
    let hashistory = true;
    let state:any = this.location.getState();
    if (state) { hashistory = (state.navigationId > 1); }

    return this._useHistory && hashistory;
  }
  
  /*Methods*/
  public back() {
    this.location.back();
  }
}
