<swe-card header="{{title}}" [(open)]="open" (refreshChange)="search(false)" (openChange)="search(false)">
  <span swe-hits *ngIf="sum>0&&!employmentplan">({{generalService.formatdecimal(sum)}}h)</span>
  <span swe-rightcommand><i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="goto(0,$event)"></i></span>
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <div class="btn-group ml-1 align-top" *ngIf="more">
    <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>
  <div class="list-group">
    <a class="list-group-item list-group-item-action d-flex swe-click" [ngClass]="{'list-group-item-warning': booking.Id==settingService.lastbooking}" *ngFor="let booking of bookings" (click)="goto(booking.Id,$event)">
      <div class="col-6 col-md-2">
        <i class="fas fa-2x fa-check-circle" *ngIf="booking.checked"></i>
        <i class="fas fa-border swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status>10" [attr.title]="booking.StatusText" [ngClass]="{'fa-share-square': booking.Status==20,'fa-key': booking.Status==30,'fa-clock': booking.Status==40,'fa-check': booking.Status==50,'fa-lock': booking.Status==60,'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
        <i class="far fa-border fa-square swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status==10" [attr.title]="booking.StatusText" [ngClass]="{'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
        <i class="fas fa-border fa-link" *ngIf="!booking.checked&&booking.Serie>0&&!employmentplan" [attr.title]="languageService.getItem(203)"></i>
        <i class="fas fa-border fa-list-ul" *ngIf="!booking.checked&&booking.HasActivities>0&&!employmentplan" [attr.title]="languageService.getItem(741)"></i>
        <i class="fas fa-border fa-shield-alt" *ngIf="!booking.checked&&booking.EmploymentPlan" [attr.title]="languageService.getItem(698)"></i>
        <i class="fas fa-border fa-comment" *ngIf="!booking.checked&&booking.Sms&&!employmentplan" [attr.title]="languageService.getItem(205)"></i>
        <i class="fas fa-border fa-eye" *ngIf="!booking.checked&&booking.IsWatching&&!employmentplan" [attr.title]="languageService.getItem(339)"></i>
        <i class="fas fa-border fa-retweet" *ngIf="!booking.checked&&booking.HasReplaceable&&!employmentplan" [attr.title]="languageService.getItem(423)"></i>
        <i class="fas fa-border fa-street-view" *ngIf="!booking.checked&&booking.HasStandby&&!employmentplan" [attr.title]="languageService.getItem(279)"></i>
        <i class="fas fa-border fa-ban" *ngIf="!booking.checked&&booking.HasAbscent&&!employmentplan" [attr.title]="languageService.getItem(280)"></i>
      </div>
      <div class="col-6 col-md-10 d-flex flex-wrap">
        <div class="col-12 col-md-6">{{booking.dateheader}}</div>
        <div class="col-12 col-md-6">{{booking.Header}}</div>
      </div>
    </a>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
</swe-card>
