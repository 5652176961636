<div class="list-group-item" [ngClass]="{'d-flex': settingService.timeline.groupby != -3}" *ngIf="settingService.timeline.showgraph">
  <div [ngClass]="{'col-2 col-md-2': settingService.timeline.groupby != -3,'col-12': settingService.timeline.groupby == -3}" *ngIf="settingService.timeline.groupby != -3">
    <div *ngIf="templates&&templates.length>0&&showBookingTemplate()">
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(1009)" [statusLabel]="2" [(model)]="settingService.timeline.templateIdList" (modelChange)="calculateTemplate()" [items]="templates"></swe-element>
      <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(6)" [statusLabel]="2" [(model)]="settingIdList" (modelChange)="calculateTemplate()" [items]="templatesettings" *ngIf="templateIdList&&templateIdList.length>0"></swe-element>
    </div>
    &nbsp;
  </div>
  <div [ngClass]="{'col-10 col-md-10': settingService.timeline.groupby != -3,'col-12': settingService.timeline.groupby == -3}">
    <div class="radio">
      <label>
        <input type="radio" name="sumoptions" [value]="0" [(ngModel)]="settingService.timeline.sumoptions" [attr.aria-label]="languageService.getItem(722)" (change)="changeSumOptions()" />
        {{languageService.getItem(722)}}
      </label>
      &nbsp;
      <label>
        <input type="radio" name="sumoptions" [value]="1" [(ngModel)]="settingService.timeline.sumoptions" [attr.aria-label]="languageService.getItem(794)" (change)="changeSumOptions()" />
        {{languageService.getItem(794)}}
      </label>
      &nbsp;
      <label>
        <input type="radio" name="sumoptions" [value]="2" [(ngModel)]="settingService.timeline.sumoptions" [attr.aria-label]="languageService.getItem(795)" (change)="changeSumOptions()" />
        {{languageService.getItem(795)}}
      </label>
    </div>
    <canvas baseChart
            [datasets]="chartdata"
            [labels]="chartlabels"
            [options]="chartoptions"
            [legend]="chartlegend"
            [chartType]="charttype"
            height="50" *ngIf="chartdata.length>0">
    </canvas>
  </div>
</div>
<div class="p-0" [index]="2" sweFixedHeader>
  <div class="list-group-item px-0" [ngClass]="{'d-flex': settingService.timeline.groupby != -3}" *ngIf="settings">
    <!--Resource-->
    <div [ngClass]="{'col-2 col-md-4 col-lg-3 col-xl-2': settingService.timeline.groupby != -3,'col-12': settingService.timeline.groupby == -3}">
      <div class="btn-group align-top" *ngIf="permissionService.permissions.GroupByLevel||(permissionService.permissions.GroupBy&&permissionService.permissions.GroupBy.length>0)">
        <button class="btn btn-swe dropdown" (click)="toggle()">
          <span class="d-none d-md-inline text-nowrap">{{groupbyname}}&nbsp;</span>
          <span><i class="fas fa-caret-down"></i></span>
        </button>
        <ul class="dropdown-menu" [ngClass]="{'show': groupbymenu}">
          <li class="dropdown-item swe-click"><a (click)="change(-3)"><i class="fas fa-fw" [ngClass]="{'fa-check':settingService.timeline.groupby==-3}"></i>{{languageService.getItem(846)}}</a></li>
          <li class="dropdown-item swe-click"><a (click)="change(0)"><i class="fas fa-fw" [ngClass]="{'fa-check':settingService.timeline.groupby==0}"></i>{{languageService.getItem(2)}}</a></li>
          <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.GroupByLevel"><a (click)="change(-1)"><i class="fas fa-fw" [ngClass]="{'fa-check':settingService.timeline.groupby==-1}"></i>{{languageService.getItem(3)}}</a></li>
          <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.GroupByLevel"><a (click)="change(-2)"><i class="fas fa-fw" [ngClass]="{'fa-check':settingService.timeline.groupby==-2}"></i>{{languageService.getItem(793)}}</a></li>
          <li class="dropdown-item swe-click" *ngFor="let groupby of permissionService.permissions.GroupBy"><a (click)="change(groupby)"><i class="fas fa-fw" [ngClass]="{'fa-check':settingService.timeline.groupby==groupby}"></i>{{propertyService.getProperty(groupby).Name}}</a></li>
        </ul>
      </div>
      <div class="btn-group float-right d-none d-lg-inline-flex">
        <button class="btn btn-swe" (click)="prev()"><i class="fas fa-chevron-left"></i></button>
        <button class="btn btn-swe" (click)="next()"><i class="fas fa-chevron-right"></i></button>
      </div>
    </div>
    <!--Shifts-->
    <div [ngClass]="{'col-10 col-md-8 col-lg-9 col-xl-10': settingService.timeline.groupby != -3,'col-12': settingService.timeline.groupby == -3}" [ngStyle]="{'height': settings.headerheight + 'px'}">
      <!--Year/Month/Week line-->
      <ng-container *ngFor="let day of settings.days;index as i">
        <div style="position:absolute;border-left: 1px solid #999999;background-color:white;height:40px;overflow-x:hidden;"
             [ngStyle]="{'left': day.left + '%'}"
             *ngIf="day.main&&(settings.showyear||settings.days.length==1||monthshow(day.date,i)||weekshow(day.date,i))">
          <span class="swe-timeline-header d-none d-md-inline" *ngIf="settings.showyear&&yearshow(day.date,i)">&nbsp;{{day.date.getFullYear()}}</span>
          <span class="swe-timeline-header d-none d-md-inline" *ngIf="settings.days.length==1||monthshow(day.date,i)" (click)="getMonth(day.date)">&nbsp;{{dateTimeService.monthname(day.date,3)}}</span>
          <span class="swe-timeline-header" *ngIf="settings.showweek&&weekshow(day.date,i)" (click)="getWeek(day.date)">&nbsp;v.{{dateTimeService.weeknumber(day.date)}}</span>
        </div>
      </ng-container>
      <!--Day line-->
      <ng-container *ngFor="let day of settings.days;index as i">
        <div style="position:absolute;top:24px;border-left: 1px solid #999999;background-color:white;overflow-x:hidden;"
             [ngStyle]="{'width': 100/settings.days.length + '%','left': day.left + '%'}"
             (dblclick)="!settings.showhour&&create(day.date, $event)"
             *ngIf="settings.showday&&day.main">
          <span class="swe-timeline-header" [attr.title]="day.date.getDate() + ' ' + dateTimeService.monthname(day.date)" (click)="getDay(day.date)">
            <span>&nbsp;{{day.date.getDate()}}</span>
            <span class="d-none d-md-inline">&nbsp;{{dateTimeService.weekdayname(day.date,2)}}</span>
            <span class="text-success d-none d-md-inline" *ngIf="!settings.showhour&&all">&nbsp;{{generalService.formatdecimal(settings.sum[i])}} {{settingService.timeline.sumoptions==0 ? 'h' : languageService.getItem(724)}}</span>
          </span>
        </div>
      </ng-container>
      <!--Hour line-->
      <ng-container *ngFor="let hour of settings.hours;index as i">
        <div [ngClass]="{'d-none d-md-inline-block':(hour % 3)}"
             style="position:absolute;top:44px;border-left: 1px solid #999999;background-color:white;overflow-x:hidden;"
             [ngStyle]="{'width': 100/settings.hours.length + '%','left': i*100*settings.hourfactor/settings.hours.length + '%'}"
             (dblclick)="create(settings.cols[i].date, $event)"
             [title]="generalService.formatdecimal(settings.sum[i]) + ' h'"
             *ngIf="settings.showhour">
          <span class="swe-timeline-header pl-md-1">{{hour}}</span>&nbsp;<span class="text-success d-none d-md-inline-block" *ngIf="all&&(settingService.timeline.precision>-3)">{{generalService.formatdecimal(settings.sum[i])}} h</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
