<swe-pageheader [header]="languageService.getItem(4)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<div class="swe-fill-to-edge" sweFixedHeader>
  <div class="btn-group mr-1 align-top" *ngIf="listutility.toggleaccess">
    <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe dropdown" (click)="listchecked=!listchecked;listutility.checkall(listchecked)" *ngIf="listutility.toggleaccess&&hits>0">
      <span><i class="fas fa-check"></i></span>
    </button>
    <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe dropdown" (click)="actionmenu=!actionmenu" *ngIf="listutility.ischecked">
      <span><i class="fas fa-fw fa-ellipsis-v"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.CopyShift>0"><a (click)="action('copy')"><i class="fas fa-fw fa-copy"></i>&nbsp;{{languageService.getItem(354)}}</a></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiShift>0"><a (click)="action('multi')"><i class="fas fa-fw fa-clone"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
      <li class="dropdown-divider" *ngIf="(permissionService.permissions.CopyShift>0||permissionService.permissions.MultiShift>0)&&(permissionService.permissions.ListSms>0||permissionService.permissions.BookingListSms>0)"></li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.BookingListSms>0">
        <swe-confirm [body]="languageService.getItem(927)" (acceptChange)="send(3, 0, $event)" (declineChange)="actionmenu=false">
          <i class="fas fa-fw fa-mobile-alt"></i>&nbsp;{{languageService.getItem(320)}}
        </swe-confirm>
      </li>
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ListSms>0">
        <swe-confirm [body]="languageService.getItem(928)" (acceptChange)="send(6, 0, $event)" (declineChange)="actionmenu=false">
          <i class="fas fa-fw fa-mobile-alt"></i>&nbsp;{{languageService.getItem(178)}}
        </swe-confirm>
      </li>
    </ul>
  </div>
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <div class="btn-group ml-1 align-top" *ngIf="more">
    <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>
  <swe-infotext [text]="languageService.getItem(535)"></swe-infotext>
</div>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="search(false)">
      <span swe-rightcommand *ngIf="permissionService.permissions.Bookings>1">
        <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="open(0, $event)"></i>
      </span>
      <swe-list [data]="bookings" [loading]="loading">
        <div *ngFor="let groupby of groupedby | keyvalue">
          <!--Header-->
          <a class="list-group-item list-group-item-action d-flex flex-wrap swe-bg-light">
            <h2>{{groupby.value.header}}<span class="text-danger" *ngIf="groupby.value.today">&nbsp;({{languageService.getItem(76)}})</span></h2>
          </a>
          <!--Booking-->
          <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex swe-click" [ngClass]="{'list-group-item-warning': booking.Id==settingService.lastbooking&&!booking.checked,'list-group-item-info': booking.checked}" *ngFor="let booking of groupby.value.items" (click)="open(booking.Id, $event)">
            <div class="col-2 col-md-1" (click)="listutility.toggle(booking, $event)" (contextmenu)="showdetail(booking, $event)">
              <i class="fas fa-swe fa-check-circle" *ngIf="booking.checked"></i>
              <i class="fas fa-border swe-icon-color" *ngIf="!booking.checked&&booking.Status>10" [attr.title]="booking.StatusText" [ngClass]="{'fa-share-square': booking.Status==20,'fa-key': booking.Status==30,'fa-clock': booking.Status==40,'fa-check': booking.Status==50,'fa-lock': booking.Status==60,'alert-danger border-danger': booking.Amount==0&&(booking.Max>0||!permissionService.permissions.ZeroAsFull),'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
              <i class="far fa-border fa-square swe-icon-color" *ngIf="!booking.checked&&booking.Status==10" [ngClass]="{'alert-danger border-danger': booking.Amount==0&&(booking.Max>0||!permissionService.permissions.ZeroAsFull),'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}" [attr.title]="booking.StatusText"></i>
              <i class="fas fa-border fa-link" *ngIf="!booking.checked&&booking.Serie>0" [attr.title]="languageService.getItem(203)"></i>
              <i class="fas fa-border fa-list-ul" *ngIf="!booking.checked&&booking.HasActivities>0" [attr.title]="languageService.getItem(741)"></i>
              <i class="fas fa-border fa-shield-alt" *ngIf="!booking.checked&&booking.EmploymentPlan" [attr.title]="languageService.getItem(698)"></i>
              <i class="far fa-border fa-comment" *ngIf="!booking.checked&&booking.Sms" [attr.title]="languageService.getItem(205)"></i>
              <i class="fas fa-border fa-eye" *ngIf="!booking.checked&&booking.IsWatching" [attr.title]="languageService.getItem(339)"></i>
              <i class="fas fa-border fa-retweet" *ngIf="!booking.checked&&booking.HasReplaceable" [attr.title]="languageService.getItem(423)"></i>
              <i class="fas fa-border fa-street-view" *ngIf="!booking.checked&&booking.HasStandby" [attr.title]="languageService.getItem(279)"></i>
              <i class="fas fa-border fa-ban" *ngIf="!booking.checked&&booking.HasAbscent" [attr.title]="languageService.getItem(280)"></i>
              <!--Detail-->
              <div class="swe-detail-block"
                   [ngClass]="{'swe-reference-block': detail.booking.Type>1, 'alert-danger border-danger': detail.booking.Amount==0&&(detail.booking.Max>0||!permissionService.permissions.ZeroAsFull),'alert-warning border-warning': (detail.booking.Amount>0&&detail.booking.Amount<detail.booking.Max), 'alert-success border-success': (detail.booking.Amount==detail.booking.Max)}"
                   [ngStyle]="{'left': detail.left + '%','top': detail.top + 'px','height': detail.height + 'px', 'min-width': detail.width + 'px'}"
                   (contextmenu)="resetdetail($event)"
                   *ngIf="detail.row == booking.Id">
                <div style="position:relative;width:100%;height:100%;overflow:hidden;">
                  <!--Detail Header-->
                  <div class="swe-detail-header">
                    <div>
                      <i class="fas pl-1" *ngIf="detail.booking.Status>10" [ngClass]="{'fa-share-square': detail.booking.Status==20,'fa-key': detail.booking.Status==30,'fa-clock': detail.booking.Status==40,'fa-check': detail.booking.Status==50,'fa-lock': detail.booking.Status==60}"></i>
                      <i class="far fa-square pl-1" *ngIf="detail.booking.Status==10"></i>
                      <i class="fas fa-link pl-1" *ngIf="detail.booking.Serie>0"></i>
                      <i class="fas fa-shield-alt pl-1" *ngIf="detail.booking.EmploymentPlan"></i>
                      <i class="far fa-comment pl-1" *ngIf="detail.booking.Sms"></i>
                      <i class="fas fa-retweet pl-1" *ngIf="detail.booking.Type==0||detail.booking.HasReplaceable"></i>
                      <i class="fas fa-street-view pl-1" *ngIf="detail.booking.Type==2||detail.booking.HasStandby"></i>
                      <i class="fas fa-ban pl-1" *ngIf="detail.booking.Type==3||detail.booking.HasAbscent"></i>
                      <i class="fas fa-sign-in-alt pl-1" *ngIf="detail.booking.HasCheckIn"></i>
                      {{detail.booking.dateheader}}
                      <i class="fas fa-fw fa-times fa-pull-right swe-click" (click)="resetdetail($event)"></i>
                    </div>
                    <div *ngIf="!permissionService.permissions.HideLevels">{{detail.booking.Level}}</div>
                    <div>{{detail.booking.Header}}</div>
                    <div>
                      <span *ngIf="!permissionService.permissions.HideAmount">{{detail.booking.Amount}}({{detail.booking.Max}})</span>
                      <span *ngIf="!permissionService.permissions.HideAmount&&detail.booking.Serie>0">, </span>
                      <span *ngIf="detail.booking.Serie>0">{{languageService.getItem(250)}}: {{detail.booking.Serie}}</span>
                    </div>
                  </div>
                  <!--Detail Content-->
                  <div class="table-responsive">
                    <table class="swe-table">
                      <tr>
                        <td>
                          <div class="swe-table-block" *ngIf="detailprofileready">
                            <table class="swe-table">
                              <tr>
                                <th colspan="2">{{languageService.getItem(29)}}</th>
                              </tr>
                              <tr *ngFor="let detailprofile of detail.profile">
                                <td>{{detailprofile.Name}}</td>
                                <td [ngSwitch]="detailprofile.Type">
                                  <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !detailprofile.Value,'fa-check-square': detailprofile.Value}" *ngSwitchCase="'System.Boolean'"></i>
                                  <span *ngSwitchCase="'Internetplatsen.Document'">{{detailprofile.Value.split('|')[0]}}</span>
                                  <span *ngSwitchCase="'System.List'">{{detailprofile.Value}}</span>
                                  <span *ngSwitchCase="'System.MultiList'">{{detailprofile.Value}}</span>
                                  <ng-container *ngSwitchCase="'System.DateTime'">
                                    <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'System.Date'">
                                    <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                                  </ng-container>
                                  <ng-container *ngSwitchCase="'System.Time'">
                                    <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                                  </ng-container>
                                  <span *ngSwitchDefault>{{detailprofile.Value}}</span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                        <td class="align-top">
                          <div class="swe-table-block" *ngFor="let user of detail.users | keyvalue">
                            <table class="swe-table">
                              <tr>
                                <th colspan="2">{{user.value.header}}</th>
                              </tr>
                              <tr *ngFor="let detailuser of user.value.items">
                                <td>{{detailuser.Firstname}}</td>
                                <td>{{detailuser.Lastname}}</td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 col-md-11 d-flex flex-wrap">
              <div class="col-7 col-md-2">{{booking.dateheader}}</div>
              <div class="col-5 col-md-1">{{booking.Amount}} ({{booking.Max}})</div>
              <div class="col-12 col-md-3">{{booking.Header}}</div>
              <div class="col-12 col-md-2">{{booking.Desc}}<span *ngIf="booking.Serie>0"> {{languageService.getItem(250)}}: {{booking.Serie}}</span></div>
              <div class="col-12 col-md-4" *ngIf="!permissionService.permissions.HideLevels">{{booking.Level}}</div>
            </div>
          </a>
        </div>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
