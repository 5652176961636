import { Component, OnChanges, Input, ElementRef, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ListService } from '../../_services/list.service';
import { PropertyService } from '../../_services/property.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'swe-timereportdata',
  templateUrl: './timereportdata.component.html'
})
export class TimereportDataComponent implements OnChanges, OnDestroy {
  @Input() id: number;
  @Input() othertimereports:any[] = [];
  @Input() open: boolean;
  @Input() userid: number = 0;
  @Input() type: number = 0;
  @Output() typeChange = new EventEmitter<any>();
  @Output() headerChange = new EventEmitter<any>();
  @Output() idChange = new EventEmitter<any>();
  @ViewChild('sweSaveElement', { static: false }) saveElement: ElementRef;

  private unsubscribe$: any[] = [
    new Subject<void>()
  ];
  private _timereport: any;
  private _loading: boolean;
  private _header: string;
  private _abscent: boolean = false;
  private _coreChanged: any;
  private _isReload: boolean;
  private _groupedby: any;
  private _timereports:any[] = [];


  constructor(
    public languageService: LanguageService,
    private dataService: DataService,
    private alertService: AlertService,
    public settingService: SettingService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {

    settingService.onSave$
      .pipe(takeUntil(this.unsubscribe$[0]))
      .subscribe((e) => {
        //Save
        this.save();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.forEach((unsubscribe) => {
      unsubscribe.next();
      unsubscribe.complete();
    });
  }

  ngOnChanges() {
    if (this.open) {
      this.load();
    }

  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get timereport() {
    return this._timereport;
  }
  public get header() {
    return this._header;
  }
  public get abscent() {
    return this._abscent;
  }
  public get coreChanged() {
    return this._coreChanged;
  }
  public get isReload() {
    return this._isReload;
  }
  public set isReload(val) {
    this._isReload = val;
  }
  public get groupedby() {
    return this._groupedby;
  }



  /*Methods*/
  public manageCoreChange(idx, val) {
    let item = this._coreChanged[idx];
    if (item != null) {
      item.Changed = (item.Original != null && item.Original != val);
    }
  }
  public profiledone() {
    if (this._timereport.Access > 1) {
      let element = this.saveElement.nativeElement;
      element.click();
    }
  }
  public load() {

    this._header = this.languageService.getItem(52);

    this._isReload = true;
    setTimeout(() => {
      this._isReload = false;
    }, 500);

    if (!this.open) { return; }

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/timereports/' + this.id + '/type/' + this.type, 'GET')
      .subscribe(res => {
        if (res) {

          if (res.BookingType == 3) {
            this._header = this.languageService.getItem(984);
            this._abscent = true;
            this.headerChange.emit(this.languageService.getItem(983));
          }
          else {
            this.headerChange.emit(this.languageService.getItem(52));
          }

          if (res.Id == 0) {
            //Storage
            let storage = localStorage.getItem('level');
            if (!storage) {
              storage = this.permissionService.permissions.BookingLevel;
            }
            if (storage) {
              let storageArr = storage.split('|');
              res.LevelId = storageArr[0];
              if (storageArr.length > 1) {
                res.LevelName = storageArr[1];
              }
              if (storageArr.length > 2) {
                res.LevelPath = storageArr[2];
              }
            }
          }

          this._timereport = res;

          //Use breaklist component (even for Single Break)
          if (!this.permissionService.permissions.MultipleBreak && this._timereport.Break > 0) {
            this._timereport.Breaks = [{ Break: this._timereport.Break, BreakStart: this._timereport.BreakStart }];
          }

          this._coreChanged = {
            Status: { Original: this._timereport.Status, Changed: false },
            Break: { Original: this._timereport.Break, Changed: false }
          };


          if (this.othertimereports && this.othertimereports.length > 0) {

            this._timereports = [];

            let filter = {
              Top: 0,
              Multiple: 1,
              //Timereport
              //Timereports
              RemoveStandby: false,
              TimereportList: this.othertimereports.map(function (othertimereport) { return othertimereport.id; }).join()
            };

            this.dataService.tokenRequest('/api/v1/timereports/search', 'POST', filter)
              .subscribe(res2 => {
                res2.TimeReports.forEach(othertimereport => {
                  othertimereport.dateheader = this.dateTimeService.format(othertimereport.Start, 'HH:mm') + ' - ' + this.dateTimeService.format(othertimereport.End, 'HH:mm');

                  this._timereports.push(othertimereport);
                });

                this._groupedby = this.listService.groupByDate(this._timereports, "Start");
              });
          }


          this._loading = false;
        }
      });
  }
  public resetBreak() {
    this.save(true);
  }
  public save(resetBreak: boolean = false) {

    //Mange use of breaklist component for Single Break
    if (!this.permissionService.permissions.MultipleBreak) {
      if (resetBreak) {
        //Reset
        this._timereport.Break = 0;
        this._timereport.BreakStart = null;
      }
      else if (this._timereport.Breaks.length == 0 || this._timereport.Breaks[0].Break == 0) {
        //No break
        this._timereport.Break = -1;
        this._timereport.BreakStart = null;
      }
      else {
        //Break
        this._timereport.Break = this._timereport.Breaks[0].Break;
        this._timereport.BreakStart = this._timereport.Breaks[0].BreakStart;
      }
    }

    if (!this.validatebreak()) { return; }
    if (!this.propertyService.validate(this._timereport.Profile)) { return; }

    let verb = 'POST';
    let path = '/api/v1/timereports/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;
    }

    //Manage Break
    if (this.permissionService.permissions.MultipleBreak) {
      for (let i = 0; i < this._timereport.Breaks.length; i++) {
        if (new Date(this._timereport.Breaks[i].BreakStart) <= new Date(1970, 1, 1) || this._timereport.Breaks[i].Break <= 0) {
          if (this.permissionService.permissions.BreakStart || this._timereport.Breaks[i].Break <= 0) {
            this._timereport.Breaks.splice(i, 1);
            i--;
          }
          else {
            this._timereport.Breaks[i].BreakStart = '0001-01-01T00:00:00Z';
          }
        }
      }
    }
    if (new Date(this._timereport.BreakStart) <= new Date(1970, 1, 1) || this._timereport.Break <= 0) {
      this._timereport.BreakStart = '0001-01-01T00:00:00Z';
    }

    let dto: any = {
      Start: this._timereport.Start,
      StartAccess: this._timereport.StartAccess,
      End: this._timereport.End,
      EndAccess: this._timereport.EndAccess,
      BreakStartAccess: this._timereport.BreakStartAccess,
      Break: this._timereport.Break,
      BreakAccess: this._timereport.BreakAccess,
      Breaks: this._timereport.Breaks,
      Status: this._timereport.Status,
      Profile: this._timereport.Profile,
      ExternalCategory: this._timereport.ExternalCategory ? this._timereport.ExternalCategory : 0,
      ResetBreak: resetBreak,
      LevelId: this.id > 0 ? 0 : this._timereport.LevelId,
      UserId: this.userid,
      BookingType: this.type
    };

    if (new Date(this._timereport.BreakStart) > new Date(1970, 1, 1)) {
      dto["BreakStart"] = this._timereport.BreakStart;
    }
    else {
      dto["BreakStart"] = new Date('0001-01-01T00:00:00Z');
    }

    dto["Breaks"].forEach((item) => {

      if (new Date(item.BreakStart) <= new Date(1970, 1, 1)) {
        item.BreakStart = new Date('0001-01-01T00:00:00Z');
      }
    });

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {
          this.alertService.Add({ message: response.body, type: 'success' });

          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
          else {
            this.type = 0;
            this.typeChange.emit(this.type);
          }
        }
      });
  }
  public managestatus(id) {

    let disabled = true;

    if (id == 40) {
      //**********************************************/
      //Timereported
      //**********************************************/
      if (this.timereport.Status < 40 && this.timereport.TimereportAccess > 1) {
        //Upgrade, need write timereport access
        disabled = false;
      }
      else if (this.timereport.Status == 40) {
        //Current status always allowed
        disabled = false;
      }
      else if (this.timereport.Status == 50 && this.timereport.ConfirmedAccess > 2) {
        //Downgrade from confirmed, need delete confirmed access
        disabled = false;
      }
      else if (this.timereport.Status == 60) {
        //Downgrade from completed, need delete confirmed access and delete completed access if confirmed exist
        if (this.listService.find(this.generalService.timereportstatus, 'Id', 50)) {
          if (this.timereport.CompletedAccess > 2 && this.timereport.ConfirmedAccess > 2) {
            disabled = false;
          }
        }
        else if (this.timereport.CompletedAccess > 2) {
          disabled = false;
        }
      }
    }

    if (id == 50) {
      //**********************************************/
      //Confirmed
      //**********************************************/
      if (this.timereport.Status < 50 && this.timereport.ConfirmedAccess > 1) {
        //Upgrade, need write confirmed access
        disabled = false;
      }
      else if (this.timereport.Status == 50) {
        //Current status always allowed
        disabled = false;
      }
      else if (this.timereport.Status == 60 && this.timereport.CompletedAccess > 2) {
        //Downgrade from completed, need delete completed access
        disabled = false;
      }
    }

    if (id == 60) {
      //**********************************************/
      //Completed
      //**********************************************/
      if (this.timereport.Status < 60 && this.timereport.CompletedAccess > 1) {
        //Upgrade, need write confirmed access
        disabled = false;
      }
      else if (this.timereport.Status == 60) {
        //Current status always allowed
        disabled = false;
      }
    }

    return disabled;
  }
  public booking(id: number, e) {
    e.stopPropagation();

    this.router.navigate(['/bookings', id]);
  }
  public goto(id, e) {

    e.stopPropagation();

    let idx = this.othertimereports.map(function (timereport) { return timereport.id; }).indexOf(id);

    let url = '/timereports/' + this.othertimereports[idx].id;
    if (this.type > 0) {
      url += '/user/' + this.userid;
      url += '/type/' + this.othertimereports[idx].type;
    }

    this.router.navigate([url], { replaceUrl: true });

    this.idChange.emit(id);
  }
  public manageDateTime(e) {

    if (this.permissionService.permissions.BreakStart) {

      if (this.permissionService.permissions.MultipleBreak) {

        for (let i = 0; i < this._timereport.Breaks.length; i++) {
          if (new Date(this._timereport.Breaks[i].BreakStart) > new Date(1970, 1, 1)) {
            this._timereport.Breaks[i].BreakStart = this.adjustbreakstart(new Date(this._timereport.Start), new Date(this._timereport.End), new Date(this._timereport.Breaks[i].BreakStart));
          }
        }

      }
      else {

        if (new Date(this._timereport.BreakStart) > new Date(1970, 1, 1)) {
          this._timereport.BreakStart = this.adjustbreakstart(new Date(this._timereport.Start), new Date(this._timereport.End), new Date(this._timereport.BreakStart));
        }
      }
    }

  }


  //Functions
  private validatebreak() {

    let breaks: any[] = [{ Break: this._timereport.Break, BreakStart: new Date(this._timereport.BreakStart) }];
    if (this.permissionService.permissions.MultipleBreak) {
      breaks = this._timereport.Breaks;
    }

    let res = true;
    for (let i = 0; i < breaks.length; i++) {
      let breakObj = breaks[i];

      if (this.permissionService.permissions.BreakStart) {
        if (breakObj.BreakStart != null && new Date(breakObj.BreakStart) > new Date(1970, 1, 1)) {
          if (new Date(breakObj.BreakStart) < new Date(this._timereport.Start) || new Date(this._timereport.End) < new Date(breakObj.BreakStart)) {
            this.alertService.Add({ message: this.languageService.getItem(788), type: 'danger' });

            res = false;
            break;
          }
          else if ((new Date(breakObj.BreakStart).getTime() + (breakObj.Break * 60 * 1000)) > new Date(this._timereport.End).getTime()) {
            this.alertService.Add({ message: this.languageService.getItem(789), type: 'danger' });

            res = false;
            break;
          }
        }
      }
    }

    return res;
  }
  private adjustbreakstart(start: Date, end: Date, breakstart: Date) {

    let newBreakStart: Date = breakstart;

    if ((newBreakStart < start || end < newBreakStart)) {
      //Adjust to start date
      newBreakStart = new Date(start.getFullYear(), start.getMonth(), start.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
      if ((newBreakStart < start || end < newBreakStart)) {
        //Adjust to end date
        newBreakStart = new Date(end.getFullYear(), end.getMonth(), end.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
        if ((newBreakStart < start || end < newBreakStart)) {
          //Can't adjust
          newBreakStart = breakstart;
        }
      }
    }

    return this.dateTimeService.formatWithTimeZone(newBreakStart);
  }
}
