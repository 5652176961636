/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../_directives/fixedheader.directive";
import * as i3 from "./timelinerow.component.ngfactory";
import * as i4 from "./timelinerow.component";
import * as i5 from "../../_services/language.service";
import * as i6 from "../../_services/setting.service";
import * as i7 from "../../_services/datetime.service";
import * as i8 from "../../_services/general.service";
import * as i9 from "../../_services/permission.service";
import * as i10 from "../../_services/list.service";
import * as i11 from "../../_services/data.service";
import * as i12 from "../../_services/alert.service";
import * as i13 from "../../_services/viewcache.service";
import * as i14 from "../../_services/property.service";
import * as i15 from "@angular/router";
import * as i16 from "./timelineheader.component.ngfactory";
import * as i17 from "./timelineheader.component";
import * as i18 from "./timelinegrid.component";
var styles_TimelineGridComponent = [];
var RenderType_TimelineGridComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimelineGridComponent, data: {} });
export { RenderType_TimelineGridComponent as RenderType_TimelineGridComponent };
function View_TimelineGridComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", ""], ["sweFixedHeader", ""]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "overflow": 0 }), i0.ɵdid(4, 81920, null, 0, i2.FixedHeaderDirective, [i0.ElementRef], { class: [0, "class"], index: [1, "index"], nopadding: [2, "nopadding"], topadjustmentclass: [3, "topadjustmentclass"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "swe-timelinerow", [], null, [[null, "settingsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("settingsChange" === en)) {
        var pd_0 = (_co.changedBySettings($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TimelineRowComponent_0, i3.RenderType_TimelineRowComponent)), i0.ɵdid(6, 573440, null, 0, i4.TimelineRowComponent, [i5.LanguageService, i6.SettingService, i7.DateTimeService, i8.GeneralService, i9.PermissionService, i10.ListService, i11.DataService, i12.AlertService, i13.ViewCacheService, i14.PropertyService, i15.Router], { row: [0, "row"], dofilter: [1, "dofilter"], notifyemail: [2, "notifyemail"], notifysms: [3, "notifysms"], settings: [4, "settings"] }, { settingsChange: "settingsChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_v.parent.context.$implicit.GroupById == _co.settings.detail) ? "unset" : "hidden")); _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; var currVal_2 = 3; var currVal_3 = true; var currVal_4 = (_co.settings.showhour ? "pt-4" : ""); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.parent.context.$implicit; var currVal_6 = _co.dofilter; var currVal_7 = _co.notifyemail; var currVal_8 = _co.notifysms; var currVal_9 = _co.settings; _ck(_v, 6, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_TimelineGridComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(3, { "overflow": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "swe-timelinerow", [], null, [[null, "settingsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("settingsChange" === en)) {
        var pd_0 = (_co.changedBySettings($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TimelineRowComponent_0, i3.RenderType_TimelineRowComponent)), i0.ɵdid(5, 573440, null, 0, i4.TimelineRowComponent, [i5.LanguageService, i6.SettingService, i7.DateTimeService, i8.GeneralService, i9.PermissionService, i10.ListService, i11.DataService, i12.AlertService, i13.ViewCacheService, i14.PropertyService, i15.Router], { row: [0, "row"], dofilter: [1, "dofilter"], notifyemail: [2, "notifyemail"], notifysms: [3, "notifysms"], settings: [4, "settings"] }, { settingsChange: "settingsChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((_v.parent.context.$implicit.GroupById == _co.settings.detail) ? "unset" : "hidden")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _co.dofilter; var currVal_3 = _co.notifyemail; var currVal_4 = _co.notifysms; var currVal_5 = _co.settings; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_TimelineGridComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineGridComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineGridComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.GroupById == 0) && _co.settings.emptyRowPinned); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.GroupById != 0) || !_co.settings.emptyRowPinned); _ck(_v, 4, 0, currVal_1); }, null); }
function View_TimelineGridComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-block btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changedByMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(57); _ck(_v, 2, 0, currVal_0); }); }
function View_TimelineGridComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "swe-scroll-spot up"], ["id", "ScrollUp"]], null, [[null, "dragover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragover" === en)) {
        var pd_0 = (_co.doScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-3x fa-arrow-up"]], null, null, null, null, null))], null, null); }
function View_TimelineGridComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "swe-scroll-spot down"], ["id", "ScrollDown"]], null, [[null, "dragover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragover" === en)) {
        var pd_0 = (_co.doScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-fw fa-3x fa-arrow-down"]], null, null, null, null, null))], null, null); }
export function View_TimelineGridComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, [[null, "dragover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragover" === en)) {
        var pd_0 = (_co.prepareScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "swe-timelineheader", [], null, [[null, "groupbyChange"], [null, "sumoptionsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("groupbyChange" === en)) {
        var pd_0 = (_co.changedByGroupBy($event) !== false);
        ad = (pd_0 && ad);
    } if (("sumoptionsChange" === en)) {
        var pd_1 = (_co.changedBySumOptions($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i16.View_TimelineHeaderComponent_0, i16.RenderType_TimelineHeaderComponent)), i0.ɵdid(2, 573440, null, 0, i17.TimelineHeaderComponent, [i5.LanguageService, i9.PermissionService, i6.SettingService, i8.GeneralService, i14.PropertyService, i7.DateTimeService, i13.ViewCacheService, i11.DataService, i15.Router], { data: [0, "data"], settings: [1, "settings"], all: [2, "all"] }, { groupbyChange: "groupbyChange", sumoptionsChange: "sumoptionsChange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineGridComponent_1)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineGridComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineGridComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineGridComponent_6)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = _co.settings; var currVal_2 = _co.all; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.rows; _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.more && !_co.dofilter); _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.scrollup; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.scrolldown; _ck(_v, 10, 0, currVal_6); }, null); }
export function View_TimelineGridComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-timelinegrid", [], null, null, null, View_TimelineGridComponent_0, RenderType_TimelineGridComponent)), i0.ɵdid(1, 573440, null, 0, i18.TimelineGridComponent, [i5.LanguageService, i6.SettingService, i9.PermissionService, i7.DateTimeService], null, null)], null, null); }
var TimelineGridComponentNgFactory = i0.ɵccf("swe-timelinegrid", i18.TimelineGridComponent, View_TimelineGridComponent_Host_0, { data: "data", more: "more", dofilter: "dofilter", notifyemail: "notifyemail", notifysms: "notifysms", all: "all", allchecked: "allchecked", holidays: "holidays" }, { moreChange: "moreChange", groupbyChange: "groupbyChange", sumoptionsChange: "sumoptionsChange", settingsChange: "settingsChange" }, []);
export { TimelineGridComponentNgFactory as TimelineGridComponentNgFactory };
