<swe-pageheader [header]="languageService.getItem(51)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<div class="swe-fill-to-edge" sweFixedHeader>
  <div class="btn-group mr-1 align-top" *ngIf="listutility.toggleaccess">
    <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe dropdown" (click)="listchecked=!listchecked;listutility.checkall(listchecked)" *ngIf="listutility.toggleaccess&&usermarkeddates.length>0">
      <span><i class="fas fa-check"></i></span>
    </button>
    <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe dropdown" (click)="actionmenu=!actionmenu" *ngIf="listutility.ischecked">
      <span><i class="fas fa-fw fa-ellipsis-v"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiMarkedDate>0"><a (click)="action('multi')"><i class="fas fa-fw fa-clone"></i>&nbsp;{{languageService.getItem(670)}}</a></li>
    </ul>
  </div>
  <swe-datepager [type]="'usermarkeddate'" (dateChange)="search()" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <div class="btn-group ml-1 align-top" *ngIf="all">
    <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>
</div>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false">
      <span swe-rightcommand *ngIf="permissionService.permissions.MarkedDates>1">
        <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="open(0, $event)"></i>
      </span>
      <swe-list [data]="usermarkeddates" [loading]="loading">
        <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': umd.checked}" *ngFor="let umd of usermarkeddates" (click)="open(umd.Id, $event)">
          <div class="col-3 col-md-1" (click)="listutility.toggle(umd, $event)">
            <span class="fa-stack fa-swe-letter-circle-small" *ngIf="umd.checked">
              <i class="fas fa-check-circle fa-stack-2x"></i>
            </span>
            <img class="swe-circle-image" alt="{{umd.Name}}" sweImage imageFolder="userimages" [imageFile]="umd.Image" imageWidth="32" imageHeight="32" *ngIf="!umd.checked&&umd.Image.length>0" />
            <span class="fa-stack fa-swe-letter-circle-small" *ngIf="!umd.checked&&umd.Image.length==0">
              <i class="fas fa-circle fa-stack-2x"></i>
              <span class="fa-stack-1x fa-stack-1x fa-swe-letter">{{umd.Name[0]}}</span>
            </span>
          </div>
          <div class="col-9 col-md-11 d-flex flex-wrap">
            <div class="col-12 col-md-2">{{umd.dateheader}}</div>
            <div class="col-12 col-md-3">{{umd.Name}}</div>
            <div class="col-12 col-md-5">{{umd.Comment}}</div>
            <div class="col-12 col-md-2"><div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': umd.Color}"></div>&nbsp;{{umd.Type}}</div>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
