<swe-pageheader [header]="languageService.getItem(110)"><span swe-hits>&nbsp;(#{{id}})</span></swe-pageheader>
<div class="row py-2">
  <div class="col-12 col-md-4">
    <swe-usermarkeddatedata [id]="id" [userId]="userId" [open]="cards.data"></swe-usermarkeddatedata>
  </div>
  <ng-container *ngFor="let obj of objecttoobjects">
    <div class="col-12 col-md-8 float-left" *ngIf="id>0">
      <swe-objecttoobjects [id]="id" [data]="obj" [open]="false"></swe-objecttoobjects>
    </div>
  </ng-container>
</div>
