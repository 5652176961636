/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./infotext.component";
import * as i3 from "../_services/language.service";
var styles_InfoTextComponent = [];
var RenderType_InfoTextComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InfoTextComponent, data: {} });
export { RenderType_InfoTextComponent as RenderType_InfoTextComponent };
function View_InfoTextComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "swe-info-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 0, 0, currVal_0); }); }
function View_InfoTextComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "d-inline-block"]], null, null, null, null, null))], null, null); }
export function View_InfoTextComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "i", [["class", "fas fa-fw fa-swe fa-pull-right swe-click"]], [[1, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showhelp = !_co.showhelp) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "fa-question-circle": 0, "fa-times": 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InfoTextComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InfoTextComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fas fa-fw fa-swe fa-pull-right swe-click"; var currVal_2 = _ck(_v, 3, 0, !_co.showhelp, _co.showhelp); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.showhelp; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.preventdrowning; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
export function View_InfoTextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-infotext", [], null, null, null, View_InfoTextComponent_0, RenderType_InfoTextComponent)), i0.ɵdid(1, 114688, null, 0, i2.InfoTextComponent, [i3.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoTextComponentNgFactory = i0.ɵccf("swe-infotext", i2.InfoTextComponent, View_InfoTextComponent_Host_0, { text: "text", preventdrowning: "preventdrowning", showtitle: "showtitle" }, {}, []);
export { InfoTextComponentNgFactory as InfoTextComponentNgFactory };
