<swe-pageheader [header]="languageService.getItem(400)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12" *ngFor="let logsetting of logsettings">
    <swe-card [color]="logsetting.Active ? 'success' : ''" [header]="logsetting.Key + ' (' + logsetting.Name + ')'" [open]="false" [hasCollapse]="true" [hasRefresh]="true">
      <div class="row py-2">
        <div class="col-12 col-md-4">
          <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="false">
            <swe-element [type]="'System.String'" [label]="languageService.getItem(586)" [statusLabel]="2" [(model)]="logsetting.Name"></swe-element>
            <swe-element [type]="'System.String'" [label]="languageService.getItem(587)" [statusLabel]="2" [(model)]="logsetting.Inserted"></swe-element>
            <swe-element [type]="'System.String'" [label]="languageService.getItem(588)" [statusLabel]="2" [(model)]="logsetting.Updated"></swe-element>
            <swe-element [type]="'System.String'" [label]="languageService.getItem(589)" [statusLabel]="2" [(model)]="logsetting.Deleted"></swe-element>
            <swe-element [type]="'System.Int32'" [label]="languageService.getItem(590)" [statusLabel]="2" [(model)]="logsetting.Sort"></swe-element>
            <button class="btn btn-primary btn-block" (click)="save(logsetting)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
            <swe-confirm [body]="languageService.getItem(593)" (acceptChange)="delete(logsetting)">
              <button class="btn btn-danger btn-block mt-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
            </swe-confirm>
          </swe-card>
        </div>
        <div class="col-12 col-md-8">
          <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="false">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>{{languageService.getItem(591)}}</th>
                  <th>{{languageService.getItem(586)}}</th>
                  <th>{{languageService.getItem(590)}}</th>
                  <th>{{languageService.getItem(929)}}</th>
                  <th>{{languageService.getItem(592)}}</th>
                  <th>{{languageService.getItem(594)}}</th>
                </tr>
              </thead>
              <tbody>
                <tr [ngClass]="{'alert-success': column.Active}" *ngFor="let column of logsetting.Columns">
                  <td>{{column.Key}}</td>
                  <td><swe-element [bottomMargin]="0" [type]="'System.String'" [label]="languageService.getItem(586)" [statusLabel]="0" [(model)]="column.Name"></swe-element></td>
                  <td><swe-element [bottomMargin]="0" [type]="'System.Int32'" [label]="languageService.getItem(590)" [statusLabel]="0" [(model)]="column.Sort"></swe-element></td>
                  <td><swe-element [bottomMargin]="0" [type]="'System.String'" [label]="languageService.getItem(929)" [statusLabel]="0" [(model)]="column.Filter"></swe-element></td>
                  <td><swe-element [bottomMargin]="0" [type]="'System.Boolean'" [label]="languageService.getItem(592)" [statusLabel]="0" [(model)]="column.Visible"></swe-element></td>
                  <td><swe-element [bottomMargin]="0" [type]="'System.Boolean'" [label]="languageService.getItem(594)" [statusLabel]="0" [(model)]="column.Active"></swe-element></td>
                </tr>
              </tbody>
            </table>
          </swe-card>
        </div>
      </div>
    </swe-card>
  </div>
</div>
