<swe-pageheader [header]="languageService.getItem(4)">
  <span swe-hits>
    &nbsp;(#{{id}})
    <i class="fas fa-fw fa-plus fa-pull-right swe-click" (click)="create()" *ngIf="permissionService.permissions.Bookings>1&&id>0"></i>
  </span>
</swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-left">
    <swe-bookingdata [id]="id" [(open)]="cards.data" [userid]="userid" [levelid]="levelid" (bookingdataChange)="manageBookingdata($event)"></swe-bookingdata>
  </div>
  <div class="col-12 col-md-8 float-left" *ngIf="id>0">
    <swe-bookingusers [id]="id" [(open)]="cards.users" [bookingdata]="bookingdata"></swe-bookingusers>
  </div>
  <div class="col-12 col-md-8 float-left" *ngIf="id>0&&bookingdata.UsersAccess>0">
    <swe-bookingavailableusers [id]="id" [(open)]="cards.availableusers" [bookingdata]="bookingdata"></swe-bookingavailableusers>
  </div>
  <ng-container *ngFor="let obj of objecttoobjects">
    <div class="col-12 col-md-8 float-left" *ngIf="id>0">
      <swe-objecttoobjects [id]="id" [data]="obj" [open]="false"></swe-objecttoobjects>
    </div>
  </ng-container>
  <div class="col-12 col-md-8 float-left" *ngIf="id>0&&bookingdata.LogAccess>0">
    <swe-card header="{{languageService.getItem(400)}}" [(open)]="cards.logs" [hasRefresh]="false">
      <swe-generallogs [id]="id" [(open)]="cards.systemlogs" [type]="'booking'" [data]="bookingdata"></swe-generallogs>
      <swe-generalmessages [id]="id" [(open)]="cards.messagelogs" [type]="'booking'" [data]="bookingdata"></swe-generalmessages>
    </swe-card>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-left] on columns-->
