<swe-card header="{{languageService.getItem(72)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
  <span swe-rightcommand>
    <i class="fas fa-fw fa-swe fa-sign-in-alt fa-pull-right" [attr.title]="languageService.getItem(455)" *ngIf="permissionService.user.IsCheckedIn"></i>
  </span>
  <swe-adminrule [rulelist]="['SweCheckInLevels']"></swe-adminrule>
  <div class="form-group">
    <div class="mb-2" *ngIf="nextcheckin.Id>0">
      <div><b>{{languageService.getItem(938)}}</b></div>
      <div>{{nextcheckin.datetimeheader}}</div>
      <div>{{nextcheckin.Level}}</div>
      <div *ngIf="nextcheckin.Header.length>0">{{nextcheckin.Header}}</div>
      <div *ngIf="nextcheckin.Desc.length>0">{{nextcheckin.Desc}}</div>
    </div>
    <button class="btn btn-primary btn-block" (click)="checkin()"><i class="fas fa-fw fa-sign-in-alt"></i>&nbsp;{{languageService.getItem(73)}}</button>
  </div>
  <div class="form-group">
    <div class="mb-2" *ngIf="nextcheckout.Id>0">
      <div><b>{{languageService.getItem(939)}}</b></div>
      <div>{{nextcheckout.datetimeheader}}</div>
      <div>{{nextcheckout.Level}}</div>
      <div *ngIf="nextcheckout.Header.length>0">{{nextcheckout.Header}}</div>
      <div *ngIf="nextcheckout.Desc.length>0">{{nextcheckout.Desc}}</div>
    </div>
    <button class="btn btn-primary btn-block" (click)="checkout()"><i class="fas fa-fw fa-sign-out-alt"></i>&nbsp;{{languageService.getItem(74)}}</button>
  </div>
</swe-card>
