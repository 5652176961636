<div class="d-inline ml-2" *ngIf="settingService.difflist.length>0">
    <span class="badge badge-pill badge-primary swe-badge swe-click ml-1" [ngClass]="{'swe-badge-grayed-out': permissionService.isLocked(diff.locked)}" *ngFor="let diff of settingService.difflist" (click)="!permissionService.isLocked(diff.locked)&&settingService.resetItem(diff)">
        {{languageService.getItem(diff.language)}} <span *ngIf="diff.icon.length>0"><i class="fas fa-fw {{diff.icon}}"></i></span><span *ngIf="isArray(diff.value)"> ({{diff.value.length}})</span><i class="fas fa-fw fa-times"></i>
    </span>
</div>
<div class="d-inline" (click)="settingService.reset()" [attr.title]="languageService.getItem(974)">
  <span class="badge badge-pill badge-secondary swe-badge swe-click ml-1"><i class="fas fa-fw fa-redo"></i></span>
</div>
<div class="d-inline" (click)="menuService.toggleSearch()" [attr.title]="menuService.ShowSearch ? languageService.getItem(976) : languageService.getItem(975)">
    <span class="badge badge-pill badge-secondary swe-badge swe-click ml-1"><i class="fas fa-fw" [ngClass]="{'fa-search-minus': menuService.ShowSearch, 'fa-search-plus': !menuService.ShowSearch}"></i></span>
</div>
