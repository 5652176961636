import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { AlertService } from '../../_services/alert.service';
import { ListService } from '../../_services/list.service';
import { SettingService } from '../../_services/setting.service';


@Component({
  selector: 'app-systemtables',
  templateUrl: './systemtables.component.html'
})
export class SystemTablesComponent implements OnInit  {

  private _debugmode: any;
  private _tables: any[] = [];

  constructor(
    public languageService: LanguageService,
    private dataService: DataService,
    private permissionService: PermissionService,
    private generalService: GeneralService,
    private alertService: AlertService,
    private listService: ListService,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();

    this._debugmode = localStorage.getItem('debug');
  }


  ngOnInit() {
    this.loadTables();
  }


  //Properties
  public get debugmode() {
    return this._debugmode;
  }
  public set debugmode(val) {
    this._debugmode = val;
  }
  public get tables() {
    return this._tables;
  }


  //Methods
  public changeDebug() {
    if (this._debugmode) {
      localStorage.setItem('debug', 'true');
    }
    else {
      localStorage.removeItem('debug');
    }
  }
  public clearcache() {
    this.dataService.tokenRequest('/api/v1/system/cache/', 'DELETE', {})
      .subscribe((res) => {
        this.generalService.reloadRoles().subscribe();
        this.generalService.reloadLevelGroups().subscribe();
        this.generalService.reloadReports().subscribe();

        this.permissionService.reload().subscribe();

        window.open('/', '_self');

        this.alertService.Add({ type: 'success', message: this.languageService.getItem(119) });
      });
  }
  public getTable(table) {
    this.dataService.tokenRequest('/api/v1/system/tables/' + table.ClassName, 'GET', {})
      .subscribe((res) => {

        table.headers = [];
        table.rows = [];
        table.footer = {};

        res.forEach((row, index) => {
          if (index == 0) {
            table.headers = row.Properties;
          }

          if (row.Id != 0) {
            table.rows.push(row);
          }
          else {
            table.footer = row;
          }
        });
      });
  }
  public getItems(table, name) {
    return this.listService.formatArray(table.Lists, [name], 'Name')[0].List;
  }
  public getGroupByItems(table, name) {
    let list = this.listService.formatArray(table.Lists, [name], 'Name')[0].List;

    let groupbyList: any[] = [];
    list.forEach((item) => {
      let groupby = this.listService.find(groupbyList, 'Name', item.Extra);
      if (groupby) {
        groupby.Items.push({ Id: item.Id, Name: item.Name });
      }
      else {
        groupbyList.push({ Name: item.Extra, Items: [{ Id: item.Id, Name: item.Name }] });
      }
    });

    return groupbyList;
  }
  public save(table) {
    let list = [...table.rows];

    let isloaded = false;
    table.footer.Properties.forEach((property) => {

      let isEmptyList = (property.Type == "System.List" && property.Value == 0);

      if (property.Type == "System.DateTime") {
        if (property.Value != "0001-01-01T00:00:00") {
          isloaded = true;
          list.push(table.footer);
        }
      }
      else if (((property.Type == "System.List" && !isEmptyList) || (property.Value != null && property.Value.length > 0)) && !isloaded) {
        isloaded = true;
        list.push(table.footer);
      }
    });

    this.dataService.tokenRequest('/api/v1/system/tables/' + table.ClassName, 'PUT', list, 'text')
      .subscribe((res) => {

        if (res.length > 0) {
          table.rows = [];
          this.getTable(table);
          this.alertService.Add({ type: 'success', message: res });
        }
      });
  }




  //Functions
  private loadTables() {

    this.dataService.tokenRequest('/api/v1/system/tables/', 'GET', {})
      .subscribe((res) => {

        this._tables = [];
        res.forEach((item) => {

          //Add extra fields
          item.visible = false;
          item.headers = [];
          item.rows = [];
          item.footer = {};

          this._tables.push(item);
        });

      });
  }


}
