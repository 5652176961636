<swe-pageheader [header]="languageService.getItem(354)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-left">
    <swe-card header="{{languageService.getItem(4)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
      <!--Repeat Options-->
      <swe-element [(model)]="repeatoption" [label]="languageService.getItem(355)" [statusLabel]="2" [items]="repeatoptions" [type]="'System.List'"></swe-element>
      <!--Time-->
      <div class="form-group">
        <label>{{languageService.getItem(360)}}</label>
        <div>
          <swe-datetime [(start)]="start" (startChange)="adjustMonday($event)" [labelStart]="languageService.getItem(360)" [hideTime]="true" [hideEnd]="true"></swe-datetime>
        </div>
      </div>
      <!--AdjustDay Options-->
      <swe-element [(model)]="adjustoption" [label]="languageService.getItem(934)" [statusLabel]="2" [items]="adjustoptions" [type]="'System.List'"></swe-element>
      <!--Copy Frequency-->
      <div class="form-group">
        <label>{{languageService.getItem(356)}}</label>
        <div class="form-check">
          <input type="radio" class="form-check-input swe-click" [(ngModel)]="endoption" name="endoptions" [value]="0" [attr.aria-label]="languageService.getItem(356)" />
          <input type="number" class="form-control w-25 d-inline" [(ngModel)]="endtimes" [attr.aria-label]="languageService.getItem(794)" [disabled]="endoption==1" />
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input swe-click" [(ngModel)]="endoption" name="endoptions" [value]="1" [attr.aria-label]="languageService.getItem(356)" />
          <swe-element [(model)]="enddate" [label]="languageService.getItem(714)" [type]="'System.Date'" [disabled]="endoption==0"></swe-element>
        </div>
      </div>
      <!--Level-->
      <swe-element [(model)]="level" [label]="languageService.getItem(790)" [statusLabel]="2" [container]="{type: 'Booking'}" [type]="'System.LevelSearch'"></swe-element>
      <!--Status-->
      <swe-element [(model)]="status" [label]="languageService.getItem(361)" [statusLabel]="2" [items]="generalService.bookingstatus" [optional]="true" [type]="'System.List'"></swe-element>
      <!--Include Users-->
      <swe-element [(model)]="includeusers" [label]="languageService.getItem(357)" [statusLabel]="2" [type]="'System.Boolean'"></swe-element>
      <!--Buttons-->
      <button class="btn btn-primary btn-block mb-2" (click)="copy()"><i class="fas fa-fw fa-copy"></i>&nbsp;{{languageService.getItem(358)}}</button>
    </swe-card>
  </div>
</div>  
