/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layout/card.component.ngfactory";
import * as i2 from "../../layout/card.component";
import * as i3 from "../../_services/language.service";
import * as i4 from "../../layout/element.component.ngfactory";
import * as i5 from "../../layout/element.component";
import * as i6 from "../../_services/authentication.service";
import * as i7 from "../../_services/permission.service";
import * as i8 from "../../_services/property.service";
import * as i9 from "../../_services/datetime.service";
import * as i10 from "../../_services/list.service";
import * as i11 from "@angular/common";
import * as i12 from "./levelsettings.component";
import * as i13 from "../../_services/data.service";
import * as i14 from "../../_services/alert.service";
import * as i15 from "../../_services/setting.service";
var styles_LevelSettingsComponent = [];
var RenderType_LevelSettingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LevelSettingsComponent, data: {} });
export { RenderType_LevelSettingsComponent as RenderType_LevelSettingsComponent };
function View_LevelSettingsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(16); _ck(_v, 1, 0, currVal_0); }); }
export function View_LevelSettingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "swe-card", [], null, [[null, "openChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openChange" === en)) {
        var pd_0 = ((_co.open = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CardComponent_0, i1.RenderType_CardComponent)), i0.ɵdid(1, 114688, null, 0, i2.CardComponent, [i3.LanguageService], { header: [0, "header"], open: [1, "open"], hasRefresh: [2, "hasRefresh"] }, { openChange: "openChange" }), (_l()(), i0.ɵeld(2, 0, null, 3, 1, "i", [["class", "small"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.newRegistrationEmail = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ElementComponent_0, i4.RenderType_ElementComponent)), i0.ɵdid(5, 638976, null, 0, i5.ElementComponent, [i6.AuthenticationService, i7.PermissionService, i8.PropertyService, i9.DateTimeService, i10.ListService, i3.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"], access: [4, "access"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵeld(6, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.changeEmail = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ElementComponent_0, i4.RenderType_ElementComponent)), i0.ɵdid(7, 638976, null, 0, i5.ElementComponent, [i6.AuthenticationService, i7.PermissionService, i8.PropertyService, i9.DateTimeService, i10.ListService, i3.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"], access: [4, "access"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵeld(8, 0, null, 3, 1, "swe-element", [], null, [[null, "modelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelChange" === en)) {
        var pd_0 = ((_co.changeSms = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ElementComponent_0, i4.RenderType_ElementComponent)), i0.ɵdid(9, 638976, null, 0, i5.ElementComponent, [i6.AuthenticationService, i7.PermissionService, i8.PropertyService, i9.DateTimeService, i10.ListService, i3.LanguageService], { label: [0, "label"], statusLabel: [1, "statusLabel"], type: [2, "type"], model: [3, "model"], access: [4, "access"] }, { modelChange: "modelChange" }), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_LevelSettingsComponent_1)), i0.ɵdid(11, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.languageService.getItem(463), ""); var currVal_1 = _co.open; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.languageService.getItem(460); var currVal_5 = 1; var currVal_6 = "System.String"; var currVal_7 = _co.newRegistrationEmail; var currVal_8 = _co.settingaccess; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.languageService.getItem(461); var currVal_10 = 1; var currVal_11 = "System.String"; var currVal_12 = _co.changeEmail; var currVal_13 = _co.settingaccess; _ck(_v, 7, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.languageService.getItem(462); var currVal_15 = 1; var currVal_16 = "System.String"; var currVal_17 = _co.changeSms; var currVal_18 = _co.settingaccess; _ck(_v, 9, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_19 = (_co.access > 1); _ck(_v, 11, 0, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.languageService.getItem(459); _ck(_v, 3, 0, currVal_3); }); }
export function View_LevelSettingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-levelsettings", [], null, null, null, View_LevelSettingsComponent_0, RenderType_LevelSettingsComponent)), i0.ɵdid(1, 573440, null, 0, i12.LevelSettingsComponent, [i3.LanguageService, i13.DataService, i14.AlertService, i15.SettingService], null, null)], null, null); }
var LevelSettingsComponentNgFactory = i0.ɵccf("swe-levelsettings", i12.LevelSettingsComponent, View_LevelSettingsComponent_Host_0, { id: "id", open: "open", leveldata: "leveldata" }, {}, []);
export { LevelSettingsComponentNgFactory as LevelSettingsComponentNgFactory };
