/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./list.component";
import * as i3 from "../_services/language.service";
var styles_ListComponent = [];
var RenderType_ListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListComponent, data: {} });
export { RenderType_ListComponent as RenderType_ListComponent };
function View_ListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "font-italic"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageService.getItem(996); _ck(_v, 2, 0, currVal_0); }); }
export function View_ListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.list && (_co.list.length == 0)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "swe-list", [], null, null, null, View_ListComponent_0, RenderType_ListComponent)), i0.ɵdid(1, 638976, null, 0, i2.ListComponent, [i3.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListComponentNgFactory = i0.ɵccf("swe-list", i2.ListComponent, View_ListComponent_Host_0, { data: "data", loading: "loading" }, {}, ["*"]);
export { ListComponentNgFactory as ListComponentNgFactory };
