<swe-pageheader [header]="languageService.getItem(1009)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.permissions.Templates>0">
      <swe-element [type]="'System.Text'" [label]="languageService.getItem(509)" [statusLabel]="2" [(model)]="template.Name"></swe-element>
      <div class="mb-3">
        <swe-element [bottomMargin]="0" [type]="'System.LevelSearch'" [label]="languageService.getItem(14)" [statusLabel]="2" [(model)]="template.LevelId" [display]="template.LevelName" [optional]="true"></swe-element>
        <span class="small" *ngIf="template.LevelPath&&template.LevelPath.length>0"><i>({{template.LevelPath}})</i></span>
      </div>
      <swe-element [type]="'System.Date'" [label]="languageService.getItem(396)" [statusLabel]="2" [(model)]="template.Start"></swe-element>
      <swe-element [type]="'System.Date'" [label]="languageService.getItem(397)" [statusLabel]="2" [(model)]="template.End"></swe-element>
      <label>
        <span>Inställningar</span>
      </label>
      <div class="table-responsive">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th>{{languageService.getItem(480)}}</th>
              <th>{{languageService.getItem(1010)}}</th>
              <th>{{languageService.getItem(1011)}}</th>
              <th>{{languageService.getItem(1012)}}</th>
              <th>{{languageService.getItem(1013)}}</th>
              <th>{{languageService.getItem(355)}}</th>
              <th>{{languageService.getItem(1014)}}</th>
              <th>{{languageService.getItem(1015)}}</th>
              <th>{{languageService.getItem(1016)}}</th>
              <th>{{languageService.getItem(1017)}}</th>
              <th>{{languageService.getItem(1018)}}</th>
              <th>{{languageService.getItem(1019)}}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let setting of template.Settings" [ngClass]="{'bg-danger': setting.IsDeleted, 'bg-warning': setting.IsError,'swe-disabled-block': !setting.IsActive}">
              <td style="width:150px;"><swe-element [type]="'System.String'" [label]="languageService.getItem(480)" [statusLabel]="0" [(model)]="setting.Name" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.Time'" [label]="languageService.getItem(1010)" [statusLabel]="0" [(model)]="setting.Start" [format]="'HH:mm'" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.Time'" [label]="languageService.getItem(1011)" [statusLabel]="0" [(model)]="setting.End" [format]="'HH:mm'" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:150px;"><swe-element [type]="'System.MultiList'" [label]="languageService.getItem(1012)" [statusLabel]="0" [(model)]="setting.Weekdays" [items]="weekdays" [container]="{isbit:true}" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [label]="languageService.getItem(1013)" [statusLabel]="0" [(model)]="setting.WeekNr" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.List'" [label]="languageService.getItem(355)" [statusLabel]="0" [(model)]="setting.Iteration" [items]="iterations" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [label]="languageService.getItem(1014)" [statusLabel]="0" [(model)]="setting.Quantity" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [label]="languageService.getItem(1015)" [statusLabel]="0" [(model)]="setting.Min" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td style="width:75px;"><swe-element [type]="'System.Int32'" [label]="languageService.getItem(1016)" [statusLabel]="0" [(model)]="setting.Max" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1017)" [statusLabel]="0" [(model)]="setting.ShiftType" [items]="shifttypes" [optional]="true" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1018)" [statusLabel]="0" [(model)]="setting.Activity" [items]="generalService.activitytypes" [optional]="true" [access]="setting.IsEdit?3:1"></swe-element></td>
              <td>
                <div class="table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th>{{languageService.getItem(1020)}}</th>
                        <th>{{languageService.getItem(1021)}}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let competence of setting.Competences; index as i" [ngClass]="{'bg-danger': competence.IsDeleted}">
                        <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1020)" [statusLabel]="0" [(model)]="competence.PropertyId" (modelChange)="propertyChosen($event, setting, i)" [items]="properties" [access]="competence.IsEdit?3:1"></swe-element></td>
                        <td><swe-element [(model)]="competence.Value" [label]="languageService.getItem(1021)" [type]="competence.Type" [items]="manageReload(competence)" [access]="competence.IsEdit?3:1"></swe-element></td>
                        <td>
                          <a href="javascript:void(0)" class="swe-no-link mr-2" (click)="deleteCompetence(setting, competence)" *ngIf="competence.IsEdit"><i class="fas fa-fw fa-swe" [ngClass]="{'fa-trash': !competence.IsDeleted, 'fa-undo': competence.IsDeleted}"></i></a>
                          <a href="javascript:void(0)" class="swe-no-link" (click)="competence.IsEdit=!competence.IsEdit"><i class="fas fa-fw fa-swe" [ngClass]="{'fa-edit': !competence.IsEdit, 'fa-times': competence.IsEdit}"></i></a>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="3"><a href="javascript:void(0)" class="swe-no-link" (click)="addCompetence(setting)"><i class="fas fa-fw fa-swe fa-plus-square"></i>{{languageService.getItem(100)}}</a></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </td>
              <td>
                <a href="javascript:void(0)" class="swe-no-link mr-2" (click)="deleteSetting(setting)" *ngIf="setting.IsEdit"><i class="fas fa-fw fa-swe" [ngClass]="{'fa-trash': !setting.IsDeleted, 'fa-undo': setting.IsDeleted}"></i></a>
                <a href="javascript:void(0)" class="swe-no-link" (click)="setting.IsEdit=!setting.IsEdit"><i class="fas fa-fw fa-swe" [ngClass]="{'fa-edit': !setting.IsEdit, 'fa-times': setting.IsEdit}"></i></a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="13"><a href="javascript:void(0)" class="swe-no-link" (click)="addSetting()"><i class="fas fa-fw fa-swe fa-plus-square"></i>{{languageService.getItem(100)}}</a></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div>
        <button class="btn btn-primary btn-block" (click)="save()" *ngIf="(permissionService.permissions.Templates>1)"><i class="fas fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-danger btn-block" (click)="delete()" *ngIf="id>0&&(permissionService.permissions.Templates>2)"><i class="fas fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </div>
    </swe-card>
  </div>
</div>
