<i class="fas fa-fw fa-swe fa-pull-right swe-click" [ngClass]="{'fa-history': !showhistory, 'fa-times':showhistory}" (click)="getHistory()"></i>
<div class="swe-history" *ngIf="showhistory">
  <div [ngClass]="{'swe-history-item': i>0}" *ngFor="let hist of history;index as i" [ngSwitch]="type">
    <div class="swe-history-column" *ngSwitchCase="'System.Boolean'"><i class="far fa-fw align-bottom" [ngClass]="{'fa-check-square': hist.Value, 'fa-square': !hist.Value}"></i></div>
    <div class="swe-history-column" *ngSwitchDefault>
      <div *ngFor="let val of hist.Value.toString().split('|')">{{val}}</div>
    </div>
    <div class="swe-history-column">{{hist.Extra}}</div>
  </div>
</div>
<div class="d-inline-block" *ngIf="preventdrowning"><!--Make nothing floats on top--></div>
