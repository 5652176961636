<swe-card header="{{header}}" *ngIf="timereport" [(open)]="open" (refreshChange)="load()" [focus]="true" [hasCollapse]="false" (saveChange)="save()" [hasSave]="timereport.Access>1">
  <span swe-rightcommand>
    <span class="swe-click" (click)="booking(timereport.Booking,$event)" *ngIf="id>0"><i class="fas fa-fw fa-calendar fa-pull-right"></i></span>
  </span>
  <!--Level-->
  <div class="form-group row" *ngIf="!loading&&!permissionService.HideLevels&&id==0">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fas fa-fw fa-swe fa-sitemap" [attr.title]="languageService.getItem(14)"></i>
      </div>
      <div class="swe-col-content">
        <swe-levelsearch [(model)]="timereport.LevelId" [displayname]="timereport.LevelName" [onlyone]="true" [type]="'Booking'" [disabled]="timereport.LevelAccess<2" [markChanges]="true" [storeAtClient]="true"></swe-levelsearch>
        <span class="small" *ngIf="timereport.LevelPath&&timereport.LevelPath.length>0"><i>({{timereport.LevelPath}})</i></span>
      </div>
    </div>
  </div>
  <!--Status-->
  <div class="form-group row" *ngIf="!loading&&!permissionService.HideStatus">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fas fa-fw fa-swe" [ngClass]="{'fa-clock': timereport.Status==40,'fa-check': timereport.Status==50,'fa-lock': timereport.Status==60}" [attr.title]="languageService.getItem(15)"></i>
      </div>
      <div class="swe-col-content">
        <!--Can't use swe-element cause of the disabled structure-->
        <select [attr.aria-label]="languageService.getItem(15)" class="form-control" [(ngModel)]="timereport.Status" (ngModelChange)="manageCoreChange('Status', $event)" [ngClass]="{'bg-warning': coreChanged.Status.Changed}">
          <option *ngFor="let statusObj of generalService.timereportstatus" [value]="statusObj.Id" [disabled]="managestatus(statusObj.Id)">{{statusObj.Name}}</option>
        </select>
      </div>
    </div>
  </div>
  <!--DateTimes-->
  <div class="form-group row">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fa fa-fw fa-swe" [ngClass]="{'fa-clock': !abscent, 'fa-ban': abscent}" [attr.title]="languageService.getItem(10)+' '+languageService.getItem(430)+' '+languageService.getItem(11)"></i>
      </div>
      <div class="swe-col-content">
        <swe-datetime [(start)]="timereport.Start" [(end)]="timereport.End" [labelStart]="languageService.getItem(10)" [labelEnd]="languageService.getItem(11)" [hideTime]="!permissionService.permissions.ShiftTime" [disabled]="timereport.StartAccess<2" (startChange)="manageDateTime($event)" (endChange)="manageDateTime($event)" [markChanges]="true" [undoChanges]="isReload"></swe-datetime>
      </div>
    </div>
  </div>
  <!--IncludedBreak-->
  <div class="form-group row" *ngIf="!loading&&timereport.BreakAccess>0">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fa fa-fw fa-swe fa-coffee" [attr.title]="languageService.getItem(785)"></i>
      </div>
      <div class="swe-col-content">
        <swe-breaklist [(model)]="timereport.Breaks" (modelChange)="resetBreak()" [disabled]="timereport.BreakAccess<2" [disabledStart]="timereport.BreakStartAccess<2" [minStart]="timereport.Start" [maxStart]="timereport.End" [hasBreakInfo]="true" [reset]="true" [markChanges]="true" [onlyOne]="!permissionService.permissions.MultipleBreak"></swe-breaklist>
      </div>
    </div>
  </div>
  <!--User-->
  <div class="form-group row" *ngIf="userid==0">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fa fa-fw fa-swe fa-user" [attr.title]="timereport.FirstName+' '+timereport.LastName"></i>
      </div>
      <div class="swe-col-content">
        <span>{{timereport.FirstName}}&nbsp;{{timereport.LastName}}</span>
      </div>
    </div>
  </div>
  <!--BookingType-->
  <div class="form-group row" *ngIf="userid==0">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fa fa-fw fa-swe" [ngClass]="{'fa-retweet': timereport.BookingType==0,'fa-user': timereport.BookingType==1,'fa-street-view': timereport.BookingType==2, 'fa-ban': timereport.BookingType==3}" [attr.title]="timereport.BookingTypeName"></i>
      </div>
      <div class="swe-col-content">
        <span>{{timereport.BookingTypeName}}</span>
      </div>
    </div>
  </div>
  <!--Profile-->
  <div *ngIf="!loading">
    <swe-profile [(model)]="timereport.Profile" (modelChange)="profiledone()" [id]="id"></swe-profile>
  </div>
  <swe-card [header]="languageService.getItem(112)" [color]="'swe'" [open]="false" [hasRefresh]="false" *ngIf="!loading&&timereport.ExternalAccess>0">
    <swe-element [statusLabel]="1" [optional]="true" [(model)]="timereport.ExternalCategory" [label]="languageService.getItem(517)" [items]="timereport.ExternalCategories" [type]="'System.List'"></swe-element>
  </swe-card>
  <!--Buttons-->
  <div class="mb-4" *ngIf="!loading">
    <button class="btn btn-primary btn-block" (click)="save()" *ngIf="timereport.Access>1" #sweSaveElement>{{languageService.getItem(16)}}</button>
  </div>
  <swe-card [header]="languageService.getItem(52)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="false" *ngIf="othertimereports&&othertimereports.length>1">
    <div class="row py-2">
      <div class="col-12" *ngFor="let groupby of groupedby | keyvalue">
        <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light">
          <h2>{{groupby.value.header}}</h2>
        </a>
        <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning': othertimereport.Id==settingService.lasttimereport}" *ngFor="let othertimereport of groupby.value.items" (click)="goto(othertimereport.Id, $event)">
          <div class="col-12 col-md-3">{{othertimereport.dateheader}}</div>
          <div class="col-12 col-md-6">{{othertimereport.Name}}</div>
          <div class="col-12 col-md-3">{{othertimereport.BookingTypeName}}</div>
        </a>
      </div>
    </div>
  </swe-card>
</swe-card>
