<div class="table-responsive">
  <table class="table table-responsive table-bordered">
    <tr>
      <td (click)="prev()" class="swe-click"><i class="fa fa-fw fa-caret-left"></i></td>
      <th [attr.colspan]="hasWeeknumbers?6:5" class="text-center">
        <a (click)="toggleMonth()" *ngIf="!openMonth">{{currentMonthName}}</a>
        <select [attr.aria-label]="languageService.getItem(528)" class="form-control-static" [(ngModel)]="currentMonth" (ngModelChange)="toggleMonth()" *ngIf="openMonth">
          <option *ngFor="let month of months" [value]="month.Id">{{month.Name}}</option>
        </select>
        &nbsp;
        <a (click)="toggleYear()" *ngIf="!openYear">{{currentYear}}</a>
        <select [attr.aria-label]="languageService.getItem(694)" class="form-control-static" [(ngModel)]="currentYear" (ngModelChange)="toggleYear()" *ngIf="openYear">
          <option *ngFor="let year of years" [value]="year">{{year}}</option>
        </select>
      </th>
      <td (click)="next()" class="swe-click"><i class="fa fa-fw fa-caret-right"></i></td>
    </tr>
    <tr *ngFor="let row of days">
      <td class="small swe-bg-grayed-out" *ngIf="hasWeeknumbers">{{weeknumber(row[0].date)}}</td>
      <td *ngFor="let col of row" class="swe-click text-right" [ngClass]="{'swe-grayed-out': col.outside, 'swe-disabled': disabled}" [ngStyle]="{'background-color': col.color}" (click)="select(col)">{{col.date.getDate()}}</td>
    </tr>
  </table>
</div>
