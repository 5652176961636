<swe-card header="{{languageService.getItem(29)}}" *ngIf="booking" (refreshChange)="load()" [hasSave]="booking.SaveAccess>1" (saveChange)="saveWithSerieConfirm()" [focus]="true">
  <span swe-hits>
    {{booking.ShiftLength}}
  </span>
  <span swe-rightcommand>
    <i class="fas fa-eye fa-pull-right" *ngIf="booking.IsWatching" [attr.title]="languageService.getItem(339)"></i>
    <a class="swe-no-link" href="javascript:void(0);" (click)="send(3, $event)" [attr.title]="languageService.getItem(320)" *ngIf="booking&&booking.MessageAccess>0&&hasCell"><i class="fas fa-fw fa-mobile-alt fa-pull-right"></i></a>
    <a class="swe-no-link" href="javascript:void(0);" (click)="send(1, $event)" [attr.title]="languageService.getItem(321)" *ngIf="booking&&booking.MessageAccess>0&&hasEmail"><i class="fas fa-fw fa-envelope fa-pull-right"></i></a>
  </span>
  <swe-infotext [text]="languageService.getItem(532)" [preventdrowning]="true"></swe-infotext>
  <!--Level-->
  <div class="form-group row" *ngIf="!loading&&!permissionService.HideLevels">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fas fa-fw fa-swe fa-sitemap" [attr.title]="languageService.getItem(14)"></i>
      </div>
      <div class="swe-col-content">
        <swe-levelsearch [(model)]="booking.LevelId" (modelChange)="levelchosen($event)" [displayname]="booking.LevelName" [onlyone]="true" [type]="'Booking'" [disabled]="booking.Status==60||booking.LevelAccess<2" [autoload]="booking.Autoload" [reset]="levelreset" [markChanges]="true" [storeAtClient]="true"></swe-levelsearch>
        <span class="small" *ngIf="booking.LevelPath&&booking.LevelPath.length>0"><i>({{booking.LevelPath}})</i></span>
      </div>
    </div>
  </div>
  <!--Status-->
  <div class="form-group row" *ngIf="!loading&&!permissionService.HideStatus">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fas fa-fw fa-swe swe-icon-color" [ngClass]="{'fa-share-square': booking.Status==20,'fa-key': booking.Status==30,'fa-clock': booking.Status==40,'fa-check': booking.Status==50,'fa-lock': booking.Status==60}" [attr.title]="languageService.getItem(15)" *ngIf="booking.Status>10"></i>
        <i class="far fa-fw fa-swe fa-square swe-icon-color" [attr.title]="languageService.getItem(15)" *ngIf="booking.Status==10"></i>
      </div>
      <div class="swe-col-content">
        <!--Can't use swe-element cause of the disabled structure-->
        <select [attr.aria-label]="languageService.getItem(15)" class="form-control" [(ngModel)]="booking.Status" (ngModelChange)="manageCoreChange('Status', $event)" [ngClass]="{'bg-warning': coreChanged.Status.Changed}">
          <option *ngFor="let statusObj of generalService.bookingstatus" [value]="statusObj.Id" [disabled]="(booking.Status<40 && statusObj.Id>30) || (booking.StatusAccess<3 && statusObj.Id<booking.Status) || (booking.StatusAccess<2 && statusObj.Id>booking.Status)">{{statusObj.Name}}</option>
        </select>
      </div>
    </div>
  </div>
  <!--Header-->
  <div class="row" *ngIf="!loading&&booking.HeaderAccess>0">
    <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.header}">
      <div class="swe-col-icon">
        <i class="fa fa-fw fa-swe fa-quote-right" [attr.title]="header.Name"></i>
      </div>
      <div class="swe-col-content">
        <swe-element [(model)]="booking.Header" [label]="header.Name" [propertyId]="booking.HeaderProperty" [placeholder]="header.Name" [disabled]="booking.HeaderAccess<2"></swe-element>
      </div>
    </div>
  </div>
  <!--DateTimes-->
  <div class="form-group row">
    <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.datetime}">
      <div class="swe-col-icon">
        <i class="fa fa-fw fa-swe fa-clock" [attr.title]="languageService.getItem(10)+' '+languageService.getItem(430)+' '+languageService.getItem(11)"></i>
      </div>
      <div class="swe-col-content">
        <swe-datetime [(start)]="booking.Start" [(end)]="booking.End" [labelStart]="languageService.getItem(10)" [labelEnd]="languageService.getItem(11)" [hideTime]="!permissionService.permissions.ShiftTime" [disabled]="booking.StartAccess<2" (startChange)="manageDateTime($event)" (endChange)="manageDateTime($event)" [markChanges]="true" [undoChanges]="isReload"></swe-datetime>
      </div>
    </div>
  </div>
  <!--IncludedBreak-->
  <div class="row" *ngIf="!loading&&booking.BreakAccess>0">
    <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.break}">
      <div class="swe-col-icon">
        <i class="fa fa-fw fa-swe fa-coffee" [attr.title]="languageService.getItem(785)"></i>
      </div>
      <div class="swe-col-content">
        <swe-break [(model)]="booking.Break" [(modelStart)]="booking.BreakStart" [disabled]="booking.BreakAccess<2" [disabledStart]="booking.BreakStartAccess<2" [minStart]="booking.Start" [maxStart]="booking.End" [markChanges]="true" *ngIf="!permissionService.permissions.MultipleBreak"></swe-break>
        <swe-breaklist [(model)]="booking.Breaks" [disabled]="booking.BreakAccess<2" [disabledStart]="booking.BreakStartAccess<2" [minStart]="booking.Start" [maxStart]="booking.End" [markChanges]="true" *ngIf="permissionService.permissions.MultipleBreak"></swe-breaklist>
      </div>
    </div>
  </div>
  <!--SlotAmountMax-->
  <div class="row" *ngIf="!loading&&booking.MaxAccess>0">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fa fa-fw fa-swe fa-users" [attr.title]="languageService.getItem(888)"></i>
      </div>
      <div class="swe-col-content">
        <swe-element [(model)]="booking.Max" [label]="max.Name" [propertyId]="booking.MaxProperty" [type]="'System.Int32'" [style]="'width:60px'" [disabled]="booking.MaxAccess<2"></swe-element>
      </div>
    </div>
  </div>
  <!--Owner-->
  <div class="form-group row" *ngIf="!loading&&permissionService.permissions.Owner>0">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fas fa-fw fa-swe fa-key" [attr.title]="languageService.getItem(654)"></i>
      </div>
      <div class="swe-col-content">
        <swe-usersearch [(model)]="booking.UserId" [displayname]="booking.User" [onlyone]="true" [type]="'Owner'" [disabled]="booking.Status==60||permissionService.permissions.Owner<2" [markChanges]="true"></swe-usersearch>
      </div>
    </div>
  </div>
  <!--AutoBook-->
  <div class="row" *ngIf="!loading&&booking.AutoAccess>0">
    <div class="swe-row-icon">
      <div class="swe-col-icon">
        <i class="fas fa-fw fa-swe fa-magic" [attr.title]="languageService.getItem(797)"></i>
      </div>
      <div class="swe-col-content">
        <swe-element [(model)]="booking.Auto" [label]="languageService.getItem(797)" [type]="'System.Boolean'" [container]="{markChanges: true}"></swe-element>
      </div>
    </div>
  </div>
  <!--Series-->
  <div *ngIf="!loading&&booking.SeriesAccess>0||booking.Serie.Id>0">
    <swe-card [header]="languageService.getItem(529)" [color]="'swe'" [open]="false" [hasRefresh]="false" (openChange)="loadSeries()">
      <span swe-leftcommand><i class="fas fa-link text-primary mr-2" *ngIf="booking.Serie.Id>0"></i></span>
      <swe-infotext [text]="languageService.getItem(534)" [preventdrowning]="true"></swe-infotext>
      <swe-element [(model)]="isSerie" [statusLabel]="1" [label]="languageService.getItem(251)" [disabled]="booking.Serie.Id>0" [type]="'System.Boolean'"></swe-element>
      <swe-element [(model)]="serieOption" [label]="languageService.getItem(252)" [items]="serieOptionList" [type]="'System.RadioList'" [disabled]="!isSerie||booking.Serie.Id>0"></swe-element>
      <swe-element [(model)]="booking.Serie.Type" [statusLabel]="2" [label]="languageService.getItem(529)" [items]="serieTypeList" [type]="'System.List'" [disabled]="!isSerie||booking.Serie.Id>0"></swe-element>
      <swe-element [(model)]="booking.Serie.Days" [container]="container" [statusLabel]="2" [label]="languageService.getItem(629)" [items]="serieDayList" [type]="'System.MultiList'" [disabled]="!isSerie||booking.Serie.Id>0"></swe-element>
      <div class="form-group">
        <label>{{languageService.getItem(255)}}</label>
        <div class="form-check">
          <input type="radio" class="form-check-input swe-click" [(ngModel)]="endOption" name="endoptions" [value]="1" [attr.aria-label]="languageService.getItem(255)" [disabled]="!isSerie||booking.Serie.Id>0" />
          <swe-element [(model)]="booking.Serie.End" [label]="languageService.getItem(255)" [type]="'System.Date'" [disabled]="!isSerie||booking.Serie.Id>0||endOption==0"></swe-element>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input swe-click" [(ngModel)]="endOption" name="endoptions" [value]="0" [attr.aria-label]="languageService.getItem(255)" [disabled]="!isSerie||booking.Serie.Id>0" />
          <input type="number" class="form-control w-25 d-inline" [(ngModel)]="booking.Serie.Times" [attr.aria-label]="languageService.getItem(697)" [disabled]="!isSerie||booking.Serie.Id>0||endOption==1" />
          <label class="form-check-label ml-2">{{languageService.getItem(697)}}</label>
        </div>
      </div>
      <div class="form-group" *ngIf="booking.SeriesAccess>0&&booking.Serie.Id==0&&booking.Id>0">
        <swe-element [(model)]="includeUsers" [statusLabel]="1" [label]="languageService.getItem(357)" [disabled]="!isSerie" [type]="'System.Boolean'"></swe-element>
      </div>
      <div class="form-group" *ngIf="booking.SeriesAccess>0&&booking.Serie.Id>0">
        <swe-confirm [body]="languageService.getItem(266)" (acceptChange)="deleteSerie()">
          <button class="btn btn-danger btn-block mb-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(267)}}</button>
        </swe-confirm>
      </div>
      <div class="list-group" *ngIf="booking.Serie.Id>0">
        <h2>{{languageService.getItem(299)}}</h2>
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let serie of series" [ngClass]="{'list-group-item-warning': booking.Id==serie.Id}" (click)="openSerie(serie.Id, $event)">
          <div class="col-12 col-md-5">{{serie.dateheader}}</div>
          <div class="col-12 col-md-2">{{serie.Amount}} ({{serie.Max}})</div>
          <div class="col-12 col-md-5">{{serie.Header}}</div>
        </a>
      </div>
    </swe-card>
  </div>
  <!--Activities-->
  <div *ngIf="!loading&&permissionService.permissions.Activity>0">
    <swe-card [header]="languageService.getItem(395)" [color]="'swe'" [open]="false" [hasRefresh]="false" (openChange)="loadActivities($event)">
      <span swe-leftcommand><i class="fas fa-list-ul text-primary mr-2" *ngIf="booking.HasActivities"></i></span>
      <span swe-rightcommand><i class="fas fa-plus fa-pull-right swe-click" *ngIf="permissionService.permissions.Activity>1&&activities.length>0&&!activities[0].isedit" (click)="editActivity(activities[0], $event)"></i></span>
      <div class="list-group">
        <ng-container *ngFor="let activity of activities">
          <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" (click)="editActivity(activity, $event)" *ngIf="permissionService.permissions.Activity>0 && !activity.isedit && activity.Id!=0">
            <div class="col-4 col-md-4">{{activity.dateheader}}</div>
            <div class="col-8 col-md-4"><div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': activity.Color}"></div>&nbsp;{{activity.Type}}</div>
            <div class="col-12 col-md-3">{{activity.Text}}</div>
            <div class="col-12 col-md-1"><i class="fas fa-fw fa-swe fa-trash fa-pull-right swe-click" (click)="deleteActivity(activity,$event)" *ngIf="permissionService.permissions.Activity>1"></i></div>
          </div>
          <swe-card [header]="languageService.getItem(395)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="false" *ngIf="activity.isedit">
            <span swe-rightcommand><i class="fas fa-times fa-pull-right swe-click" (click)="editActivity(activity, $event)"></i></span>
            <swe-datetime [(start)]="activity.Start" [(end)]="activity.End" [labelStart]="languageService.getItem(396)" [labelEnd]="languageService.getItem(397)" [hideTime]="!permissionService.permissions.ShiftTime"></swe-datetime>
            <swe-element [(model)]="activity.TypeId" [statusLabel]="2" [label]="languageService.getItem(398)" [items]="generalService.activitytypes" [type]="'System.ColorList'"></swe-element>
            <swe-element [(model)]="activity.Text" [statusLabel]="2" [label]="languageService.getItem(399)" [type]="'System.String'"></swe-element>
            <button class="btn btn-primary btn-block" (click)="saveActivity(activity, $event)"><i class="fas fa-fw fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
            <button class="btn btn-danger btn-block" (click)="deleteActivity(activity, $event)"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
          </swe-card>
        </ng-container>
      </div>
    </swe-card>
  </div>
  <!--Split shift-->
  <div *ngIf="!loading&&booking.SplitAccess>0&&booking.Id>0&&permissionService.permissions.ShiftTime">
    <swe-card [header]="languageService.getItem(1029)" [color]="'swe'" [open]="false">
      <swe-element [type]="'System.DateTime'" [(model)]="splittime" [statusLabel]="0" [label]="languageService.getItem(1029)"></swe-element>
      <button class="btn btn-primary btn-block" (click)="splitShift()"><i class="far fa-fw fa-object-ungroup"></i>&nbsp;{{languageService.getItem(1030)}}</button>
    </swe-card>
  </div>
  <!--EmploymentPlan-->
  <div *ngIf="!loading&&permissionService.permissions.EmploymentPlan>0&&!permissionService.permissions.HideShifts">
    <swe-element [(model)]="booking.EmploymentPlan" [label]="languageService.getItem(698)" [statusLabel]="1" [type]="'System.Boolean'"></swe-element>
  </div>
  <!--Profile-->
  <div *ngIf="!loading">
    <swe-profile [(model)]="booking.Profile" (modelChange)="profiledone()" [id]="id"></swe-profile>
  </div>
  <!--Remove ATK-->
  <div *ngIf="!loading&&permissionService.permissions.RemoveATK">
    <swe-element [(model)]="booking.RemoveATK" [statusLabel]="1" [label]="languageService.getItem(943)" [type]="'System.Boolean'"></swe-element>
  </div>
  <!--Hidden User-->
  <div class="my-2" *ngIf="!loading&&userid>0">
    <div class="btn-group">
      <span class="swe-click" (click)="checkuser=!checkuser">
        <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checkuser, 'fa-check-square': checkuser}"></i>{{languageService.getItem(281)}} {{user}}
      </span>
    </div>
    <div class="btn-group ml-2">
      <!--Email Notification-->
      <button [attr.aria-label]="languageService.getItem(920)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationemail=!checknotificationemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
        <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationemail, 'fa-check-square': checknotificationemail}"></i>
        <i class="fas fa-fw fa-swe fa-envelope"></i>
      </button>
      <!--SMS Notification-->
      <button [attr.aria-label]="languageService.getItem(921)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationsms=!checknotificationsms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
        <i class="far fa-fw fa-swe" [ngClass]="{'fa-square': !checknotificationsms, 'fa-check-square': checknotificationsms}"></i>
        <i class="fas fa-fw fa-swe fa-mobile-alt"></i>
      </button>
    </div>
  </div>
  <div *ngIf="!loading">
    <!--Buttons-->
    <!--Save-->
    <swe-confirmserie (acceptChange)="save($event)" [isSerie]="booking.Serie&&booking.Serie.Id>0" [affectAllOnly]="booking.AffectAllOnly">
      <button class="btn btn-primary btn-block mb-2" *ngIf="booking.SaveAccess>1" #sweSaveElement><i class="fas fa-fw fa-save"></i>&nbsp;{{languageService.getItem(16)}}</button>
    </swe-confirmserie>
    <!--Copy-->
    <swe-confirm *ngIf="booking.Id>0 && booking.CopyAccess>0" [body]="languageService.getItem(522)" (acceptChange)="copy()">
      <button class="btn btn-primary btn-block mb-2"><i class="fas fa-fw fa-copy"></i>&nbsp;{{languageService.getItem(477)}}</button>
    </swe-confirm>
    <!--Slots-->
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(884)" [(model)]="slots" *ngIf="permissionService.permissions.Slots&&booking.MyselfAccess==2"></swe-element>
    <!--Book MySelf-->
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="bookMyself($event, 1)" [affectAllOnly]="booking.AffectAllOnly">
      <button class="btn btn-success btn-block mb-2" *ngIf="booking.MyselfAccess==2"><i class="fas fa-calendar-plus"></i>&nbsp;{{languageService.getItem(248)}}</button>
    </swe-confirmserie>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="unbookMyself($event, 1)" [affectAllOnly]="booking.AffectAllOnly">
      <button class="btn btn-success btn-block mb-2" *ngIf="booking.MyselfAccess==3"><i class="fas fa-calendar-minus"></i>&nbsp;{{languageService.getItem(249)}}</button>
    </swe-confirmserie>
    <!--Book MySelf As Standby-->
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="bookMyself($event, 2)" [affectAllOnly]="booking.AffectAllOnly">
      <button class="btn btn-success btn-block mb-2" *ngIf="booking.MyselfStandbyAccess==2"><i class="fas fa-calendar-plus"></i>&nbsp;{{languageService.getItem(690)}}</button>
    </swe-confirmserie>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="unbookMyself($event, 2)" [affectAllOnly]="booking.AffectAllOnly">
      <button class="btn btn-success btn-block mb-2" *ngIf="booking.MyselfStandbyAccess==3"><i class="fas fa-calendar-minus"></i>&nbsp;{{languageService.getItem(691)}}</button>
    </swe-confirmserie>
    <!--Book MySelf As Abscent-->
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0&&!permissionService.permissions.DetailWhenAbscent" (acceptChange)="bookMyself($event, 3)" [affectAllOnly]="booking.AffectAllOnly">
      <button class="btn btn-success btn-block mb-2" *ngIf="booking.MyselfAbscentAccess==2"><i class="fas fa-calendar-plus"></i>&nbsp;{{languageService.getItem(941)}}</button>
    </swe-confirmserie>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="unbookMyself($event, 3)" [affectAllOnly]="booking.AffectAllOnly">
      <button class="btn btn-success btn-block mb-2" *ngIf="booking.MyselfAbscentAccess==3"><i class="fas fa-calendar-minus"></i>&nbsp;{{languageService.getItem(942)}}</button>
    </swe-confirmserie>
    <!--Book MySelf As WantToChange-->
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="bookMyself($event, 0)" [affectAllOnly]="booking.AffectAllOnly">
      <button class="btn btn-success btn-block mb-2" *ngIf="booking.ReplaceableAccess>0"><i class="fas fa-calendar-plus"></i>&nbsp;{{languageService.getItem(426)}}</button>
    </swe-confirmserie>
    <!--Delete-->
    <swe-confirmserie (acceptChange)="delete($event)" [isSerie]="booking.Serie&&booking.Serie.Id>0" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.Serie&&booking.Serie.Id>0">
      <button class="btn btn-danger btn-block mb-2" *ngIf="booking.Id>0&&booking.Access>2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirmserie>
    <swe-confirm *ngIf="!(booking.Serie&&booking.Serie.Id>0)&&booking.Id>0 && booking.Access>2" [body]="languageService.getItem(191)" (acceptChange)="delete()">
      <button class="btn btn-danger btn-block mb-2"><i class="fas fa-fw fa-trash"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>
